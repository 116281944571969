// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                            desktop query for list
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

@media screen and (min-width: 768px) {
  .abs-list-table {
    &_head {
      .abs-icon {
        margin-right: 15px;
        color: $color-brand-primary-dark;
      }
    }

    .collapse-container {
      border-top: none;
      .collapse {
        border-top: none;
      }
      .collapsing {
        border-top: none;
      }
    }

    .abs-list-table_item {
      &--name {
        font-weight: bold;
      }
    }

    .col-fr-12 {
      flex-basis: 100%;
    }
    .col-fr-11 {
      flex-basis: 91.66%;
    }
    .col-fr-10 {
      flex-basis: 83.33%;
    }
    .col-fr-9 {
      flex-basis: 75%;
    }
    .col-fr-8 {
      flex-basis: 66.66%;
    }
    .col-fr-7 {
      flex-basis: 58.33%;
    }
    .col-fr-6 {
      flex-basis: 50%;
    }
    .col-fr-5 {
      flex-basis: 41.66%;
    }
    .col-fr-4 {
      flex-basis: 33.33%;
    }
    .col-fr-3 {
      flex-basis: 25%;
    }
    .col-fr-2 {
      flex-basis: 16.66%;
    }
    .col-fr-1 {
      flex-basis: 8.33%;
    }

    &_row {
      display: flex !important;
      flex-direction: row;
      background-color: $btn-white-bg;
      color: $color-neutral-100;
      border-bottom: 1px solid $color-neutral-8;

      &--center-all {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-header {
        display: flex;
        background-color: $abs-grid-header-bg-color;
        border-bottom: 2px solid $abs-grid-bg-border-color;
      }
    }

    &_item {
      border-bottom: none !important;
      &--bold {
        font-weight: bold;
      }
      // &--black {
      //     color: $abs-link-hover-color;
      // }
      &--num {
        border-right: 1px solid $border-color;
      }
      &--right {
        text-align: right !important;
      }
    }

    &_block {
      display: block;
    }

    &_link {
      font-weight: bold;
    }

    .abs-list-table_row {
      &:hover {
        background-color: $color-neutral-2;
      }
      &-header {
        .col--header {
          font-weight: bold;
          &:not(:last-child) {
            border-right: 1px solid $color-neutral-8;
          }
        }
      }
      &--selected {
        background-color: $color-warning-light-1;
      }
    }

    .collapse-row {
      padding: 0;
      // height: 71px;
      border-top: none;
      .collapse-panel-section {
        &.collapse-state-indicator {
          margin-left: 0;
        }
        &.md {
          flex-basis: 43%;
        }
      }
    }

    .collapse-in-data {
      .collapse-row {
        padding: 8px 15px;
      }
    }

    .block-switch {
      height: 16px;
      padding: 0;
    }

    .abs-list-table-header-content {
      height: 16px;
      display: flex;
      align-items: center;

      .abs-list-table-check-name {
        margin-left: 0;
      }

      .abs-icon {
        margin-right: 6px;
        color: $color-positive-dark;
        &:before {
          font-size: 16px;
        }
      }
    }

    input.toggle + label {
      position: initial;
    }

    .abs-label {
      &:before {
        display: none;
      }
    }

    .collapse-row {
      height: 100%;
      &[aria-expanded="true"] {
        // .collapse-panel-section {
        .abs_expand_more {
          transform: rotate(-180deg);
        }
        // }
      }
    }

    .col {
      height: auto;
    }

    .abs-list-row, .abs-list-table_row {
      &--no-checkbox {
        .fw_info {
          padding-left: 0px;
        }
        .abs-list-table-check-name {
          margin-left: 0;
        }
      }
    }

    .abs-bold {
      font-weight: bold;
    }
  }
  .abs-table-container {
    padding: 0;
  }

  .abs-flex-table {
    .abs-list-table {
      .col {
        padding: 8px 15px;
        &:first-child {
          padding-left: 15px;
          display: flex;
          align-items: center;
        }
      }
      .abs-list-table-tbody {
        input.toggle-flip + label:before, input.toggle-flip + label:after {
          top: 0;
        }
      }
      .block-switch {
        padding-right: 30px;
      }
      .info {
        .fw_info {
          padding-left: 0;
          .nr {
            font-weight: bold;
          }
        }
      }
    }
    .info {
      .fw_info {
        padding-left: 0;
      }
    }
    .load-more {
      a {
        border-right: none;
        border-left: none;
      }
    }
    .collapse-row {
      .collapse-panel-section {
        &.collapse-state-indicator {
          padding: 0 0 0 10px;
        }
      }
    }
  }
}

// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                            mobile query for list
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

@media screen and (max-width: 767px) {
  .abs-list-table {
    .collapse-row {
      height: 100%;
      &[aria-expanded="true"] {
        .collapse-panel-section {
          .abs_expand_more {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .col {
      padding-left: 60px;
      &:first-child {
        padding: 0;
      }
    }

    .collapse-row {
      .col {
        padding-left: 0;
      }
      .collapse-state-indicator {
        margin-left: 0;
        padding-top: 0;
      }
    }

    &_head {
      border-bottom: 1px solid $forms-element-border-color;
      padding: 25px 0;
      margin-bottom: 10px;
    }

    &_block {
      display: none;
    }

    &_link {
      font-weight: bold;
    }

    &_row {
      display: flex;
      flex-direction: column;

      .col {
        &:first-child {
          height: 64px;
        }
      }

      &-header {
        display: none;
      }

      .collapse-state-indicator {
        order: 2;
      }
    }

    &_item {

      &:empty {
        display: none;
      }

      &--name {
        font-size: 16px;
        color: $btn-type-b-hover-bg;
        text-transform: uppercase;
        font-weight: bold;
        .light-txt {
          color: $btn-type-c-color;
          font-size: 13px;
        }
      }

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }

      &--under.col {
        padding-top: 20px;
      }
    }

    .collapse-container {
      border: none;
    }

    .abs-list-row, .abs-list-table_row {
      &--no-checkbox {
        .col:not(:first-child) {
          padding-left: 15px;
        }
        .fw_info {
          padding-left: 15px;
        }
      }
    }

    .abs-mobile-none {
      display: none;
    }
  }

  .abs-list-table.style-b {
    .abs-list-table_row {
      background-color: $text-on-color-brand-secondary;
      border-top: 2px solid $color-neutral-20;
    }
  }
}

// @media screen and (min-width: 1023px) {
//     .abs-flex-table {
//         .before-dropdown {
//             .no-checkbox {
//                 padding-left: 0;
//             }
//         }

//         .abs-list-table-check-name {
//             &.no-checkbox {
//                 margin-left: 0;
//             }
//         }
//     }
// }

// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                             general list styles
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

.abs-flex-table {
  .sortable {
    &:hover {
      background-color: $color-neutral-4;
    }
  }
}

.abs-list-table {
  &_link {
    &--bold {
      font-weight: bold;
    }
  }
  .col {
    word-break: break-all;
  }
}


.abs-list-table .col {
  word-break: normal;
}

.abs-flex-table {
  @media (min-width: $desktop-mode-min) {

    border-left: none;
    border-right: none;
  }
}

.abs-incorrect-corrective {
  word-break: break-word !important;
}
