$color-brand-primary: #CC0000;
$color-brand-primary-dark: #6F7779;
$color-brand-primary-light: #EBEBEB;
$color-brand-primary-utility-1: #9BC3D3;
$color-brand-primary-utility-2: #F0F8FA;
$text-on-color-brand-primary: #FFFFFF;
$color-brand-secondary: #6F7779;
$text-on-color-brand-secondary: #FFFFFF;
$color-brand-secondary-dark: #494E4F;
$text-on-color-brand-secondary-dark: #000000;

$abs-body-desktop-bg: #FFFFFF;
$text-color: #000000;
$abs-link-color: $text-color;

$color-brand-primary-menu: $text-on-color-brand-primary;
$text-on-color-brand-primary-menu: $color-brand-secondary-dark;
$color-brand-primary-menu-active: $color-brand-secondary;
$text-on-color-brand-primary-menu-active: $text-on-color-brand-secondary;
$color-brand-primary-menu-light: $color-brand-primary-light;
$text-on-color-brand-primary-menu-inner: $text-on-color-brand-secondary-dark;

$text-on-menu-hover: $color-brand-secondary-dark;
$text-on-menu-focus: $color-brand-secondary-dark;

$abs-menu-default-link-hover-color: #F5F5F5;
$abs-menu-default-link-hover-bg: #F5F5F5;

$btn-type-a-focus-border-color: #262829;
$btn-type-a-active-bg: #262829;
$btn-type-a-active-border-color: #262829;

$btn-type-b-bg: $color-brand-primary;
$btn-type-b-color: $text-on-color-brand-primary;
$btn-type-b-hover-bg: #000000;
$btn-type-b-hover-border-color: #000000;
$btn-type-b-color-hover: $text-on-color-brand-primary;
$btn-type-b-border-color: $color-brand-primary;
$btn-type-b-focus-bg: $color-brand-primary;
$btn-type-b-focus-border-color: #990000;
$btn-type-b-active-bg: #990000;
$btn-type-b-active-border-color: #990000;
$btn-type-b-pressed-bg: #990000;

$btn-type-d-color: #000000;
$btn-type-d-focus-border-color: $color-brand-primary-utility-1;

$sub-nav-border-color: $color-brand-primary-utility-1;
$container-details-panel-bg: $color-brand-primary-utility-2;

$abs-container-box-bg: #FFFFFF;

$abs-footer-bg: #FFFFFF;
$abs-footer-type-a-bg: #FFFFFF;
$abs-footer-type-a-color: #4C4C4C;
$abs-footer-font-color: #8A8A8A;

$progress-background: #E5E5E5;
$progress-foreground: #262829;
$icon-placeholder-background: $color-brand-primary;

$formatted-money-integer-color: #000000;
$formatted-money-fraction-color: #2A2A2A;
$formatted-money-currency-color: #2A2A2A;

$abs-dropdown-open-color: $text-on-color-brand-secondary;
$abs-dropdown-open-bg-color: $color-brand-secondary;
$abs-dropdown-open-border-color: $color-brand-secondary;

$abs-dropdown-open-color-hover: $text-on-color-brand-secondary;
$abs-dropdown-open-bg-color-hover: $color-brand-secondary;
$abs-dropdown-open-border-color-hover: $color-brand-secondary;

$quick-settings-item-color: $color-brand-secondary-dark;
$quick-settings-item-color-focus: $color-brand-secondary-dark;
$quick-settings-item-color-hover: $color-brand-secondary-dark;
$quick-settings-item-bg-color-hover: $color-brand-primary-light;
$quick-settings-item-border-color-hover: $color-brand-primary-light;
$quick-settings-icon-color: $color-brand-secondary-dark;
$quick-settings-placeholder-text-color: $color-brand-secondary-dark;
$quick-settings-placeholder-icon-color: $color-brand-secondary-dark;
$quick-settings-placeholder-text-color-active: $text-on-color-brand-secondary;
$quick-settings-placeholder-icon-color-active: $text-on-color-brand-secondary;

$fw-menu-configuration-icon-color: $color-brand-secondary-dark;

$abs-card-header-border-bottom-color: #E3E3E3;
$abs-card-header-bg-color: #E8F4F7;
$abs-card-header-bg-color-hover: #DDEFF3;
$abs-card-arrow-bg-color-hover: #DDEFF3;

$abs-menu-dropdown-open-bg-color: $color-brand-primary-utility-2;
$abs-menu-dropdown-border-width: 2px;
$abs-menu-dropdown-open-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.35);

$tab-default-bg: transparent;
$tab-default-mouseover-bg: #F5F5F5;
$bootstrap-tabs-context-color: $color-brand-primary-dark;
$bootstrap-tabs-context-color-active: $text-on-color-brand-secondary-dark;
$bootstrap-tabs-context-color-hover: $color-brand-secondary-dark;
$bootstrap-tabs-context-border-hover: $color-brand-primary-dark;
$bootstrap-tabs-context-border: $color-brand-primary-utility-1;

$tab-active-bg: $color-brand-primary-dark;
$tab-context-active-border-color: $color-brand-primary;
$tab-nested-active-bg-color: $color-brand-primary-dark;

$input-color-active: #000000;
$input-border-color: #BABABA;
$input-border-color-hover: #24708F;
$input-border-color-focus: $input-border-color-hover;
$input-border-color-active: $input-border-color-hover;
$input-box-shadow-focus: 0 0 5px 0 #24708F;
$input-shadow-color-focus: #24708F;
$input-bg-color-active: #E4F3F6;
$input-checkbox-color: #666666;
$input-checkbox-color-active: #24708F;
$input-checkbox-border-color-active:  #24708F;

$abs-list-dropdown-color: $color-brand-primary;
$input-background-color-active: #E4F3F6;
$input-border-color-active: #24708F;
$input-text-color-active: #000000;
$dropdown-item-selected-bg-color: #E4F3F6;

$dropdown-item-selected-color: #000000;
$info-bg-color: #E4F3F6;
$info-border-color: #24708F;
$info-icon-color: $color-brand-primary-utility-1;

$abs-toggle-off-line-color: #D6D6D6;
$abs-toggle-off-line-color-active: #BADFED;
$abs-toggle-off-point-color: #8A8A8A;
$abs-toggle-off-point-color-hover: #666666;
$abs-toggle-off-point-color-active: #05455F;

$abs-toggle-on-line-color: #C3DEE7;
$abs-toggle-on-line-color-active: #D6D6D6;
$abs-toggle-on-point-color: #24708F;
$abs-toggle-on-point-color-hover: #05455F;
$abs-toggle-on-point-color-active: #666666;

$abs-toast-info-background-color: #f0fafd;
$abs-toast-info-border-color: #00a4e1;

$abs-login-page-color-top: #EC0000;
$abs-login-font-color: $color-brand-secondary-dark;
$sub-nav-bg: #FFFFFF;
$login-page-loader-color-primary: $color-brand-secondary;
$sub-nav-mobile-color: $text-on-color-brand-secondary-dark;

$btn-primary-mobile-bg-color: $color-brand-secondary;
$btn-primary-mobile-color: $text-on-color-brand-secondary;
$btn-primary-mobile-bg-active: #262829;
$btn-primary-mobile-color-active: $text-on-color-brand-secondary;

$abs-prompt-border-color: $color-brand-secondary;

$timeline-circle-bg-color: #FFFFFF;
$timeline-main-color: #EC0000;
$timeline-nonactive-color: #CCCCCC;