//comment widget	

.comment{
	padding-left:25px;
	padding-right:15px;
	margin-top:30px;	


	&:last-of-type{
		margin-bottom:20px;
	}

	h2{
		margin-left:-25px;
		font-size:13px;
		font-weight:bold;
		span > span:nth-of-type(1){			
			font-size:13px;
			font-weight:bold;
			color:$comment-author-color;	
		}
		.date{
			color:$comment-date-color;
			font-weight:normal;
		}
		i{
			color:$comment-icon-color;
			font-size:18px;
			float: left;
			margin-right: 5px;
			margin-top: -2px;
		}
	}
	.client-comment , .client-comment i{
		color:$comment-client-icon-color;
	}
	.client-comment{
		>span> span:nth-of-type(1){
			color:$comment-client-icon-color;
		}
	}
	&:last-of-type{
		//border-bottom:1px solid $hr-color;
	}
	p{
		font-size:1em;	
	}

	.panel.panel-default > .list-group > .list-group-item:nth-of-type(1){
		font-size:13px;
		color:$comment-date-color;
	}
	.panel.panel-default > .list-group > .list-group-item{
		.fa-usd{
			margin-top:0px;
		}
	}
	.panel.panel-default > .list-group > .list-group-item a{
		display:inline-block;
		//padding:3px 0px 0px 0px;
	}
	.panel.panel-default > .list-group > .list-group-thumbnails{
		padding-bottom:0px;
	}
		.panel.panel-default > .list-group > .list-group-item span{
		display:inline-block;
		margin-left:5px;
		color:$comment-date-color;
	}
	.panel.panel-default > .list-group > .list-group-item > .image-thumbnail{
		padding:0px;
	}

	.panel.panel-default > .list-group > .list-group-item > .comments-file-download{
		@extend %ellipsis;
		color:$abs-link-color;		  		
  		display:inline-block;
  		float:left;  		
  		cursor:pointer;
  		&:hover{
  			text-decoration:underline;
  		}
	}	

}