// .navbar-fixed-top,
// .navbar-fixed-bottom ,
.abs-menu {

  @media (min-width: $grid-float-breakpoint) and (max-width: $grid-breakpoint-desktop-large) {
    margin-right: 20px;
    margin-left: 20px;
  }

  .navbar-collapse {
    //max-height: $navbar-collapse-max-height;
    max-height: none;
    border-top: 0px;
    box-shadow: none;
    float: right;
    right: 0;
    left: 0;
    z-index: 1030;
    order: -1;
    padding: 0px;
    flex-grow: 2;
    text-align: right;

    @media (max-width: $grid-float-breakpoint-max) {
      border-top: 1px solid $abs-menu-default-link-hover-bg;
      margin: 0px 0px 0px 0px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .k-ie &, .k-ff & {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0px;
      }
    }

    @media (max-device-width: $screen-xs-min) and (orientation: landscape) {
      max-height: 200px;
    }
  }
}


%abs-icon-placeholder {

}


.abs-menu.without-icons {
  .navbar-collapse .navbar-nav .dropdown-toggle > .abs-icon:nth-of-type(1) {
    visibility: hidden;
    width: 1px;
    margin-right: 1px;
  }
}

.abs-menu .navbar-collapse .navbar-nav.nav-without-icon .dropdown-toggle > .abs-icon:nth-of-type(1) {
  visibility: hidden;
  width: 1px;
  margin-right: 1px;
}


.abs-menu {


  > .container-fluid {
    @include flexbox();
    padding-left: 0px;
    padding-right: 0px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 0px;
      margin-left: 0px;
      display: block;
    }

    > .navbar-header {
      order: -2;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: -1px;
        //padding-top:1px;
        margin-right: 0px;
        margin-left: 0px;
      }

      > .navbar-brand {
        height: 46px;
        padding: 0px 20px 0px 20px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        fill: currentColor;
        color: $text-on-color-brand-primary-menu;
        border-right: 0px;
        @media (max-width: $grid-breakpoint-desktop-large) {
          margin-left: 0px;
        }
        // @media (min-width: $grid-float-breakpoint) {
        //   margin-left:0px;
        // }
        @media (min-width: $grid-float-breakpoint) and (max-width: $grid-breakpoint-desktop-large) {
          margin-left: -20px;
        }

        > div {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
        }

        @media (max-width: $grid-float-breakpoint-max) {
          border-right: 0px;
          position: relative;
          z-index: 1;
        }

        svg {
          margin-top: 0px;
        }

        &:hover {
          background: $abs-menu-default-link-hover-bg;
        }
      }
    }
  }


  &.fixed-menu {
    margin-left: auto;
    margin-right: auto;
    position: fixed;
  }

  .notification-alert {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include border-radius(50%);
    background: $error-circle-bg;
    min-width: 15px;
    height: 15px;
    line-height: 15px;
    position: absolute;
    top: 6px;
    right: 11px;
    font-size: 9px;

    &:not(.style-a):empty {
      display: none;
    }

    &.style-a {
      min-width: 7px !important;
      height: 7px;
      top: 11px;
      left: 30px;
      width: 7px;
    }

    > h1 {
      // display:table-cell;
      // vertical-align: middle;
      color: $abs-menu-default-color;
      font-size: 11px;
      font-weight: normal;
      padding: 0px;
      margin: 0px;

    }
  }


}


.abs-menu.navbar {
  border: 0px solid transparent;

  .material-icons, .abs-icon {
    font-size: $abs-menu-icon-size;
  }

  a {
    color: $text-on-color-brand-primary-menu-inner;
  }
}


.k-ff .abs-menu .navbar-non-collapse,
.k-ie .abs-menu .navbar-non-collapse {
  @media (max-width: $grid-float-breakpoint-max) {
    margin-right: 0px;
  }
}

.abs-menu .navbar-non-collapse {
  //position:absolute;
  //right:0;
  position: relative;
  float: right;
  // flex-grow:2;
  @include flexbox();
  @include justify-content(flex-end);
  //     flex-grow: 1;

  @media (min-width: $grid-float-breakpoint) and (max-width: $grid-breakpoint-desktop-large) {
    margin-right: -7px;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    // margin-top:- $abs-menu-height;
    margin-top: -45px;
    margin-right: 50px;
  }

  > .nav.navbar-nav > .dropdown > .dropdown-menu {
    right: 0;
    width: 50%;
  }
}

.abs-menu .navbar-non-collapse > .nav.navbar-nav > .dropdown > .dropdown-menu {
  right: 0;
  width: 50%;
}


.abs-menu .nav > li {
  @media (min-width: $grid-float-breakpoint) {
    //border-left:1px solid  $abs-menu-element-border-color;
    //border-right:   1px solid  $abs-menu-element-border-color;
    border-right: 0px;
    border-color: $abs-menu-element-border-color;
  }
}

.abs-menu .nav:nth-of-type(1) > li {
  @media (min-width: $grid-float-breakpoint) {
    // border-left:1px solid  $abs-menu-element-border-color;
  }
}


.abs-menu .nav > li.dropdown.open {
  @media (min-width: $grid-float-breakpoint) {
    position: static;
  }

}

// caret down to up animation
.abs-menu .nav > li.dropdown > a.dropdown-toggle .fa-angle-down,
.abs-menu .nav > li.dropdown > a.dropdown-toggle .abs_angle_down,
.abs-menu .nav > li.dropdown > a.dropdown-toggle .abs_expand_more {
  // font: normal normal normal 14px/1 Lumen-Icon-Font;
  // display: inline-block;
  font-size: 16px;
  margin-right: 0px;
  @media (max-width: $grid-float-breakpoint-max) {
    float: right;
    margin-top: 4px;
    font-size: 20px;
    margin-right: -2px;
  }
}

// caret down to up animation

.abs-menu .nav > li.dropdown > a.dropdown-toggle .navbar-nav-icon.abs_expand_more {
  // font: normal normal normal 14px/1 Lumen-Icon-Font;
  // display: inline-block;
  font-size: 20px;
  margin-top: 0px;
  margin-right: 0px;
  height: 46px;
  line-height: 46px;
  @media (max-width: $grid-float-breakpoint-max) {
    float: right;
    margin-top: 0px;
    font-size: 22px;
    height: 46px;
    line-height: 46px;
    margin-right: -4px;
  }
}


.abs-menu .nav > li.dropdown > a.dropdown-toggle .abs_expand_more {
  transform: rotate(0deg);
  transition: transform .2s linear;
}

.abs-menu .nav > li.dropdown.open > a.dropdown-toggle .abs_expand_more {
  transform: rotate(-180deg);
  transition: transform .2s linear;
}

.abs-menu .nav > li.dropdown > a.dropdown-toggle .fa-angle-down.anim-down,
.abs-menu .nav > li.dropdown > a.dropdown-toggle .abs_angle_down.anim-down {
  transform: rotate(0deg);
  transition: transform .2s linear;
}

.abs-menu .nav > li.dropdown.open > a.dropdown-toggle .fa-angle-down.anim-up,
.abs-menu .nav > li.dropdown.open > a.dropdown-toggle .abs_angle_down.anim-up {
  transform: rotate(180deg);
  transition: transform .2s linear;
}


//sm-collapse anim-up anim-down
.abs-menu .dropdown-header > p .fa-angle-down.anim-down,
.abs-menu .dropdown-header > p .abs_angle_down.anim-down {
  &:before {
    display: inline-block;
    transform: rotate(0deg);
    transition: transform .2s linear;
  }
}

.abs-menu .dropdown-header.expanded-header > p .fa-angle-down.anim-up,
.abs-menu .dropdown-header.expanded-header > p .abs_angle_down.anim-up {
  &:before {
    transform: rotate(-180deg);
    transition: transform .2s linear;
  }
}

//dropdown dropdown-4-level open animacja

.abs-menu .dropdown-4-level > a.dropdown-toggle .fa-angle-down.anim-down,
.abs-menu .dropdown-4-level > a.dropdown-toggle .abs_angle_down.anim-down {
  &:before {
    display: inline-block;
    transform: rotate(0deg);
    transition: transform .2s linear;
  }
}

.abs-menu .dropdown-4-level.open > a.dropdown-toggle .fa-angle-down.anim-up,
.abs-menu .dropdown-4-level.open > a.dropdown-toggle .abs_angle_down.anim-up {
  &:before {
    transform: rotate(-180deg);
    transition: transform .2s linear;
  }
}


.k-webkit .abs-menu .nav > li.dropdown.open .dropdown-menu {
  @media (min-width: $grid-float-breakpoint) {
    //display:table; width: 100%; text-align: center; left:0; right:0;
    margin-top: -1px;

  }
}

.abs-menu .nav > li.dropdown.open .dropdown-menu {
  @media (min-width: $grid-float-breakpoint) {
    //display:table; width: 100%; text-align: center; left:0; right:0;
    width: 100%;

  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin-top: -5px;
    padding-bottom: 0px;
  }

  padding-bottom: 40px;


  > .col-md-12 > .col-menu-3 > .li:nth-of-type(2) {
    @media (max-width: $grid-float-breakpoint-max) {
      //background:yellow !important;
    }
  }


  .dropdown-header {
    padding-left: 8px;
    font-weight: bold;
    color: $abs-menu-header-color;
    font-size: 13px;

    &:hover {
      background: $abs-menu-2nd-level-bg;
      border-color: $abs-menu-2nd-level-bg;
    }


    > div > span {
      //centralka
      display: flex;
    }
  }


  &.sub-child {
    @include border-radius(0);
    border: 3px solid $abs-menu-dropdown-border-color;
    padding: 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      @include box-shadow(none);
      padding: 0px;
      border: 0px;
      margin-left: 20px;
      margin-top: 0px;
      //margin-top:5px;

    }

    //left: 29%;
    //top: -50%;
    .col-sm-12 {
      padding: 0px;
    }

    a {
      padding-left: 30px;
      padding-right: 20px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-left: 0px;
      }
    }

    &:after {
      content: "\25b2";
      position: absolute;
      top: -15px;
      left: 49%;
      color: $abs-menu-default-link-hover-bg;
      transform: scaleX(1.5);
      @media (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }
  }


  .col-md-10, .col-xs-3, .col-sm-12 {

    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
      display: block;
      background: $abs-menu-3rd-level-bg;
      padding: 0px;
    }
  }


  .col-menu-3 {
    @media (max-width: $grid-float-breakpoint-max) {
      border-right: 0px;
    }

    &:last-child {
      border-right: 0px;
    }

    .dropdown-header.expanded-header {
      @media (max-width: $grid-float-breakpoint-max) {
        //background-color:$abs-menu-3rd-level-border;
        background: $abs-menu-2nd-level-bg;
        padding: 0px 0px 0px 0px;
      }

      > p {
        @media (max-width: $grid-float-breakpoint-max) {
          background-color: $abs-menu-2nd-level-mobile-bg;
          height: 43px;
          width: 100%;
          margin: 0px;
          //padding:13px 0px 10px 50px;
          padding: 13px 0px 10px 50px;
        }
      }

    }

    .dropdown-header {
      #menuSearch {
        margin: 10px 0px;
        width: 100%;
      }

      #menu_search_result_div {
        height: 100%;
        margin: 0px;
        padding: 0px;

        > ul {
          margin: 0px;
          padding: 0px 0px 0px 10px;
          list-style: none;
          white-space: nowrap;
          overflow-y: auto;
          height: auto;
        }
      }

      span {
        white-space: pre-line;
      }

      > div > ul {
        list-style: none;
        padding-left: 0px;
      }

      > p {
        margin: 0px 0px 2px 0px;

        > i.material-icons, > i.abs-icon {
          color: $abs-menu-bg;
          margin: -3px 0px 0px -27px;
          padding: 0px;
          font-size: 22px;
        }

        @media (max-width: $grid-float-breakpoint-max) {
          margin: 0px;
          width: 100%;
          //padding:13px 0px 10px 50px;
          padding: 13px 0px 10px 50px;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        margin-left: 0px;
      }
      padding-top: 30px;
      padding-bottom: 3px;
      position: relative;

      .sm-collapse {
        display: none;
      }


      @media (max-width: $grid-float-breakpoint-max) {
        //padding:13px 0px 10px 50px;
        //padding:13px 0px 10px 0px;
        padding: 0px;
        border-top: 1px solid $abs-menu-3rd-level-border;
        background-color: $abs-menu-2nd-level-bg;
        cursor: pointer;
        //background-color:$abs-menu-3rd-level-border;

        .sm-collapse {
          width: 100%;
          height: 43px;
          position: absolute;
          right: 0px;
          top: 0px;
          background: transparent;
          display: block;

          &:before {
            font-size: 20px;
            margin-right: 13px;
            margin-top: 16px;
            display: table-cell;
            float: right;
            color: $abs-menu-mobile-collapse-ico;
          }
        }
      }

    }


  }

  .col-xs-3 {
    @media (min-width: $grid-float-breakpoint) and (max-width: $grid-breakpoint-desktop) {
      width: 33%;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;
    }
  }

  li {
    padding: 0px;
    margin: 0px;

    > ul {
      padding-left: 0px;
      list-style: none;
      @media (max-width: $grid-float-breakpoint-max) {
        background: $abs-menu-3rd-level-bg;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: 60px;
    }

  }

  li.dropdown-4-level {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: 80px;
    }


    > .dropdown-toggle:focus {
      outline: 1px solid $abs-menu-4th-level-focus;
      @media (max-width: $grid-float-breakpoint-max) {
        outline: none;
      }
    }

    > .dropdown-toggle:hover:focus {
      outline: 1px solid $abs-menu-4th-level-hover-focus;
      @media (max-width: $grid-float-breakpoint-max) {
        outline: none;
      }
    }


  }

  li.dropdown-4-level.open {
    > .dropdown-toggle:focus {
      outline: none;
    }
  }

  .dropdown-4-level.open > .sub-child li {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: 0px;
    }
  }

  .dropdown-4-level.open > a {
    background: $abs-menu-4th-level-hover-focus;
    border: 1px solid $abs-menu-4th-level-focus;
    @media (max-width: $grid-float-breakpoint-max) {
      background: $abs-menu-2nd-level-mobile-bg;
      border: 0px;
      padding: 13px 0px 16px 20px;
    }

    .abs_keyboard_arrow_right2 {
      transform: rotate(-90deg);
      transition: transform .2s linear;
    }
  }

  .no-border-sm > a {
    @media (max-width: $grid-float-breakpoint-max) {
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid transparent;

      }

    }
  }

  li > a, li > .dropdown > a {
    position: relative;
    background: $abs-menu-2nd-level-bg;
    border: 1px solid $abs-menu-2nd-level-bg;
    padding: 2px 20px 2px;
    width: 100%;
    display: block;
    text-decoration: none;
    font-size: 13px;
    font-weight: normal;
    margin-left: -8px;
    outline: none;
    border-radius: 2px;

    &:focus {
      outline: 1px solid $abs-menu-4th-level-focus;
      @media (max-width: $grid-float-breakpoint-max) {
        outline: none;
      }

      &:hover:focus {
        outline: 1px solid $abs-menu-4th-level-hover-focus;
        @media (max-width: $grid-float-breakpoint-max) {
          outline: none;
        }
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      background: $abs-menu-3rd-level-bg;
      border-color: $abs-menu-3rd-level-bg;
      color: $sub-nav-bg;
      border-bottom: 1px solid $abs-menu-3rd-level-border;
      padding: 12px 0px 12px 0px;
      margin-left: 0px;
    }

    > .fa-angle-right, > .abs_angle_right,
    .abs_keyboard_arrow_right2 {
      font-size: 1.3em;
      // margin-left: 1px;
      // margin-right: 0px;
      // margin-top: -13px;
      transform: rotate(0deg);
      transition: transform .2s linear;
      position: absolute;
      left: 1px;

      @media (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }

    &:hover {
      background: $abs-menu-4th-level-hover-focus;
      border: 1px solid $abs-menu-4th-level-focus;
      text-decoration: none;
      @media (max-width: $grid-float-breakpoint-max) {
        background: $abs-menu-3rd-level-bg;
        border-color: $abs-menu-3rd-level-bg;
        color: $abs-menu-header-color;
        border-bottom: 1px solid $abs-menu-3rd-level-border;
        // padding:12px 0px 12px 30px;
      }
    }

  }

  .add-to-fav {
    display: none;
    @media (max-width: $grid-float-breakpoint-max) {
      display: block;
    }

    .fa-star, .abs_star {
      font-size: 15px;
      position: absolute;
      //right:7px;
      //top:4px;
      right: 2px;
      top: 0px;
      width: 23px;
      height: 22px;
      line-height: 22px;
      @media (max-width: $grid-float-breakpoint-max) {
        top: 0px;
        right: 0px;
        width: 40px;
        height: 40px;
      }

      &:before {
        position: absolute;
        top: 4px;
        right: 5px;
        @media (max-width: $grid-float-breakpoint-max) {
          top: 14px;
          right: 12px;
        }
      }

      z-index: 998;
      color: $abs-menu-star-bg;
      margin: -4px;
    }

    .fa-star-o, .abs_star_o {
      font-size: 15.5px;
      position: absolute;
      //right:7px;
      //top:4px;
      right: 2px;
      top: 0px;
      width: 23px;
      height: 22px;
      line-height: 22px;
      @media (max-width: $grid-float-breakpoint-max) {
        top: 0px;
        right: 0px;
        width: 40px;
        height: 40px;
      }

      &:before {
        position: absolute;
        top: 4px;
        right: 5px;
        @media (max-width: $grid-float-breakpoint-max) {
          top: 14px;
          right: 12px;

        }
      }

      z-index: 999;
      color: $abs-menu-star-border;
      margin: -4px;

    }

    .clicked-star {
      color: $abs-menu-star-clicked-bg;
    }

    .clicked-star-o {
      color: $abs-menu-star-clicked-border;
    }
  }

  li:hover > a > .add-to-fav {
    display: flex;
    float: right;
  }

  li > .dropdown:hover a {
    text-decoration: none;
  }

  li > .dropdown a > .fa-angle-right,
  li > .dropdown a > .abs_angle_right,
  li > .dropdown a > .abs_keyboard_arrow_right2 {
    position: absolute;
    left: 8px;
  }

}


.abs-menu .navbar-non-collapse > .nav > li.dropdown.open .dropdown-menu {
  @media (min-width: $grid-float-breakpoint) {
    //display:table; width: 100%; text-align: center; left:0; right:0;
    width: 50%;
    right: 0;
    left: inherit;
  }
}


.abs-menu .dropdown-menu > li {
  @media (min-width: $grid-float-breakpoint) {
    display: table-cell;
  }
}

.abs-menu .action-button .dropdown-menu li {
  @media (min-width: $grid-float-breakpoint) {
    display: block;
  }
}


.abs-menu .navbar-nav {
  margin: ($navbar-padding-vertical / 2) (-$navbar-padding-horizontal);

  > li > a {
    // padding-top:    10px;
    // padding-bottom: 10px;
    // line-height: $line-height-computed + 5;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 46px;
    line-height: 46px;
    white-space: nowrap;
    overflow: hidden;
    //text-overflow: ellipsis;
    .active {
      background: $sub-nav-bg;
    }

    &:focus {
      outline: 0;

    }
  }


  @media (max-width: $grid-float-breakpoint-max) {

    margin-top: 1px;
    margin-bottom: 1px;
    > li {
      //border-bottom:1px solid $abs-menu-element-border-color;
      border-bottom: 0px;

    }

    > li > a > .fa:nth-of-type(1) {
      margin-right: 13px;
      font-size: 1.5em;
      float: left;
      margin-top: 3px;
    }
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;

      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }


      > li > a {
        line-height: $line-height-computed;

        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: $grid-float-breakpoint) {
    // float: right;
    //float:right;
    margin: 0 0 0 -4px;
    float: none;
    height: 42px;
    display: inline-block;

    > li {
      float: left;

      > a {
        // padding-top:    $abs-menu-padding-vertical;
        // padding-bottom: $abs-menu-padding-vertical;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 46px;
        line-height: 46px;
        padding-left: $abs-menu-padding-horizontal;
        padding-right: $abs-menu-padding-horizontal;
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
          padding-left: $abs-menu-padding-horizontal - 5;
          padding-right: $abs-menu-padding-horizontal -5;

        }

      }

      > a.active {
        background: $sub-nav-bg;
      }

      .material-icons, .abs-icon {
        margin-top: 1px;
        margin-right: 13px;
        float: left;
      }

      > span {
        display: inline-block;
        float: left;
        margin-top: 3px;
      }

      > .fa:nth-of-type(1) {
        margin-right: 13px;
        font-size: 1.5em;
        float: left;
        margin-top: 3px;

        &.only-icon-view {
          margin-right: 0px
        }
      }

      .fa:nth-of-type(2), .abs-icon:nth-of-type(2) {
        // font: normal normal normal 14px/1 Lumen-Icon-Font;
        //  display: inline-block;
        margin-left: $abs-menu-dropdown-right-icon-margin;
        margin-top: 4px;
      }
    }
  }
}

%gradient-mask-for-nav {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 100%;
  bottom: 0px;
  background: -webkit-linear-gradient(left, transparent, $abs-menu-bg, $abs-menu-bg);
  background: -o-linear-gradient(right, transparent, $abs-menu-bg, $abs-menu-bg);
  background: -moz-linear-gradient(right, transparent, $abs-menu-bg, $abs-menu-bg);
  background: linear-gradient(to right, transparent, $abs-menu-bg, $abs-menu-bg);
  @media (max-width: $grid-float-breakpoint-max) {
    background: -webkit-linear-gradient(left, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
    background: -o-linear-gradient(right, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
    background: -moz-linear-gradient(right, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
    background: linear-gradient(to right, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
  }
}

%gradient-mask-for-nav-hover {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 100%;
  bottom: 0px;
  background: -webkit-linear-gradient(left, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
  background: -o-linear-gradient(right, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
  background: -moz-linear-gradient(right, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);
  background: linear-gradient(to right, transparent, $abs-menu-default-link-hover-bg, $abs-menu-default-link-hover-bg);


}


.abs-menu .navbar-collapse .navbar-nav {

  > li {
    @media (max-width: $grid-float-breakpoint-max) {
      border-bottom: 1px solid $abs-menu-sm-border-color;
      margin-top: -1px;
      background: $abs-menu-default-link-active-bg;
    }

    &.open > a {
      @media (max-width: $grid-float-breakpoint-max) {
        background: $abs-menu-mobile-a-bg;
      }
    }
  }

  > li > a[aria-expanded="true"] {
    > span.with-mask-dropdown:after {
      @extend %gradient-mask-for-nav-hover;
      @media (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }

  }


  > li > a > span {
    overflow: hidden;
    max-width: 180px;
    overflow: hidden;
    float: left;
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: $grid-float-breakpoint-max) {
      max-width: 100%;
      font-weight: bold;
    }
  }

  > li > a > span.with-mask:after {
    @extend %gradient-mask-for-nav;
    right: 0px;
  }

  > li > a > span.with-mask-dropdown:after {
    @extend %gradient-mask-for-nav;
    right: 30px;

  }

  .navbar-nav-icon {
    margin-top: 0px;
    margin-right: 13px;
    height: 46px;
    line-height: 46px;
    float: left;

    &.only-icon-view {
      margin-right: 0px
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
      margin-right: 2px;
    }
  }

  // .material-icons , .abs-icon{
  //     margin-top:0px;
  //     margin-right:13px;
  //     height:46px;
  //     line-height:46px;
  //     float:left;
  //     &.only-icon-view{
  //       margin-right:0px
  //     }

  //     @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  //       margin-right:2px;
  //      }

  // }
  > span {
    display: inline-block;
    float: left;
    margin-top: 3px;
  }

}

.abs-menu .navbar-collapse.only-icon {
  > .navbar-nav > li > a {
    padding-left: 9px;
    padding-right: 9px;
  }

  > .navbar-nav > li > a > span {
    // display:none;
    font-size: 11px;
  }

  .navbar-nav > li > a > .abs-icon:nth-of-type(1) {
    font-size: 18px;
    margin-right: 5px;
  }

  > .navbar-nav > li > a > .abs-icon:nth-of-type(1) {
    // margin-right:2px;

  }

  > .navbar-nav > li > a > .fa:nth-of-type(1) {
    margin-right: 0px;
  }
}

.abs-menu.without-icons .navbar-collapse.only-icon > .navbar-nav > li > a {
  padding-left: 10px;
  padding-right: 10px;

  > span.with-mask-dropdown:after {
    content: none;
  }

  > span {
    display: inline;
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}


.navbar-collapse .navbar-nav > li > a:hover, .navbar-collapse .navbar-nav > li > a:focus {
  > span.with-mask:after, > span.with-mask-dropdown:after {
    @extend %gradient-mask-for-nav-hover;
  }
}


.abs-menu .navbar-non-collapse > .navbar-nav {


  //dla ihd
  .action-button {
    border-left: 0px !important;

    .dropdown.open .dropdown-menu {
      top: 23px;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .dropdown.open button {
        float: right;
      }
      .dropdown.open .dropdown-menu {
        @include box-shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.3));
        background: transparent;
        position: absolute;
        top: 32px;
        border: 1px solid $abs-menu-bg;
        left: 0px;
        width: 198px;
        overflow-y: auto;
        max-height: 200px;
      }
      .dropdown.open .dropdown-menu li:nth-of-type(1) {
        > a {
          //border-top:4px solid $abs-menu-bg;
        }
      }
      .dropdown.open .dropdown-menu li > a {
        white-space: pre-line;
        background: $abs-menu-2nd-level-bg;
        color: $abs-menu-header-color;
        padding: 8px 10px 6px;

        &:hover {
          background: $forms-elements-mouseover-bg;
        }

      }
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    float: left;
    margin: 0px 0px 0px 0px;

    &:nth-of-type(1) {
      > li {
        border-left: 1px solid $abs-menu-element-border-color;
      }
    }

    > li {
      float: left;
      //border-right:   1px solid  $abs-menu-element-border-color;
      border-color: $abs-menu-element-border-color;

      > a {
        // padding-top:    $navbar-padding-vertical;
        // padding-bottom: $navbar-padding-vertical - 5;
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .material-icons, .abs-icon {
        margin-top: 0px;
        height: 46px;
        line-height: 46px;
      }

      .fa:nth-of-type(1) {
        margin-right: 0px;
        font-size: 1.3em;
      }

      .fa:nth-of-type(2) {
        margin-left: $abs-menu-dropdown-right-icon-margin;
      }
    }

  }

  > .action-button {
    height: 40px;
    padding: 0px 10px;

    button {
      background: none;
      border: 0px;
      color: $abs-menu-2nd-level-bg;

      i {
        margin-top: 0px;
      }

      &:hover, &:active {
        color: $abs-menu-action-button-hover;
      }

      .fa.fa-angle-down {
        margin-top: 3px;
        margin-left: 5px;

        &:before {
          position: absolute;
          top: 5px;
        }
      }
    }

    .dropdown-type-c {
      margin-top: 8px;


    }

  }

  // Uncollapse the nav
  @media (min-width: $grid-float-breakpoint) {
    // float: left;
    float: none;
    display: inline-block;
    height: 42px;
    margin: 0 0 0 -4px;


    > li {
      float: left;

      > a {
        // padding-top:    $navbar-padding-vertical + 1;
        // padding-bottom: $navbar-padding-vertical + 2;
        // padding-top:12px;
        // padding-bottom:11px;
        height: 46px;
        line-height: 46px;
        padding-top: 0px;
        padding-bottom: 0px;
      }

      // .material-icons, .abs-icon{
      //   margin-right:0px;
      //   margin-top:0px;
      //   height:46px;
      //   line-height:46px;
      // }
      .navbar-nav-icon {
        margin-top: 0px;
        margin-right: 0px;
        height: 46px;
        line-height: 46px;
        color: $text-on-color-brand-primary-menu;
      }

      .fa:nth-of-type(1) {
        margin-right: 0px;
        font-size: 1.3em;
      }

      .fa:nth-of-type(2) {
        margin-left: $abs-menu-dropdown-right-icon-margin;
      }
    }
  }
}


.abs-menu .navbar-toggle {
  @include border-radius(0px);
  color: $abs-menu-default-color;
  padding: 0px 14px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 46px;
  line-height: 46px;
  //border-color:transparent;
  border: 0px;
  background: $abs-menu-default-link-hover-bg !important;

  &.collapsed {
    background: $abs-menu-bg !important;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    //margin-right:$abs-menu-hamburger-right-margin;
    margin-right: 0px;
  }

  &:hover {
    //background:$abs-menu-default-link-hover-bg;
  }

}

.iphone .abs-menu .navbar-toggle.collapsed {
  padding-bottom: 12px;
}

// Menu position and menu carets
.abs-menu .navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  @include border-radius(0);
  border: 3px solid $abs-menu-dropdown-border-color;

  .k-ie &, .k-ff & {
    margin-top: -1px;
  }
}


.abs-menu .nav > li.dropdown.open > .dropdown-menu li.dropdown-4-level > a {
  .sm-collapse {
    display: none
  }

  @media (max-width: $grid-float-breakpoint-max) {
    padding: 12px 0px 11px 0px;
    border: 1px solid $abs-menu-2nd-level-mobile-border;
    font-weight: bold;
    color: $abs-menu-header-color;

    .sm-collapse {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      background: transparent;
      display: block;

      &:before {
        font-size: 20px;
        margin-right: 13px;
        margin-top: 13px;
        display: table-cell;
        float: right;
      }
    }


  }


}

.abs-menu .nav > li.dropdown.open > .dropdown-menu li.dropdown-4-level {
  @media (max-width: $grid-float-breakpoint-max) {
    border-bottom: 1px solid $abs-menu-3rd-level-border;
  }

  > a > .abs_angle_down:before {
    margin-top: 10px !important;
    margin-right: -1px !important;
  }
}

.abs-menu .nav > li.dropdown.open > .dropdown-menu li.dropdown-4-level.open {
  @media (max-width: $grid-float-breakpoint-max) {
    border-bottom: 0px;
    margin-top: -1px;
    margin-left: 65px;
  }

  > a {
    @media (max-width: $grid-float-breakpoint-max) {
      border: 1px solid $abs-menu-3rd-level-border;
      //padding:12px 0px 14px 15px;
      padding: 13px 0px 11px 15px;
    }
  }

  .dropdown-menu.sub-child > li > a {
    padding-left: 30px;
  }
}

.abs-menu .nav > li.dropdown.open > .dropdown-menu {

  .hide-level {
    display: none;
    @media (min-width: $grid-float-breakpoint) {
      display: block;
    }
  }

  .show-level {
    display: block;
    @media (min-width: $grid-float-breakpoint) {
      display: block;
    }
  }

  [data-id] {
    display: none;
    @media (min-width: $grid-float-breakpoint) {
      display: block !important;
    }
  }
}

.search-mobile-nav {
  background: $abs-menu-default-link-hover-bg;
  margin-top: 0px !important;

  > li > span {
    width: 97%;
    margin: 8px auto;
    display: block;
    position: relative
  }

  > li > span > input {
    @include border-radius(0px);
    border-color: $abs-menu-2nd-level-bg;
    width: 100%;
    height: 36px;
    padding-left: 20px;
    padding-right: 30px;
    font-size: 14px;
  }

  > li > span > input[type=text]::-ms-clear {
    display: none;
  }

  > li > span > i.search-icon {

    position: absolute;
    left: 8px;
    top: 5px;
    color: $abs-menu-search-ico;
    font-size: 22px;
  }

  > li > span > i.close-icon {

    position: absolute;
    right: -7px;
    top: 5px;
    color: $abs-menu-close-ico;
    font-size: 22px;
  }

  > li > .resultsDiv {
    padding: 0px;

    > ul {
      padding: 0px;
      background: $abs-menu-2nd-level-bg;
    }

    > ul > li {
      list-style: none;
      width: 100%;
      height: 43px;
      border-bottom: 1px solid $abs-menu-3rd-level-border;
      position: relative

    }

    > ul > li > a {
      margin: 0px;
      width: 100%;
      padding: 13px 0px 10px 18px;
      display: block;
      text-decoration: none;

      &:hover {
        color: $abs-menu-header-color;
      }
    }

    .add-to-fav {
      display: none;
      @media (max-width: $grid-float-breakpoint-max) {
        display: block;
      }


      .fa-star, .abs_star {

        font-size: 15px;
        position: absolute;
        right: 7px;
        top: 4px;
        @media (max-width: $grid-float-breakpoint-max) {
          top: 0px;
          right: 0px;
          width: 40px;
          height: 40px;
        }

        &:before {
          @media (max-width: $grid-float-breakpoint-max) {
            top: 14px;
            right: 12px;
            position: absolute;
          }
        }

        z-index: 2001;
        color: $abs-menu-star-bg;
        margin: 0px;
      }

      .fa-star-o, .abs_star_o {

        font-size: 15.5px;
        position: absolute;
        right: 7px;
        top: 4px;
        @media (max-width: $grid-float-breakpoint-max) {
          top: 0px;
          right: 0px;
          width: 40px;
          height: 40px;
        }

        &:before {
          @media (max-width: $grid-float-breakpoint-max) {
            top: 14px;
            right: 12px;
            position: absolute;
          }
        }

        z-index: 2002;
        color: $abs-menu-star-border;
        margin: 0px;

      }

      .clicked-star {
        color: $abs-menu-star-clicked-bg;
      }

      .clicked-star-o {
        color: $abs-menu-star-clicked-border;
      }
    }

    li:hover > a > .add-to-fav {
      display: flex;
      float: right;
    }
  }
}

.hidden-menu {
  visibility: hidden !important;
}

.ipad .abs-menu .nav > li.dropdown.open .dropdown-menu .col-xs-3.col-menu-3.hide-mobile {
  display: block !important;
}

.ipad .abs-menu .nav > li.dropdown.open .dropdown-menu .add-to-fav {
  display: block;
}


.mac .navbar-nav > .dropdown.open > .ios-visible,
.ipad .navbar-nav > .dropdown.open > .ios-visible {
  visibility: visible;
}

.mac .navbar-nav > .dropdown.open > .ios-unvisible,
.ipad .navbar-nav > .dropdown.open > .ios-unvisible {
  visibility: hidden;
}

/*
.ipad .navbar-nav > .dropdown.open > .dropdown-menu.sub-parent,
.mac .navbar-nav > .dropdown.open > .dropdown-menu.sub-parent{
  //display:block !important;
  visibility:visible !important;
  //padding:100px;
}
.ipad .navbar-nav > .dropdown > .dropdown-menu.sub-parent,
.mac .navbar-nav > .dropdown > .dropdown-menu.sub-parent{
  //display:none !important;
  visibility:hidden  !important;
}
*/


//testowe pushdownsy

.pushdowns {
  //display:none;

  max-height: 0;
  transition: max-height .2s ease-out;

  .pushdown-panel {
    opacity: 0;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    background: $abs-menu-bg !important;
  }
}

.pushdowns.is-visible {
  max-height: 40px;
  transition: max-height .2s ease-in;
  @media (max-width: $grid-float-breakpoint-max) {
    background: $abs-menu-bg;
  }

  .pushdown-panel {
    opacity: 1;
  }


}


.pushdown-panel {
  position: absolute;
  right: 0px;
  top: 40px;
  transition: top .2s ease-out;
}


.pushdown-panel.open {
  position: absolute;
  right: 0px;
  top: 0px;
  transition: top .2s ease-in;
}


// wersja menu gdy nie ma ikonek
.abs-menu.without-icons .navbar-collapse .navbar-nav .dropdown-toggle > .abs-icon:nth-of-type(1) {
  visibility: hidden;
  width: 1px;
  margin-right: 1px;
}

.abs-menu .navbar-collapse .navbar-nav.nav-without-icon .dropdown-toggle > .abs-icon:nth-of-type(1) {
  visibility: hidden;
  width: 1px;
  margin-right: 1px;
}


//abs-menu-type-a faktor web

.abs-menu.only-icons-view {
  @media (min-width: $grid-float-breakpoint) {
    .navbar-collapse .navbar-nav > li > a > span {
      display: none;
    }
    .navbar-collapse .navbar-nav .abs-icon {
      margin-right: 0px;
    }
  }
}


.abs-menu.abs-menu-type-a {

  .navbar-collapse {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: -1px;
    }
  }

  .navbar-non-collapse {
    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
      margin-right: 50px;
    }
    @media (min-width: $grid-float-breakpoint) and (max-width: $grid-breakpoint-desktop-large) {
      margin-right: 0px;
    }

    > .navbar-nav {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0px;
      }
    }

    > .navbar-nav:nth-of-type(1) > li {
      border-left: 0px;
    }

    > .navbar-nav:nth-last-of-type(1) {
      @media (max-width: $grid-float-breakpoint-max) {
        // margin-right:50px;
      }
    }

    > .navbar-nav > .dropdown.open {
      @media (max-width: $grid-float-breakpoint-max) {
        position: static;
      }

      .dropdown-toggle {
        float: right;
      }

      > .dropdown-menu {
        width: auto;
        @include border-radius(0);
        border: 3px solid $abs-menu-dropdown-border-color;
        z-index: 1050;
        @media (max-width: $grid-float-breakpoint-max) {
          right: -50px !important;
          width: 100%;
          min-width: 100%;
          position: absolute;
          margin-top: 0;
        }

        > div {
          padding: 0px;
        }
      }
    }
  }

  .nav > li.dropdown.open {
    @media (min-width: $grid-float-breakpoint) {
      position: relative;
    }
  }

  .nav > li.dropdown.open .dropdown-menu li > a, .nav > li.dropdown.open .dropdown-menu li > .dropdown > a {
    margin-left: 0px;
    padding: 6px 12px;
    min-width: 250px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 12px 12px 12px 50px;
      border-radius: 0px;
    }
  }

  .nav > li.dropdown.open .dropdown-menu li {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: 0px;
    }
  }

  .nav > li.dropdown.open > .dropdown-menu {
    width: auto;
    padding: 0px;
    margin-top: -3px;
    display: flex;

    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 0px;
      flex-direction: column;

    }

    > div {
      padding: 15px;
      width: 290px;
      min-width: 290px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
        margin: 0px;
        width: 100% !important;
      }
    }

    &.with-actions {
      min-width: 460px;

      @media (max-width: $grid-float-breakpoint-max) {
        min-width: 100%;
      }

      &.with-slider {
        @include flexbox();
        @include justify-content(flex-start);
        @include align-items(flex-start);
        min-width: 650px;
        @media (max-width: $grid-float-breakpoint-max) {
          min-width: 100%;
          display: block;
        }

        > div:nth-of-type(1) {
          width: 40%;
        }

        > div:nth-of-type(2) {
          width: 60%;
          padding: 20px 0px 0px 0px;

          li > button, li > .menu-action {
            width: 70%;
            margin-bottom: 5px;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }

          // li:nth-last-of-type(1) > button{
          //   @media (max-width: $grid-float-breakpoint-max) {
          //     margin-bottom:0px;
          //   }
          // }
        }


        .carousel {
          margin-top: 20px;
          border-top: 1px solid $abs-menu-type-a-inside-border-color;
          padding-top: 20px;
          @media (max-width: $grid-float-breakpoint-max) {
            padding-bottom: 20px;
          }

          .carousel-inner > .item {
            text-align: center;
            // margin-left:40px;
            // margin-right:40px;
            > button {
              width: auto;
              padding: 3px 20px;
            }

            span {
              display: block;
              font-weight: bold;
              font-size: 13px;
              margin-bottom: 0px;
            }

            a {
              display: block;
              margin-bottom: 10px;
              font-size: 20px;
            }

          }

          .carousel-control.right, .carousel-control.left {
            background-image: none;
            text-shadow: none;
            background-color: $abs-menu-type-a-inside-bg;
            opacity: 1;
            width: 40px;
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);

            i {
              color: $abs-menu-type-a-carousel-icon-color;
              margin: 0px;
              padding: 0px;
              font-size: 27px;
            }
          }

        }


      }

      > div {
        float: left;
        width: auto;

        &:nth-of-type(1) {
          border-right: 1px solid $abs-menu-type-a-inside-border-color;
          width: 55%;
        }

        &:nth-of-type(2) {
          @include flexbox();
          @include flex-direction(column);
          @include justify-content(flex-start);
          @include align-items(stretch);
          width: 45%;
          background: $abs-menu-type-a-inside-bg;
          align-self: normal;

          // li:nth-last-of-type(1) > button{
          //   @media (max-width: $grid-float-breakpoint-max) {
          //     margin-bottom:20px;
          //   }
          // }

          button, .menu-action {
            margin-bottom: 5px;
            margin-left: auto;
            margin-right: auto;

            display: block;
            width: 100%;

            @media (max-width: $grid-float-breakpoint-max) {
              width: 70%;
            }

            @media (max-width: $grid-float-breakpoint-max) {
              margin-bottom: 10px;
            }
          }

          .abs-dropdown button {
            display: flex;
            justify-content: center;
          }

          @media (max-width: $grid-float-breakpoint-max) {
            padding: 20px 20px 10px 20px;
          }
        }

      }
    }
  }

  .nav > li.dropdown.open .dropdown-menu .abs-dropdown {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 10px;
    }

    button {
      margin-bottom: 0px !important;

    }

    button > .abs-icon {
      float: right;
      margin: -1px 0px 0px 0px;
      height: auto;
    }

    .dropdown-menu {
      right: 0px;
      left: inherit;
      min-width: 240px;
      padding: 2px 4px;
      @media (max-width: $grid-float-breakpoint-max) {

        @include box-shadow(0px, 2px, 3px, 0px, $context-menu-border);
        border: 1px solid $context-menu-border;
        min-width: 100%;
        width: 90%;
        margin: 0px auto 5px auto;


      }

      > li {
        display: block;

        a {
          @include border-radius(0px);
          color: $context-menu-link-color;
          @media (max-width: $grid-float-breakpoint-max) {
            border: 1px solid transparent;
            padding: 7px 12px 7px 12px;
            background: $abs-menu-type-a-inside-bg;
          }

          &:hover {
            background: $context-menu-hover-bg;
            border: 1px solid $context-menu-hover-bg;
          }
        }
      }
    }
  }
}

.abs-menu.navbar-default .navbar-nav > .dropdown > a {
  color: $text-on-color-brand-primary-menu;
}

.abs-menu.navbar-default .navbar-nav > .dropdown > a:hover,
.abs-menu.navbar-default .navbar-nav > .dropdown > a:hover .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:hover .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .dropdown > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .dropdown > a:active .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:active .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a:hover .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:hover .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a:active .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:active .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a,
.abs-menu.navbar-default .navbar-nav > .open > a:hover,
.abs-menu.navbar-default .navbar-nav > .open > a:focus {

  color: $text-on-color-brand-primary-menu-active;
}


$max-width-navbar: $grid-breakpoint-desktop-large + 10px;

.abs-menu.navbar {
  .container-fluid {
    @media (min-width: $grid-breakpoint-desktop-large) {
      max-width: $max-width-navbar;
      margin: 0 auto;
    }
  }

  @media (min-width: $grid-float-breakpoint-max) and (max-width: 1280px) {
    .dropdown-toggle .dropdown-title-span {
      display: none;
    }
  }
}

.abs-menu.abs-menu-type-a .nav .dropdown.btn-group {
  width: 100%;
}

.abs-menu .navbar-non-collapse > .navbar-nav > li .abs-icon.abs-btn-icon_icon {
  height: auto;
  line-height: 18px;
  font-size: 18px;
  margin: 0;
  float: none;
}

/* nadpisane style dla ikon wewnątrz elementów menu */

.abs-menu.abs-menu-type-a .nav > li.dropdown.open > .dropdown-menu.fw-alerts > div {
  width: 343px;
  min-width: 343px;
}

.abs-menu .navbar-non-collapse > .navbar-nav > li .abs-icon.fw-alerts__show-details-icon {
  margin-right: 6px;
}

.abs-menu .navbar-non-collapse > .navbar-nav > li .fw-alerts__empty .abs-icon {
  font-size: 40px;
}

.abs-menu .navbar-collapse .navbar-nav > li {
  .dropdown-title-span {
    font-size: 14px;
    max-width: 230px;
  }
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open {
  > .dropdown-toggle {
    @media (max-width: $mobile-mode-max) {
      box-shadow: 0 1px 8px 0 grey;
    }
  }

  .dropdown-menu {
    z-index: 100;

    > div.actions-panel {
      display: flex;
      flex-direction: column;
      background: $abs-menu-dropdown-open-bg-color;
      width: auto;
      min-width: auto;
      align-items: stretch;

      li {
        .btn {
          margin-left: auto;
          margin-right: auto;
          display: block;

          .btn-label, .btn-additional-label {
            font-size: 13px;
          }

          &.btn--uppercase {
            .btn-label {
              text-transform: uppercase;
            }
          }
        }
      }

      .abs-dropdown {
        @media (max-width: $mobile-mode-max) {
          margin-bottom: 0;
        }

        .btn {
          height: 35px;
          min-width: 148px;
          justify-content: space-between;
          font-size: 13px;
        }

        .dropdown-menu {
          @media (max-width: $mobile-mode-max) {
            width: 251px;
            min-width: 251px;
            border-color: $color-brand-primary-utility-2;
            > li a {
              white-space: initial;
              color: $color-brand-primary-dark;
              font-weight: 500;
            }
          }
        }

        button > .abs-icon {
          @media (max-width: $mobile-mode-max) {
            margin-left: 5px;
          }
        }
      }

      &:empty {
        display: none;
      }
    }

    @media (min-width: $desktop-mode-min) {
      width: auto;

      > div.actions-panel {
        border-left: 1px solid $color-neutral-11;

        .dropdown-menu {
          padding: 0 2px;
        }

        li .btn {
          margin-bottom: 7px;
          width: 100%;
        }
      }
    }

    @media (max-width: $mobile-mode-max) {
      > div.actions-panel {
        padding: 11px 20px 10px 20px;
        border: 1px solid $color-neutral-11;

        li .btn {
          margin-bottom: 10px;
          width: 70%;
        }
      }
    }

    @include box-shadow($abs-menu-dropdown-open-box-shadow)
  }

  .abs-menu .navbar-toggle {
    height: 45px;
  }

  .abs-menu .navbar-collapse .navbar-nav > li {
    @media (max-width: $mobile-mode-max) {
      border-bottom: 1px solid #015C88;
    }

    .dropdown-title-span {
      font-size: 14px;
      font-weight: 500;
      @media (max-width: $mobile-mode-max) {
        line-height: 50px;
      }
    }
  }
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu li > a {
  @media (max-width: $mobile-mode-max) {
    padding: 13.5px 12px 13.5px 50px;
    border: none;
    background-color: $color-neutral-0;
    position: initial;
  }
}

.abs-menu.abs-menu-type-a .navbar-non-collapse > .navbar-nav > .dropdown.open > .dropdown-menu > .fw-menu-configuration {
  background: $color-neutral-0;
  padding: 25px;
  min-width: 300px;
  width: 300px;

  .fw-menu-configuration__btn {
    display: block;
    width: 100%;
    text-align: left;
    min-height: 36px;
  }

  .fw-menu-configuration__btn {
    .abs-icon {
      color: $fw-menu-configuration-icon-color;
      font-size: 22px;
    }

    .abs-btn-icon_title {
      margin-left: 5px;
      font-weight: 400;
    }
  }
}

@media (min-width: $desktop-mode-min) {
  .abs-menu .navbar-non-collapse > .navbar-nav > li.open .navbar-nav-icon {
    color: $text-on-color-brand-primary-menu-active;
  }
}

@media (max-width: $mobile-mode-max) {
  .abs-menu .navbar-non-collapse > .navbar-nav > li > a {
    color: $text-on-color-brand-primary-menu;
  }

  .abs-menu .navbar-toggle.collapsed {
    color: $text-on-color-brand-primary-menu;

    .icon-bar {
      background-color: $text-on-color-brand-primary-menu;
    }
  }

  .abs-menu.navbar-default .navbar-collapse .navbar-nav > .dropdown > a {
    color: $text-on-color-brand-primary-menu-active;
  }

  .abs-menu .nav > li.dropdown.open .dropdown-menu li > a, .abs-menu .nav > li.dropdown.open .dropdown-menu li > .dropdown > a {
    color: $sub-nav-mobile-color;
  }
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu {

  .fw-menu-configuration .abs-dropdown.client-select-dropdownmenu {

    &.disabled {

      .dropdown-toggle {
        opacity: 1;

        .abs_expand_more {
          display: none;
        }
      }
    }

    .dropdown-menu {
      width: auto;
      max-width: 400px;

      a {
        outline: none;
        padding: 3px 3px 3px 5px;

        .menu-client-select-option {
          display: flex;
          justify-content: space-between;

          &__name {
            color: $color-neutral-100;
            word-break: break-word;
            word-wrap: break-word;
            max-width: 300px;
          }

          &__no-agreements {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 11px;
            margin-left: 10px;

            &--expanded {
              -webkit-animation: jump .3s linear 0s;
              -moz-animation: jump .3s linear 0s;
              -ms-animation: jump .3s linear 0s;
              animation: jump .3s linear 0s;
            }

            .fw-i18n {
              white-space: nowrap;
            }

            .abs-icon.abs_warning2 {
              height: 13px;
              line-height: 11px;
              font-size: 13px;
              color: $color-warning-dark-3;
              margin: 0 0 0 5px;
              float: none;
            }
          }

          &__no-agreements-desc {
            font-size: 11px;
            border-top: 1px solid $color-neutral-11;
            padding-top: 3px;
          }
        }
      }
    }
  }
}

@media (min-width: $desktop-mode-min) {
  .abs-menu .navbar-nav {
    margin: 0 0 0 0;
  }

  .abs-menu .navbar-non-collapse > .navbar-nav {
    margin: 0 0 0 0;
  }
}

.abs-menu.navbar-default .navbar-nav > .dropdown > a:hover,
.abs-menu.navbar-default .navbar-nav > .dropdown > a:hover .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:hover .navbar-nav-icon {
  color: $text-on-menu-hover;
}

.abs-menu.navbar-default .navbar-nav > .dropdown > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > li > a:focus .navbar-nav-icon {
  color: $text-on-menu-focus;
}

.abs-menu.navbar-default .navbar-nav > .open > a:hover .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a:focus .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a:active .navbar-nav-icon,
.abs-menu.navbar-default .navbar-nav > .open > a,
.abs-menu.navbar-default .navbar-nav > .open > a:hover,
.abs-menu.navbar-default .navbar-nav > .open > a:focus {
  color: $text-on-color-brand-primary-menu-active;
}

.abs-menu .navbar-nav > li > .dropdown-menu {
  border: $abs-menu-dropdown-border-width solid $abs-menu-dropdown-border-color;
}

.abs-dropdown-btn-text {
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
}

.navbar-menu-item {
  @media (max-width: 400px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.client-select-dropdown {
  max-height: none !important;
  border: 1px solid $color-neutral-46;
  padding-bottom: 8px!important;

}

.client-select-dropdown__item {
  margin: 4px 8px;
}

.client-select-option {
  display: flex;
  justify-content: space-between;
  margin: 8px 8px 0px;
  padding: 2px;

  &:hover {
    background: $color-brand-primary-light;
    margin: 8px 8px 0px;
    padding: 2px;
  }

  &__name {
    color: $color-neutral-100;
    word-break: break-word;
    word-wrap: break-word;
    max-width: 300px;
  }

  &__no-agreements {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 11px;
    margin-left: 8px;
    margin-right: 0px;
    padding: 2px;

    &--expanded {
      -webkit-animation: jump .3s linear 0s;
      -moz-animation: jump .3s linear 0s;
      -ms-animation: jump .3s linear 0s;
      animation: jump .3s linear 0s;
    }

    .fw-i18n {
      white-space: nowrap;
    }

    .abs-icon.abs_warning2 {
      height: 13px;
      line-height: 11px;
      font-size: 13px;
      color: $color-warning-dark-3;
      float: none;
      margin-right: 0px !important;
      margin-left: 5px !important;
    }
  }

  &__no-agreements-desc {
    font-size: 11px;
    border-top: 1px solid $color-neutral-11;
    padding-top: 3px;
    margin: 0px 8px 8px;
  }
}

.absui-dropdown--check.show .abs-rotate {
  -webkit-transform: rotate(-180deg); /* Chrome, Safari, Opera */
  transform: rotate(-180deg);
}

.client-select-option-placeholder {
  display: flex;
  align-items: center;
  border: 0px !important;
  padding: 8px 7px 8px 7px !important;
  background: #6f7779;
  height: auto!important;

  .client-select-option-placeholder-icon {
    margin: 0px !important;

  }

  &:hover, &:active, &:active:focus {
    color: $quick-settings-item-color-hover;
    background: $quick-settings-item-bg-color-hover;
    border-color: $quick-settings-item-border-color-hover;
    box-shadow: none;
  }

  .fw-i18n {
    line-height: 1.428571429;
  }

  .client-select-option-placeholder-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;

    .client-select-option-placeholder-text__content {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 185px;
      line-height: 1.428571429;
    }
  }

  .abs-icon {
    margin-right: 7px !important;

    &.abs_employee_data {
      font-size: 32px;
      margin-right: 4px;
    }

    &.abs_business {
      margin-right: 7px;
    }
  }
}

.absui-dropdown.absui-dropdown--check .client-select-option-placeholder {
  .client-select-option-placeholder-text__content, i {
    color: $quick-settings-placeholder-icon-color;
  }
}

.absui-dropdown.absui-dropdown--check.show .client-select-option-placeholder {
  .client-select-option-placeholder-text__content, i {
    color: #fff !important;
  }
}
