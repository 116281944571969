@keyframes placeholderAnimation {
  0% {
    left: 10px;
  }
  50% {
    left: -80px;
  }
  100% {
    left: 10px;
  }
}

@mixin placeholderAnimate() {
  animation:placeholderAnimation 4s infinite;
  -moz-animation:placeholderAnimation 4s infinite;
  -webkit-animation:placeholderAnimation 4s infinite;
}

.r-ss-wrap {
  position: relative;
}

.r-ss-trigger {
  background: transparent;
  border: 1px solid transparent;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  outline: none;
  cursor: pointer;
  text-align: left;
  position: relative;

  .abs-select__selected-value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .r-ss-dropdown-selected-info {
    padding-right: 10px;
  }

  .selected-value-template-view {
    .option-main-value {
      color: $color-neutral-100;
    }
  }

  &:hover {
    background: #eff9ff;
    border: 1px solid #b8d8ea;
    @include border-radius(2px);
  }

  &.r-ss-disabled {
    background: transparent;
    border-color: transparent;
    cursor: default;
  }

  .carat {
    //display: none;
    @extend .abs-icon;
    @extend .abs_expand_more;

    color: $color-neutral-60;

    margin-left: auto;

    &:before {
      display: block;
      transform: rotate(0deg);
      transition: transform .2s linear;
    }
  }

  &:after {
    @extend .abs-icon;
    @extend .abs_expand_more;

    color: $color-neutral-60;

    margin-left: auto;

    &:before {
      display: block;
      transform: rotate(0deg);
      transition: transform .2s linear;
    }
  }

  &.r-ss-open {
    .carat {
      color: $input-text-color-active;

      &:before {
        display: block;
        transform: rotate(180deg);
        transition: transform .2s linear;
      }
    }

    //&:after {
    //  color: $input-text-color-active;
    //  display: block;
    //  transform: rotate(180deg);
    //  transition: transform .2s linear;
    //}
  }

  .r-ss-selection-clear + .carat {
    margin-right: 24px;
    margin-left: -24px;
    padding-right: 5px;
  }

  &:after {
    display: none;
  }
}

.r-ss-dropdown {
  background: $color-neutral-0;
  border: 1px solid $color-neutral-33;
  @include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.35));
  @include border-radius(2px);
  @include font-style($roboto-font-family, 400, normal);
  height: auto;
  margin: 4px 0 0 -5px;
  overflow: hidden;
  position: absolute;
  width: 266px;
  z-index: 100;

  .r-ss-search-wrap {
    background: $color-neutral-2;
    box-shadow: 0 1px 0 0 $color-neutral-11;
    @include radius(1px, 1px, 0, 0);
    padding: 9px;
    margin-bottom: 1px;

    .r-ss-search-inner {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;

      > input {
        background: $input-background-color;
        border: 1px solid $input-border-color;
        color: $input-text-color;
        outline: none;

        &:hover {
          box-shadow: none;
          background: $input-background-color-hover;
          border-color: $input-border-color-hover;
          color: $input-text-color-hover;
        }

        &:focus {
          background: $input-background-color-focus;
          border-color: $input-border-color-focus;
          color: $input-text-color-hover;
          @include box-shadow(0 0 5px 0 $input-shadow-color-focus);
        }

        &:active {
          background: $input-background-color-active;
          border-color: $input-border-color-active;
          color: $input-text-color-active;
          @include box-shadow(none);
        }

        font-size: 13px;
        line-height: 16px;
        @include radius(2px);
        width: 100%;
        padding-right: 32px;
      }

      .r-ss-search-aria-label {
        display: none;
        font-weight: normal;
      }

      .r-ss-search-clear {
        @extend .abs-icon;
        @extend .abs_close;

        background: none;
        border: none;
        height: 18px;
        width: 18px;
        position: absolute;
        right: 10px;
        text-rendering: auto;
        display: table-cell;
        top: 3px;
        vertical-align: middle;
        font-size: 18px;
        text-align: center;
        outline: none;

        &:before {
          color: #D05B61;
        }

        & + .r-ss-magnifier {
          display: none;
        }
      }

      .r-ss-magnifier {
        @extend .abs-icon;
        @extend .abs_material_search;

        position: absolute;
        right: 4px;
        font-size: 18px;
        color: transparent;
        width: 18px;
        top: 4px;

        &:before {
          color: $color-neutral-60;
          position: absolute;
        }
      }
    }
  }

  .r-ss-options-wrap {
    max-height: 350px;
    overflow-y: auto;
    .r-ss-dropdown-options {
      padding: 0;
      margin: 0;

      > li {
        border: none;
        display: flex;
        align-items: center;
      }

      .r-ss-option-group-heading {
        background: $color-neutral-0;
        border-bottom: 1px solid $color-neutral-11;
        height: 39px;
        cursor: default;
        outline: none;
        margin-left: 10px;
        margin-top: 0;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;

        @include font-style($roboto-font-family, 500, normal);
        font-style: normal;
        font-size: 13px;
        text-decoration: none;
        color: $color-neutral-60;
      }

      .r-ss-dropdown-option {
        background: $color-neutral-0;
        min-height: 26px;
        cursor: pointer;
        outline: none;
        margin: 1px 1px 2px 1px;
        padding: 5px 9px;
        text-align: left;
        font-size: 13px;
        line-height: 16px;
        color: $color-neutral-100;
        word-break:break-word;

        .r-ss-dropdown-multiselect-option {
          display: flex;
          cursor: pointer;
          font-weight: normal;
          margin: 0;

          > input[type="checkbox"] {
            cursor: pointer;
          }

          > div {
            margin-left: 25px;
          }
        }

        .option-template-view {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: normal;
        }

        &.r-ss-selected {
          background: $dropdown-item-selected-bg-color;
          @include radius(2px);
          outline: none;
          color: $dropdown-item-selected-color;

          .light-txt, .hint {
            color: $dropdown-item-selected-color;
          }
        }

        &:hover, &:active {
          background: $dropdown-item-hover-bg-color;
          @include radius(2px);
          outline: none;
        }

        .agreement-number-select-item {
          @include font-style($roboto-font-family, 400, normal);
          font-size: 13px;
          color: $color-neutral-100;
        }

        .currency-select-item {
          @include font-style($roboto-font-family, 400, normal);
          font-size: 13px;
          color: $color-neutral-80;
        }

        .agreement-additional-info-select-item, .option-additional-info {
          @include font-style($roboto-font-family, 400, normal);
          color: $color-neutral-60;
          text-align: left;
          display: flex;
          align-items: center;

          i, .fa {
            font-size: 6px;
            margin: 0 4px;
          }
        }

        &.r-ss-selected {

          .agreement-number-select-item {
            color: $dropdown-item-selected-color;
          }

          .currency-select-item {
            color: $dropdown-item-selected-color;
          }

          .agreement-additional-info-select-item, .option-additional-info {
            color: $dropdown-item-selected-color;
          }
        }
      }
    }
  }
}

.r-ss-wrap.placeholder-overflow .r-ss-dropdown .r-ss-search-wrap .r-ss-search-inner {
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    width: 8px;
    //background-color: $color-neutral-0;
    z-index: 1;
  }
  &:hover {
    .r-ss-search-aria-label {
      overflow: visible;
      text-overflow: clip;
      @include placeholderAnimate();
    }
  }
  .r-ss-search-aria-label {
    display: block;
    color: #999;
    position: absolute;
    padding-right: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 10px;
    top: 5px;
  }
  > input {
    width: 100%;
    &::-webkit-input-placeholder {
      color: $color-neutral-0;
    }
    &::-moz-placeholder {
      color: $color-neutral-0;
    }
    &:-ms-input-placeholder {
      color: $color-neutral-0;
    }
    &:-moz-placeholder {
      color: $color-neutral-0;
    }

    &:active {
      background: transparent;
    }
  }

  .r-ss-magnifier {
    position: absolute;
    font-size: 18px;
    color: transparent;
    top: 1px;
    width: 35px;
    background-color: $color-neutral-0;
    display: flex;
    align-items: center;
    right: 1px;
    bottom: 1px;
    &:before {
      margin-left: 12px;
    }
  }

}

.r-ss-wrap.placeholder-overflow.search-active .r-ss-dropdown .r-ss-search-wrap .r-ss-search-inner {
  .r-ss-search-aria-label {
    display: none;
  }
}

.detail-section-container {
  .r-ss-dropdown {
    @media (max-width: $mobile-mode-max) {
      position: fixed;
      width: 95%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.r-ss-dropdown-max-content {
  width: max-content;
}

