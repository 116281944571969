.abs-card-panel {
  float: left;
  height: auto;
  border: 1px solid $color-neutral-16;
  @include radius(2px);
  @include box-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
  position: relative;
  width: 100%;

  @media (min-width: $desktop-mode-min) {
    width: 357px;
    margin: 8px;
  }

  @media (max-width: $mobile-mode-max) {
    margin-top: 15px;
  }

  &:hover {
    .abs-card-arrow {
      display: flex;
    }
  }

  .abs-card-header {
    width: 100%;
    height: 43px;
    padding-top: 12px;
    box-sizing: border-box;
    border-bottom: 1px solid $abs-card-header-border-bottom-color;
    @include radius(2px 2px 0 0);
    word-break: break-all;
    background-color: $abs-card-header-bg-color;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: $abs-card-header-color;

    &.abs-card-header-link {
      cursor: pointer;

      &:hover {
        background-color: $abs-card-header-bg-color-hover;
      }
    }
  }

  .abs-card-dots {
    cursor: pointer;
    margin: 0px 1px;
    width: 9px;
    height: 9px;
    border-radius: 10px;
    display: inline-block;
    background-color: $color-neutral-16;

    &:hover {
      background-color: $color-neutral-46;
    }
  }

  .abs-card-container {
    background: $color-neutral-0;
    @include radius(0 0 2px 2px);
    height: 277px;
    box-sizing: border-box;

    &.abs-card-container__show-overflow {
      .slick-list {
        overflow: visible;
      }

      @media (max-width: $mobile-mode-max) {
        .slick-list {
          .slick-track {
            .slick-slide {
              height: auto;

              .dropdown-menu {
                position: unset;
                float: none;
              }
            }
          }
        }
      }
    }

    .slick-list {
      height: 100%;
      box-sizing: border-box;

      .slick-track {
        height: 100%;

        .slick-slide {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .abs-card-content {
      padding-top: 5px;
      box-sizing: border-box;
      height: 236px;

      &:active {
        cursor: move;
      }

      &__no-slider {
        height: 100%;
      }

      .tile-content-page__no-slider {
        width: 100%;
      }
    }

    .abs-card-dots-position {
      bottom: -40px;
      margin: auto;
      right: 0;
      left: 0;
    }
  }

  .abs-card-arrow {
    position: absolute;
    width: 25px;
    height: 25px;
    border: 1px solid $color-neutral-16;
    border-radius: 50%;
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    box-sizing: border-box;
    top: 50%;
    background-color: $color-neutral-0;
    z-index: 15;
    display: none;
    text-decoration: none;

    &:hover {
      border-color: $color-brand-primary-utility-2;
      background-color: $abs-card-arrow-bg-color-hover;
      color: $color-brand-primary-dark;
    }

    i {
      line-height: 24px;
    }

    @media (max-width: $desktop-mode-min) {
      display: none !important;
    }

    &.right {
      right: -10px;

      i {
        margin-left: 2px;
      }
    }

    &.left {
      left: -10px;
    }
  }

  .slick-dots li {
    cursor: auto;
    margin: auto;
  }

  .slick-active {
    .abs-card-dots {
      background-color: $color-brand-primary-additional;
    }
  }

  /* CHARTS */
  .recharts-wrapper {
    .recharts-legend-wrapper {
      position: relative !important;
      width: auto !important;

      ul {
        list-style: none;
        margin: 0px;
        padding: 20px 0px 20px 5px;
        display: block;

        li {
          margin: 0;
          position: relative;
          display: list-item;
          height: auto;

          &.selected {
            font-weight: bold;
          }

          span.chart-legend-item {
            .chart-legend-item-point {
              @include radius(50%);
              width: 8px;
              height: 8px;
              display: inline-block;
              top: 3px;
            }

            .chart-legend-item-value {
              padding-left: 5px;
              text-align: left;
            }
          }
        }
      }

    }
  }

  .text-chart-panel .text-chart-container ul li span:nth-of-type(1) {
    width: auto;
    height: auto;
    display: flex;
    position: relative;
    top: auto;
    padding: 0;
  }

  .text-chart-panel .text-chart-container ul li span:nth-of-type(3) {
    margin-left: 0;
  }

  .text-chart-panel .text-chart-container ul li span:nth-of-type(2) {
    padding-left: 0;
  }
}

/**************/
/* AGREEMENTS */

.fw-dashboard-agreements__summary {
  border: 0;
  width: 290px;

  .icon-placeholder {
    background-color: $icon-placeholder-background;
    border-radius: 50%;
    width: 57px;
    height: 57px;
    margin: 0 auto 10px;
    display: table;

    > .fa, > .abs-icon {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $color-neutral-0;
      font-size: 31px;
    }
  }

  .fw-dashboard-agreements__summary-limits {
    text-align: center;
    width: auto;
    margin: 10px auto;

    &__block {
      margin-top: 30px;

      &:first-child {
        margin-top: 0px;
      }
    }

    &__title {
      color: $color-neutral-60;
    }

    &__total {
      .formatted-money {
        font-size: 15px;
        color: $formatted-money-integer-color;

        .formatted-money-integer {
          font-size: 22px;
          text-align: left;
          font-weight: 500;
        }

        .formatted-money-fraction {
          font-size: 22px;
          text-align: left;
          color: $formatted-money-fraction-color;
        }

        .formatted-money-currency {
          margin-left: 3px;
          display: inline;
          font-size: 22px;
          color: $formatted-money-currency-color;
        }
      }
    }
  }
}

.fw-dashboard-agreements-page {
  text-align: center;
}

.fw-dashboard-agreement-data {
  width: 260px;

  &.fw-dashboard-agreement-data__danger-bottom-border {
    border-bottom: 1px solid $color-neutral-8;
    padding-bottom: 10px;
  }

  .fw-dashboard-agreement-data__agreement-no {
    font-size: 18px;
    font-weight: 700;
    word-break: break-all;
  }

  .hint {
    line-height: 17px;
    color: $color-neutral-60;
    font-size: 13px;
    white-space: nowrap;
    overflow: auto;
  }
}

.fw-dashboard-agreements-limits {
  width: 100%;

  .progress-with-percent {
    padding: 8px 0;
    width: 100%;

    .progress-container {
      width: 100%;

      .progress-bar-danger {
        background-color: $color-negative-dark-2;
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  .fw-dashboard-agreements-limit-values {
    display: flex;
    align-self: center;

    p {
      line-height: 20px;
      margin: 0;
      word-break: break-all;
    }

    .fw-dashboard-agreements-limit-values__labels {
      font-weight: bold;
      text-align: left;
      display: flex;
      flex-direction: column;
    }

    .fw-dashboard-agreements-limit-values__values {
      text-align: right;
      display: flex;
      flex-direction: column;
      margin-left: auto;

      .formatted-money-integer {
        font-weight: 500;
      }
    }
  }
}

.fw-dashboard-agreement-data__request-btn-panel {
  border-top: 1px solid $color-neutral-8;
  margin-top: 20px;
}

.fw-dashboard-agreement-data__request-btn {
  margin: 15px auto 0;
}

.fw-dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 277px;
  justify-content: center;

  .fw-alerts__list {
    height: 231px;
    margin-bottom: 3px;
  }

  .fw-alerts__empty {
    height: 234px;
  }
}

.fw-dashboard-actions {
  .fw-dashboard-action {
    &:nth-last-child(n + 2) {
      padding-bottom: 15px;
    }

    .btn-group {
      width: 100%;
    }

    .fw-dashboard-action__element {
      width: 100%;
      padding: 9px 11px;
      display: flex;
      justify-content: center;

      .fw-dashboard-action__link-with-value__label {
        color: $btn-type-d-color;
        font-size: 13px;
        padding: 5px;
      }

      .fw-dashboard-action__link-with-value__value {
        color: $color-neutral-100;
        font-size: 34px;
        padding: 5px;
      }
    }
  }
}

.abs-card-panel.fw-dashboard-confirmation-tile {

  .abs-card-content {
    padding: 2px;

    .fw-dashboard-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      &--horizontal {
        padding: 0 70px;
      }

      &--vertical {
        padding: 70px 0;
      }

      &__row {
        display: flex;
        flex: 1;

        & + .fw-dashboard-box__row {
          border-top: 1px solid $color-neutral-8;
        }

        .fw-dashboard-box-item {
          flex-basis: 100%;
          justify-content: center;
          display: flex;

          & + .fw-dashboard-box-item {
            border-left: 1px solid $color-neutral-8;
          }

          .fw-dashboard-action {
            display: flex;
            width: 100%;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .fw-dashboard-action__element {
              align-items: center;
              display: flex;
              margin: 5px;
              white-space: normal;
              width: auto;
              min-width: 140px;
            }
          }
        }
      }
    }
  }
}

.fw-dashboard-no-items {
  text-align: center;
  width: 115px;

  .abs-icon {
    color: $color-neutral-11;
    font-size: 45px;
  }

  .fw-dashboard-no-items-title {
    color: $color-neutral-27;
    display: block;
    font-size: 14px;
    padding-top: 10px;
    word-wrap: break-word;
  }
}
