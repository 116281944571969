
.details-panel{		
	padding-top:$abs-menu-height;
	overflow: hidden;
	width: 100%;
	margin:0px;
	padding-top:0px;
	border:0px;
	@media (min-width: $grid-float-breakpoint-max) and (max-width:   $grid-breakpoint-desktop-large){
    	padding-left:20px;
    	padding-right:20px;
    	.col-md-12, .col-md-6{
    		padding:0px;
    	}
  	}

	&.with-tabs{
		padding-bottom:50px;
	}

	.icon-placeholder{
		background:$abs-menu-bg;					
		border-radius:50%;
		width:68px;
		height:68px;
		float:left;
		display:table;
		margin-top:15px;		
		@media (max-width: $grid-float-breakpoint-max){
			margin-top:-5px;
		}	
		@media (max-width:   $grid-breakpoint-desktop-large) {
          margin-left:0px;
     
        }
		> .fa , > .abs-icon{
			display:table-cell;
			vertical-align: middle;
			text-align:center;
			color:$icon-placeholder-color;
			font-size:2.8em;
		}
	}
	.simple-view .icon-placeholder > .abs-icon{
		font-size:1.6em;
	}
	h1{
		font-size:20px;
		//@include fonts-size(20px, 120px);
		font-weight:bold;
		padding:0px 0px 0px 0px;
		margin:5px 0px 32px 87px;
		color:$details-panel-h1-color;
		// word-break: break-all;

	}

	.print-version{
		float: right;
    	margin-top: 37px;
    	padding: 1px 5px;
    	margin-left: 4px;
		@media (max-width: $grid-breakpoint-desktop-large){
			margin-right:10px;
		}				
    	@media (max-width: $grid-float-breakpoint-max){
    		display:none;
    		//margin-top:0px;
    	}	
    	i{
    		font-size:17px;
    		vertical-align:middle;
    	}
	}
	&.info-card .col-sm-12{		
		@media (max-width: $screen-lg-min) {
			 float:left;
			 width:100%;
		}	

		.detail-h1,.detail-h2,.detail-h3{			
			white-space: pre-line;		
			margin:0px;		
			padding:0px;

		}
		.detail-h1{
			font-size:20px;
			font-weight:bold;
			//margin-top:-1px;
			//margin-bottom:2px;
			
		}
		.detail-h2{
			color:$details-panel-h2-color;
			font-size:13px;
			font-weight:bold;						
		}		
		.detail-h3{
			color:$details-panel-h2-color;
			font-size:13px;
			font-weight:bold;	
			

		}
		.icon-placeholder{
			@media (max-width: $grid-float-breakpoint-max){			
				margin-top:15px;
			}	
			position:absolute;
			top:0px;
			left:0px;
			line-height:68px;
			text-align:center;

			img{
				width: 85%;
    			height: auto;
    			    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
			}
		}
		.detail-main-section{
			float:left;
			margin:13px 10px 20px 87px;
			padding:0px;
			width:290px;
			@media (max-width: $grid-float-breakpoint-max){
				width:auto;
			}
			@media ( min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min){
					width:190px;					
			}			
		}
		.detail-section-container{

				//float:right;

				//width:calc(100% - 390px);
			@media ( min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min){
				//width:	calc(100% - 290px);
				
			}
			@media ( max-width: $grid-float-breakpoint-max){
				width:100% !important;
				clear:left;
			}	


		}
		.detail-section{			
			align-self:stretch;
			float:left;
			line-height:1.4em;
			> div{
				&:after{
					content:"";
					display:table;
					clear:both;
				}
			}
			&:nth-of-type(1)
			{
				float:left;
				width:303px;
				margin:21px 0px 0px 87px;
				min-height:77px;
					@media ( min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min){
							width:213px;					
					}
					@media ( max-width: $grid-float-breakpoint-max) {
						width:auto;
					}

					
			}
			
			@media  (max-width: $grid-float-breakpoint-max) {
				margin-top:10px;
				//min-width:48%;
				//max-width:48%;
			}	
			&:nth-of-type(odd){
			 	@media (max-width: $grid-float-breakpoint-max) {
			 			clear:left;
			 	}	
			 }
			margin:23px 10px 10px 0px;
			color:$details-panel-color;
			//min-width:180px;
			float:left;
			//min-width:195px;
			//max-width:240px;
			padding-right:10px;
			width:auto;
			
			.detail-title{
				font-size:13px;
				font-weight:bold;				
				display:block;
				margin-bottom:2px;
			}
			.detail-label{
				padding-right:4px;
				//word-break: 	break-all;
				//float:left;
				display:inline;
				
			}
			.detail-value{
				//float:left;
				display:inline;
				&:after{
					content:"";
					display:table;
					clear:both;
				}
			}
			a{
				word-break: 	break-all;
			}
		}

		
	}
	h2{
		color:$details-panel-h2-color;
		font-size:13px;
		font-weight:bold;
		padding:10px 0px 0px 0px;
		margin:20px 0px -5px 87px;
		@media (max-width: $grid-breakpoint-desktop){
			
		}		
	}
	.toolbar{
		margin:30px 0px 10px 0px;
		@media (max-width: $grid-float-breakpoint-max){
 			margin-top:14px;
 		}  	

		box-shadow:none;
		border:0px;
		 text-align:right;
		&.toolbar-left{
			text-align:left;
		}
		//background:$abs-container-box-bg;
		.k-button , .k-separator{
			//float:right;
		}
		.k-button-group{
			float:right;
		}
		.k-separator{
			margin-top:1px;
			@media (max-width: $grid-float-breakpoint-max){
				display:none;			
			}	
		}
		.k-overflow-anchor{
			margin-top:7px;
		}
	}


	.toolbar-with-linked-modules{
		padding-left:0px;
		padding-right:0px;
		margin-top:0px;
		border-top:1px solid $details-panel-inner-border;
		clear:both;
		.k-button-icontext {
 		   padding-right: .8em;
    		padding-left: 1em;
    		> img{
    			margin-left:-5px;
    		}
		}
		.k-overflow-anchor{
			margin-top:0px
		}
		.col-md-6, .col-sm-6{
			padding:0px;

		}
		.col-sm-6 , .col-sm-12{
			@media (max-width:   $grid-breakpoint-desktop-large) {				
				padding-left:0px;
				padding-right:0px;
			}	
		}
		.toolbar{
			margin-top:10px;
			margin-bottom:4px;
			line-height:1em;
			.k-button{
				float:none;
				&.k-overflow-anchor{
					float:right;
				}
			}

			&.linked-modules{
				text-align:right;

				.k-button-icontext{
					.abs-icon{
						margin-left: -9px;
    					margin-right: 4px;
					}
				}
				.k-overflow-anchor{
					// background:none;
					// border-color:transparent;
					> .k-icon.k-i-arrow-s{
						height:19px;
						margin-top:-12px;
						transition: transform .2s linear;
						transform: rotate(0deg);
						transform-origin: 50% 50%;
					}
					> .k-icon.k-i-arrow-s:before{
						color:$abs-link-color;
						font-size:20px;
					}
					&:hover, &:focus{
					// 	background:none !important;
					// border-color:transparent;
					}
					&.k-state-border-down{
						> .k-icon{
							transform: rotate(180deg);

    						transition: transform .2s linear;
    						transform-origin: 50% 50%;
						}
					}
					
					
				}
				.k-button:not(div){
					background:none;
					border-color:transparent;
					color:$abs-link-color;
					padding:1px 10px;
					.k-icon{
						color:$abs-link-color;
						font-size:14px;
					}
					&:hover{
						color:$details-panel-linked-button-hover-color;
					}
					&:focus{
						background:none !important;
						border-color:transparent;
					}

				}
				span:not(.k-icon):not(.k-sprite){
					font-weight:bold;
					margin-right:5px;
					
				}

			}
		}

	}

}


  .details-panel{
    .toolbar-section{
      padding-right:0px;
      @media (max-width: $grid-float-breakpoint-max) {   
      	padding-right:15px;
      }
      float:right;
        .toolbar{
          margin:20px 0px 0px 0px; 
          line-height:auto;
          text-align:right;
          .k-toolbar>.k-button{
            // flaot:none;
          }
          .k-overflow-anchor{
             margin-top:7px;
          }
        }
    }
  }

  .abs-back {
  a {
    color: $btn-type-c-color;
    text-decoration: none;
  }
}
