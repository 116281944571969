.progress-steps{
	@include flexbox();
	@include justify-content(center);
	@include align-items(center);
	list-style:none;
	margin:0px;
	li{
		line-height:30px;
		a{
			font-weight:bold;	
			color:$color-neutral-100;
		}	
		&.active-step a{
			color:$abs-link-color;
		}
		&.active-step ~ li a{
			color:$color-neutral-60 !important;

		}
		&:not(:first-child):before{
			font-family:Lumen-Icon-Font;
			content:"\e318";
			margin:0px 5px;
			color:$color-neutral-20;
			display: block;
			float:left;
			font-size:22px;
			transform:scale(0.6, 1.6);
		}
	}
}