.container-nav {
  .details-panel {
    overflow: visible;
  }

  .abs-tabs-lite {
    margin-bottom: -2px;
  }
}

.page-header-container {

  .page-header {
    height: 40px;
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .page-title {
      color: #000;
      font-family: 'Roboto-Medium', 'Roboto Medium', 'Roboto';
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      line-height: 40px;
    }
  }

  .page-header-data {
    border-top: 1px solid #d7d7d7;
  }

.details-panel .detail-section-container {
  //position: relative;
  display: flex;
  align-items: flex-start;

  &.page-summary-panel {
    align-items: center;
    margin: 30px 0;

    .r-ss-wrap {
      align-self: center;
    }
  }

  &.summary-panel {
    margin: 30px 0;
    .col-sm-12 {
      @media (max-width: $screen-lg-min) {
        float: left;
        width: 100%;
      }
    }
    .detail-h1, .detail-h2, .detail-h3 {
      white-space: pre-line;
      margin: 0px;
      padding: 0px;

    }
    .detail-h1 {
      font-size: 20px;
      font-weight: bold;
      margin-top: -1px;
      margin-bottom: 2px;

    }
    .detail-h2 {
      color: $details-panel-h2-color;
      font-size: 13px;
      font-weight: bold;

    }
    .detail-h3 {
      color: $details-panel-h2-color;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 21px;
      //margin-top:-2px;

    }
    .icon-placeholder {
      position: relative;
      line-height: normal;
      text-align: center;

      img {
        width: 85%;
        height: auto;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
        -ms-filter: brightness(0) invert(1);
      }
    }
    .detail-main-section {
      float: left;
      margin: 13px 10px 20px 87px;
      padding: 0px;
      width: 290px;
      @media (max-width: $grid-float-breakpoint-max) {
        width: auto;
      }
      @media (min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min) {
        width: 190px;
      }
    }
    .detail-section-container {

      //float:right;

      //width:calc(100% - 390px);
      @media (min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min) {
        //width:	calc(100% - 290px);

      }
      @media (max-width: $grid-float-breakpoint-max) {
        width: 100% !important;
        clear: left;
      }

    }
    .detail-section {
      flex-direction: column;

      color: #000;
      padding-right: 40px;
      width: auto;

      .detail-title {
        color: #666666;
        display: block;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 5px;
      }
      a {
        word-break: break-all;
      }
    }

  }
}

.icon-initials {
  color: #fff;
  font-family: 'Roboto-Regular', 'Roboto';
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;

  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.details-panel {
  min-height: 98px;

  [role="listbox"] {
    min-height: 98px;

    .detail-section:nth-of-type(1) {
      .detail-title {
        color: #868686;
      }
    }

    .detail-section {
      .detail-title {
        color: #868686;

        &.title-primary {
          font-family: Roboto-Bold, "Roboto Bold", Roboto;
          font-weight: 700;
          font-size: 20px;
          color: #333;
        }
      }

      .detail-value {
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        font-size: 15px;
        color: #000;

        &.additional-info {
          font-size: 13px;
          color: #868686;
        }
      }

      .r-ss-wrap {
        margin-top: -5px;
        min-width: 200px;
        max-width: 300px;

        .r-ss-trigger {
          border: 1px solid transparent;
          @include border-radius(2px);
          padding: 4px 5px;
          margin: 0 0 0 -6px;
          line-height: normal;
          height: auto;

          &:hover {
            background: #eff9ff;
            border-color: #1a69aa;
          }

          &:active {
            background: #d5ebf9;
            border-color: #1a69aa;
          }

          &.r-ss-disabled {
            background: transparent;
            border-color: transparent;
            cursor: default;
          }

          .agreement-number-container {
            * {
              line-height: normal;
            }

            .agreement-number {
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              font-size: 15px;
              color: #000;
            }

            .agreement-currency {
              color: #797979;
              font-size: 15px;
            }

            .agreement-status {
              color: #797979;
              font-size: 11px;
              text-align: left;
              display: flex;
              align-items: center;

              i, .fa {
                font-size: 6px;
                margin: 0 4px;
              }
            }
          }

          .carat.down, .carat.up {
            display: none;
          }
        }
      }

      .dt-col-1 {
        dt, dd {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.agreements-header {

  .details-panel [role="listbox"] .detail-section {
    margin-right: 30px;

    &:nth-of-type(1) {
      width: auto;
    }

    @media (max-width: $screen-lg-min) {
      padding-right: 25px;
    }

    @media (max-width: $screen-md-min) {
      padding-right: 18px;
    }

    .formatted-money {
      display: block;
      margin-top: 12px;

      .formatted-money-integer {
        color: #000;
        font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto';
        text-align: left;
        font-size: 36px;
        font-weight: 700;

        @media (max-width: $screen-lg-min) {
          font-size: 30px;
        }

        @media (max-width: $screen-md-min) {
          font-size: 23px;
        }
      }

      .formatted-money-fraction, .formatted-money-currency {
        color: #797979;
        text-align: left;
        font-size: 30px;
        font-weight: 400;

        @media (max-width: $screen-lg-min) {
          font-size: 24px;
        }

        @media (max-width: $screen-md-min) {
          font-size: 17px;
        }
      }

      .formatted-money-currency {
        margin-left: 7px;
      }
    }
  }

}
}

.abs-toolbar {
   .navbar-header {
      padding-right: 0px;
      .abs-badge {
         border-radius: 100%;
         width: 3em;
         height: 3em;
         background-color: $color-brand-primary;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-right: 12px;
         .abs-icon {
            color: $text-on-color-brand-secondary;
            &:before {
               font-size: 18px;
            }
         }
      }
      
   }
}

.abs-detail-header {
  color: red;

  .box-flex-a {
    box-shadow: none;
    border-bottom-width: 2px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    padding: 10px 0px;
    margin: 0px;
    &--thin {
      border-bottom-width: 1px;
    }
  }

  .abs-toolbar {

    .abs-dropdown {
      .btn {
        padding: 1px 6px;
      }
    }

    .header {
      &--black {
        color: $color-neutral-100;
      }
    }

    .abs-icon {
      line-height: normal;
    }

    .navbar-header {
      padding: 0;
      margin: 0;
      padding-right: 0px;

      .btn {
        .abs-icon {
          margin-left: 0;
          padding: 0;
        }
      }

      .abs-icon {
        margin-left: -15px;
        padding: 0 15px;
        &:before {
          font-size: 18px;
          display: block;
        }
      }

      .abs-badge {
        border-radius: 100%;
        width: 3em;
        height: 3em;
        background-color: $color-brand-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        &--sm {
          width: 35px;
          height: 35px;
        }
        .abs-icon {
          color: $text-on-color-brand-secondary;
          margin-left: 0;
          &:before {
            font-size: 18px;
          }
        }
      }

    }

    .navbar-nav {
      height: auto;
    }

  }

}

.header {
  &--black {
    color: $color-neutral-100;
  }
}

.abs-badge-container {

  .box-flex-a {
    box-shadow: none;
    border-bottom-width: 2px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    padding: 20px 0px;
    margin: 0px;
    &--thin {
      border-bottom-width: 1px;
    }
  }

  .abs-badge-panel {
    max-width: $grid-breakpoint-desktop-large-value;
    display: flex;
    align-items: center;

    .col-fr-2 {
      flex-basis: 16.66%;
    }

    .block {
      display: block;
    }

    .header_1 {
      font-size: 16px;
      color: $color-neutral-100;
      padding: 0;
      margin: 0;
    }
  }
}

.abs-badge {
  border-radius: 100%;
  width: 3em;
  height: 3em;
  background-color: $color-brand-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  &--sm {
    width: 35px;
    height: 35px;
  }
  &--md {
    width: 69px;
    height: 69px;
    .abs-icon {
      &:before {
        font-size: 34px !important;
      }
    }
  }

  .abs-icon {
    color: $text-on-color-brand-secondary;
    margin-left: 0;
    &:before {
      font-size: 18px;
    }
  }
}