.abs-boolean {

  .abs-boolean-icon {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    position: relative;
    top: 2px;
    display: inline-block;
    margin-top: -5px;
    margin-right: 5px;

    &.abs-boolean-icon-positive {
      color: $success-icon-color;
    }

    &.abs-boolean-icon-negative {
      color: $danger-icon-color;
    }

    &.abs-boolean-icon-warning {
      color: $color-warning-dark-2;
    }
  }
}
