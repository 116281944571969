/* Lumen forms
   ========================================================================= */

%input-default-style {
  box-shadow: none;
  border-radius: $token-form-element-radius;
  border-color: $token-form-element-border-color;
  color: $token-form-element-text-color;
}

%input-hover-style {
  border-color: $token-form-element-border-color-hover;
  background-color: $token-form-element-background-hover;
}

%input-focus-style {
  border-color: $token-form-element-border-color-focus;
  background-color: $token-form-element-background-focus;
  outline: 0;
}

%input-active-style {
  box-shadow: none;
  outline: 0;
  background: $token-form-element-background-active;
  color: $token-form-element-text-color-active;
}

@mixin input-focus {
  border-color: $token-form-element-border-color-focus;
  background-color: $token-form-element-background-focus;
  outline: 0;
}

::placeholder {
  color: $token-form-element-placeholder-color;
  opacity: 1;
}

.absui-form-control {
  @extend %input-default-style;
  display: block;
  width: 100%;
  height: $token-form-element-height;
  padding: $token-form-element-padding;
  position: relative;
  background-color: $token-form-element-background;
  background-clip: padding-box;
  border: 1px solid $token-form-element-border-color;
  color: $token-form-element-text-color;
  font-size: 1rem;
  text-indent: 0;
  line-height: $token-form-element-height;
  //transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
    height: 36px;
  }

  &:hover {
    @extend %input-hover-style;
  }

  &:focus,
  &.focus {
    @include input-focus();
  }

  .absui-icon--close {
    font-size: $token-form-element-icon-size;
    padding: 0 4px;
    cursor: pointer;
    color: $token-theme-color-icon-negative;

    &:hover {
      color: $token-theme-color-icon-neutral-stronger;
    }
  }
}

*[disabled] {
  pointer-events: none;
  opacity: 0.45;
}

.absui-form-control:disabled, .absui-custom-control-input:disabled {
  background-color: $token-form-element-background-disabled;
  opacity: $token-form-element-opacity-disabled;
}

.absui-form-control[readonly]:focus {
  @extend %input-default-style;
}

input::-ms-clear {
  display: none;
}

.iphone input:not([type=checkbox]):not([type=radio]) {
  -webkit-appearance: none;
}

:-webkit-autofill,
:-webkit-autofill:first-line,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
  //border: 1px solid green;
  font-size: 1rem;
  font-family: $token-font-family-default;
  -webkit-text-fill-color: $token-form-element-text-color;
  -webkit-box-shadow: 0 0 0px 1000px $token-form-element-background inset;
}

.iphone textarea {
  appearance: none;
  box-shadow: none;
}

textarea, textarea.absui-form-control {
  border: 1px solid $token-form-element-border-color;
  border-radius: $token-textarea-radius;
  font-size: 1rem;
  resize: vertical;
  min-height: $token-textarea-min-height;
  height: auto;
  padding: $token-textarea-padding;
  line-height: $token-textarea-line-height;

  &:focus {
    @include input-focus();
    background: $token-form-element-background-focus;
  }
}

.textarea-control-wrap {
  padding: 6px 0;
  border: 1px solid $token-form-element-border-color;
  border-radius: $token-textarea-radius;
  overflow-y: auto;

  textarea {
    border: none!important;
    border-radius: unset;
  }
}

.absui-control-wrap {
  width: 100%;
  $token-form-label-width: 170px;

  //&:first-child {
  //  @include absui-breakpoint-up(lg) {
  //    margin-left: 178px;
  //  }
  //
  //  @include absui-breakpoint-only(md) {
  //    margin-left: 128px;
  //  }
  //}

  .absui-control-label + & {
    width: calc(100% - #{$token-form-label-width});
    min-width: 62px;

    @include absui-breakpoint-down(sm) {
      width: 100%;
    }

    @include absui-breakpoint-only(md) {
      width: calc(100% - 128px);
    }
  }

  @include absui-breakpoint-up(lg) {

    .absui-form-col &.half {
      width: calc(100% - #{$token-form-label-width});
    }

    .absui-form-col &.quarter {
      width: calc(66% - #{$token-form-label-width});
    }

    .absui-form-col &.eighths {
      width: calc(44% - #{$token-form-label-width});
    }

    .absui-form-col-full &.half {
      width: calc(50% - #{$token-form-label-width});
    }

    .absui-form-col-full &.quarter {
      width: calc(33% - #{$token-form-label-width});
    }

    .absui-form-col-full &.eighths {
      width: calc(22% - #{$token-form-label-width});
    }
  }
}

.absui-control-set {
  display: flex;

  &__item:not(:last-child) {
    margin-right: 15px;
  }

  .absui-dropdown {
    width: 100%;
  }
}

.absui-control-label {
  display: inline-block;
  position: relative;
  font: $token-form-element-label-font;
  color: $token-form-element-label-color;
  padding-top: 4px;
  padding-right: 15px;
  margin-bottom: 4px;

  .absui-form-group & {
    text-align: right;
    padding-left: 12px;
    margin-bottom: 8px;

    @include absui-breakpoint-down(sm) {
      text-align: left;
      padding-left: 0;
    }

    @include absui-breakpoint-only(md) {
      width: 120px;
    }

    @include absui-breakpoint-up(lg) {
      width: 170px;
    }
  }
}

.required:after {
  content: "*";
  color: $token-theme-color-text-negative;
  position: absolute;
  font-size: $token-form-element-required-star-size;
  top: 0;
  right: 4px;

  @include absui-breakpoint-down(sm) {
    top: -2px;
  }
}

.absui-form-col {
  @include absui-col-ready();
  @include absui-col(6);
  padding: 0 12px;

  @include absui-breakpoint-down(md) {
    @include absui-col(12);
  }
}

.absui-form-col-full {
  @include absui-col-ready();
  @include absui-col(12);
  padding: 0 12px;
}

/* Forms custom elements
   ========================================================================= */

.absui-custom-control {
  position: relative;
  display: block;
  padding-left: 24px;
  margin-bottom: 6px;
}

.absui-custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0 !important;
  height: auto;
  line-height: 1;
}

.absui-custom-control-label {
  cursor: pointer;
  user-select: none;
  line-height: $token-checkbox-size;
  padding-right: 6px;
}

.absui-custom-control-label::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: $token-checkbox-size;
  height: $token-checkbox-size;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: $token-form-element-background;
  border: 1px solid $token-form-element-border-color;
  border-radius: $token-checkbox-radius;
}

.absui-custom-control-label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: $token-checkbox-size;
  height: $token-checkbox-size;
  content: "";
  font-size: $token-checkbox-size;
}

.absui-custom-control-input:hover ~ .absui-custom-control-label::before {
  background-color: $token-form-element-background-hover;
  border-color: $token-form-element-border-color-hover;
}

.absui-custom-control-input:focus ~ .absui-custom-control-label::before {
  border-color: $token-form-element-border-color-focus!important;
}

.absui-custom-control-input:active ~ .absui-custom-control-label::before {
  background-color: $token-form-element-background-active;
  border-color: $token-form-element-border-color-active;
}

.absui-control-checkboxes,
.absui-control-radio {
  padding-top: 4px;
}

.absui-checkbox .absui-custom-control-input:checked ~ .absui-custom-control-label::before {
  background-color: $token-checkbox-checked-background;
  border-color: $token-checkbox-checked-background;
}

.absui-checkbox .absui-custom-control-input:checked:hover ~ .absui-custom-control-label::before {
  background-color: $token-checkbox-checked-background-hover;
  border-color: $token-checkbox-checked-background-hover;
}

.absui-checkbox .absui-custom-control-input.invalid:checked ~ .absui-custom-control-label::before {
  background-color: $token-form-element-border-color-invalid;
  border-color: $token-form-element-border-color-invalid;
}

.absui-checkbox .absui-custom-control-input.invalid:checked ~ .absui-custom-control-label::after {
  content: '\e5cd';
}

.absui-checkbox .absui-custom-control-input:checked ~ .absui-custom-control-label::after {
  font-family: Lumen-Linear-Icon-Font;
  content: '\e5ca';
  color: $token-checkbox-icon-color;
}

.absui-checkbox .absui-custom-control-input:active ~ .absui-custom-control-label::after {
  color: $token-checkbox-background-active;
}

.absui-checkbox .absui-custom-control-input.not-all-checked:checked ~ .absui-custom-control-label::before {
  background-color: $token-form-element-background;
  border-color: $token-form-element-border-color;
}

.absui-checkbox .absui-custom-control-input.not-all-checked:checked:hover ~ .absui-custom-control-label::before {
  background-color: $token-form-element-background-hover;
  border-color: $token-form-element-border-color-hover;
}

.absui-checkbox .absui-custom-control-input.not-all-checked:checked ~ .absui-custom-control-label::after {
  background-image: none;
  content: '';
  display: block;
  background-color: $token-checkbox-indeterminate-icon-color;
  height: 2px;
  width: 8px;
  top: 8px;
  left: 5px;
}

.absui-radio .absui-custom-control-label::before {
  border-width: 2px;
  border-radius: 50%;
}

.absui-radio .absui-custom-control-input:checked ~ .absui-custom-control-label::before {
  border-color: $token-checkbox-checked-background;
}

.absui-radio .absui-custom-control-input:checked ~ .absui-custom-control-label::after {
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  //position: absolute;
  //left: 5px;
  //top: 5px;
  transform: translate(5px, 5px);
  border-radius: 50%;
  background-color: $token-checkbox-checked-background;
}

.absui-radio .absui-custom-control-input:checked:hover ~ .absui-custom-control-label::after {
  background-color: $token-checkbox-checked-background-hover;
}

.absui-radio .absui-custom-control-input.invalid:checked ~ .absui-custom-control-label::after {
  background-color: $token-form-element-border-color-invalid;
}

.absui-radio .absui-custom-control-input.invalid:checked ~ .absui-custom-control-label::before,
.absui-custom-control-label.invalid::before {
  border-color: $token-form-element-border-color-invalid;
}

.absui-custom-control-input:disabled ~ .absui-custom-control-label,
.absui-custom-control-input:disabled ~ .absui-custom-control-label::before,
.absui-custom-control-input:disabled ~ .absui-custom-control-label::after {
  opacity: $token-form-element-opacity-disabled;
}

/* Form groups
   ========================================================================= */

.absui-form-group {
  display: flex;
  flex-wrap: wrap;
  margin: $token-form-group-inline-margin;

  @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
    margin: $token-form-group-vertical-margin-mobile;
  }

  @include absui-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }

  input {
    @include absui-breakpoint-down(sm) {
      width: 100%
    }
  }
}

.absui-form-group--vertical {
  display: block;
  margin: $token-form-group-vertical-margin;

  .absui-control-label {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 0 2px 0;
    font: $token-form-element-label-vertical-font;
    text-align: left;
  }

  .required::after {
    position: relative;
    right: 0;
  }

  .absui-control-wrap {
    width: 100%;
  }
}

.absui-form-group--inline {
  flex-wrap: nowrap;

  @include absui-breakpoint-down(sm) {
    display: block;
  }

  .absui-control-label {
    width: auto;
    white-space: nowrap;
    padding-left: 0;
  }

  .absui-control-wrap {
    width: 100%;
  }
}

.absui-form-group--small {
  .absui-control-label {
    width: 30%;
  }
  .absui-control-wrap {
    width: 70%;
  }
}

.absui-form-group--medium {
  .absui-control-label {
    width: 40%;
  }
  .absui-control-wrap {
    width: 60%;
  }
}

.absui-form-group--half {
  .absui-control-label {
    width: 50%;
  }
  .absui-control-wrap {
    width: 50%;
  }
}

.absui-form-group--big {
  .absui-control-label {
    width: 70%;
  }
  .absui-control-wrap {
    width: 30%;
  }
}

.absui-input-group {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: $token-form-element-radius;
  width: 100%;
}

.absui-input-group > .absui-form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-right: 0;
  padding-right: 4px;
}

.absui-input-group > .absui-form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.absui-input-group-prepend,
.absui-input-group-append {
  display: flex;
}

.absui-input-group > .absui-input-group-append > .absui-input-group-text,
.absui-input-group > .absui-input-group-prepend:not(:first-child) > .absui-input-group-text,
.absui-input-group > .absui-input-group-prepend:first-child > .absui-input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.absui-input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  padding: 0 4px;
  margin-bottom: 0;
  color: $token-theme-color-text-neutral-strong;
  text-align: center;
  white-space: nowrap;
  border: 1px solid $token-form-element-border-color;
  border-radius: $token-form-element-radius;
  background-color: $token-form-element-background;
}

.absui-input-group-controls {
  border-color: $token-form-element-border-color;
  border-left: none;
  font-size: $token-form-element-icon-size;
  line-height: 1;
  color: $token-form-element-icon-color;
  cursor: pointer;

  .absui-icon--schedule {
    margin-right: 3px;
  }
}

.absui-form-control:hover + .absui-input-group-append .absui-input-group-text,
.absui-form-control.invalid:hover + .absui-input-group-append .absui-input-group-text,
.absui-dropdown.show > .absui-input-group .absui-input-group-text,
.absui-input-group:hover .absui-form-control,
.absui-input-group:hover .absui-input-group-text {
  border-color: $token-form-element-border-color-hover;
  background-color: $token-form-element-background-hover;
}

.absui-form-control:focus + .absui-input-group-append .absui-input-group-text,
.absui-form-control.focus + .absui-input-group-append .absui-input-group-text {
  background-color: $token-form-element-background-focus;
  border-color: $token-form-element-border-color-focus;
}

/* Lumen search box
   ========================================================================= */

.absui-searchbox {
  width: 100%;

  &__hint {
    display: none;
    color: $token-theme-color-neutral-40;
    line-height: 26px;
    white-space: nowrap;
    margin-left: 8px;
  }

  .absui-icon--material-search {
    color: $token-form-element-search-icon-color;

    &:hover {
      color: $token-form-element-search-icon-color-hover;
    }
  }

  .absui-icon--close:not(:last-child) {
    padding: 0;
  }

  .absui-icon--expand-more {
    color: $token-form-element-search-icon-color-hover;
  }

  .absui-icon {
    font-size: $token-form-element-icon-size;
    padding: 0 8px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      height: 34px;
      padding: 0 12px;
    }
  }

  &--b {
    .absui-icon--material-search {
      margin-left: -12px;
    }
  }
}

.absui-searchbox,
.absui-spinner {

  .absui-form-control {
    display: flex;
    align-items: center;
    padding-right: 0;
    overflow: hidden;

    &:focus-within,
    &.focus {
      @include input-focus();
    }
  }

  input {
    width: 98%;
    height: calc(#{$token-form-element-height} - 2px);
    border: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      height: 34px;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

/* Lumen infobox
   ========================================================================= */

.absui-infobox {
  font-size: $token-infobox-text-size;
  font-family: $token-font-family-default;
  color: $token-infobox-text-color;
  white-space: pre-wrap;
  margin-bottom: 0;
  margin-left: 178px;
  //border-radius: $token-form-element-radius;

  &--strong {
    font-size: 16px;
    color: $token-body-font-color;
    padding: 12px;
    background-color: #FDE7E7;
  }
}

.absui-infotext {
  border-color: transparent!important;
  background: transparent!important;
  line-height: 28px;
}

/* Lumen spinner
 ========================================================================= */

.absui-spinner {
  width: 100%;

  &__controls-wrap {
    flex-direction: column;
    border-left: none;
    padding: 0;
  }

  &__controls {
    display: block;
    height: 16px;
    width: 36px;
    padding: 0;
    color: $token-form-element-icon-color;
    text-align: center;
    vertical-align: middle;
    position: relative;
    cursor: pointer;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      height: 17px;
    }

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.5;
    }

    .absui-icon {
      display: block;
      font-size: 14px;
      line-height: 16px;

      @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
        line-height: 18px;
      }
    }
  }

  .absui-form-control {
    position: relative;
    right: -1px;
  }
}

/* Lumen switch
   ========================================================================= */

.absui-switch {
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 38px;
  height: 18px;
  border-radius: 32px;
  padding: 4px;
  background-color: $token-checkbox-background;
  border: 1px solid $token-checkbox-border-color;

  &:hover,
  &:focus {
    background-color: $token-form-element-background-hover;
    border-color: $token-form-element-border-color-hover;
    cursor: pointer;
    outline: none;
  }

  &:after {
    font-family: 'lumen-linear-icon-font';
    content: $absui-icon--close;
    position: absolute;
    background: transparent;
    color: $token-color-neutral-40;
    left: 4px;
    bottom: -2px;
    font-size: 12px;
    font-weight: bold;
    transition: $token-time-transition-fast ease left;
  }

  &:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $token-color-neutral-40;
    left: 4px;
    bottom: 2px;
    transition: $token-time-transition-fast ease left;
  }

  &:checked {
    background-color: $token-checkbox-checked-background;
    border-color: $token-checkbox-checked-border-color;

    &:hover,
    &:focus {
      background-color: $token-checkbox-checked-background-hover;
      border-color: $token-checkbox-checked-border-color-hover;
    }

    &:after {
      content: $absui-icon--check;
      color: $token-checkbox-checked-background;
      left: 21px;
    }

    &:before {
      background-color: $token-checkbox-checked-icon-color;
      left: 21px;
    }
  }
}

/* Lumen withCheckbox
   ========================================================================= */

.absui-with-checkbox {
  display: flex;
  align-items: baseline;

  .absui-checkbox {
    margin-left: 8px;
  }
}

/* Lumen single-select-input
   ========================================================================= */

.absui-single-select-input {
  display: flex;
  width: 100%;
  min-width: 320px;

  .absui-dropdown {
    margin: 0;
    width: 128px;
    min-width: 128px!important;

    &.show .absui-form-control {
      z-index: 1;
    }

    &.show .absui-dropdown__expander {
      transform: rotate(0.5turn);
    }

    .absui-form-control {
      border-radius: 16px 0 0 16px;
      padding-right: 6px;

      &:focus,
      &:hover {
        z-index: 1;
      }
    }

    .absui-dropdown__expander {
      transform: rotate(0turn);
    }

    .absui-dropdown__value {
      display: block;
    }
  }

  & > .absui-form-control {
    border-radius: 0 16px 16px 0;
    margin-left: -1px;
  }
}

/* Lumen dropdown
   ========================================================================= */

.absui-dropdown {
  position: relative;
  cursor: pointer;

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $token-z-index-dropdown;
    float: left;
    min-width: 8rem;
    margin: 2px 0 0;
    padding: $token-dropdown-padding;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: $token-dropdown-background;
    background-clip: padding-box;
    border: 1px solid $token-dropdown-border-color;
    border-radius: $token-dropdown-radius;
    box-shadow: $token-dropdown-shadow;
    transition: all $token-dropdown-transition-time ease-out;
    transform-origin: top;
    visibility: hidden;
    transform: scaleY(0);

    .absui-dropdown.show > & {
      visibility: visible;
      transform: scaleY(1);
    }
  }

  &__menu--right {
    left: auto !important;
    right: 0;
  }

  &__menu--wide {
    width: 100%;
  }

  &__menu--up {
    top: auto;
    bottom: 100%;
    transform-origin: bottom;
  }

  &__menu--clean {
    border: none;
    box-shadow: none;
  }

  &__menu--nowrap {
    .absui-dropdown__link {
      white-space: nowrap;
    }
  }

  &__menu--flex {
    .absui-dropdown__link {
      display: flex;
      align-items: center;
    }
  }

  &__menu--nav {
    right: 0;
    left: inherit;
    min-width: 240px;
    border-radius: 0;

    @include absui-breakpoint-down(sm) {
      border: 1px solid $token-theme-color-brand-70 !important;
      width: 70% !important;
      margin: 0 auto 5px;
      box-shadow: none;
      position: static;
      float: none;

      .absui-dropdown__link {
        font-weight: 500;
        color: $token-link-text-color;
        border: 1px solid transparent;
        border-radius: 0;
        padding: 7px 10px;
      }
    }
  }

  &__item {
    margin: 4px 8px;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      margin: 16px 8px;
    }

    &:first-child {
      margin-top: 8px;
    }

    &:last-of-type {
      margin-bottom: 8px;
    }
  }

  &__item-title {
    line-height: 1.2;
  }

  &__item-desc {
    display: block;
    padding-top: 2px;
    color: $token-theme-color-text-neutral-middle;
    font-size: 12px;
  }

  &__link {
    display: flex;
    width: 100%;
    clear: both;
    font-weight: $token-font-weight-regular;
    color: $token-list-item-text-color;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    border-radius: $token-list-item-radius;
    min-height: $token-list-item-min-height;
    padding: 5px 8px;
    margin: 0!important;
    white-space: normal;
    cursor: pointer;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      padding: 12px 16px;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $token-form-element-border-color-focus;
      text-decoration: none;
    }

    &:hover {
      color: $token-list-item-text-color;
      background-color: $token-list-item-background-hover!important;
      text-decoration: none;
    }

    &:active {
      background-color: $token-list-item-background-active!important;
      box-shadow: none;
      text-decoration: none;
    }

    &.active,
    &.chosen {
      background-color: $token-list-item-background-chosen;
    }

    .absui-custom-control {
      padding: 1px;
      margin-bottom: 0;
    }

    .absui-custom-control-label {
      display: block;
      margin-bottom: 0;
      padding: 6px 6px 6px 36px;
      position: relative;

      &:before,
      &:after {
        top: 6px;
        left: 8px;
      }
    }

    .absui-icon {
      width: $token-list-item-icon-size;
      height: $token-list-item-icon-size;
      font-size: $token-list-item-icon-size;
      vertical-align: middle;

      &.active {
        color: $token-theme-color-icon-brand;
      }
    }

    .absui-icon--arrow-upward,
    .absui-icon--arrow-downward {
      font-size: 14px;
      line-height: 20px;
      color: $token-theme-color-text-neutral-middle;
      margin-right: 0;
    }

    .absui-simple-truncate {
      display: block;
    }
  }

  &__link--clipped {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__link--empty {
    color: $token-list-item-text-color-inactive;
    font-style: italic;
    cursor: default;
  }

  &__link-icon {
    color: $token-list-item-icon-color;
    margin-right: 8px;
  }

  &__link-img {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex: 0 0 auto;
    margin-right: 8px;
    width: $token-list-item-image-width;
    height: $token-list-item-image-height;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__name {
    padding-right: 2px;
  }

  &__divider {
    margin: 4px 0;
    border-bottom: 1px solid $token-list-item-divider-color;
  }

  &__value {
    @include ellipsis();
    line-height: 26px;
    display: block;
    border: 0;
    padding-left: 0;
    padding-right: 4px;
    height: 26px;
    width: 100%;
    background-color: transparent;
    outline: 0 !important;
    box-shadow: none !important;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      height: 34px;
      line-height: 34px;
    }

    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  &__panel {
    padding: 12px;
    border-bottom: 1px solid $token-dropdown-border-color;
    min-height: 44px;

    .absui-searchbox {
      display: flex;
    }

    .absui-custom-control {
      padding-left: 32px;
      min-height: 22px;
    }

    .absui-custom-control-label {
      position: absolute;
      left: 8px;
      top: 6px;
    }
  }

  &__panel-label {
    font-weight: $token-font-weight-medium;
    padding-left: 24px;
  }

  &__list {
    width: 100%;
    border: 0 !important;
  }

  &__list-scroll {
    max-height: 258px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &__header {
    font-size: $token-dropdown-title-font-size;
    font-weight: $token-font-weight-medium;
    color: $token-dropdown-title-color;
    padding: 10px 8px 6px;
    margin: 0 8px;
    border-bottom: 1px solid $token-dropdown-border-color;
  }

  &__actions {
    border-top: 1px solid $token-dropdown-border-color;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .absui-btn {
      @extend %btn-equal;
      margin-left: 10px;
    }
  }

  .absui-actions-box-footer {
    padding: 12px;
  }

  &__expander {
    font-size: $token-form-element-icon-size;
    line-height: 24px;
    color: $token-form-element-icon-color;
    //height: 24px;
    width: 18px;
    transition: all $token-dropdown-transition-time;
  }

  &__close {
    display: none;
  }

  &__filter-title {
    color: $token-filter-label-color;
    margin-right: 4px;

    & + * {
      overflow: hidden;
    }
  }

  &--hidden {
    visibility: hidden;
  }

  .absui-form-control {
    display: flex;
    align-items: center;
    min-height: $token-form-element-height;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      min-height: 36px;
    }
  }

  .absui-form-control--tags {
    height: auto;
    padding: 1px 6px;
    flex-wrap: wrap;

    .absui-tag {
      margin: 2px 4px 2px 0;
    }

    .absui-dropdown__value {
      height: 22px;
    }
  }

  .absui-form-control,
  .absui-input-group {
    cursor: pointer;
  }

  .absui-input-group-text {
    background-color: $token-form-element-background;
    padding: 0 10px;
  }

  &.show {

    > .absui-form-control {
      background-color: $token-form-element-background-open;
      border-color: $token-form-element-border-color-open!important;
      box-shadow: none!important;
    }

    .absui-searchbox .absui-form-control {

      &:focus-within,
      &.focus {
        border-color: $token-form-element-border-color-focus!important;
      }
    }
  }

  &:hover .absui-dropdown__close {
    display: block;
  }

  .absui-form-col-full & {
    @include absui-breakpoint-up(lg) {
      width: calc(50% - 88px);
    }
  }

  .absui-truncate p,
  .absui-dropdown__link p {
    line-height: inherit;
  }
}

.absui-dropdown--combo {

  &.show {
    .absui-form-control {
      color: $token-form-element-text-color;
      background-color: $token-form-element-background-open;
      border-color: $token-form-element-border-color-open;

      &:focus-within {
        background-color: $token-form-element-background-open;
      }
    }

    .absui-input-group {
      border-color: $token-form-element-border-color-open;
    }

    .absui-input-group-append .absui-input-group-controls {
      background-color: $token-form-element-background-open;
      border-color: $token-form-element-border-color-open;
    }

    .absui-form-control:focus-within + .absui-input-group-append .absui-input-group-text,
    .absui-form-control.focus + .absui-input-group-append .absui-input-group-text {
      background-color: $token-form-element-background-open;
    }
  }

  .absui-form-control:focus-within,
  .absui-form-control.focus {
    @include input-focus();
  }

  .absui-form-control:focus-within + .absui-input-group-append .absui-input-group-text,
  .absui-form-control.focus + .absui-input-group-append .absui-input-group-text {
    @include input-focus();
    border-left-color: transparent;
  }

  .absui-icon--close {
    margin-right: -12px;
  }
}

.absui-dropdown--filter {
  min-width: 80px;
  max-width: 300px;

  > .absui-form-control {
    color: $token-filter-value-color;
    border-color: $token-filter-border-color;
    background-color: $token-filter-background;
    padding: 0 12px;

    &:focus {
      border-color: $token-filter-border-color-focus;
      background-color: $token-filter-background-focus;
    }

    &:hover {
      border-color: $token-filter-border-color-hover;
      background-color: $token-filter-background-hover;
    }

    &:active {
      border-color: $token-filter-border-color-active;
      background-color: $token-filter-background-active;
    }
  }

  &.active {
    > .absui-form-control {
      background-color: $token-filter-seleted-background;
      border-color: $token-filter-seleted-border-color!important;

      &:focus {
        background-color: $token-filter-seleted-background-focus;
        border-color: $token-filter-seleted-border-color-focus!important;
      }

      &:hover {
        background-color: $token-filter-seleted-background-hover;
        border-color: $token-filter-seleted-border-color-hover!important;
      }

      &:active {
        background-color: $token-filter-seleted-background-active;
        border-color: $token-filter-seleted-border-color-active!important;
      }
    }

    &.show {
      > .absui-form-control {
        background-color: $token-filter-seleted-background-open;
        border-color: $token-filter-seleted-border-color-open!important;
      }
    }
  }

  &.show {
    > .absui-form-control {
      background-color: $token-filter-background-open;
      border-color: $token-filter-border-color-open!important;
    }
  }

  .absui-dropdown__value {
    user-select: none;
    display: flex;
  }

  .absui-dropdown__link {
    padding: 0;
    min-height: 0;
  }

  .absui-dropdown__expander {
    color: $token-filter-label-color;
    font-size: 16px;
  }
}

.absui-dropdown--filter-b {
  display: flex;
  flex-direction: column;
  border-radius: $token-dropdown-radius;
  margin-bottom: 8px;

  > .absui-form-control {
    border-color: transparent;
    background-color: $token-section-header-background;
    color: $token-link-text-color;
    font-size: $token-section-header-text-font-size;
    font-weight: $token-section-header-text-font-weight;
    border-radius: $token-section-header-radius;
    box-shadow: none;

    &:hover {
      background-color: $token-section-header-background-hover;
    }
  }

  &.active {
    //background-color: $token-list-item-background-active;
  }

  &.show {
    > .absui-form-control {
      background-color: $token-section-header-background-open;
      border-color: transparent!important;
    }

    .absui-dropdown__menu {
      height: auto;
    }
  }

  .absui-dropdown__expander {
    color: $token-filter-label-color;
    font-size: 16px;
  }

  .absui-dropdown__menu {
    position: static;
    width: 100%;
    height: 0;
    margin: 0;
    overflow: hidden;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  .absui-dropdown__panel {
    border: none;
  }

  .absui-dropdown__value {
    font-weight: $token-font-weight-regular;
    user-select: none;
    display: flex;
  }

  .absui-dropdown__link {
    padding: 0;
    min-height: 0;
  }

  .absui-dropdown__filter-title {
    font-weight: 500;
  }

  .absui-icon--close {
    margin-left: 4px;
  }

  .absui-control-label {
    width: auto;
  }

  .absui-control-wrap {
    flex: 1;
  }
}

.absui-dropdown--rich {

  .absui-dropdown__value {
    height: auto;
    padding: 6px 0;
  }

  .absui-dropdown__link {
    height: auto;
  }

  .absui-dropdown__expander {
    height: 50px;
    line-height: 50px;
  }

  .absui-form-control {
    height: auto;
  }
}

.absui-dropdown--check {

  .absui-dropdown__link {
    position: relative;
    padding: 5px 16px 5px 36px;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      padding: 16px 16px 16px 44px;
    }

    &.chosen:before {
      content: '\ea83';
      font-family: Lumen-Linear-Icon-Font;
      font-size: $token-form-element-icon-size;
      position: absolute;
      top: 5px;
      left: 10px;

      @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
        top: 16px;
        left: 13px;
      }
    }
  }
}

.absui-dropdown--custom {
  .absui-dropdown__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 100%;
    color: $token-color-orange-10;
    background-color: $token-color-orange-80;
  }

  .absui-dropdown__actions {
    margin-top: auto;
  }
}

.absui-dropdown-submenu {
  position: relative;

  &__toggle.absui-icon {
    color: $token-list-item-icon-color;
    margin-left: 8px;
    margin-right: 0;
  }

  .absui-dropdown__menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    margin-top: 0;
    margin-left: 2px;
    transform: scaleY(0);
  }

  .absui-dropdown__link {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    &:active,
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }

    &.open {
      background-color: $token-list-item-background-active;

      & + .absui-dropdown__menu {
        display: block;
        visibility: visible;
        transform: scaleY(1);
      }

      .absui-dropdown-submenu__toggle{
        transform: scaleX(-1);
      }
    }
  }
}

.absui-dropdown-virtulized-list {
  padding-right: 8px;

  .ReactVirtualized__Grid__innerScrollContainer {
    padding: 8px 0;
    box-sizing: content-box;
  }
}

.absui-dropdown.show .absui-icon--expand-more,
.absui-dropdown.open .absui-icon--expand-more {
  transform: rotate(0.5turn);
}

/* Lumen file upload
   ========================================================================= */

.absui-file-upload {
  position: relative;

  &__area {
    position: relative;
    min-height: $token-upload-area-min-height;
    padding: $token-upload-area-padding;
    border: 1px dashed $token-form-element-border-color;
    border-radius: $token-upload-area-radius;
    background-color: $token-upload-area-background;
    margin: 0 auto;
    text-align: center;

    @include absui-breakpoint-down(sm) {
      display: flex;
      padding: 0;
      border: none!important;
      min-height: unset;

      .absui-btn {
        width: 100%;
        justify-content: center;
      }
    }

    &:focus {
      border: 1px dashed $token-form-element-border-color-focus;
      outline: none;
    }

    &:active {
      border: 1px dashed $token-form-element-border-color-focus;
      background-color: $token-upload-area-background-active;
    }
  }

  &__label {
    color: $token-form-element-label-color;
    display: inline-block;
    margin: 0 4px;

    @include absui-breakpoint-down(sm) {
      display: none;
    }
  }

  &__info {
    color: $token-theme-color-text-neutral-middle;
    border-top: 1px solid $token-form-element-border-color;
    padding: 12px 0 0;
    margin: 8px 20px 0;

    @include absui-breakpoint-down(sm) {
      display: none;
    }
  }

  &__list {
    width: 100%;
    padding: 0;
    margin: 10px auto 0;
    list-style-type: none;
    background-color: $token-upload-list-background;
    box-shadow: $token-upload-list-shadow;
    border-radius: $token-upload-list-radius;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $token-upload-item-padding;
    margin: 1px 2px;
    position: relative;
    color: $token-upload-item-text-color;

    &:active,
    &:focus,
    &:hover {
      //background-color: $token-list-item-background-active;
    }

    &.error {
      color: $token-theme-color-text-negative;

      .absui-icon {
        color: $token-theme-color-icon-negative;
      }
    }

    .absui-icon--close {
      font-size: $token-form-element-icon-size;
      color: $token-theme-color-icon-negative;
      cursor: pointer;
      margin-left: auto;

      &:hover {
        color: $token-form-element-text-color;
      }
    }
  }

  &__item-icon {
    font-size: $token-upload-item-icon-size;
    color: $token-upload-item-icon-color;
    width: 40px;
  }

  &__item-info {
    width: 100%;
  }

  &__item-title {

  }

  &__item-size {
    color: $token-upload-item-subtext-color;
    font-size: $token-upload-item-subtext-size;
  }

  &__item-progress {
    width: 40px;
    margin-left: auto;
  }

  &__progress-bar-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $token-upload-progress-height;
    border-radius: $token-upload-progress-radius;
    background-color: $token-upload-progress-background;
  }

  &__progress-bar {
    display: block;
    height: $token-upload-progress-height;
    border-radius: $token-upload-progress-radius;
    background-color: $token-upload-progress-color;
  }

  &__status {
    float: right;
    font-weight: bold;
    padding: 3px 0 0 10px;

    .absui-icon {
      font-size: 18px;
      vertical-align: text-bottom;
    }
  }

  .absui-icon-help {
    position: absolute;
    top: 0;
    right: -46px;

    @include absui-breakpoint-down(sm) {
      position: relative;
      right: 0;
    }
  }
}

/* Validations
   ========================================================================= */

.absui-validation-header {
  border: 1px solid $token-form-element-border-color-invalid;
  border-radius: 3px 3px 0 0;
  background: $token-form-element-background-invalid;
  margin-top: 0;
  margin-bottom: -3px;
  padding: 12px 0;
  z-index: 15;
  position: relative;

  @include absui-breakpoint-down(xs) {
    display: flex;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    width: 100%;
  }

  &__title {
    font-size: 1rem;
    margin: 2px 0 0;
    line-height: 1.4em;
    padding-right: 15px;
  }

  &__icon {
    color: $token-theme-color-icon-negative;
    font-size: 1.8rem;
    display: block;
    padding: 0 32px 0 14px;
    width: 20px;
    float: left;
  }
}

%validation-style {
  border-color: $token-form-element-border-color-invalid;
}

.absui-form-control.invalid {
  @extend %validation-style;

  &:hover {
    @extend %input-hover-style;
  }

  &:focus,
  &.focus {
    //@include input-focus();
    border-color: $token-form-element-border-color-focus;
    outline: 0;
  }

  input {
    color: $token-theme-color-icon-negative;
  }
}

.absui-dropdown.show .absui-form-control.invalid,
.absui-dropdown.show .absui-form-control.invalid + .absui-input-group-append .absui-input-group-text {
  border-color: $token-form-element-border-color-open;
}

.absui-custom-control-input.invalid ~ .absui-custom-control-label::before {
  border-color: $token-form-element-border-color-invalid;
}

.absui-form-control.invalid + .absui-input-group-append .absui-input-group-text {
  border-color: $token-form-element-border-color-invalid;
}

.absui-form-control.invalid:hover + .absui-input-group-append .absui-input-group-text,
.absui-form-control.invalid + .absui-input-group-append:hover .absui-input-group-text {
  @extend %input-hover-style;
}

.absui-dropdown.show .absui-form-control.invalid:focus + .absui-input-group-append .absui-input-group-text {
  background-color: $token-form-element-background-open;
}

.absui-form-control.invalid:focus + .absui-input-group-append .absui-input-group-text,
.absui-form-control.invalid.focus + .absui-input-group-append .absui-input-group-text,
.absui-form-control.invalid:hover:focus + .absui-input-group-append .absui-input-group-text,
.absui-input-group:hover .absui-form-control.invalid:focus + .absui-input-group-append .absui-input-group-text {
  border-color: $token-form-element-border-color-focus!important;
  background-color: $token-form-element-background-invalid;
}

.absui-validation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $token-theme-color-text-negative;
  font-size: $token-form-element-notification-text-size;
  clear: both;
  padding: $token-form-element-notification-padding;

  &:before {
    font-family: Lumen-Linear-Icon-Font;
    font-size: $token-form-element-notification-icon-size;
    content: '\e002';
    margin-right: 4px;
  }
}

.absui-hint {
  font-size: $token-form-element-notification-text-size;
  color: $token-form-element-hint-color;
  clear: both;
  padding: $token-form-element-notification-padding;
}

/* Form panels
   ========================================================================= */

.absui-form-panel {
  position: relative;
  border-radius: $token-form-panel-radius;
  box-shadow: $token-form-panel-shadow;
  background: $token-form-panel-background;
  padding: 40px 40px 92px;
  margin: 0 auto 16px;

  @include absui-breakpoint-down(sm) {
    border-radius: 0;
    border-width: 1px 0 0 0;
    padding: 16px 16px 48px;
  }

  &--window {
    padding: 0;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }

  .absui-header-2b,
  .absui-header-3 {
    font-size: 22px;
    margin: 30px 0;
  }

  .absui-alert {
    margin-bottom: 32px;
  }

  &:before {
    z-index: 999;
  }
}

.absui-form-section-header {

  &__icon {
    color: $token-form-header-icon-color;
    font-size: $token-font-size-s;
    background: $token-form-header-icon-background;
    width: $token-form-header-icon-size;
    height: $token-form-header-icon-size;
    border-radius: $token-radius-circle;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    position: relative;
    top: -4px;
    margin-right: 8px;

    &.absui-icon--check {
      background: $token-theme-color-icon-positive;
    }
  }

  &__desc {
    margin: 0 12px 24px;
  }
}

.absui-form-footer {
  background: $token-form-panel-background;
  height: $token-form-footer-height;
  border-top: 1px solid $token-form-footer-separator-color;
  border-bottom-left-radius: $token-form-panel-radius;
  border-bottom-right-radius: $token-form-panel-radius;
  margin-top: -1px;

  @include absui-breakpoint-up(md) {
    margin-bottom: 10px;
  }

  &.fixed-bottom {
    margin-bottom: 0 !important;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;

    @include absui-breakpoint-down(sm) {
      height: auto;
    }
  }

  &.on-scroll {
    position: relative;
    margin-top: -70px;

    @include absui-breakpoint-down(sm) {
      margin-top: -80px;
    }

    .absui-form-footer__container {
      padding: 0 24px;

      @include absui-breakpoint-down(xs) {
        padding: 0 8px;
      }
    }
  }

  &--simple {
    background: transparent;
    border-top: none;
    margin-top: 0;

    .absui-form-footer__container {
      padding: 0;
    }
  }

  &__container {
    @include absui-container();
    @include absui-container-max();
    display: flex;
    justify-content: space-between;
    padding: 0 40px;

    @include absui-breakpoint-down(xs) {
      padding: 0 24px;
    }
  }

  &__group {
    padding: 10px 16px;

    &:last-of-type {
      text-align: right;
    }

    & > * {
      display: inline-block;
    }
  }

  .absui-btn:not(:first-of-type) {
    margin-left: 8px;
  }
}

.absui-form-nav {
  position: fixed;
  left: 16px;
  top: 16px;

  @include absui-breakpoint-down(sm) {
    display: none;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    width: $token-form-nav-item-size;
    height: $token-form-nav-item-size;
    margin: 15px;
    background-color: $token-form-nav-item-color;
    border-radius: $token-radius-circle;

    &.active {
      background-color: $token-form-nav-item-color-active;
    }
  }

  &__item-link {
    font-size: 8px;
    text-align: center;
    display: block;
    opacity: 0;
    overflow: hidden;
  }
}