.absui-popover__arrow {
  position: absolute;
  display: block;
  width: 16px;
  height: 8px;
  margin: 0 0.3rem;
  left: calc(50% - 180px);
  top: -10px;
  &:before,
  &:after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0 8px 10px;
  }

  &:before {
    top: 0;
    border-bottom-color: $color-neutral-27;
  }

  &:after {
    top: 1px;
    border-bottom-color: white;
  }
}