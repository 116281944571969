.abs-tabs-nested {
  width: 100%;

  .nav-tabs {
    border-bottom: 5px solid $tab-active-bg;
    display: flex;
    align-items: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }

  .nav-tabs > li {
    background: $tab-default-bg;
    @include border-radius(0px);
    @include borders($panel-a-border-color, 1px, 1px, 1px, 1px);
    font-weight: bold;
    margin: 0 -1px -2px 0;

    &:first-child {
      @include radius(0px, 0px, 0px, 0px);
    }

    &:hover {
      background: $tab-default-mouseover-bg;
    }

    &.active {
      @include borders($tab-active-bg, 1px, 1px, 1px, 1px);
      @include radius(0px, 0px, 0px, 0px);
      background: $tab-active-bg;
      z-index: 1;
      &:hover {
        background: $tab-active-bg;
      }

      > a, > a:hover {
        background: transparent;
        border: none;
        color: #fff;
        font-weight: 500;
        font-style: normal;
        cursor: pointer;

        .tab-title-additional-info {
          color: #fff;
        }
      }
    }

    > a, > a:hover {
      background: transparent;
      color: $abs-tab-item-color;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: .63em 1.5em;
      border: none;
      @include radius(0px, 0px, 0px, 0px);
      outline: none;

      @media (max-width: $grid-float-breakpoint-max) {
        text-align: center;
      }

      .tab-title-value {
        font-weight: 500;
      }

      .tab-title-additional-info {
        color: #999;
        font-weight: normal;
      }
    }
  }

  .tab-pane {
    &.tab-with-details {
      background: #fff;
      border: 1px solid $container-box-border-color;
      border-top: none;
    }
  }
}

.abs-tabs-nested.image-tabs {
  .nav-tabs > li {
    > a {
      padding: 2px 13px;
      .abs-icon {
        font-size: 20px;
      }
    }
  }
}

.abs-tabs-context {
  border-bottom: 2px solid $bootstrap-tabs-context-border;
  width: 100%;

  .nav-tabs {
    border-bottom: none;
    display: flex;
    align-items: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    position: relative;
    top: 2px;
  }

  .nav-tabs > li {
    background: transparent;
    @include border-radius(0px);
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Roboto-Medium', 'Roboto Medium', 'Roboto';
    font-weight: 500;
    margin: 10px 0 0 0;
    border-bottom: 5px solid transparent;

    &:focus {
      border: none;
    }

    > a {
      background: $tab-default-bg;
      border: none;
      color: $bootstrap-tabs-context-color;
      outline: none;
      padding: 15px;
    }

    &:hover {
      border-bottom: 5px solid $bootstrap-tabs-context-border-hover;

      a {
        background: $tab-default-bg;
        color: $bootstrap-tabs-context-color-hover;
      }
    }

    &.active {
      border-bottom: 5px solid $tab-active-bg;

      a {
        background: $tab-default-bg;
        color: $bootstrap-tabs-context-color-active;

        &:hover, &:focus {
          border: none;
        }
      }
    }

  }
}

.abs-tabs-context.tabs-in-center {
  .nav-tabs {
    justify-content: center;
  }
}


.abs-tabs-context .nav-tabs > li,
.abs-tabs-nested .nav-tabs > li {

  &.abs-pull-right-margin {
    margin-left: auto;
  }
}

.page-header-data {
  .abs-tabs-context {
    margin-bottom: -2px;
  }
}

body .abs-tabs-context .nav-tabs > li {
  margin: 0;
  border-bottom-width: 4px;

  &:hover {
    border-bottom-width: 4px;
    a {
      cursor: pointer;
    }
  }

  &.active {
    border-bottom-width: 4px;
  }

  a {
    margin: 0;
    padding: 11px 15px 12px 15px;

    .tab-title-value {
      @include flexbox();
      @include align-items(flex-end);

      .abs-icon {
        font-size: 22px;
        margin-right: 5px;
      }
    }

  }
}

.tab-title-value {
  display: flex;
  justify-content: center;
  line-height: 1.2;

  .abs-custom-tab-title-top {
    font-weight: 400;
  }
}

.abs-tabs-nested .nav-tabs > li > a, .abs-tabs-nested .nav-tabs > li > a:hover {
  display: flex;
  align-items: flex-start;
}

body .abs-tabs-nested .nav-tabs {
  border-bottom-width: 4px;
  > li {
    > a {
      padding: 12px 20px 13px;
      white-space: nowrap;
      &:hover {
        padding: 12px 20px 13px;
      }
    }
  }
}

.abs-tabs-context .nav-tabs > li.active {
  border-bottom: 5px solid $tab-context-active-border-color;
}

.abs-tabs-nested .nav-tabs {
  border-bottom-color: $tab-nested-active-bg-color;

  > li.active {
    background: $tab-nested-active-bg-color;
    @include borders($tab-nested-active-bg-color, 1px, 1px, 1px, 1px);
  }
}
