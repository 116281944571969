
%alert-msg-placeholder{
	font-size:1em;
	margin:-5px 0px 10px 0px;
	font-weight:bold;
}

.message-box{
	border-top:1px solid $abs-login-message-border-top;
	margin:8px 30px 0px 85px;
	width:auto;
	clear:both;
	position:relative;
	padding:15px 0px 0px 0px;
	@media (max-width: $abs-login-panel-width) {
		margin-left:65px;
	}

	.abs-icon{
		color:$abs-login-message-icon-color;
		float:left;
		margin-right:7px;
		margin-top:-3px;
		font-size:24px;
	}
	p{
		padding-left:30px;
	}
}
.message-box-type-a {
	background: $abs-login-message-type-a-bg;
	border-left: 2px solid $abs-login-message-type-a-border;
	padding: 5px 8px;
	color: $abs-login-font-color;
	margin: -10px 0px 15px 0px;
	>p{
		opacity:.8;
		margin:0px;
	}
}

.login-box-triangle{
	color:$sub-nav-bg;
	fill:currentColor;
	display:inline;
	svg{
		margin-top:-1px;
	}
	@media (max-width: $grid-float-breakpoint-max) {
		margin-left:-38px;
	}
}

.logo-right{
	float:right;
	margin:32px 30px;
	@media (max-width: $grid-float-breakpoint-max - 300) {
		display:none;
	}

}


.login-box.login-box-ihd{
	@media (max-width: $abs-login-panel-width + 20) {
		background: url("../../../img/login-panel-ihd-bg.png") -120px bottom $sub-nav-bg no-repeat;
	}
	background: url("../../../img/login-panel-ihd-bg.png") bottom center $sub-nav-bg no-repeat;
}

.login-box{
	@include radius(8px, 8px, 8px , 0px);
	background:$sub-nav-bg;
	width:$abs-login-panel-width;
	position:relative;
	@media (max-width: $abs-login-panel-width + 20) {
		width:100%;
		//@include border-radius(0px);
		background: url("../../../img/login-panel-bg-lumen.png") -120px 0px no-repeat $sub-nav-bg;
		background-size:cover;
	}
	@media (max-width: $grid-float-breakpoint-max) {
		@include radius(0px);
	}
	padding:40px;
	background: url("../../../img/login-panel-bg-lumen.png") top center no-repeat $sub-nav-bg;
	background-size:cover;

	.lang-box{
		float:right;
		position:absolute;
		top:20px;
		right:20px;


		.dropdown-menu{
			@include border-radius(0px);
			@include box-shadow(none);
			border-color:$lang-box-border-color;
			padding:0px;
			margin:2px 0px 0px 0px;
			min-width:60px;
			li a{
				padding:.28em 1.8em .38em .9em;
				font-size:1em;
				@include border-radius(2px);
			}
			li a:hover{
				background:$context-menu-hover-bg;
			}
		}
		.dropdown.open button{
			&.dropdown-toggle.btn-default i{
				transform: rotate(180deg);
				transition: transform .2s linear;
			}
		}
		.dropdown button{
			@include box-shadow(none);
			@include border-radius(2px);
			background:$abs-menu-default-link-hover-bg;
			border-color:$abs-menu-default-link-hover-bg;
			color:$abs-login-font-color !important;
			padding:0px 7px 0px;
			font-size:12px;
			height:26px;
			line-height:26px;
			position: relative;
			span{
				line-height:26px;
				height:26px;
				display: block;
				padding-right:13px;
			}
			i{
				margin-left:5px;
				margin-right:11px;
				font-size:14px;
				height:26px;
				line-height:26px;
				position: absolute;
				top:0px;
				&:before{
					// position:absolute;
					// top:0px;
				}
			}

			&.dropdown-toggle.btn-default i{
				transform: rotate(0deg);
				transition: transform .2s linear;
			}



			&:hover , &:active ,&:focus{
				background:darken($abs-menu-default-link-hover-bg,2);
				border-color:darken($abs-menu-default-link-hover-bg,2);
				outline:none;
			}
		}
	}
	.login-box-panel{
		float:right;
		width:240px;
		@media (max-width: $grid-float-breakpoint-max - 300) {
			margin:0px auto;
			float:none;

		}

		h1{
			color:$abs-login-font-color;
			text-transform: uppercase;
			margin:0px;
			padding:0px 0px 29px 0px;
			font-size:$abs-login-page-title-size;
			margin-top:16px;
			font-size:17px;
		}
		.form-group{
			margin-bottom:9px;
		}
		label{
			color:$abs-login-font-color;
			font-weight:normal;
			margin-bottom: 3px;
		}
		input:not([type='checkbox']), .k-textbox{
			@include border-radius(2px);
			border-color:$abs-login-button-bg;
			text-indent: .2em;
			//height:2.23em;
			height:26px;
			// &:focus{
			// 	@include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5));
			// }
		}
		input.k-invalid{
			@extend %validation-style;
		}
		input[type='checkbox'] {
			@include box-shadow( 0px 0px 0px 0px rgba(0, 0, 0, 0.5));
		}
		input:focus{
			@include box-shadow(none);
			background:$color-brand-primary-light;
			border-color:$color-brand-primary-light;
		}

		.remember-me{
			margin-top:17px;
		}

		.remember-me input, .remember-me label{
			//display:block;
			//float:left;
			//height:auto;
			// color:$abs-login-remember-me-color;
			color:$abs-login-font-color;
			//padding-left:1.6em;
		}
		.remember-me input{
			//margin:3px 10px 0px 0px;
		}
		.k-checkbox-label:hover:before{
			border-color:$abs-login-button-bg;
		}
		.k-checkbox-label:before{
			@include border-radius(2px);
			width:13px;
			height:13px;
			top:-1px;
			border-color:$abs-login-button-bg;
			top:1px;

		}
		.k-checkbox-label:after, .k-checkbox:checked + .k-checkbox-label:after{
			@include border-radius(2px);
			width:13px;
			height:13px;
			border-color:$abs-login-check-border;
			margin-top:-1px;
			top:2px;
			&:hover {
				//@extend %input-hover-style;
			}
			&:focus {
				//@extend %input-focus-style;
			}
			&:active {
				//@extend %input-active-style;
			}
		}
		.k-checkbox:checked + .k-checkbox-label:after{
			padding-top:1px;
			padding-left:1px;
			font-size:.8em;
		}





	}
	.btn-type-d{
		background:$abs-login-button-bg;
		border-color:$abs-login-button-bg;
		color:$abs-login-button-color;
		text-transform: uppercase;
		font-weight:bold;
		padding:2px 30px;
		float:right;
		margin-top:40px;
		font-size:.9em;
		&:hover{
			background:$abs-login-button-bg-hover;
			border-color:$abs-login-button-bg-hover;
			color:$abs-login-button-color-hover;
		}
		&:focus{
			@include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5));
		}
	}
}



@-moz-document url-prefix() {

	.login-box .login-box-panel .k-checkbox:checked + .k-checkbox-label:after{
		padding-top:1px;
		padding-left:1px;
		font-size:.8em;
	}
}



.login-page{
	//display:block;
	border-top:6px solid $abs-login-page-color-top;
	background:$abs-login-page-background;
	//padding-top:15%;
	@include flexbox();
	@include align-items(flex-end);
	@include justify-content(flex-end);
	@media (max-width: $grid-float-breakpoint-max) {
		// padding-top:10%;
		@include justify-content(flex-start);
		@include align-items(stretch);
		border-top:0px;

	}
	.abs-alert{
		width: 86.3%;
		margin-right: 26px;
		margin-left: auto;
		margin-top:7px;
		@media (max-width: $grid-float-breakpoint-max) {
			position: absolute;
			bottom: 0px;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			margin-top: 0px;
			left: 0;
			right: 0;
		}
	}
	.abs-alert span {
		font-weight: bold;
		color:$color-neutral-100;
		@media (max-width: $grid-float-breakpoint-max) {
			padding: 5px;
		}
	}


	.container-fluid, .col-sm-12{
		padding:0px;
	}

	.login-container{
		@include flexbox();
		@include align-items(stretch);
		@include justify-content(center);
		@include flex-direction(column);
		max-width:$abs-login-panel-width;
		position:relative;
		flex-grow:2;
		width:100%;
		@media (max-width: $grid-float-breakpoint-max) {
			@include justify-content(flex-start);
		}
	}
}

.footer-type-a{
	align-self:flex-end;
	// position: absolute;
	// bottom: 0px;
	width: 100%;
	font-size: 12px;
	padding: 0px;
	// @media (max-width: $abs-login-panel-width) {
	// 	position:relative;
	// }
	.copyrights{
		background:$abs-footer-bg;
		color:$abs-footer-copyrights-color;
		width:100%;
		display:block;
		padding:10px 0px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		> span{
			padding:0px 10px 0px 20px;
			// max-width:900px;
			// display:block;
			// text-align:center;
			// margin:0px auto;
		}
	}
	.cookies{
		background: $abs-footer-type-a-bg;
		color: $abs-footer-type-a-color;
		padding:15px 0px;
		@media (max-width: $grid-float-breakpoint-max) {
			padding:15px;
		}
		> span{
			max-width:900px;
			display:block;
			text-align:center;
			margin:0px auto;
			a{
				color:$abs-footer-type-a-color;
				text-decoration:underline;
				cursor:pointer;
				&:hover{
					color:$abs-footer-link-color;
				}
			}

		}
	}
}
