
%thumbnail-panel-width-behavior{
	width:45%;
  	@media (min-width: $grid-float-breakpoint-max)  and (max-width: $grid-breakpoint-desktop) {
  		width:100%;
  	}
}


%thumbnail-panel-span{
	padding:3px 0px;
 	color:$thumbnail-panel-color;
 	font-size:11px;
 	display:inline-block;
}


.thumbnail-panel{
	@include border-radius(2px);
	@include borders($thumbnail-panel-border-color,1px, 1px, 1px, 1px);	
	
	position: relative;
	width:140px;
 	height: 140px;
 	overflow: hidden;
 	padding:0px; 	
 	display:block;
 	float:left;
 	margin:0px 10px 10px 0px;
 	box-sizing: content-box;
 			
 	&:hover{ 		
 		
 		border-color:$thumbnail-panel-border-hover-color;
 		padding:0px;
 		height: 140px; 		
 		text-decoration:none; 		
 		
 		&:after{
 			font:normal normal normal 14px/1 FontAwesome;
 			content: "\f00e";
 			text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-size:30px;
            color:$sub-nav-bg;
            position:absolute;
            top:5px;
            left:5px;

 		}
 	}

 	> img{
 		position: absolute;
  		left: 50%;
  		top: 50%;
  		height: 100%;
  		width: auto;  		
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);		
        -webkit-transform: scale(50%,50%);
        -ms-transform: scale(50%,50%);
        transform: scale(50%,50%);		
 	}
 	> .thumbnail-desc{
 		background:$thumbnail-panel-desc-bg;
 		border-top:1px solid $thumbnail-panel-border-color; 		
 		position:absolute;
 		bottom:0px; 		 		
 		padding:6px 10px 6px 10px; 		
 		height:auto; 		
 		width:100%;
 		> span:nth-of-type(1){
      @extend %ellipsis; 			
 			width:90%;  			
  		display:block;
 		}
 		> span:nth-of-type(2){
 			@extend %thumbnail-panel-span;
 			text-align:left;
 			float:left;
 			display:inline-block;
 			
 		}
 		> span:nth-of-type(3){
 			@extend %thumbnail-panel-span;
 			float:right; 			
 			margin-right:20px;
 			
 		}
 	}
}

.thumbnail-2-container{
	margin:0px 20px;
	.thumbnail-panel{
		margin:0px 0px 10px 0px;
	}
	.thumbnail-panel, .thumbnail-panel:hover{
		height: 180px;
		@extend %thumbnail-panel-width-behavior;
	}	
	.thumbnail-panel:nth-of-type(odd){
		margin-right:20px;
	}
}
