.abs-attributes-list {
  // background: #fff;
  @include flex-wrap(wrap);
  @include flex-direction(row);
  padding: 16px 10px 24px 10px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 10px 0px;
  }

  .col-sm-4 {
    margin-bottom: 20px;
    padding-left: 1%;
    padding-right: 1%;
    width: 31.3%;
    @media (max-width: $grid-float-breakpoint-max) {
      padding-left: 4%;
      padding-right: 4%;
      width: 92%;
    }
    @media (max-width: $screen-md-min + 100) and (min-width: $grid-float-breakpoint-max) {
      width: 48%;
    }
  }

  .col-md-6 {
    margin-bottom: 20px;
    padding-left: 1%;
    padding-right: 1%;
    @media (max-width: $screen-md-min + 100) {
      width: 100%;
    }
  }

  .col-sm-4, .col-md-6 {
    .abs-title {
      // background: #fff;
      display: inline-block;
      padding-right: 10px;
    }

    hr {
      margin-top: -18px;
    }

    .dt-col-1 {
      display: block;
      float: left;
      font-size: 1em;
      margin-bottom: 0px;
      width: 100%;
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px 10px;
      }
      dt, dd {
        display: inline-block;
        float: left;
        padding: 5px 0px;
      }
      dt {
        padding-right: 10px;
        text-align: right;
        width: 38%;
      }
      dd {
        width: 58%;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }

  &.abs-attributes-list-style-a {

    .dt-col-1 {
      dt {
        font-weight: bold;
      }
    }
  }

  &.abs-attributes-list-style-b {

    .dt-col-1 {
      dt {
        color: $abs-title-color;
        font-weight: normal;
      }
      dd {
        color: $abs-font-color;
      }
    }
  }
}


.abs-attributes-list {
  padding: 0;
  @media (max-width: $grid-breakpoint-desktop-large) {
    padding: 0;
  }

  .abs-attributes-list-section {
    width: 100%;
  }

  .abs-attributes-list-column {
    @media (min-width: $desktop-mode-min) {
      min-width: 30%;
      max-width: 45%;

      &:not(:first-child) {
        margin-left: 5%;
      }
    }

    @media (max-width: $mobile-mode-max) {
      width: 100%;
    }

    .abs-attributes-list-section {
      @include clearfix();

      /*.abs-title {
        font-weight: 500;
      }*/

      &:not(:first-child) {
        .abs-title {
          margin-top: 50px;
        }
      }

      &__items {
        @media (min-width: $desktop-mode-min) {
          display: table;
          padding: 0 10px;
          width: 100%;
        }

        .abs-attributes-list-item {
          @media (min-width: $desktop-mode-min) {
            display: table-row;
            float: none;
            width: auto;
          }

          @media (max-width: $mobile-mode-max) {
            margin-bottom: 0;
            display: flex;
          }

          &__label {
            font-weight: bold;
            padding: 5px 10px 5px 0;
            text-align: right;
          }

          &__value {
            padding: 5px 0;
            width: 60%;
          }

          &__label, &__value {
            @media (min-width: $desktop-mode-min) {
              display: table-cell;
            }

            @media (max-width: $mobile-mode-max) {
              width: 50%;
              display: inline-block;
              margin-bottom: 0;

              &.text-align-right {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  &.loader-mask-style-a {
    height: 35px;
  }

  .abs-header-line-invisible {
    visibility: hidden;

    @media (max-width: $mobile-mode-max) {
      display: none;
    }
  }

  .abs-header-line-without-title {
    margin-top: 10px;
  }

  .abs-attributes-list-info{
    width: 100%;
    text-align: justify;
  }

  .abs-attributes-list-info-truncate {
    text-align: right;
    margin-top: 5px;
  }

}

.import-info-list-item-part {
  float: right;
}

.import-info-list-item {
  white-space: nowrap;
}

.import-info-list {
  word-break: break-all;
}

.abs-attributes-list-item-lowercase {
  text-transform: lowercase;
}

.tab-content .abs-attributes-list {
  background: $color-neutral-0;
  border: 1px solid $color-neutral-20;
  border-top: none;
  padding: 20px 20px;
}
