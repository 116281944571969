.toast-flex-container {
  @include flexbox();
  @include flex-direction(column);
  position: fixed;
  right: 10px;
  top: -10px;
  z-index: 9999;
}

.toast-body {
  align-items: flex-end;
}


.toast-flex-item {
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
  max-width: 400px;
  min-width: 300px;
  float: right !important;
}

.toast-slide-in {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: slide-in 0.2s forwards;
  -webkit-animation: slide-in 0.2s forwards;
}

.toast-slide-out {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: slide-out 0.2s forwards;
  -webkit-animation: slide-out 0.2s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(1000px);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(1000px);
  }
}


.abs-toast {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  button.close {
    color: rgba(119, 119, 119, 1);
    font-size: 16px;
    font-family: Lumen-Icon-Font;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      color: rgba(0, 0, 0, 1);
      background: #DCDCDC;
      border-radius: 2px;
    }
  }

  .toast-margin {
    margin: 10px 10px 10px 15px;
  }

  .toast-head {
    font-family: Roboto-Medium, Roboto;
    font-size: 15px;
    line-height: 18px;
    margin: $abs-toast-margin-top-all-elements $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
    font-weight: bold;
  }

  .toast-footer {
    font-family: Roboto-Regular, Roboto;
    font-size: 13px;
    line-height: 15px;
    margin: $abs-toast-margin-top-all-elements $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
  }

  .toast-body {
    font-family: Roboto-Regular, Roboto;
    font-size: 13px;
    margin: $abs-toast-margin-top-all-elements $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
  }

  .toast-browser-link {
    display: inline-block;
    margin-right: 5px;
  }

  .toast-line-info {
    border-color: $abs-toast-info-border-color;
    opacity: .2;
    size: 1px;
    margin: $abs-toast-margin-top-line $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
  }

  .toast-line-danger {
    border-color: $abs-toast-danger-border-color;
    opacity: .2;
    size: 1px;
    margin: $abs-toast-margin-top-line $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
  }

  .toast-line-success {
    border-color: $abs-toast-success-border-color;
    opacity: .2;
    margin: $abs-toast-margin-top-line $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
  }

  .toast-line-warning {
    border-color: $abs-toast-warning-border-color;
    opacity: .2;
    margin: $abs-toast-margin-top-line $abs-toast-margin-right-all-elements $abs-toast-margin-bottom-all-elements $abs-toast-margin-left-all-elements;
  }

  &.toast-info {
    background: $abs-toast-info-background-color;
    border: 2px solid $abs-toast-info-border-color;
  }

  &.toast-danger {
    background: $abs-toast-danger-background-color;
    border: 2px solid $abs-toast-danger-border-color;
  }

  &.toast-success {
    background: $abs-toast-success-background-color;
    border: 2px solid $abs-toast-success-border-color;
  }

  &.toast-warning {
    background: $abs-toast-warning-background-color;
    border: 2px solid $abs-toast-warning-border-color;
  }

}

.close-animation-block {
  -moz-transition: all .5s;
  -o-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
  height: 0;
  overflow: hidden;
}

.toastContainer-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: 1051;
}

.toastContainer {
  display: flex;
  max-width: 420px;
  min-width: 300px;
  margin: 0 0 8px;
  padding: 16px;
  border: 2px solid transparent;
  border-radius: 3px;
  background-color: #ffffff;

  &__header {
    font-family: Roboto-Medium,Roboto;
    font-size: 15px;
    line-height: 18px;
    margin: 10px 30px 10px 15px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__body {
    font-family: Roboto-Regular,Roboto;
    font-size: 13px;
    margin: 10px 30px 10px 15px;
  }

  &__footer {
    font-family: Roboto-Regular, Roboto;
    font-size: 13px;
    line-height: 15px;
    margin: 10px 30px 10px 15px;
  }

  &__icon {
    font-size: 20px;
    width: 32px;
  }

  &__content {
    flex-basis: 100%;
    max-width: 400px;
    min-width: 300px;
  }

  &__link {
    display: inline-block;
    margin-right: 5px;
  }

  &--info {
    border: 2px solid #00a4e1;
    border-radius: 3px;
    background: #f0fafd;
    box-shadow: 0px 6px 16px 0px rgba(35, 36, 42, 0.1);
    margin: 0 0 8px;
  }

  &--danger {
    border: 2px solid #dd9985;
    border-radius: 3px;
    background: #fef5f5;
    box-shadow: 0px 6px 16px 0px rgba(35, 36, 42, 0.1);
    margin: 0 0 8px;
  }

}

.toastContainer-slide-in {
  transform: translateX(100%);
  animation: slide-in 0.25s forwards;
}

.toastContainer-slide-out {
  transform: translateX(100%);
  animation: slide-out 0.25s forwards;
}

.toastContainer-close {
  color: #777;
  font-size: 16px;
  font-family: Lumen-Icon-Font;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 10px 10px 15px;
}