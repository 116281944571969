/* Lumen toasts
   ========================================================================= */

.absui-toast-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: 1051;
}

.absui-toast {
  display: flex;
  max-width: 380px;
  min-width: 300px;
  margin: $token-toast-margin;
  padding: $token-toast-padding;
  border: 1px solid transparent;
  border-radius: $token-toast-radius;
  background-color: $token-toast-background;
  box-shadow: $token-toast-shadow;

  &__header {
    font: $token-toast-title-font;
    color: $token-toast-title-color;
    margin: 0 0 8px;
  }

  &__body {
    font-size: $token-toast-text-font-size;
  }

  &__icon {
    font-size: $token-toast-icon-size;
    width: 32px;
  }

  &__content {
    flex-basis: 100%;
  }

  &__link {
    display: inline-block;
    margin-right: 5px;
  }

  &__footer {
    font-size: $token-toast-text-font-size;
    line-height: $token-toast-line-height;
    margin-top: 8px;
  }

  &--info {
    border-color: $token-toast-info-border-color;

    .absui-toast__icon {
      color: $token-toast-info-icon-color;
    }
  }

  &--danger {
    border-color: $token-toast-danger-border-color;

    .absui-toast__icon {
      color: $token-toast-danger-icon-color;
    }
  }

  &--success {
    border-color: $token-toast-success-border-color;

    .absui-toast__icon {
      color: $token-toast-success-icon-color;
    }
  }

  &--warning {
    border-color: $token-toast-warning-border-color;

    .absui-toast__icon {
      color: $token-toast-warning-icon-color;
    }
  }

  .absui-close {
    position: relative;
    top: -8px;
    right: -8px;
  }
}

.toast-slide-in {
  transform: translateX(100%);
  animation: slide-in $token-toast-transition-time forwards;
}

.toast-slide-out {
  transform: translateX(100%);
  animation: slide-out $token-toast-transition-time forwards;
}

@keyframes slide-in {
   100% { transform: translateX(0%); }
 }

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(1000px); }
}

.close-animation-block {
  transition: all $token-toast-transition-time;
  height: 0;
  overflow: hidden;
}