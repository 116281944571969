.fw-accordion-panel {
  position: relative;
  height: 47px;
  width: 910px;
  border-bottom: 2px solid $color-neutral-16;
  cursor: pointer;
  margin: 30px;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    width: 620px;
  }

  @media (max-width: $mobile-mode-max) {
    width: auto;
  }

  &.mouseOver {
    border-bottom-color: $color-brand-primary-dark;
    .fw-accordion-panel-expand-icon {
      color: $color-brand-primary-dark;
    }
  }
  &.collapsed {
    border-bottom-color: $color-brand-primary;
    .fw-accordion-panel-expand-icon {
      color: $color-brand-primary;
    }
  }
  &.incorrect {
    .fw-accordion-panel-expand-icon {
      border-left: 1px solid $color-neutral-16;
    }
    .values-sequence {
      .values-sequence-value {
        &:first-child {
          color: $color-negative-dark-2;
        }
      }
    }
  }
  .values-sequence {
    margin-left: 20px;
    font-size: 13px;
    color: $color-neutral-60;
    .values-sequence-value {
      &:first-child {
        font-weight: 500;
        color: $color-neutral-100;
      }
    }
  }
}

.fw-accordion-panel-expand-icon {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 10px;
  padding-right: 12px;
  padding-left: 12px;
  border-right: 0px;
  height: 27px;
  line-height: 27px;
  color: $color-neutral-16;
}

.fw-accordion-panel-export-icon {
  margin-left: auto;
  margin-right: 58px;
  z-index: 1;
}

.fw-accordion-panel-grid {
  width: 95%;
  margin: auto;
  margin-top: 20px;
}

.unsent-invoice-accordion-grid {
  width: 96%;
}

@media (max-width: 991px) {
  .unsent-invoice-accordion-grid {
    width: 700px;
  }
}

@media (max-width: $mobile-mode-max) {
  .unsent-invoice-accordion-grid {
    width: 100%;
  }
}