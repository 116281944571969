
.abs-breadcrumb {
	@media (max-width:$grid-breakpoint-desktop-large) {
		margin-left: 0px;
		margin-right: 10px;
	}
	display: inline-block;
	padding: 10px 0px;
	color: $breadcrumb-line-color;
	font-size: 1.1em;
	border-bottom: 1px solid $breadcrumb-border-color;

	span {
		color: $breadcrumb-color;
	}
}