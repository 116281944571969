.timeline-type-a .timeline-block .timeline-date > span {
  font-size: 1em;
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open > .dropdown-menu > div.abs-quick-search-open {
  width: 334px;
  padding: 0;
}

.invoice-textarea {
  height: 100px !important;
  width: 300px !important;
}

.invoice-search-page {

  >.abs-container {
    padding: 0;
    max-width: none;

    @media (max-width: $mobile-mode-max) {
      margin: 0 0;
    }
  }

  .container-details-panel {
    border: none;

    .abs-tabs-context {
      margin-bottom: 0;
    }
  }

  .invoice-search {
    margin-top: -20px;

    &__filter-panel {
      background: $color-neutral-0;
      border-bottom: 2px solid $color-neutral-16;

      @media (min-width: $desktop-mode-min) and (max-width: $grid-breakpoint-desktop-large - 1) {
        padding: 0 20px 0 14px;
      }

      .abs-container {
        background: none;
        padding: 20px 0;

        .abs-table-bar--collapsed {
          background: $color-neutral-0;
        }
      }

      .abs-filter-panel {
        background: $color-neutral-0;
        border: none;

        .abs-filter-panel__main-filters {
          @media (min-width: $grid-breakpoint-desktop-large) {
            margin-left: -6px;
          }
        }

        .abs-filter-panel__buttons {
          justify-content: flex-end;

          .abs-filter-panel__submit {
            border: none;
            padding: 0;
          }
        }
      }
    }

    &__result {

      .invoice-search-indicator {
        display: flex;
        flex-direction: column;
        margin-top: 70px;

        .invoice-search-indicator-loader {
          height: 35px;

          &.loader-mask-style-b:before {
            background-color: transparent;
          }
        }

        .invoice-search-indicator-text {
          margin: 10px auto;
          text-align: center;
        }
      }
    }
  }
}

.icon-with-text.invoice-corrective-status-icon {
  align-items: flex-start;

  .abs-icon {
    width: 16px;
  }
}
