// Base styles
// --------------------------------------------------

.btn {
  z-index: 99;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  @include user-select(none);
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include borders($btn-white-pressed-bg, 1px, 1px, 1px, 1px);
      background: $btn-white-pressed-bg;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }

  // [converter] extracted a& to a.btn
}

.btn-anim {
  &:after {
    z-index: 99999;
    background: #fff;
    content: "";
    height: 150px;
    width: 25px;
    left: -45px;
    opacity: .2;
    position: absolute;
    top: -60px;
    transform: rotate(35deg);
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {

    &:after {
      left: 120%;
      transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}

.btn-xs {
  // @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
  line-height: 18px;
  padding: 2px 6px;
  font-size: 13px;
}






.btn-type-a {
  @include button-variant($btn-type-a-color, $btn-type-a-bg, $btn-type-a-border-color);
  @include box-shadow(none);
  @include border-radius(2px);
  @include borders($btn-type-a-border-color, 2px, 2px, 2px, 2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      background: darken($btn-type-a-bg,20);
      outline: 0;
      border:2px solid darken($btn-type-a-bg,20);
    }
  }
  &:hover {
    @include borders($btn-type-a-hover-border-color, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    background: $btn-type-a-hover-bg;
    color: $btn-type-a-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-a-focus-border-color, 2px, 2px, 2px, 2px);
    @include box-shadow(0px 0px 3px 0px rgba(0, 0, 0, 0.5));
    background: $btn-type-a-focus-bg;
    color: $btn-type-a-states-color;
    text-decoration: none;
    &:hover {
      background: $btn-type-a-hover-bg;
      border-color: $btn-type-a-hover-bg;
    }
  }
  &:active,
  &.active {
    @include borders($btn-type-a-active-border-color, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;
    background: $btn-type-a-active-bg;
    color: $btn-type-a-color;

    &:hover {
      @include borders($btn-type-a-states-border-color, 2px, 2px, 2px, 2px);
      @include box-shadow(none);
      background: $btn-type-a-states-bg;
      color: $btn-type-a-states-color;
    }
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
    &:hover, &:active, &:focus, &:visited {
      background: $btn-type-a-states-bg;
      color: $btn-type-a-states-color;
      @include borders($btn-type-a-border-color, 2px, 2px, 2px, 2px);
    }
  }
}






.btn-type-b {
  @include button-variant($btn-type-b-color, $btn-type-b-bg, $btn-type-b-border-color);
  @include box-shadow(none);
  @include border-radius(2px);
  @include borders($btn-type-b-bg, 2px, 2px, 2px, 2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      // background:$btn-type-b-hover-bg;
      outline: 0;
      background: darken($btn-type-b-bg,5);
      border:2px solid darken($btn-type-b-bg,5);
    }
  }
  &:hover {
    @include borders($btn-type-b-hover-bg, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    background: $btn-type-b-hover-bg;
    color: $btn-type-b-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-b-focus-border-color, 2px, 2px, 2px, 2px);
    -webkit-box-shadow: 0px 0px 3px 0px darken($color-brand-primary-dark, 10%);
    box-shadow: 0px 0px 3px 0px darken($color-brand-primary-dark, 10%);
    background: $btn-type-b-bg;
    color: $btn-type-b-color;
    &:hover {
      background: $btn-type-a-hover-bg;
      border-color: $btn-type-a-hover-bg;
    }
  }
  &:active,
  &.active {
    @include borders(darken($color-brand-primary-dark, 10%), 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;
    background: darken($color-brand-primary-dark, 10%);
    color: $btn-type-b-color;

    &:hover {
      @include borders($btn-type-b-hover-border-color, 2px, 2px, 2px, 2px);
      @include box-shadow(none);
      background: $btn-type-b-hover-bg;
      color: $btn-type-b-color;
    }
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
    &:hover, &:active, &:focus, &:visited {
      background: $btn-type-b-bg;
      color: $btn-type-b-color;
      @include borders($btn-type-b-bg, 2px, 2px, 2px, 2px);
    }
  }
}





// White button
.btn-type-c {
  @include button-variant($btn-type-c-color, $btn-type-c-bg, $btn-type-c-border-color);
  @include box-shadow(none);
  @include border-radius(2px);
  @include borders($btn-type-c-bg, 2px, 2px, 2px, 2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      // background: $btn-type-c-active-bg;
      //background: $btn-type-c-bg;
      outline: 0;
      color: lighten($btn-type-c-color,100);
      background: darken($btn-type-c-bg,100);
      border:2px solid darken($btn-type-c-bg,100);
    }
  }
  &:hover {
    @include borders($btn-type-c-hover-border-color, 2px, 2px, 2px, 2px);

    @include box-shadow(none);
    background-color: $btn-type-c-hover-bg;
    color: $btn-type-c-hover-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-d-focus-border-color, 2px, 2px, 2px, 2px);
    @include box-shadow(0px 0px 2px 1px $abs-k-filter-multicheck-item-hover-bg);
    background-color: $abs-k-filter-bg;
    color: $btn-type-d-color;
    text-decoration: none;
  }
  &:active,
  &.active {
    @include borders($btn-type-c-active-border-color, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;
    background: $btn-type-c-active-bg;
    color: $btn-type-c-hover-color;
    &:hover {
      @include borders($btn-type-c-hover-border-color, 2px, 2px, 2px, 2px);
      @include box-shadow(none);
      background: $btn-type-c-active-bg;
      color: $btn-type-c-hover-color;

    }
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
  }
}






.btn-type-d {
  @include button-variant($btn-type-d-color, transparent, transparent);
  @include box-shadow(none);
  @include border-radius(2px);
  @include borders(transparent, 2px, 2px, 2px, 2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      // background: $btn-type-d-active-bg;
      outline: 0;
      color: lighten($btn-type-c-color,100);
      background: darken($btn-type-c-bg,100);
      border:2px solid darken($btn-type-c-bg,100);
    }
  }
  &:hover {
    @include borders($abs-k-filter-multicheck-item-hover-bg, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    background: $abs-k-filter-multicheck-item-hover-bg;
    color: $btn-type-d-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-d-focus-border-color, 2px, 2px, 2px, 2px);
    @include box-shadow(0px 0px 3px 0px $light-gray-drop);
    background: transparent;
    color: $btn-type-d-color;
    text-decoration: none;
    &:hover {
      background: $color-brand-primary-light;
      border-color: $color-brand-primary-light;
    }
  }
  &:active,
  &.active {
    @include borders($abs-k-filter-text-color, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;
    background: $abs-k-filter-text-color;
    color: $abs-k-filter-bg;

    &:hover {
      @include borders($btn-type-d-hover-bg, 2px, 2px, 2px, 2px);
      @include box-shadow(none);
      background: $btn-type-d-hover-bg;
      color: $btn-type-d-color;
    }
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
    &:hover, &:active, &:focus, &:visited {
      background: transparent;
      color: $btn-type-d-color;
      @include borders(transparent, 2px, 2px, 2px, 2px);
    }
  }
}

.btn-type-e {
  @include button-variant($btn-type-d-color, $abs-scheduler-today-selected-bg, $border-color);
  @include box-shadow(none);
  @include border-radius(2px);

  font-weight: bold;
  color: $btn-type-b-hover-bg;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-icon {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  // padding: 1px 10px;

  > .abs-icon {
    // vertical-align: -20%;
    line-height:inherit;
  }
}

.btn-icon-sm {
  @media (max-width: $grid-float-breakpoint) {
    padding: 1px 10px;
  }
}

.button-group {
  @include flexbox();
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include justify-content(center);
  @include align-content(stretch);
  @include align-items(flex-start);
  margin-bottom: 20px;
  margin-top: 15px;
  > button {
    min-width: 80px;
    white-space: pre-line;
    &:nth-of-type(1) {
      @include radius(2px, 0px, 0px, 2px);
      margin-right: -1px;
      &:focus, &:hover {
        position: relative;
        z-index: 10;
      }
    }
    &:nth-of-type(2) {
      @include radius(0px, 2px, 2px, 0px);
    }
  }
}

.icon-in-btn {
  font-size: 1.3em;
  vertical-align: middle;
  padding-right: 4px;
}

.btn-accept {
  color: $status-zaakceptowany;
  > span {
    vertical-align: middle;
  }
  &:hover {
    color: $status-zaakceptowany;
  }
}

.btn-deny {
  color: $status-odrzucony;
  > span {
    vertical-align: middle;
  }
  &:hover {
    color: $status-odrzucony;
  }
}

.card-box {
  .btn-cancel {
    border-color: $cancel-color;
    background: $cancel-color;
  }
  .btn-ok {
    border-color: $accept-color;
    background: $accept-color;
  }

}

// .abs-btn-default {
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
//   padding: 4px 14px;
//   background-color: $btn-gray;
//   color: $k-upload-bg;
//   border: 1px solid transparent;
//   box-sizing: border-box;
//   border-radius: 2px;
//   font-weight: 400;

//   &:hover {
//     @extend .abs-btn-default;
//     background-color: #000;
//   }

//   &:focus {
//     @extend .abs-btn-default;
//     border: 1px solid $btn-dark-gray;
//     box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
//   }

//   &:active {
//     @extend .abs-btn-default;
//     background-color: $btn-dark-gray;
//   }
// }

// .abs-btn-primary {
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
//   padding: 4px 14px;
//   background-color: $btn-blue;
//   color: $k-upload-bg;
//   border: 1px solid transparent;
//   box-sizing: border-box;
//   border-radius: 2px;
//   font-weight: bold;

//   &:hover {
//     @extend .abs-btn-primary;
//     background-color: #000;
//   }

//   &:focus {
//     @extend .abs-btn-primary;
//     border: 1px solid $btn-dark-blue;
//     box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
//   }

//   &:active {
//     @extend .abs-btn-primary;
//     background-color: $btn-dark-blue;
//   }
// }

// .abs-btn-info {
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
//   padding: 4px 14px;
//   background-color: $k-upload-bg;
//   color: $btn-dark-gray;
//   border: 1px solid $btn-gray;
//   box-sizing: border-box;
//   border-radius: 2px;
//   font-weight: bold;

//   &:hover {
//     @extend .abs-btn-info;
//     color: $btn-dark-blue;
//     border: 1px solid $btn-blue;
//   }

//   &:focus {
//     @extend .abs-btn-info;
//     color: $btn-dark-blue;
//     border: 1px solid transparent;
//     box-shadow: 0px 0px 3px 0px rgba(2, 126, 187, 0.5);
//   }

//   &:active {
//     @extend .abs-btn-info;
//     background-color: $btn-light-blue;
//     color: $btn-dark-blue;
//     border: 1px solid $btn-blue;
//   }
// }

// .abs-btn-blank {
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
//   padding: 4px 14px;
//   background-color: $k-upload-bg;
//   color: $btn-dark-blue;
//   border: 1px solid transparent;
//   box-sizing: border-box;
//   border-radius: 2px;
//   font-weight: bold;

//   &:hover {
//     @extend .abs-btn-blank;
//     color: $btn-dark-blue;
//     border: 1px solid $btn-blue;
//   }

//   &:focus {
//     @extend .abs-btn-blank;
//     color: $btn-dark-blue;
//     border: 1px solid $btn-light-blue;
//   }

//   &:active {
//     @extend .abs-btn-blank;
//     background-color: $btn-light-blue;
//   }
// }

// .abs-inline-box {
//   padding: 10px 5px;
//   font-size: 14px;
//   font-weight: 500;
//   color: $card-nav-font-color;
//   display: flex;
//   align-items: center;
//   > .abs-icon {
//     font-size: 2em;
//     margin-right: 0.4em;
//   }
// }

// .abs-inline-box-b {
//   padding: 14px 5px 15px 5px;
//   font-size: 14px;
//   font-weight: 500;
//   color: $card-nav-font-color;
//   display: flex;
//   align-items: center;
//   > .abs-icon {
//     font-size: 2em;
//     margin-right: 0.4em;
//   }
// }

.vcenter {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
}

// .fluence-btn-default {
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
//   color: $k-upload-bg;
//   @include gradient-primary ($solid-blue, $solid-light-blue);
//   border: none;
//   box-sizing: border-box;
//   border-radius: 2px;
//   font-weight: 700;
//   text-transform: uppercase;

//   &:hover {
//     @extend .fluence-btn-default;
//     @include gradient-primary ($solid-dark-blue, $solid-dark-blue);
//   }

//   &:focus {
//     @extend .fluence-btn-default;
//     box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
//   }

//   &:active {
//     @extend .fluence-btn-default;
//   }
// }

.middle {
  display: inline-block;
  vertical-align: middle;
}

span.abs-icon ~ span.middle {
  padding-left: 0.5em;
}

.abs-dropdown {
  .btn {
    display: flex;
    align-items: center;
    padding: 1px 6px;
  }
  .dropdown-menu {
    border: 1px solid $lang-box-border-color;
    border-radius: 0;
    padding: 0;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(152, 152, 152, 1);
    -moz-box-shadow: 0px 2px 3px 0px rgba(152, 152, 152, 1);
    box-shadow: 0px 2px 3px 0px rgba(152, 152, 152, 1);
    top: 100%;
    bottom: auto;

    li {
      a {
        margin: 2px;
        border-radius: 2px;
        &:hover {
          background-color: #dbf2fb;
        }
      }
      &.divider {
        margin: 2px 0px;
      }
    }
  }
}

button > span:first-child:before {
  font-size: 18px;
}

.sep-right {
  border-right: 1px solid $grid-border-color;
}

a.btn.btn-xs.btn-default.btn-type-a.pull-left {
  margin-right: 15px;
}

.open > .btn[aria-expanded="true"] {
  .abs-rotate {
    -ms-transform: rotate(-180deg); /* IE 9 */
    -webkit-transform: rotate(-180deg); /* Chrome, Safari, Opera */
    transform: rotate(-180deg);
  }
}

.abs-back {
  .abs_angle_left {
    margin-top: -3px;
  }
}

.abs-kendo-menu {

  .k-item > .k-link > .k-i-arrow-60-down {
    display: none;
  }
  .k-item >.k-link>button{
    @include flexbox();
    @include align-items(center);
  }
  .k-item, &.k-header {
    border: none;
  }

  &.k-header {
    background-color: transparent;
  }

  &.k-menu-horizontal > .k-item.k-first > .k-link {
    padding: 0
  }

  .k-item, &.k-menu-horizontal > .k-item {
    border-width: 0;
  }
  .abs-btn-icon_icon + span:not(.abs-icon){
    padding-left:5px;
  }

}

button > span + span.abs-icon.abs_expand_more {
  margin-right: -4px;
}

.abs_filter {
  display: inline-block;
  vertical-align: middle;
}

%btn-type-mini-actions {
  @include border-radius(2px);
  padding: 4px 5px;
  margin: 0 2px;
  cursor: pointer;
  color: $mini-icon-font-color;
}

.btn-type-danger {
  @extend %btn-type-mini-actions;
  background: $delete-icon-color;
}

.btn-type-primary {
  @extend %btn-type-mini-actions;
  background: $edit-icon-color;
}

.btn-type-success {
  @extend %btn-type-mini-actions;
  background: $success-icon-color;
}

button.k-button {
  padding: 3px 6px;
  font-size: 13px;
  line-height: 16px;
  border-radius: 2px;
}

.k-menu-horizontal {
  button.k-button {
    padding: 1px 6px;
  }
}

.abs-btn-icon {
  padding: 1px 6px 2px 6px !important;
  // .abs-dropdown &{
  //   margin-right:5px;
  // }
  &_icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 18px;
  }
  &_title {
    display: inline-block;
    vertical-align: middle;
    line-height: 18px;
    padding-left: 1px;
  }
}

.abs-dropdown {
  .name {
    padding-right: 6px;
  }
  .abs-btn-icon {
    &_title {
      margin-right: 6px;
      padding-left:6px;
    }
  }
  &.open {
    .btn-type-a {
      border-left: 2px solid $btn-type-a-active-bg;
      border-top: 2px solid $btn-type-a-active-bg;
      border-right: 2px solid $btn-type-a-active-bg;
      border-bottom: 2px solid $btn-type-a-active-bg;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
      background-image: none;
      background: $btn-type-a-active-bg;
      color: #ffffff;
    }
    .btn-type-b {
      background-color: darken($color-brand-primary-dark, 10%);
      &:hover {
        background-color: $color-neutral-100;
      }
    }
    .btn-type-d {
      border-left: 2px solid $abs-k-filter-text-color;
      border-top: 2px solid $abs-k-filter-text-color;
      border-right: 2px solid $abs-k-filter-text-color;
      border-bottom: 2px solid $abs-k-filter-text-color;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $abs-k-filter-text-color;
      color: $text-on-color-brand-secondary;
      &:hover, &:active:focus, &:focus{
        background-color: $color-neutral-100;
        border-color: $color-neutral-100;
      }
    }
  }
}


.abs-list-toolbar {
  height: 42px;
  margin: 0;
  &.big-toolbar {
    height: 58px;
  }
  @media (max-width: $mobile-mode-max) {
    padding: 8px 0;
  }

  .col-md-9.col-xs-9 .pull-left {
    .btn:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__default-tools {
    padding: 0;

    .btn {
      margin-left: 10px;
    }
  }
  &__selection-tools {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    > div {
      display: flex;
    }
    .dropdown {
      .dropdown-toggle {
        padding: 1px 4px;
      }
      .dropdown-menu {
        right: 0;
        left: unset;
        li a {
          .btn-type-a, .btn-type-b {
            font-size: 13px;
            font-weight: 400;
            padding: 6px 2px 6px 7px;
            color: $color-neutral-100;
            background-color: transparent;
            border-radius: 2px;
            border: none;
            width: 100%;
            height: 100%;
            &:hover {
              background-color: $color-brand-primary-light;
            }
          }
        }
      }
    }
    &__checked-section {
      @include flex-align-items-center();
      &__checked {
        @include flex-align-items-center();
        margin-right: 20px;
        .abs_keyboard_arrow_left2 {
          margin-right: 14px;
          font-size: 18px;
        }
        &__text {
          @include flex-align-items-center();
        }
        &__selected-count {
          @include flex-all-center();
          background: $color-warning-light-2;
          border: 1px solid $color-neutral-16;
          border-radius: 3px;
          width: 28px;
          height: 22px;
          margin-left: 10px;
        }
      }
      &__divider {
        margin-right: 20px;
        background: #cccccc;
        width: 1px;
        display: block;
        height: 26px;
      }
    }
    .btn:not(:last-of-type) {
      margin-right: 13px;
    }
  }
}

.abs-toolbar-additionalInformation {
  font-family: 'Roboto-Medium', 'Roboto Medium', 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  color: #666666;
  text-align: left;
}

.page-toolbar .btn:not(:last-child) {
  margin-right: 15px;
}

.dropdown-menu .btn-group > .btn, .dropdown-menu .btn-group-vertical > .btn {
  float: none;
}

.open > .btn-type-b.dropdown-toggle {
  @include button-variant($btn-type-b-color, $btn-type-b-bg, $btn-type-b-border-color);
  @include box-shadow(none);
  @include border-radius(2px);
  @include borders($btn-type-b-bg, 2px, 2px, 2px, 2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      // background:$btn-type-b-hover-bg;
      outline: 0;
      background: darken($btn-type-b-bg, 5);
      border: 2px solid darken($btn-type-b-bg, 5);
    }
  }
  &:hover {
    @include borders($btn-type-b-hover-bg, 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    background: $btn-type-b-hover-bg;
    color: $btn-type-b-color-hover;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-b-focus-border-color, 2px, 2px, 2px, 2px);
    -webkit-box-shadow: 0px 0px 3px 0px darken($color-brand-primary-dark, 10%);
    box-shadow: 0px 0px 3px 0px darken($color-brand-primary-dark, 10%);
    background: $btn-type-b-bg;
    color: $btn-type-b-color;
    &:hover {
      background: $btn-type-a-hover-bg;
      border-color: $btn-type-a-hover-bg;
      color: $btn-type-b-color-hover;
    }
  }
  &:active,
  &.active {
    @include borders(darken($color-brand-primary-dark, 10%), 2px, 2px, 2px, 2px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;
    background: darken($color-brand-primary-dark, 10%);
    color: $btn-type-b-color;

    &:hover {
      @include borders($btn-type-b-hover-border-color, 2px, 2px, 2px, 2px);
      @include box-shadow(none);
      background: $btn-type-b-hover-bg;
      color: $btn-type-b-color-hover;
    }
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
  }
}

.btn-type-b {
  &:focus,
  &.focus {
    color: $btn-type-b-color;

    &:hover {
      color: $btn-type-b-color-hover;
    }
  }
  &:active,
  &.active {
    color: $btn-type-b-color-hover;

    &:hover {
      color: $btn-type-b-color-hover;
    }
  }

  &:hover {
    color: $btn-type-b-color-hover;
  }

  &.disabled,
  &[disabled] {
    &:hover, &:active, &:focus, &:visited {
      color: $btn-type-b-color-hover;
    }
  }
}

.abs-dropdown.open > .btn-type-d.dropdown-toggle {
  color: $color-neutral-0;
}

.container-nav .abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu > div.actions-panel .btn-type-menu-mobile {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  color: #535D67;
  background-color: $color-neutral-8;
  width: 231px;
  height: 38px;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  border: none;
  font-weight: 500;
  font-size: 13px;
  border-radius: 2px;
  &.btn-primary {
    background-color: $btn-primary-mobile-bg-color;
    color: $btn-primary-mobile-color;
  }
  &:hover, &:focus, &:active, &:visited {
    background-color: $btn-primary-mobile-bg-active;
    color: $btn-primary-mobile-color-active;
  }
  .btn-label-mobile {
    text-transform: lowercase;
    margin-left: 4px;
  }
  &.dropdown {
    width: 251px;
    height: 38px;
    justify-content: center;
  }
}

.container-nav .abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu > div.actions-panel .dropdown.open .btn-type-menu-mobile {
  box-shadow: none;
  background-color: $btn-primary-mobile-bg-active;
  color: $btn-primary-mobile-color-active;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial;
}

.btn-xs {
  @media (max-width: $mobile-mode-max) {
    padding: 4px 6px;
  }
}

//.abs-dropdown .btn {
//  @media (max-width: $mobile-mode-max) {
//    padding: 3px 6px;
//  }
//}

.abs-btn-icon {
  padding: 1px 6px 2px 6px !important
}

.abs-dropdown {
  .dropdown-menu {
    li {
      a, span.menuitem {
        margin: 2px;
        border-radius: 2px;
        &:hover {
          background-color: $color-brand-primary-light;
        }
      }

      @media (max-width: $mobile-mode-max) {
        a, span.menuitem {
          border-radius: 2px;
          margin: 0px;
          &:hover {
            background-color: $color-brand-primary-light;
          }
        }
      }

    }
  }
}

.dropdown.abs-dropdown.no-text {
  width: 34px;
  height: 26px;
  > .dropdown-toggle {
    width: 100%;
    @include flex-all-center();
    i {
      font-size: 18px;
    }
  }
  @media (max-width: $mobile-mode-max) {
    height: 30px;
  }
}

.abs-page-header__toolbar, .abs-list-toolbar {
  .btn-type-a, .btn-type-b {
    height: 26px;
  }

  @media (max-width: $mobile-mode-max) {
    .btn-type-a, .btn-type-b {
      height: 30px;
    }
  }
}

//LIST DROPDOWN MENU BUTTON

.abs-md-list.style-a .abs-dropdown .btn.btn-type-d {
  padding-left: 2px;
  height: 26px;
  @media (max-width: 1023px) {
    height: 30px;
  }
}

:not(.collapse-in-content) > .row.vcenter > .abs-list-toolbar__selection-tools {
  width: auto;
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}
