.fw-radio-group-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .abs-form-field-error-message {
    margin-left: 4px;
  }
}

.fw-radio-group-element {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
  .fw-radio-group-element-input {
    display: inline;
    opacity: 0;
    width: 0;
    margin: 0;
    -webkit-appearance: none;
    overflow: hidden;

  }
  .fw-radio-group-element-input-label {
    color: $input-checkbox-color;
    font-weight: normal;
    padding-right: 6px;
    padding-left: 22px;
    padding-top: 1px;
    display: inline-block;
    position: relative;
    vertical-align: text-top;
    line-height: 16px;
    cursor: pointer;
    border-style: solid;
    border-width: 0;
    margin-bottom: 0;
    &:before {
      width: 16px;
      height: 16px;
      border-color: $input-border-color;
      border-radius: 50%;
      background-color: #fff;
      border-width: 1px;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-style: solid;
    }
    &:hover {
      &:before {
        border-color: $input-border-color-hover;
      }
    }
    &:focus {
      &:before {
        box-shadow: $input-box-shadow-focus;
        border-color: $input-border-color-hover;
      }
    }
  }
  &--invalid {
    .fw-radio-group-element-input-label {
      &:hover {
        &:before {
          border-color: $color-negative-dark-4;
        }
      }
      &:before {
        border-color: $color-negative-dark-1;
        background-color: $color-negative-light;
      }
    }
  }

  .fw-radio-group-element-input:checked + .fw-radio-group-element-input-label:before {
    background-color: $input-bg-color-active;
    border-color: $input-checkbox-border-color-active;
  }

  .fw-radio-group-element-input:checked + .fw-radio-group-element-input-label:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: $input-checkbox-color-active;
    border-radius: 50%;
  }
  .fw-radio-group-element-text {
    margin-left: 10px;
    font-weight: normal;
  }
}