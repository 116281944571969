/* Lumen tooltips
   ========================================================================= */

.absui-tooltip-toggle {
  position: relative;

  .absui-tooltip {
    visibility: hidden;
    position: absolute;
  }

  &:hover .absui-tooltip {
    visibility: visible;
    animation: fadeIn $token-tooltip-transition-time;
  }
}

.absui-tooltip {
  z-index: $token-z-index-tooltip;
  font: $token-tooltip-text-font;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  margin: 4px;
  opacity: $token-tooltip-opacity;
  animation: fadeIn ease-out $token-tooltip-transition-time;

  &__inner {
    //min-width: 150px;
    //max-width: 200px;
    //white-space: nowrap;
    padding: $token-tooltip-padding;
    color: $token-tooltip-text-color;
    background-color: $token-tooltip-background;
    border-radius: $token-tooltip-radius;
    box-shadow: $token-tooltip-shadow;
  }

  &__title {
    display: block;
    font-size: $token-tooltip-title-font-size;
    margin-bottom: 4px;
  }

  &__arrow {
    position: absolute;
    display: block;
    width: calc(#{$token-tooltip-arrow-height} * 1.5);
    height: $token-tooltip-arrow-height;

    &:before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.absui-tooltip--big {
  .absui-tooltip__inner {
    white-space: normal;
    min-width: $token-tooltip-min-width;
    max-width: $token-tooltip-max-width;
  }
}

.absui-tooltip--top {
  bottom: calc(100% + #{$token-tooltip-arrow-height});
  transform: translate(-50%, 0);
  left: 50%;

  .absui-tooltip__arrow {
    bottom: -$token-tooltip-arrow-height;
    left: calc(50% - #{$token-tooltip-arrow-height});

    &:before {
      top: 0;
      border-width: $token-tooltip-arrow-height $token-tooltip-arrow-height 0;
      border-top-color: $token-tooltip-background;
    }
  }
}

.absui-tooltip--bottom {
  top: calc(100% + #{$token-tooltip-arrow-height});
  transform: translate(-50%, 0);
  left: 50%;

  .absui-tooltip__arrow {
    top: -$token-tooltip-arrow-height;
    left: calc(50% - #{$token-tooltip-arrow-height});

    &:before {
      bottom: 0;
      border-width: 0 $token-tooltip-arrow-height $token-tooltip-arrow-height;
      border-bottom-color: $token-tooltip-background;
    }
  }
}

.absui-tooltip--left {
  right: calc(100% + #{$token-tooltip-arrow-height});
  transform: translate(0, -50%);
  top: 50%;

  .absui-tooltip__arrow {
    right: -$token-tooltip-arrow-height;
    width: $token-tooltip-arrow-height;
    height: calc(#{$token-tooltip-arrow-height} * 1.5);
    top: calc(50% - #{$token-tooltip-arrow-height});

    &:before {
      left: 0;
      border-width: $token-tooltip-arrow-height 0 $token-tooltip-arrow-height $token-tooltip-arrow-height;
      border-left-color: $token-tooltip-background;
    }
  }
}

.absui-tooltip--right {
  left: calc(100% + #{$token-tooltip-arrow-height});
  transform: translate(0, -50%);
  top: 50%;

  .absui-tooltip__arrow {
    left: -$token-tooltip-arrow-height;
    width: $token-tooltip-arrow-height;
    height: calc(#{$token-tooltip-arrow-height} * 1.5);
    top: calc(50% - #{$token-tooltip-arrow-height});

    &:before {
      right: 0;
      border-width: $token-tooltip-arrow-height $token-tooltip-arrow-height $token-tooltip-arrow-height 0;
      border-right-color: $token-tooltip-background;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: $token-tooltip-opacity; }
}