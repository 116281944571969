/* Lumen alerts
   ========================================================================= */

.absui-alert {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: $token-alert-margin;
  position: relative;
  padding: $token-alert-padding;
  border: 1px solid $token-alert-border-color;
  border-radius: $token-alert-radius;
  background: $token-alert-background;

  @include absui-breakpoint-down(sm) {
    flex-wrap: wrap;
    padding: 10px;
  }

  &__head {
    font-size: $token-alert-title-font-size;
    color: $token-alert-title-color;
    margin: 2px 0 8px;
    display: block;
  }

  &__content {
    font-size: $token-alert-text-font-size;
    color: $token-alert-text-color;
    line-height: $token-alert-line-height;
    padding: 0 0 0 20px;

    @include absui-breakpoint-down(sm) {
      padding: 10px;
    }
  }

  &__content-title {
    display: block;
    color: $token-alert-title-color;
    font-weight: 500;
  }

  &__notification-content {
    display: block;
    padding: 0 20px;

    @include absui-breakpoint-down(sm) {
      padding-left: 10px;
    }
  }

  &__browser-link {
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 8px;

    &:not(:last-of-type):after {
      color: $token-alert-text-color;
      font-size: 1.85rem;
      line-height: $token-alert-text-font-size;
      font-weight: bold;
      vertical-align: top;
      content: " • ";
      opacity: 0.2;
    }
  }

  &__btn-wrap {
    margin-left: auto;
    flex: 1 0 auto;
    @include vertical-center();

    & > * {
      margin-left: 8px;
    }
  }

  .absui-close {
    position: absolute;
    top: calc(50% - 16px);
    right: 14px;
  }

  &:before {
    color: $token-alert-text-color;
    font-family: $token-font-family-icon;
    content: "\e86c";
    font-size: $token-alert-icon-size;
    position: absolute;
    top: 8px;
    left: 8px;
    margin: auto 0;
    width: $token-alert-icon-size;
    height: $token-alert-icon-size;
    line-height: 1;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';

    @include absui-breakpoint-down(sm) {
      display: none;
    }
  }

  &--warning {
    background-color: $token-alert-warning-background;
    border-color: $token-alert-warning-border-color;

    &:before {
      content: "\e002";
      color: $token-theme-color-icon-warning;
    }
  }

  &--danger {
    background: $token-alert-danger-background;
    border-color: $token-alert-danger-border-color;

    &:before {
      content: "\e000";
      color: $token-alert-danger-icon-color;
    }
  }

  &--info {
    background: $token-alert-info-background;
    border-color: $token-alert-info-border-color;

    &:before {
      content: "\e88e";
      color: $token-alert-info-icon-color;
    }
  }

  &--success {
    background: $token-alert-success-background;
    border-color: $token-alert-success-border-color;

    &:before {
      content: "\e86c";
      color: $token-alert-success-icon-color;
    }
  }

  &--discrete {
    background-color: $token-alert-background;
    border-color: transparent;
  }

  &--inline {
    display: inline-flex;
    min-height: 32px;
    padding: 4px 16px;
    margin: 0 8px 0 0;

    &:before {
      top: 6px;
    }

    @include absui-breakpoint-down(sm) {
      padding: 8px 16px;

      &:before {
        //display: block;
      }

      .absui-alert__content {
        padding:unset;
      }
    }
  }

  &--loader {
    justify-content: center;
    text-align: center;
    padding: 10px;

    &:before {
      content: none;
    }

    .absui-alert__content {
      color: $token-theme-color-neutral-50;
      line-height: 28px;
    }

    .absui-alert__loader-spin {
      color: $token-theme-color-neutral-50;
      display: inline-block;
      padding: 0;
      line-height: 28px;
      height: 28px;
      position: relative;
      vertical-align: middle;
    }
  }
}