@font-face {
  font-family: 'Roboto';
  src: url('../../../../../../../../node_modules/lumen-icon-font/fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../../../../../../node_modules/lumen-icon-font/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../../../../../../node_modules/lumen-icon-font/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../../../../../../node_modules/lumen-icon-font/fonts/Roboto-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

html {
  font-size: 13px;
  background: #fafafa;
}

body {
  font-family: "Roboto", sans-serif;
  color: #333;
  background: #fafafa;
}

a {
  color: #017ebb;
  text-decoration: none;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 10px;
  //padding-left: 40px;
}

dl {
  padding: 0;
}

input,
input.k-textbox,
textarea {
  border-radius: 4px;
  border-color: #b2b2b2;
}

.absui-form-control,
.absui-input-group,
.absui-input-group-text {
  border-radius: 4px;
  border-color: #b2b2b2;

  &:focus {
    border-color: #000;
  }

  &:hover {
    //background-color: #fff;
  }
}

::placeholder {
  color: unset;
}

:-webkit-autofill,
:-webkit-autofill:first-line,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
  font-family: "Roboto", sans-serif;
  -webkit-box-shadow: none;
}

.absui-form-control:focus + .absui-input-group-append .absui-input-group-text {
  border-color: #000;
}

.absui-form-control:hover + .absui-input-group-append .absui-input-group-text, .absui-dropdown.show > .absui-input-group .absui-input-group-text, .absui-input-group:hover .absui-form-control.invalid + .absui-input-group-append .absui-input-group-text, .absui-input-group:hover .absui-form-control, .absui-input-group:hover .absui-input-group-text {
  border-color: #24708F;
  background-color: #fff;
}

.absui-checkbox .absui-custom-control-input:checked ~ .absui-custom-control-label::before {
  background-color: #E4F3F6;
  border: 1px solid #24708F;
}

.absui-custom-control-label:active:before {
  background-color: #E4F3F6;
  box-shadow: 0 0 5px 0 #24708f;
}

.absui-custom-control-input:focus ~ .absui-custom-control-label::before {
  border-color: #000 !important;
}

.absui-custom-control-label:after {
  color: #24708F!important;
  line-height: 16px;
}

.absui-dropdown--combo.show .absui-form-control,
.absui-dropdown--combo.show .absui-form-control:focus-within {
  background-color: #fff;
}

.absui-dropdown.show > .absui-form-control {
  background-color: #666666;
  color: #ffffff;
}

.absui-dropdown.show .absui-dropdown__menu {
  visibility: visible;
  transform: scaleY(1);
}

.absui-dropdown__menu {
  padding: 0;
  border-radius: unset;
}

.absui-dropdown__link {
  //min-height: 34px;
  padding: 10px 16px;
  border-radius: 0;

  &.active {
    background-color: #e3e6e8;
  }

  @media (max-width: $mobile-mode-max) {
    padding: 12px 16px;
  }
}

.absui-dropdown__expander {
  color: #666666;
}

.absui-form-col,
.absui-form-col-full {
  padding: 0;
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu li > a,
.abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu li > .dropdown > a {
  transition: none;
}

.header-2b {
  font-size: 20px;
}

.absui-icon {
  font-family: "Lumen-Icon-Font" !important;
}

.absui-file-upload__label {
  order: 2;
  margin: 0 8px;

  @include absui-breakpoint-down(sm) {
    display: inline-block;
  }
}

.dropzone-container--lumen {
  color: #FFD014;
}

.absui-file-upload__area {
  min-height: 50px;
}

.absui-popover {
  text-align: left;
  line-height: 1.5;
  color: $token-tooltip-text-color;
  background-color: $token-tooltip-background;
  border-radius: $token-tooltip-radius;
  box-shadow: $token-tooltip-shadow;

  .absui-close {
    display: none;
  }
}

.absui-popover__content {
  padding: 12px 40px 12px 12px;
}

.absui-data-table {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.absui-data-table-row {
  &:hover {
    background-color: transparent;
  }
}

.absui-data-table-header__cell {
  padding: 9px 12px;
  line-height: 18px;
  font-weight: 700;
  color: #000;
}

.abs-list-table .col .abs-list__price {
  font-weight: 400;
}

.absui-control-label {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  margin-right: 8px;
}

.absui-custom-control-label {
  line-height: 13px;
}

.absui-custom-control-label:after,
.absui-custom-control-label:before {
  width: 16px;
  height: 16px;
  font-family: Lumen-Icon-Font;
  font-size: 15px;
}

.abs-menu.abs-menu-type-a .nav>li.dropdown.open>.dropdown-menu>div {
  padding: 15px;
  width: 290px;
  min-width: 290px;
}

.abs-dropdown .dropdown-menu li a, .abs-dropdown .dropdown-menu li span.menuitem {
  min-width: 160px;
}

.load-more {
  color: #6F7779;

  a:hover {
    color: #CC0000!important;
  }
}