html, body {
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow:auto;
  font-size: 13px;
  line-height: normal;
  -ms-overflow-style: scrollbar;

}


.k-ff #wrap {
  overflow: hidden;
  margin-bottom: -50px - $abs-footer-margin-top;
}

#wrap {
  min-height: 100%;
  height: 100%;
  height: auto !important;
  //margin-top:-42px;
  margin-bottom: -50px - $abs-footer-margin-top;
  line-height: normal;
}


#push {
  height: 50px +$abs-footer-margin-top;
}

body {
  //border-top:42px solid $abs-menu-bg;
  @include font-style($roboto-font-family, 400, normal);
  background: $abs-body-desktop-bg;
}

%header-placeholder {
  font-size: 13px;
  color: $color-neutral-60;
  text-transform: uppercase;
  display: table;
  white-space: nowrap;
  text-align: left;
}

.header-2a {
  @extend %header-placeholder;
}

.header-2b {
  @extend %header-placeholder;
  margin: 10px 1%;

  &:after {
    border-top: 1px solid $color-neutral-8;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 99%;
  }

  &:after {
    left: 1%;
  }
}

.loader-mask-style-a {
  position: relative;

  &:before {
    content: '';
    background: url('../../../css/loader.gif') 50% 50% no-repeat #fff;
    background-size: 35px 35px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }
}

.loader-mask-style-b {
  position: relative;

  &:before {
    content: '';
    background: url('../../../css/loader.gif') 50% 50% no-repeat rgba(255, 255, 255, .8);
    background-size: 35px 35px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }
}

.loader-mask-container {
  background: url('../../../css/loader.gif') 50% 50% no-repeat #fff !important;
  position: fixed;
  overflow: hidden;
  background-size: 40px 40px !important;
  width: 100%;
  height: 100%;
  z-index: 99999;
  -webkit-transform: translateZ(0);
  // -webkit-transform: scale3d(1,1,1);
}

.body-loader-mask {
  background: url('../../../css/loader.gif') 50% 50% no-repeat #fff !important;
  opacity: 0;
  overflow: hidden;
  background-size: 40px 40px !important;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

a {
  color: $abs-link-color;
  text-decoration: none;

  &:hover, &:focus {
    cursor: pointer;
    text-decoration: underline;
    color: $abs-font-color;
    outline: none;
  }

  // &:hover{
  // 	&:before{
  // 		visibility: visible;
  // 	    -webkit-transform: scaleX(1);
  // 	    transform: scaleX(1)
  // 	}
  // }
  // &:before{
  // 	content: "";
  //     position: absolute;
  //     width: 100%;
  //     height: 1px;
  //     bottom: 0;
  //     left: 0;
  //     background-color: $abs-link-color;
  //     visibility: hidden;
  //     -webkit-transform-origin: left center;
  //     transform-origin: left center;
  //     -webkit-transform: scaleX(0);
  //     transform: scaleX(0);
  //     -webkit-transition: all .2s ease-in-out 0s;
  //     transition: all .2s ease-in-out 0s;
  // }

}

.abs-icon-link {
  display: flex;
  align-items: center;
  height: 15px;
  color: $abs-link-color;

  .abs-icon {
    font-size: 15px;
    margin-right: 2px;
  }

  &:hover {
    color: $abs-font-color;

    a {
      color: $abs-font-color;
    }
  }
}

p {
  line-height: 1.6em;
}

%container-box-placeholder {
  width: 100%;
  height: auto;
  padding: 0px;
  position: relative;
  @media (max-width: $grid-breakpoint-desktop-large) {
    @include box-shadow(none);
  }

  @media (min-width: $grid-breakpoint-desktop-large) {
    max-width: $grid-breakpoint-desktop-large;
    margin: 0px auto;
    //box-shadow: -1px 0 $container-box-shadow-color, 1px 0 $container-box-shadow-color;
  }
}

%container-fluid-desktop-large {
  max-width: $grid-breakpoint-desktop-large;
  margin: 0px auto;

}

%navbar-fixed-top-desktop-large {
  max-width: $grid-breakpoint-desktop-large;
  position: absolute;
  top: 0;

}

body {

  @media (min-width: $grid-breakpoint-desktop-large) {
    background: $abs-body-desktop-bg;
  }
}

.win-tablet {
  overflow: scroll;
}

.container-box {
  @extend %container-box-placeholder;
  //@include borders($container-box-border-color,0px, 1px, 0px, 1px);
  background: $abs-container-box-bg;
  flex-grow: 1;
  overflow: hidden;


  .container-fluid {
    @media (min-width: $grid-breakpoint-desktop-large) {
      max-width: $grid-breakpoint-desktop-large;
      margin: 0px auto;
      //@extend %container-fluid-desktop-large;

    }
  }
}

.container-box-a {
  padding-top: 10px;
  @media (max-width: $grid-float-breakpoint - 1) {
    padding-top: 0px;
  }
}

.container-box-b {
  overflow: visible;
  //@extend %container-box-placeholder;
}

.abs-container {
  @extend %container-box-placeholder;
  background: $abs-container-box-bg;

  &.abs-container--a {
    padding-top: 10px;
    @media (max-width: $grid-float-breakpoint - 1) {
      padding-top: 0px;
    }
  }
}

/*
.container-box-flex{
	@media (min-width: $grid-float-breakpoint-max){
			@include flexbox();
	}
}*/


.container-menu {
  // height:$abs-menu-height +1;
  min-height: 46px;
  background: $abs-menu-bg;
  width: 100%;
  padding: 0px;
  margin: 0px;

  .abs-menu, .navbar-fixed-top {
    position: relative;
    @media (max-width: $grid-float-breakpoint - 1) {
      //position:fixed;
    }
  }
}

.container-details-panel {
  border-bottom: 2px solid $sub-nav-border-color;
  width: 100%;
  padding: 0px;
  margin: 0px 0px 15px 0px;
  background: $container-details-panel-bg;

  &:empty {
    display: none;
  }
}


footer {
  background: $abs-footer-bg;
  width: 100%;
  padding: 0px;
  margin: $abs-footer-margin-top 0px 0px 0px;
  @media (max-width: $grid-float-breakpoint - 1) {
    //margin:0px;
  }
}

.copyrights-box {
  background: $abs-footer-copyrights-bg;

  p {
    color: $abs-footer-copyrights-color;
    text-align: center;
    margin: 0 0 0px;
    padding: 5px 0px;
    font-size: 11px;
  }
}


.container-nav {
  @extend %container-box-placeholder;

  .abs-menu, .navbar-fixed-top {
    @media (min-width: $grid-breakpoint-desktop-large) {
      max-width: $grid-breakpoint-desktop-large;
      // position:absolute;
      top: 0;
      left: 0px;
      right: 0px;
    }
  }
}

//bez sub-nav
.container-nav-a {
  @extend %container-box-placeholder;

  .container-fluid {
    @media (min-width: $grid-breakpoint-desktop-large) {
      max-width: $grid-breakpoint-desktop-large;
      margin: 0px auto;

    }
  }

  .abs-menu, .navbar-fixed-top {
    @media (min-width: $grid-breakpoint-desktop-large) {
      max-width: $grid-breakpoint-desktop-large;
      // position:absolute;
      top: 0;
    }
  }
}


.bg-color-a {
  background: $container-box-bg-color-a;
}

.container-vh-height {
  padding-bottom: 99999px;
  margin-bottom: -99999px;
}


%cookies-policy-style {
  line-height: 15px;
  color: $cookies-policy-color;
  font-size: 11px;
  text-align: center;
}


.validation-header {
  @include borders($validation-header-border-color, 1px, 1px, 1px, 1px);
  @include radius(3px, 3px, 0px, 0px);
  background: $forms-validation-bg;

  margin-top: 0px;
  margin-bottom: -3px;
  padding: 13px 0px;
  z-index: 15;
  position: relative;


  @media (max-width: $grid-float-breakpoint-max) {
    @include radius(0px);
    border-width: 1px 0px 1px 0px;
    width: 100%;
  }

  h1 {
    font-size: 1em;
    margin: 4px 0px 0px 0px;
    line-height: 1.4em;
    padding-right: 15px;


  }

  .alert-icon {
    color: $forms-validation-border-color;
    font-size: 1.8em;
    display: block;
    padding: 0px 33px 0px 14px;
    width: 20px;
    float: left;

  }
}


.abs-col-1 {
  @include borders($abs-col-border-color, 1px, 1px, 1px, 1px);
  @include border-radius(3px);
  @include box-shadow(0px 1px 3px 0px rgba(222, 222, 222, 1));
  background: $abs-record-card-bg;
  //padding-bottom:(2 * $abs-record-card-margin-top);
  padding: 0px 0px 80px 0px;
  margin-bottom: 20px;


  @media (max-width: $grid-float-breakpoint-max) {
    @include border-radius(0px);
    border-width: 1px 0px 0px 0px;
    //padding-bottom:(2 * $abs-record-card-margin-top);
  }

  > h2 {

    margin-top: 25px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 15px;
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }

  hr {
    border-top: 1px solid $hr-color;
  }

}


.form-group {
  > .col-sm-10 > input {
    width: 89.7%;
    // padding:2px 0px;
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
      width: 100%;
    }
  }

  .col-sm-10 > textarea {
    width: 89.7%;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
      width: 100%;
    }
  }

  > .col-sm-9 {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
      //width:83%;
    }
  }

  > .col-sm-10 > .k-invalid {
    width: 89.7%;
    padding-right: 30px;
    @media (max-width: $screen-md-min) {
      width: 100%;
    }
  }
}


.container.button-wrap {
  //background:$sub-nav-bg;
  //height:$abs-buttons-wrap-height;
  background: $button-wrap-bg;
  height: 45px;
  border-top: 3px solid $abs-menu-bg;
  border-left: 1px solid $container-box-border-color;
  border-right: 1px solid $container-box-border-color;
  border-bottom: 1px solid $container-box-border-color;
  margin-top: -1px;
  padding-left: 0px;
  padding-right: 0px;


  @media (min-width: $grid-float-breakpoint-max) {
    margin-bottom: 10px;
  }

  > .button-group-right {
    float: right;
    // line-height:$abs-buttons-wrap-height;
    padding: 8px;

    > .btn {
      margin: 0px 2px;
    }

    .btn:nth-of-type(1) {
      font-weight: bold;
    }

    > .button-group {
      padding: 8px;
    }
  }

  > .button-group-left {
    float: left;
    padding: 8px;

    > .btn {
      margin: 0px 2px;
    }
  }
}


.abs-upload-12 {
  //jesli akcje są jako fixed na dole strony dodaje padding bottom
  padding-bottom: 50px;
  @media (min-width: $grid-float-breakpoint) {
    padding-left: 90px;
    padding-right: 90px;
  }


}

.panel-container {
  padding: 0px;
}

.panel-a {
  @include borders($panel-a-border-color, 1px, 1px, 1px, 1px);
  @include border-radius(0px);
  background: $panel-a-bg;
  margin: 10px 5px -99999px 9px;
  padding: 10px 30px 99999px 30px;
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin-left: 0px;
  }
}

.panel-b {
  margin: 10px 9px 10px 5px;
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 0px;
  }
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin-right: 0px;
  }

}

%panel-box-placeholder {
  @include borders($panel-a-border-color, 1px, 1px, 1px, 1px);
  @include border-radius(2px);
  @include box-shadow(0px 2px 2px 0px rgba(0, 0, 0, .08));
  background: $panel-a-bg;
  height: auto;
  padding: 20px;
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 10px 0px 0px 0px;
  }
}

%panel-box-mobile-style {
  @media (max-width: $grid-float-breakpoint-max) {
    width: auto;
    margin: 0px 10px 10px 10px;
  }
}

.panel-box {
  padding: 0px;
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 0px 10px;
  }
}

.panel-box-a {
  @extend %panel-box-placeholder;
  margin: 10px 10px 0px 10px;

  @media (min-width: $grid-breakpoint-desktop-large) {
    margin: 10px 0px 0px 0px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 10px 0px 0px 0px;
  }
  @media (min-width: $grid-float-breakpoint-max) {
    @include flexbox();
  }


}

.panel-box-b {
  @extend %panel-box-placeholder;
  margin: 10px 5px 0px 10px;
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin: 10px 5px 0px 0px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 10px 0px 0px 0px;
  }
  @media (min-width: $grid-float-breakpoint-max) {
    @include flexbox();
  }
}

.panel-box-c {
  @extend %panel-box-placeholder;
  margin: 10px 10px 0px 5px;
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin: 10px 0px 0px 5px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 10px 0px 0px 0px;
  }
  @media (min-width: $grid-float-breakpoint-max) {
    @include flexbox();
    width: 100%;
  }
}


.no-padding {
  padding: 0px;
}

.panel-box-e {
  margin: 10px 10px 0px 10px;
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin: 10px 0px 0px 0px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 10px 0px 0px 0px;
  }

  .k-widget.k-tabstrip > .k-content {
    margin: 0px;
    padding: 0px;
  }
}


.panel-box-f {
  margin: 10px 5px 0px 10px;
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin: 10px 0px 0px 0px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin: 10px 0px 0px 0px;
  }

  .k-widget.k-tabstrip > .k-content {
    margin: 0px;
    padding: 0px;
  }
}


.box-flex {
  @include flexbox();
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

}

.for-tabs-swap {
  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: -10px;
  }
}

.container-box.container-box-flex.kendo-tab-mobile .for-tabs {
  @media (max-width: $grid-float-breakpoint-max) {
    margin-top: -24px !important;
    display: none;
  }
}

.container-box-flex {
  @include flexbox();
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  @media (max-width: $grid-float-breakpoint-max) {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  &.box-flex-for-tabs {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }


  &.tabs-type-a {
    background: transparent;
    // margin-top:-65px;
    .k-ff & {
      // margin-top:-66px;
    }

    .box-flex-a {
      @include box-shadow(none);
      border-color: transparent;
      background-color: transparent;
    }
  }


  align-items: stretch;


  .abs-title {
    background: $abs-title-bg-in-container;
    display: inline-block;
    padding: 0px 10px 0px 0px;
    margin: 0px;
  }

  hr {
    margin-top: -8px;
  }

  .no-padding {
    padding: 0px;
  }

  .for-tabs {
    @include radius(0px, 0px, 3px, 3px);
    //border-width:0px;
    //background:transparent;
    padding: 0px;
    margin-top: 3.1em !important;

  }

  .no-bg-box {
    background: transparent;
    border: 0px;
  }

  .box-flex-inner.for-tabs {
    margin-top: 30px;
  }

  .box-flex-inner.for-tabs:not(:nth-of-type(1)) {
    margin-top: 47px !important;
  }

  .box-flex-a {
    @extend %panel-box-placeholder;
    align-self: stretch;
    width: 100%;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 0px 10px 0px;
    }
    margin: 0px 20px 10px 20px;

    &.for-tabs {
      width: auto !important;
      height: 100%;
    }
  }

  .box-flex-b {
    @extend %panel-box-placeholder;
    align-self: stretch;
    margin: 0px 20px 10px 5px;
    width: 30%;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 0px 10px 0px;
    }
  }

  .box-flex-c {
    align-self: stretch;
    width: 70%;
    margin: 0px 5px 10px 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 10px 10px 0px;
    }
  }

  .box-flex-d {
    @extend %panel-box-placeholder;
    align-self: stretch;
    width: 70%;
    margin: 0px 5px 10px 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 10px 10px 0px;
    }

  }

  .box-flex-e {
    @extend %panel-box-placeholder;
    align-self: stretch;
    margin: 0px 5px 10px 20px;
    width: 30%;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 10px 10px 0px;
    }
  }

  .box-flex-f {
    @extend %panel-box-placeholder;
    align-self: stretch;
    width: 70%;
    margin: 0px 20px 10px 5px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 0px 10px 0px;
    }
  }

  .box-flex-g {
    align-self: stretch;
    width: 30%;
    margin: 0px 5px 10px 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 10px 10px 0px;
    }

  }

  .box-flex-h {

    //	@include borders($panel-a-border-color, 1px, 1px, 1px, 1px);
    //	@include border-radius(3px);
    //	@include box-shadow(0px 1px 3px 0px rgba(222,222,222, 1));
    //	background:$panel-a-bg;
    height: auto;
    //	padding:10px 30px 10px 30px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin: 10px 0px 0px 0px;
    }


    //@extend	%panel-box-placeholder;
    align-self: stretch;
    width: 100%;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 0px 10px 0px;
    }
    margin: 0px 10px 10px 10px;
  }


  .box-flex-i {
    // @extend	%panel-box-placeholder;
    align-self: stretch;
    margin: 0px 20px 10px 5px;
    width: 70%;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 0px 10px 0px;
    }
  }

  .box-flex-j {
    // @extend	%panel-box-placeholder;
    align-self: stretch;
    margin: 0px 20px 10px 5px;
    width: 30%;
    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin: 0px 0px 10px 0px;
    }
  }

  .box-flex-k {
    @extend %panel-box-placeholder;
    align-self: stretch;
    margin: 0px 0px 10px 0px;
    width: 33%;

    &:nth-of-type(3n+2) {
      margin: 0px 10px 10px 10px;
    }

    &:nth-of-type(3n) {
      @media (max-width: $grid-breakpoint-desktop-large) and (min-width: $grid-float-breakpoint-max) {
        margin: 0px 20px 10px 0px;
      }
    }

    &:nth-of-type(3n + 1 ) {
      @media (max-width: $grid-breakpoint-desktop-large) and (min-width: $grid-float-breakpoint-max) {
        margin: 0px 0px 10px 20px;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      width: auto;
      margin: 0px 10px 10px 10px;
    }
  }

  .box-flex-inner {
    @extend %panel-box-placeholder;
    margin: 0px;

    align-self: stretch;
    flex: 1 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    min-height: 60px;

    &:not(:nth-of-type(1)) {
      margin-top: 10px;
    }


  }


  .action-buttons-container-box {
    margin-bottom: 10px;
    margin-top: 15px;

    & > a {
      > i {
        font-size: 21px;
      }

      &:hover, &:focus {
        text-decoration: none;
      }
    }

  }
}


.box {
  @extend %panel-box-placeholder;
  min-height: 160px;
}


.abs-title {
  color: $abs-title-color;
  font-size: $abs-title-font-size;
  //border-bottom: 1px solid $abs-title-line-bg;
  text-transform: uppercase;
  font-weight: bold;
}


.issue {
  .observer {
    cursor: pointer;
  }

  p {
    font-size: 1em;
    line-height: 1.6em;
  }

  > p {
    padding: 0px 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px 10px;
    }
  }

  .abs-title {
    margin: 30px 0px 10px 0px;
    display: inline-block;
    background: $abs-title-bg-in-container;
    padding-right: 10px;
  }

  .abs-title:nth-of-type(1) {
    margin: 20px 0px 10px 0px;
  }

  hr {
    margin-top: -18px;
  }

}

.action-right {
  float: right;
  display: inline-block;
  background: $action-right-bg;
  padding-left: 10px;
  margin: 30px 0px 10px 0px;
  color: $abs-link-color;
  cursor: pointer;

  > i {
    padding-right: 5px;
  }

  &:hover {
    text-decoration: underline;
  }
}


//ihd
.panel-3-col {
  @include border-radius(0px);

  > .list-group > .list-group-item span:nth-of-type(1) {
    //float:left;
  }

  > .list-group > .list-group-item span:nth-of-type(2) {
    /*
    text-align:right;
    float:right;
    @media (min-width: $grid-float-breakpoint-max) and (max-width: 1320px) {
    float:left;
    text-align:left;
    display:block;
    width:100%;
    margin-top:1px;
    color:#666;
    }*/
  }

  > .list-group > .list-group-item > a:nth-of-type(1) {
    /*@extend %ellipsis;
    width: 180px;
      display:inline-block;
      float:left;  		*/
  }

  > .list-group > .list-group-item > .file-download {
    @extend %ellipsis;
    color: $abs-link-color;
    width: 180px;
    display: inline-block;
    float: left;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }


}


// panel zalacznikow iHD na karcie rekordu
.panel-3-col > .list-group > .list-group-item {
  color: $panel-3-col-font-color;

  > div:nth-of-type(1) {
    float: left;
    text-align: left;
    @media (max-width: 1320px) and (min-width: $grid-float-breakpoint-max) {
      width: 100%;
      float: left;
      text-align: left;
    }

    span:nth-of-type(2) {
      text-align: left;
    }
  }

  > div:nth-of-type(2) {
    float: right;
    text-align: right;
    @media (max-width: 1320px) and (min-width: $grid-float-breakpoint-max) {
      margin-top: 10px;
      width: 100%;
      float: left;
      text-align: left;
    }

    span:empty {
      height: 1.4em;
    }
  }

  > div {
    width: 48%;

    span {
      display: block;
      float: none;
      line-height: 1.4em;
    }

    span:nth-of-type(1) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span:nth-of-type(2) {
      color: lighten($panel-3-col-font-color, 60%);
      font-size: 12px;
    }

    .file-download {
      color: $abs-link-color;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}


.show-mobile-a {
  display: none !important;
  @media (max-width: $grid-float-breakpoint - 1) {
    display: block !important;
  }
}

.show-mobile {
  display: none !important;
  @media (max-width: $grid-float-breakpoint) {
    display: inline-block !important;
  }
}

.hide-mobile {
  display: inline-block !important;
  @media (max-width: $grid-float-breakpoint) {
    display: none !important;
  }
}

@media (max-width: $grid-float-breakpoint) {
  .panel-to-tab {
    display: none;
  }
  .show-mobile-block[aria-expanded="false"] {
    display: none;
  }
  .show-mobile-block[aria-expanded="true"] {
    display: block;
  }
  .tabs-to-panel {
    width: 100%;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .show-mobile-block[aria-expanded="false"], .show-mobile-block[aria-expanded="true"] {
    display: none !important;
  }
}


.vh-height {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

.fa-type {
  color: $abs-link-color;
  font-size: 1.1em;
}

.material-type {
  color: $abs-link-color;
  font-size: 1.4em !important;
  vertical-align: top;
}

.dt-col-2 {
  display: block;
  font-size: 1em;
  width: 50%;

  &:nth-of-type(odd) {
    clear: left;
  }

  @media (max-width: $grid-float-breakpoint-max * 1.5) and (min-width: $screen-sm-min * 0.6) {
    width: 45%;
  }
  float: left;
  margin-bottom: 0px;
  @media (max-width: $grid-float-breakpoint-max) {

    padding: 0px 10px;
    clear: left;
    width: 100%;
  }

  dt, dd {
    display: inline-block;
    float: left;
    padding: 5px 0px;

  }

  dt {
    width: 48%;
    text-align: right;
    padding-right: 10px;
    @media (max-width: $grid-float-breakpoint-max * 1.5) and (min-width: $screen-sm-min * 0.6) {
      width: 100%;
      text-align: left;
      padding-bottom: 0px;
      padding-right: 0px;
    }
    @media (max-width: $screen-sm-min * 0.6) {
      padding-right: 0px;
      text-align: left;
      padding-bottom: 2px;
      width: 100%;
    }

    &:nth-of-type(odd) {
      clear: left;
    }
  }

  dd {
    width: 50%;
    @media (max-width: $grid-float-breakpoint-max * 1.5) and (min-width: $screen-sm-min * 0.6) {
      width: 100%;
      text-align: left;
      padding-top: 0px;
    }
    @media (max-width: $screen-sm-min* 0.6) {

      width: 100%;
      clear: left;
      text-align: left;
      padding-top: 0px;

    }

    .status-type {
      display: inline;
    }
  }

  .wyceny {
    span {
      display: block;
      margin-bottom: 5px;

    }

    i {
      margin-top: 0px;
    }
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

}


.dt-col-1 {
  display: block;
  font-size: 1em;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  @media (max-width: $grid-float-breakpoint-max) {

    padding: 0px 10px;
  }

  dt, dd {
    display: inline-block;
    float: left;
    padding: 5px 0px;

  }

  dt {
    width: 38%;
    text-align: right;
    padding-right: 10px;

  }

  dd {
    width: 58%;

  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

}

.listview {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 10px 30px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 10px 0px;
  }

  .col-sm-4 {
    width: 31.3%;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: 92%;
      padding-left: 4%;
      padding-right: 4%;
    }

    @media (max-width: $screen-md-min + 100) and (min-width: $grid-float-breakpoint-max) {
      width: 48%;

    }
  }

  .col-md-6 {
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 20px;
    @media (max-width: $screen-md-min + 100) {
      width: 100%;
    }
  }
}

.details-list {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li:nth-of-type(even) {
    background: $forms-elements-mouseover-bg;
  }

  li {
    display: block;

    padding: 4px 7px;

    &:after {
      content: "";
      display: table;
      clear: both;

    }

    &:nth-of-type(1) {
      > span {
        color: $details-list-span-color;
        font-weight: normal;
        text-align: right;
        font-size: 1em;
      }
    }

    > p {
      float: left;
      white-space: pre-line;
      width: 80%;
      padding: 0px;
      margin: 0px;
      line-height: 1.5em !important;
    }

    > span {
      float: right;
      display: block;
      font-weight: bold;

    }

    > span .units {
      font-weight: normal;
    }


  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .summary {
    background: transparent !important;

    > p {
      text-align: right;
      color: $details-list-summary;
      font-weight: bold;
    }

    &.summary-a {
      margin-top: 15px;
    }
  }


}

.panel.panel-default {
  @include box-shadow(none);
  border-color: $panel-default-border-color;

  .list-group-item {
    border-color: $panel-default-border-color;
    padding: 10px;
  }
}


.add-link {
  padding: 10px 10px 10px 0px;
  display: inline-block;

  i {
    padding-right: 5px;
    font-size: 1em;
  }
}

.add-comment {
  padding: 20px 10px 20px 0px;
  cursor: pointer;

  .fa-plus, .fa-pencil {
    margin-right: 6px;
    @include field-icon-style($edit-icon-color);
  }
}


.footer {
  //@include radius(2px, 2px,0px,0px);
  background: $abs-footer-bg;
  width: 100%;
  min-height: 50px;
  bottom: 0px;
  display: table;
  //margin-top:10px;

  .logo {
    float: left;
    margin: 10px 30px 10px 10px;
    height: 20px;

    @media (max-width: $grid-float-breakpoint-max) {
      min-width: 100px;
      display: block;
      margin: 20px auto;
      float: none;
      text-align: center;

    }

  }

  p {
    @media (max-width: $grid-float-breakpoint-max) {
      clear: left;
      //margin:0px auto;
      margin: 0px;
      width: 300px;
      float: none;
      display: block;
      padding-left: 25px !important;
      line-height: 20px;
    }
    float: left;
    color: $abs-footer-font-color;
    display: inline-block;
    line-height: 50px;
    margin: 0px;
    padding: 0px;
    position: relative;
    font-size: 1em;


    &:nth-of-type(1) {
      padding-left: 25px;
      font-weight: bold;
      margin-left: 0px;
      margin-right: 20px;
      @media (min-width: $grid-breakpoint-desktop-large) {
        margin-left: -15px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        padding-top: 15px;
      }

    }

    &:nth-of-type(3) {
      padding-left: 20px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-bottom: 16px;
      }

    }

    > .fa-life-ring, .abs_life_ring {
      position: absolute;
      left: 0px;
      top: 15px;
      font-size: 18px;

      @media (max-width: $grid-float-breakpoint-max) {
        //top:0px;
        //left:-2px;
      }
    }

    // > .fa-circle{
    // 	color:#797979;
    // 	font-size:8px;
    // 	position:absolute;
    // 	left:7px;
    // 	top:20px;
    // 	@media (max-width: $grid-float-breakpoint-max){
    // 		display:none;
    // 	}
    // }
    > a {
      // color:$abs-footer-link-color;
      text-decoration: none;

      &:hover {
        color: $abs-menu-bg;
      }
    }
  }

  .contact {
    padding-left: 0px !important;
    font-weight: bold;
    margin-left: 0px;
    padding-top: 0px !important;
    @media (min-width: $grid-breakpoint-desktop-large) {
      margin-left: -15px;
    }

    > a > .fa-phone:before {
      content: "\f098";
      vertical-align: -13%;
      margin-right: 5px;
      font-size: 20px;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;
      margin-top: 15px;
      display: block;
      float: left;
      width: auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .version {
    margin-left: 5px;

    > span {
      @include border-radius(3px);
      background: $abs-footer-version-bg;
      color: $abs-footer-version-color;
      padding: 3px 8px;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;
      margin-top: 15px;
      display: block;
      clear: none;
      float: right;
      text-align: right;
      width: auto;
    }
  }

  a.logo-abs {
    float: right;
    margin-top: 18px;
    margin-right: 0px;
    fill: currentColor;
    color: $text-on-color-brand-secondary-dark;

    > img {
      margin-top: -3px;
    }

    @media (min-width: $grid-breakpoint-desktop-large) {
      margin-right: -15px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  // .copyrights{
  // 	float:right;
  // 	color:#87a4bd;
  // 	@media (max-width: $grid-float-breakpoint-max){

  // 		margin:0px auto;
  // 		width:300px;
  // 		float:none;
  // 		display:block;
  // 		margin-top:10px;
  // 		margin-bottom:10px;
  // 		padding-left:25px;

  // 	}
  // 	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  //    		overflow: hidden;
  //    		white-space: nowrap;
  //    		width: 172px;
  //    		direction: rtl;
  //    		text-align: left;
  //    	}
  // }
}


// pasek z przyciskami dla formularzy  mobile-fixed

.abs-fixed-bottom {
  //position:relative;
  //bottom:none;

  //@media (max-width: $grid-float-breakpoint-max){
  bottom: 0px;
  position: fixed;
  //}
  margin-bottom: 0px !important;
  z-index: 999;
}

.k-widget.k-window .abs-fixed-bottom {
  @media (max-width: $grid-float-breakpoint-max) {
    position: relative;
  }
}

.win-tablet .abs-fixed-bottom {
  bottom: 0px;
  position: fixed;
}

.abs-fixed-bottom.on-scroll {
  @media (max-width: $grid-float-breakpoint-max) {
    position: relative;
    bottom: unset;
    margin-top: -56px;

  }

  position: relative;
  bottom: unset;
  margin-top: -56px;

}

.pager-on-scroll {

}


.stick-to-top {
  position: fixed !important;
  margin-top: 0px !important;
  margin-left: auto;
  margin-right: auto;
  z-index: 1040;
  right: 0;
  left: 0;
}

.fixed-element.stick-to-top.details-panel,
.fixed-element.stick-to-top .details-panel {
  @extend %container-box-placeholder;
  flex-grow: 1;
  overflow: hidden;
  padding-top: 0px;
  /* transform: rotate(-180deg);
  transform: scale(1,0.5);
    transition: transform .2s linear;*/
  /*animation-name: height-anim;
animation-timing-function: ease;
animation-duration: 0.5s;
animation-iteration-count: 1;
animation-fill-mode: forwards; */
  border-bottom: 1px solid $container-box-border-color;
  @include radius(0px, 0px, 3px, 3px);

  .clearfix {
    border-bottom: 1px solid transparent;
  }

  .icon-placeholder {
    display: none;
    /*animation-name: icon-anim;
    animation-timing-function: ease;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;*/

  }

  h1 {
    margin-top: 20px;
    margin-left: 0px;
    animation-name: h1-anim;
    animation-timing-function: ease;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0s;
  }

  h2 {
    display: none;
    /*
    animation-name: h2-anim;
    animation-timing-function: ease;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay:0.3s;
    */
  }

  .toolbar {
    animation-name: toolbar-anim;
    animation-timing-function: ease;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    //animation-delay:0.5s;
  }

}

.fixed-element.stick-to-top .details-panel {
  /*animation-name: height-anim2;
animation-timing-function: ease;
animation-duration: 0.5s;
animation-iteration-count: 1;
animation-fill-mode: forwards; */
  padding-top: 40px;

  .icon-placeholder {

    //transform:translateX(-500px);
    //-webkit-transition: transform 1s ease-in-out;

  }
}

@keyframes toolbar-anim {
  100% {
    margin-top: 18px;
  }
}

@keyframes h1-anim {
  100% {
    margin-left: 0px;
    margin-bottom: 5px;
    font-size: 18px;
    margin-top: 20px;
  }
}

@keyframes h2-anim {
  100% {
    margin-left: 0px;
    margin-top: 5px
  }
}

@keyframes icon-anim {
  //50% { margin-left:-100px; height: 56px}
  100% {
    margin-left: -200px;
    height: 0px
  }
  //100% { display:none;}
}


.scroll-up {
  display: none;
  @include radius(3px);
  @include opacity(.9);
  background: $scroll-up-bg;
  padding: 4px 5px;
  position: fixed;
  bottom: 20px;
  z-index: 1050;
  right: 10px;

  &:focus, &:hover {
    outline: 0;
    text-decoration: none;
  }

  i {
    color: $scroll-up-icon-color;
    font-size: 22px;

  }

  @media (max-width: $grid-float-breakpoint-max) {
    @include radius(3px, 3px, 3px, 3px);
    bottom: 60px;
    right: 0px;
  }

}

.card-container {
  @media (max-width: $grid-breakpoint-desktop-large) {
    margin-left: 20px;
    margin-right: 20px;
  }

}


.mini-right-nav {

  .card-container-left {
    width: calc(100% - 104px);
    //width:92%;
    @media (max-width: $screen-lg-min) {
      float: none;
      width: 100%;
    }
  }

  .card-container-nav {
    //width:auto;
    width: 37px;
    float: left;

    &.affix {
      padding-right: 35px;
      @media (min-width: $grid-breakpoint-desktop-large) {
        padding-right: 0px;
      }
    }

    #card-nav {
      width: 30px;

      .expand-collapse {
        float: left;
      }

      p {
        visibility: hidden;
      }
    }

    .nav {
      li a {
        width: 30px;
        padding-right: 0px;
        //height:38px;
      }

      li span:nth-of-type(2) {
        //display:none;
      }
    }

    .expand-collapse {
      transform: rotate(180deg);
    }
  }

  .card-container-nav.affix #card-nav {
    //right:19px;
    right: 14px;
  }
}

.k-ie .mini-right-nav {
  .card-container-left {
    //width:calc(100% - 94px);
    @media (max-width: $screen-lg-min) {
      float: none;
      width: 100%;
    }
  }
}

.card-container-left {
  margin-left: 10px;
  padding-right: 20px;
  margin-right: 10px;
  border-right: 1px solid $card-box-border-color;
  float: left;
  width: calc(100% - 220px);
  position: relative;
  z-index: 2;

  @media (max-width: $grid-breakpoint-desktop-large) {
    margin-left: 10px;
  }
  @media (max-width: $screen-lg-min) {
    float: none;
    width: 100%;
  }

  .card-group-title {
    border-bottom: 3px solid $card-inside-border-color;
    padding-bottom: 5px;
    margin-bottom: 25px;

    h1 {
      font-size: 18px;
      color: $card-group-title-color;
      text-transform: uppercase;
      margin: 0px;
      padding: 0px;
      display: inline-block;
    }

    span {
      @include border-radius(2px);
      background: $card-box-square-alert-color;
      color: $card-group-title-badge-color;
      display: inline-block;
      padding: 2px 5px;
      font-size: 17px;
      margin-left: 7px;
      line-height: 18px;

    }
  }

  .card-box {
    width: 32%;
    @media (max-width: $screen-lg-min + 150px) {
      width: 49%;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
    }
  }
}

.card-container-nav {
  //max-width:195px;
  //width:auto;
  width: 195px;

  float: right;
  position: relative;

  @media (max-width: $screen-lg-min) {
    display: none;
  }
  @media (max-width: $grid-breakpoint-desktop-large) {
    //margin-right:10px;
    width: 185px;
  }

  &.affix {

    top: 0px;
    max-width: 1600px;
    margin: 0px auto;
    width: 100%;
    z-index: 1;
    position: fixed;

    #card-nav {
      position: relative;
      //right:19px;
      //right:0px;
    }

    div {
      float: right;
      background: $abs-body-desktop-bg;
      margin-right: 40px;
      @media (max-width: $grid-breakpoint-desktop-large) {
        margin-right: 5px;
      }

    }

    p {
      padding-top: 5px;
      //	width:20px;
    }
  }

  #card-nav {
    position: absolute;
  }

  p {
    @extend %ellipsis;
    font-weight: bold;
    font-size: 1.1em;
    color: $card-nav-font-color;
  }

  .nav {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  .nav .active a {
    background: $card-nav-link-bg;
    text-decoration: none;

  }

  .nav li a {
    @extend %ellipsis;
    position: relative;
    padding: 10px 10px 10px 40px;
    display: block;
    width: 155px;
    color: $card-nav-font-color;

    @media (max-width: $grid-breakpoint-desktop-large) {
      width: 180px;
    }

    .type-icon {
      @include border-radius(50%);
      background: $abs-menu-default-link-hover-bg;
      width: 28px;
      height: 28px;
      text-align: center;
      display: table;
      padding: 0px;
      position: absolute;
      left: 5px;
      top: 4px;

      > span {
        color: $card-nav-icon-color;
        display: table-cell;
        vertical-align: middle;
        padding: 0px;
        font-weight: normal;
      }
    }

    &:hover {
      background: $card-nav-link-bg;
      text-decoration: none;
    }
  }

  .expand-collapse {
    float: right;
    padding: 2px 5px;
    font-size: 2em;
    color: $card-nav-expand-ico;
    cursor: pointer;
    margin-top: 10px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    &:hover {
      color: darken($card-nav-expand-ico, 100%);
    }
  }

  .expanded {
    left: 0px;
  }

  .collappsed {
    left: -130px;
  }
}


// .info-section {
// 	.title-section h1 {
//     	color: $abs-title-color;
//     	font-size: 12px;
//     	text-transform: uppercase;
//     	font-weight: bold;
//     	background: $info-section-bg;
//     	display: inline-block;
//     	padding-right: 10px;
//     }
//     .title-section hr{
//     	margin-top:-18px;
//     }
// }


//horizontal
.horizontal-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  flex-direction: row;

  .right-actions {
    text-align: right;
    flex-grow: 1;

    .horizontal-container, .vertical-container {
      @include align-items(flex-end);
    }
  }
}

//vertical
.vertical-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  flex-direction: column;

}

.padding-right-15 {
  padding-right: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}


.divider:after {
  background: $container-box-border-color;
  width: 1px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
}

.visible {
  overflow: visible;
}

.abs-list-row {
  .abs-flexbox {
    display: flex;
  }
}

/* //////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////// */
/*                            abs-loader pure css                               */
/* //////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////// */

.abs-loader-mask {
  opacity: 0.2;
}

@-webkit-keyframes loading {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes loading {
  to {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes loading {
  to {
    -ms-transform: rotate(360deg);
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.abs-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;


  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: transparent;

  border-top: 3px solid $border-color;
  border-right: 3px solid $border-color;
  border-bottom: 3px solid $window-close-icon;
  border-left: 3px solid $window-close-icon;

  -webkit-animation: loading 0.7s infinite linear;
  -moz-animation: loading 0.7s infinite linear;
  -ms-animation: loading 0.7s infinite linear;
  animation: loading 0.7s infinite linear;
}

/* //////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////// */

.abs-strong {
  font-weight: bold;
}

.abs-sep-line {
  display: flex;
  align-items: center;
}

.abs-dot-sep {
  display: inline-block;
  content: " ";
  width: 5px;
  height: 5px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: #bababa;
  border-radius: 50%;
}

.sortable {
  cursor: pointer;
}

.filtered {
  border-radius: 2px;
  color: $color-neutral-0;
  background-color: $color-neutral-33;
  font-size: 10px;
  padding: 4px 6px;
  margin-left: 5px;
}

// strona bledow //

.container-error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100% - 100px);
  margin: auto;

  &-panel {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    height: 100%;

    h1 {
      color: $color-neutral-100;
      font-size: 22px;
      margin-bottom: 0;
      margin-top: 70px

    }

    h2 {
      color: $color-neutral-27;
      font-size: 18px;
      margin-top: 10px;
      font-weight: normal;
    }

    h3 {
      color: $color-neutral-100;
      font-size: 16px;
      margin: 10px 0px 5px;
    }

    a {
      display: block;
      margin-top: 40px;
      font-size: 15px;
      font-weight: bold;
    }
  }
}

#app {
  height: 100%;
}

.module-loader {
  height: 100%;

  &.loader-mask-style-b {
    &:after {
      height: 50px;
      width: 50px;
    }
  }
}

.loader-mask-style-a, .loader-mask-style-b {
  z-index: 10;
}

.abs-container {
  @media (max-width: $grid-breakpoint-desktop-large) {
    padding: 0 20px;
  }
  @media (max-width: $mobile-mode-max) {
    padding: 0;
    margin: 0 10px;
    width: auto;
  }
}

.light-txt {
  color: $color-neutral-60;
  word-break: break-word;
}

.text-align-right {
  text-align: right;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $color-neutral-46;
  outline: 1px solid slategrey;
}

.limit-progress-values {
  div:nth-child(1) {
    font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto';
    font-weight: 700;
  }

  div:nth-child(2) {
    display: flex;
  }
}

.container-box-visible {
  overflow: visible;
}

.not-displayed {
  display: none;
}

.collapse-container .collapse-pack-row {
  border: 1px solid $color-neutral-8;

  &--margin {
    margin-top: 20px;
  }
}

.collapse-container .collapse-row {
  border: none;
}

.abs-list-table-expandable {
  .collapse-container .collapse-row {
    border-bottom: 1px solid $color-neutral-8;
  }
}

.icon-with-text {
  display: flex;
  align-items: center;

  &--mobile {
    @media (max-width: $mobile-mode-max) {
      display: inline-block;
      position: relative;
      .abs-icon {
        position: absolute;
      }
      .icon-text {
        padding-left: 20px;
      }
    }
  }

  .abs-icon {
    margin-right: 5px;
  }
}

.abs-list-table .icon-with-text {
  .abs-icon {
    font-size: 16px;
  }

  .icon-text {
    line-height: 16px;
  }
}

.info-icon.abs-icon {
  color: $info-icon-color;
}

.default-icon.abs-icon {
  color: $default-icon-color;
}

.success-icon.abs-icon {
  color: $success-icon-color;
}

.danger-icon.abs-icon {
  color: $danger-icon-color;
}

.success, .abs-list-table .col .abs-list__price.success span {
  color: $color-positive-dark;
}

.success, .abs-list-table .col .abs-list__price--a.success span {
  color: $color-positive-dark;
}

.danger, .abs-list-table .col .abs-list__price.danger span {
  color: $color-negative-dark-3;
}

.danger, .abs-list-table .col .abs-list__price--a.danger span {
  color: $color-negative-dark-3;
}

.abs-list-table .col .hint.danger {
  color: $color-negative-dark-3;
}

.abs-list-table .col .abs-list__price--a.success,
.abs-list-table .col .abs-list__price--a.danger {
  justify-content: flex-start;
  margin-left: 0;
}

.abs-md-list.style-a .collapse-panel-section .abs-def .abs-def_item {
  width: auto;
}

.r-ss-wrap .r-ss-trigger .agreement-currency {
  font-size: 13px;
}

/* //////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////// */
/*                            abs-loader pure css                               */
/* //////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////// */

.loader-mask-style-b {
  position: relative;
  background: none;
}

@-webkit-keyframes loading {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes loading {
  to {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes loading {
  to {
    -ms-transform: rotate(360deg);
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.loader-mask-style-b:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: $color-neutral-0;
  left: 0px;
}

.page-header-data .loader-mask-style-b:before {
  background: $container-details-panel-bg;
}

.loader-mask-style-b:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;

  border-top: 4px solid $border-color;
  border-right: 4px solid $border-color;
  border-bottom: 4px solid $window-close-icon;
  border-left: 4px solid $window-close-icon;

  -webkit-animation: loading 0.7s infinite linear;
  -moz-animation: loading 0.7s infinite linear;
  -ms-animation: loading 0.7s infinite linear;
  animation: loading 0.7s infinite linear;

  z-index: 99999;
}

input.k-checkbox {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  -webkit-appearance: none;
  overflow: hidden;
}

.k-checkbox-label {
  display: inline-block;
  position: relative;
  padding-left: 18px;
  vertical-align: text-top;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
  margin-bottom: 0;

  &:before {
    border-color: #ccc;
    background: #fff;
    border-radius: 3px;
  }
}

.progress-steps.progress-steps__no-link li {
  a {
    text-decoration: none;
    cursor: auto;
  }
}

.fw-filter-field-link {
  display: block;
  width: fit-content;
  margin-left: auto;
  padding-top: 35px;
}

.toast-head {
  text-transform: uppercase;
}

.fw-menu-configuration {
  .quick-settings-item {
    color: $quick-settings-item-color;
    background: transparent;
    font-size: 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    min-height: 53px;

    &:focus {
      background: transparent;
      border-color: transparent;
      color: $quick-settings-item-color-focus;
    }

    &:hover, &:active, &:active:focus {
      color: $quick-settings-item-color-hover;
      background: $quick-settings-item-bg-color-hover;
      border-color: $quick-settings-item-border-color-hover;
      box-shadow: none;
    }

    .abs-icon {
      color: $quick-settings-icon-color;
    }

    .quick-settings-placeholder {
      display: flex;
      align-items: center;

      .quick-settings-placeholder-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: 400;

        .quick-settings-placeholder-text__content {
          color: $quick-settings-placeholder-text-color;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 185px;
        }
      }

      .abs-icon {
        color: $quick-settings-placeholder-icon-color;
        margin-right: 0;

        &.abs_employee_data {
          font-size: 32px;
          margin-right: 4px;
        }

        &.abs_business {
          margin-right: 7px;
        }
      }
    }
  }

  .abs-dropdown.open {
    .quick-settings-placeholder {
      .quick-settings-placeholder-text {
        .quick-settings-placeholder-text__content {
          color: $quick-settings-placeholder-text-color-active;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 185px;
        }
      }

      .abs-icon {
        color: $quick-settings-placeholder-icon-color-active;
      }
    }

    .quick-settings-item .abs-icon {
      color: $quick-settings-placeholder-icon-color-active;
    }
  }
}

.dropdown.abs-dropdown {
  &.open {
    .btn.quick-settings-dropdown {
      color: $abs-dropdown-open-color;
      background: $abs-dropdown-open-bg-color;
      border-color: $abs-dropdown-open-border-color;
      font-size: 13px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-shadow: unset;

      &:hover {
        color: $abs-dropdown-open-color-hover;
        background: $abs-dropdown-open-bg-color-hover;
        border-color: $abs-dropdown-open-border-color-hover;
      }
    }
  }
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu .fw-menu-configuration .abs-dropdown .dropdown-menu {
  width: 100%;
  min-width: auto;

  > li a {
    padding: 3px 12px;
    white-space: normal;
  }
}

.dropdown.abs-dropdown {
  @media (max-width: $mobile-mode-max) {
    display: flex;
    flex-direction: column;
  }
}

.container-details-panel {
  margin-bottom: 20px;

  &__no-margin {
    margin-bottom: 0;
  }
}

.container-box-flex .box-flex-a {
  @media (min-width: $grid-breakpoint-desktop-large) {
    margin-bottom: 5px;
  }
}

//loader

.loader {
  display: inline-flex;
  @include justify-content(center);
  @include align-items(center);
  position: relative;
  z-index: 99;
  pointer-events: none;

  &--with_opacity {
    opacity: 0.5;
  }

  &__small {
    min-height: 20px;

    .loader-component {
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }

  &__mid {
    min-height: 40px;

    .loader-component {
      width: 40px;
      height: 40px;
    }
  }

  &__large {
    min-height: 70px;

    .loader-component {
      width: 70px;
      height: 70px;
    }
  }

  > div {
    opacity: 0.7;
  }
}

.loader-component {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;
  border: 4px solid #e3e3e3;
  border-bottom-color: #666666;
  border-left-color: #666666;
  -webkit-animation: loading 0.7s infinite linear;
  -moz-animation: loading 0.7s infinite linear;
  -ms-animation: loading 0.7s infinite linear;
  animation: loading 0.7s infinite linear;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* Scroll up button */

.fw-scroll-up-button {
  width: 28px;
  height: 28px;
  @include flex-all-center();
  background-color: $scroll-up-button-background;
  border-radius: 2px;

  .abs_arrow_upward {
    color: $color-neutral-0;
    font-size: 20px;
  }
}

.scroll-tooltip {
  width: 114px;
  height: 34px;

  &.top {
    padding: 0;
  }
}

//DETAILS PANEL

.details-panel [role="listbox"] .icon-placeholder {
  @media (min-width: $desktop-mode-min) {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
  }
}

//LIST DROPDOWN

.abs-list-row .hamburger > .dropdown > .dropdown-menu {
  position: fixed;
}

.abs-list-row .hamburger > .dropdown .dropdown-menu {
  bottom: initial;
}

//CONTAINERS PADDING

.container-fluid {
  @media (max-width: $grid-breakpoint-desktop-large) and (min-width: $desktop-mode-min) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.details-panel {
  @media (max-width: $grid-breakpoint-desktop-large) and (min-width: $desktop-mode-min) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-box-flex .box-flex-a {
  margin: 0;
}

/* //////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////// */

/* ERROR PAGE */

.logout-form {
  &__submit-btn {
    background: none;
    border: none;
    color: $color-brand-primary-dark;
    display: block;
    font-size: 15px;
    font-weight: bold;
    margin-top: 40px;
    text-decoration: none;

    &:hover, &:focus {
      color: $color-neutral-100;
      cursor: pointer;
      outline: none;
      text-decoration: underline;
    }
  }
}

//BOTTOM BUTTON PANEL

.container.button-wrap, body .abs-container .button-wrap.container {
  @media (max-width: $mobile-mode-max) {
    height: 50px;
  }
}

//CHECKBOX

input.toggle-flip + label:before, input.toggle-flip + label:after, input.toggle-flip + label:hover:after {
  transition: none;
}

input.toggle-flip:checked + label:before, input.toggle-flip:checked + label:after {
  transform: none;
}

//CONTAINERS WIDTH

@media (min-width: $grid-breakpoint-desktop-large) {
  .container-nav .container-fluid, .abs-container {
    max-width: 1320px;
  }
  .container-nav .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

//CONTAINER

.container-box {
  @media (min-width: $grid-breakpoint-desktop-large) {
    max-width: 1320px;
  }
}

//COLUMN LABEL (MOBILE)

.mobile-column-label {
  font-weight: 700;
  display: inline-block;
  margin-right: 5px;
}

//CONTEXT DROPDOWN MOBILE MODE
.abs-page-header-title .dropdown.abs-dropdown.btn-group {
  @media (max-width: $mobile-mode-max) {
    display: inline-block;
  }
}

//TABLE EMPTY MOBILE COLUMN
.abs-list-table .abs-list-table-mobile-empty:last-of-type {
  padding-bottom: 20px;
}

//INVOICE CONFIRMATION SELECTION TOOLS WARNING
.selection-tools-info {
  position: relative;
  @include flex-align-items-center();

  .info-text {
    white-space: nowrap;
  }

  @media (max-width: $mobile-mode-max) {
    .tooltip.top {
      top: -40px !important;
      left: -180px !important;
    }
    .tooltip-arrow {
      left: 46.25% !important;
    }
    .tooltip-inner {
      max-width: none;
    }
  }

  .icon-warning {
    color: $color-warning-dark-2;
    font-size: 20px;
    margin-right: 10px;
    @media (max-width: $mobile-mode-max) {
      cursor: pointer;
    }
  }

  @media (max-width: 420px) {
    .info-text {
      white-space: normal;
    }
    .tooltip-inner {
      width: 300px;
    }
    .tooltip.top {
      top: -60px !important;
    }
    .tooltip-arrow {
      left: 63.25% !important;
    }
  }
}

.fw-dashboard-cards.fw-import-templates-cards {
  justify-content: flex-start;

  @media (max-width: $mobile-mode-max) {
    justify-content: center;
  }

  .abs-settings.abs-card-panel {
    margin: 20px 0 0 20px;
  }
}

.col-sm-12 {
  width: 100%;
}

.fw-i18n--block {
  display: block;
}

@media (min-width: 1025px) {
  .footer-content {
    display: flex;
    justify-content: space-between;
    min-height: 50px;

    &--left {
      flex-grow: 1;
    }

    &--right {
      color: $color-neutral-20;
      align-items: center;
      justify-content: flex-end;
      display: flex;
    }
  }
}

@media (max-width: 1024px) {
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--left, &--right {
      text-align: center;
      width: auto !important;
    }

    p .abs_life_ring {
      display: none;
    }

    &--right {
      color: $color-neutral-20;
      border-top: 1px solid $color-neutral-60;
      padding: 16px 0;
    }
  }

  .footer p {
    clear: left;
    margin: 0;
    width: 300px;
    display: block;
    float: none;
    padding-left: 25px !important;
    line-height: 20px;

    &:nth-of-type(1) {
      padding-top: 15px;
    }

    &:last-child {
      padding-bottom: 15px;
    }
  }
}

.hyphen {
  white-space: pre;
}

.abs-toast .toast-body {
  word-break: break-word;
}

.details-tabs-after-details-data-panel {
  margin-top: 30px;
}

.footer p {

  &:nth-of-type(1) {
    font-weight: normal;
  }

  &.helpdesk-name {
    font-weight: bold;
  }
}

.alert-unsupported {
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding: 20px;
  text-align: center;
  top: -15%;

  @media (max-width: $screen-sm-min) {
    width: 90%;
  }

  &:before {
    display: none;
  }

  &__title {
    color: $color-neutral-80;
    font-size: 18px !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    text-transform: none;
  }

  &__desc {
    color: $color-neutral-70;
    overflow: hidden;
    width: 100%;
  }

  &__links {
    font-size: 13px;
    margin-top: 20px;

    a {
      display: inline-block;
      margin-top: 0;
      font-size: 13px;
      margin-right: 4px;
    }

    .browser-link:not(:last-of-type)::after {
      color: $color-neutral-100;
      font-size: 1.846rem;
      line-height: 13px;
      font-weight: bold;
      vertical-align: middle;
      content: " • ";
      opacity: 0.2;
    }
  }

  svg {
    margin: 0 auto 12px;
  }
}
