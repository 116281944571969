.absui-spinner {
  width: 100%;
}

.absui-spinner__controls-wrap {
  flex-direction: column;
  border-left: none;
  padding: 0; }

.absui-form-control {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  position: relative;
  padding-right: 0;
  display: flex;
}

.absui-form-control {
  height: 26px;
  text-indent: 0;
  line-height: 26px;
}

.absui-spinner__controls-wrap {
  display:flex;
  position: relative;
  left: -20px;
}

.absui-spinner__controls {
  height: 13px;
  position: relative;
  cursor: pointer;
}

.absui-spinner input {
  width: 100%;
  padding-right: 22px;
}

.fw-spinner-icon--more {
  transform: rotate(180deg);
}

.fw-spinner-icon {
  width: 13px;
  height: 13px;
  position: absolute;
}