// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                             desktop query for list
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

@media screen and (min-width: 768px) {
  .container-visible {
    flex-grow: 1;
    visibility: visible;
    width: 96%;
    margin: 0 auto;
  }
  .abs-list-table {
    border: 1px solid $info-chart-panel-border;
    display: table;

    .abs-list-table-tbody {
      input.toggle-flip + label:before, input.toggle-flip + label:after {
        top: -10px;
      }
    }

    &.style-a {
      .col:not(.abs-flexbox) {
        .abs-label {
          &:before {
            display: none;
          }
        }
      }
      .l-info.abs-label {
        > a {
          font-weight: normal;
        }
      }
      .info.abs-label {
        > a {
          font-weight: normal;
        }
      }
    }
    &.style-b {
      .l-info.abs-label {
        > a {
          font-weight: normal;
        }
      }
      .info.abs-label {
        > a {
          font-weight: normal;
        }
      }
    }
    &.style-a {
      .abs-label {
        &:before {
          display: none;
        }
      }
    }
    .header {
      background-color: $info-chart-li-selected-bg;
      font-weight: bold;
      height: 2.4em;
      display: table-row;
      .col {
        border-right: 1px solid $info-chart-panel-border;
        border-bottom: 2px solid $info-chart-panel-border;
        &:first-child {
          padding: 0;
        }
        span {
          &.ico {
            color: $color-positive-dark;
            display: inline-block;
            vertical-align: bottom;
            margin-bottom: 2px;
            margin-right: 6px;
            &:before {
              font-size: 16px;
            }
          }
        }
        &:last-child {
          border-right: none;
        }
        .block-switch + span.checkbox-label {
          border-left: 1px solid $info-chart-panel-border;
        }
        span.checkbox-label {
          display: inline-block;
          padding: 10px 15px;
          &.sortable {
            width: 100%;
          }
        }
      }
      .sortable {
        &:hover {
          cursor: pointer;
          background-color: $k-upload-file-border;
        }
      }
      .block-switch {
        margin: 8px 24px 5px 20px;
        padding: 0;
      }
    }
    .abs-list-row {
      display: table-row;

      .col:first-child {
        &.col--no-sep {
          border-right: none;
        }
      }

      .abs-label {
        &--strong {
          font-weight: bold;
        }
        &--positive {
          color: $abs-toast-success-border-color;
        }
        &--negative {
          color: $forms-validation-border-color;
        }
        &--std {
          text-align: right;
          display: block !important;
        }
        &:before {
          display: none;
        }
      }

      .abs-label > a {
        font-weight: bold;
      }

      .col {
        border-bottom: 1px solid $border-color;
        &.col--mid {
          vertical-align: middle;
        }
        &:first-child {
          padding: 10px 10px 10px 20px;
          border-right: 1px solid $border-color;
        }

        .hamburger {
          position: absolute;
          top: 6px;
          right: 10px;
          // float: right;
          // margin-top: -30px;

          .btn-primary {
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: 2px;

          }

          .block-switch + .info > .fw_info {
            padding-left: 57px;
          }
        }
      }

      &:last-child .col {
        border-bottom: 0;
      }

      &:hover {
        background-color: $grid-tr-on-hover;
      }
    }
    .col {
      display: table-cell;
      vertical-align: top;
      height: 100%;
      text-align: left;
      position: relative;
      .abs-value {
        &_item {
          display: inline-block;
          &:not(:first-child):before {
            color: $color-neutral-27;
            content: " • ";
            font-size: 24px;
            vertical-align: middle;
            line-height: 13px;
            padding-left: 5px;
          }
          &--drop {
            display: block;
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .col--mid {
      vertical-align: middle;
    }
    .abs-amount {
      text-align: right;
    }
    .fw_info .hamburger {
      position: absolute;
      right: 10px;
      padding: 0;
    }
    input {
      height: auto;
    }
    .abs-first-label {
      display: none;
    }
    .info {
      // flex-basis: 80%;
      flex-grow: 1;
      padding-left: 0;
      .fw_info {
        padding-left: 38px;
      }
    }
    .info-field {
      word-break: break-all;
    }
    .info-large-field {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dropdown-menu {
      font-size: 13px;
    }
    .dropdown-menu > li > a {
      padding: 6px 3px 6px 15px;
    }
    .block-switch {
      padding-top: 4px;
      padding-right: 0px;
    }
    ul.dropdown-menu.dropdown-menu-right {
      position: absolute;
      top: 90%;
    }

    .icon-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      &_icon {
        font-size: 18px;
      }
      &_info {
        display: block;
      }
      &--col {
        flex-direction: column;
        align-items: flex-start;
      }
      .abs-icon {
        padding-right: 10px;
      }
      &_detailes {
        display: flex;
        flex-direction: row;
        align-items: center;
        .abs-icon {
          font-size: 15px;
          padding-right: 5px;
        }
      }
    }

    .abs-decker {
      display: flex;
      flex-direction: column;
      .col {
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          border-right: 1px solid $color-neutral-8;
        }
      }
    }

    .before-dropdown {
      display: flex;
    }
  }
  .load-more {
    a {
      border-left: 1px solid $color-neutral-20;
      border-right: 1px solid $color-neutral-20;
    }
  }
  .dropdown-submenu {
    .dropdown-link {
      i {
        -ms-transform: rotate(-90deg); /* IE 9 */
        -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
        transform: rotate(-90deg);
      }
    }
  }
}

// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                            mobile query for list
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

@media screen and (max-width: 767px) {
  .abs-list-table {
    &.style-a {
      .col:not(.abs-flexbox) {
        .abs-label {
          font-weight: normal;
          font-size: 13px;
          padding: 0;
          text-align: left;
          color: $k-upload-dropzone-color;
          line-height: 17px;
          &:before {
            display: block;
            font-weight: bold;
            color: $abs-k-filter-text-color;
            padding: 0 4px 0 0;
            font-size: 100%;
            content: attr(data-label) ": ";
          }
        }
      }
    }
    &.style-b {
      .col {
        .abs-list__price--a {
          margin-left: 0;
        }
      }
    }
    .header {
      display: none;
      background-color: $btn-white-bg;
    }
    .abs-list-row {
      border-top: 2px solid $info-chart-panel-border;
      &.selected {
        .block-switch {
          background-color: $abs-scheduler-today-selected-bg;
        }
      }
      .col:first-child {
        display: flex;
        height: 64px;
        padding: 0;
        .info {
          // flex-basis: 90%;
          flex-grow: 1;
        }
      }
      .col:last-child {
        padding-bottom: 20px;
      }
      .col {
        padding-top: 15px;
        padding-bottom: 0;
        .abs-list__price {
          justify-content: flex-start;
        }
        .abs-list__price--a {
          justify-content: flex-start;
          width: auto;
        }
        .info {
          display: block;
          .hint {
            // display: inline-block;
            display: none;
          }
        }
        .hamburger {
          .info {
            border-bottom: 1px solid $container-box-border-color;
          }
          .info:first-child {
            padding-left: 60px;
          }
          .dropdown-menu-right {
            max-height: 260px;
            overflow-y: auto;
          }
          .dropdown-menu {
            top: 0;
            width: 100%;
            margin: 0;
            border: none;
            background-color: $forms-elements-mouseover-bg;
            border-bottom: 1px solid $container-box-border-color;
            box-shadow: none;
            li {
              margin: 0px 0px 0px 60px;
              border-bottom: 1px solid $window-title-border-color;
              padding: 0;
              &:last-child {
                border: none;
              }
              a {
                font-size: 13px;
                padding: 10px;
                &:hover {
                  background-color: $color-brand-primary-light;
                }
              }
            }
            .dropdown-submenu {
              a {
                background-color: transparent;
                &:active, &:focus {
                  border: none;
                  outline: none;
                }
              }
              .dropdown-menu {
                left: 0;
                font-size: 13px;
                border-bottom: none;
                li {
                  margin-left: 30px;
                  border-bottom: 1px solid $window-title-border-color;
                  &:first-child {
                    border-top: 1px solid $window-title-border-color;
                  }
                }
              }
            }
          }
          button {
            background-color: transparent;
            box-shadow: none;
            color: $btn-blue;
            border-radius: 0px;
            border: none;
            border-bottom: 1px solid $container-box-border-color;
            &.btn {
              padding: 13px 15px;
              display: flex;
              align-items: center;
              height: 65px;
            }
          }
          button[aria-expanded="true"] {
            background-color: transparent;
            box-shadow: none;
            color: $btn-blue;
            border-radius: 0px;
            border: none;
            border-left: 1px solid $container-box-border-color;
            border-bottom: 1px solid transparent;
            background-color: $forms-elements-mouseover-bg;
            &.btn {
              padding: 6px 15px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .col-a {
        padding-top: 10px;
      }
      .abs-first-label {
        padding-top: 20px;
      }

      .abs-value {
        &--portrait {
          .icon-text_info, .icon-text_icon {
            display: none;
          }
        }
      }
    }
    .hamburger {
      flex-wrap: wrap;
      align-items: stretch !important;
      width: 100%;
    }
    .fw_info {
      padding-bottom: 10px;
      padding-top: 15px;
      margin-bottom: 0;
    }
    .abs-list-row .col:not(:first-child) {
      padding-left: 60px;
    }
    .list {
      color: $abs-font-color;
      div {
        display: inline-block;
        &:not(:first-child):before {
          color: $color-neutral-27;
          content: " • ";
          font-size: 24px;
          display: inline;
          vertical-align: middle;
          line-height: 13px;
          padding-left: 5px;
        }
      }
    }
    .l-info {
      display: block;
    }
    .dropdown-menu {
      position: relative;
    }
    .block-switch {
      padding-top: 23px;
      top: 1px;
      background-color: $tooltip-color;
    }
    .open {
      .block-switch {
        border-bottom: 1px solid $container-box-border-color;
      }
    }
    .before-dropdown {
      position: absolute;
      z-index: 998;
      display: flex;
      //100%-dropdown
      width: calc(100% - 72px);
      border-bottom: 1px solid $container-box-border-color;
    }
    .col.abs-flexbox.abs-dropdown-none {
      .before-dropdown {
        width: 100%;
      }
    }

    .icon-text {
      display: inline-block;
      &_icon {
        font-size: 18px;
        display: inline-block;

      }
      &_info {
        display: inline-block;
      }
      .abs-icon {
        padding-right: 0;
        &.label--positive {
          color: $color-positive-dark;
        }
      }
    }
    .abs-label {
      &--positive {
        color: $color-positive-dark;
      }
      &--negative {
        color: $color-negative-dark-4;
      }
      &--strong {
        font-weight: bold;
      }
    }

    .abs-decker {
      .col {
        height: auto;
        &:first-child {
          height: auto;
          padding-left: 60px;
          padding-top: 10px;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .hint {
          &:before {
            color: $color-neutral-11;
            content: " • ";
            font-size: 24px;
            display: inline;
            vertical-align: middle;
            line-height: 13px;
            padding-left: 5px;
          }

        }
      }
    }

    .abs-def {
      &_row {
        &:not(:first-child) {
          padding-top: 10px;
        }
        dt {
          display: none;
        }
      }
      &_item {

      }
    }

    .col {
      .abs-list {
        &__price {
          &--a {
            margin-left: 0;
          }
        }
      }
    }
  }
  .nr > a {
    &:after {
      font-family: 'Lumen-Icon-Font';
      content: '\e318';
      color: $color-neutral-27;
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;
      height: 22px;
      font-size: 19px;
    }
  }
}

// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                                general list styles
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

.abs-list-table {
  width: 100%;
  font-size: 13px;

  .block-switch {
    input.toggle-flip[type=checkbox][disabled] + label {
      opacity: 0.4;
      cursor: not-allowed;
      &:hover:before {
        border: 1px solid $forms-validation-border-color;
        background-color: $validation-header-border-color;
      }
    }
  }
  .abs-list-row {
    background-color: $k-calendar-link-focused;
    &.selected {
      background-color: $abs-scheduler-today-selected-bg;
    }
  }
  .header {
    .col {
      &:first-child {
        display: flex;
        align-items: center;
      }
    }
  }
  .info {
    display: inline-block;
    .l-info {
      color: $text-info;
    }
    .dropdown-toogle {
      border: none;
    }
  }
  .nr {
    font-weight: bold;
    font-size: 16px;
  }
  .abs-label {
    font-weight: normal;
    font-size: 13px;
    padding: 0;
    text-align: left;
    color: $k-upload-dropzone-color;
    line-height: 17px;
    display: grid;
    &:before {
      display: flex;
      font-weight: bold;
      color: $abs-k-filter-text-color;
      padding: 0;
      font-size: 100%;
      content: attr(data-label) ": ";
    }

    .abs-list__price {
      &--a {
        .hint {
          color: $icon-pin;
        }
      }
    }
  }
  .col {
    padding: 8px 10px;
    line-height: 17px;
    &--header {
      padding: 10px 15px 10px 15px;
    }
    .abs-list__price {
      display: flex;
      justify-content: flex-end;
      color: $abs-scheduler-day-selected-color;
      .abs-list__price-bold {
        font-weight: bold;
      }
      span {
        display: inline;
        &:last-child {
          margin-left: 3px;
        }
      }
    }
    .abs-list__price--a {
      display: inline-flex;
      width: 100%;
      justify-content: flex-end;
      color: $abs-scheduler-day-selected-color;
      // margin-left: -3px;
      .abs-list__price-bold {
        font-weight: bold;
      }
      span {
        display: inline;
        &:last-child {
          margin-left: 3px;
        }
      }
    }
  }

  .hamburger {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    .btn-primary {
      border: none;
      color: $btn-blue;
      background-color: transparent;
      &:hover, &:active {
        color: $btn-blue;
        border: none;
        background-color: $btn-type-c-focus-border-color;
      }
    }
    .open > .btn-primary.dropdown-toggle {
      &:focus {
        color: $btn-blue;
        border: none;
        background-color: $btn-type-c-focus-border-color;
      }
    }
    .info {
      .fw_info {
        color: $abs-scheduler-day-selected-color;
        .nr {
          font-size: 13px;
          a {
            font-weight: bold;
          }
        }
      }
      button {
        display: flex;
        align-items: center;
      }
    }
    &.open {
      .btn {
        .abs_expand_more {
          display: inline-block;
          transform: rotate(180deg);
          -ms-transform: rotate(180deg); /* IE 9 */
          -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        }
      }
    }
  }
  .dropdown-submenu {
    position: relative;
    .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -1px;
    }
    .dropdown-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      i {
        color: $flat-gray;
        font-size: 20px;
      }
    }
  }
  .abs_menu:before {
    font-size: 22px;
    line-height: 20px;
  }
}

.abs-dropdown--is-hidden {
  visibility: hidden;
}

.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  a {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    font-size: 13px;
    padding: 18px 0;
    font-weight: bold;
    color: $btn-type-c-hover-color;
    background-color: $btn-type-c-hover-bg;
    height: 24px;
    border-bottom: 1px solid $container-box-border-color;
    border-top: 1px solid $border-color;
    margin-top: -1px;
    &:hover {
      background-color: $forms-elements-mouseover-bg;
    }
  }
}

.abs_expand_more {
  font-size: 20px;
}

@media screen and (min-width: 1600px) {
  .container-visible {
    margin: 0 auto;
    max-width: 1600px;
  }
}

.abs-def {
  &_row {
    width: 100%;
    margin-bottom: 0px;
  }
  &_item {
    display: inline-block;
    width: calc(70% - 10px);
    &--right {
      text-align: right;
      width: 30%;
      padding-right: 10px;
    }
  }
}

.list-min-width {
  min-width: 70px;
}

.abs-list-table .col .hint {
  color: $color-neutral-60;
}

.success, .abs-list-table .col .abs-list__price.success span {
  color: $color-positive-dark;
}

.success, .abs-list-table .col .abs-list__price--a.success span {
  color: $color-positive-dark;
}

.danger, .abs-list-table .col .abs-list__price.danger span {
  color: $color-negative-dark-3;
}

.danger, .abs-list-table .col .abs-list__price--a.danger span {
  color: $color-negative-dark-3;
}

.abs-list-table .col .hint.danger {
  color: $color-negative-dark-3;
}

.abs-list-table .col .hint .abs-list__price--a,
.abs-list-table .col .hint .abs-list__price {
  color: $color-neutral-60;
}

.abs-list-table .col .icon-with-text + .hint {
  margin-left: 21px;
}

.abs-list-table .col .abs-list__price--a.success,
.abs-list-table .col .abs-list__price--a.danger,
.abs-list-table .col .abs-list__price--a.info {
  justify-content: flex-start;
  margin-left: 0;
}

.tab-content .abs-list-table {
  &.abs-list-with-top-border {

    border-top: 1px solid $color-neutral-20;
  }
}

.abs-list-table .col {
  padding: 9px 10px;
}

@media screen and (min-width: $desktop-mode-min) {
  .abs-list-table {
    height: 100%;

    .header .col {
      line-height: 100%;
      height: 100%;

      span.ico {
        margin-top: -2px;
        margin-bottom: 0;
      }

      .col-content {
        display: flex;
        line-height: 100%;
        height: 100%;
        width: 100%;

        .header-label {
          line-height: 17px;

          .abs-icon {
            line-height: 17px;
          }

          .filtered-amount {
            height: 19px;
            width: 24px;
            background: $color-neutral-33;
            border-radius: 2px;
            color: $color-neutral-0;
            text-align: center;
            font-size: 10px;
            margin-left: 10px;
          }
        }
      }
    }

    &.not-selectable {
      .header {
        .col:first-child {
          padding: 9px 10px;

          span.checkbox-label {
            padding: 0;
          }
        }
      }

      .abs-list-row .col:first-child {
        padding: 10px 10px 10px 10px;

        .info .fw_info {
          padding-left: 0;
        }
      }
    }

    .header {
      .block-switch {
        margin: 11px 24px 8px 20px;
      }
    }
  }
}

.abs-list-table-no-results {
  @include font-style($roboto-font-family, 500, normal);
  text-transform: uppercase;
  color: #666;
  padding-top: 40px;
  text-align: center;
}

.abs-list-table-body, .load-more a {
  border-left: none;
  border-right: none;
}

.dropdown-submenu {
  .btn-default:hover, .btn-default:active, .btn-default:focus,
  .open > .btn-default.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .btn-default.dropdown-toggle:hover {
    background-color: $color-brand-primary-light;
  }

  .btn-group {
    display: block;
    padding: 2px;

    .dropdown-toggle {
      @include box-shadow(none);
      padding: 6px 0 6px 7px;
      width: 100%;
      @include border-radius(2px);

      span {
        font-weight: 400;
        color: $color-neutral-100;
      }
    }

    @media screen and (min-width: $desktop-mode-min) {
      ul.dropdown-menu.dropdown-menu-right {
        top: 0;
      }
    }
  }

  .open > .btn[aria-expanded="true"] .abs-rotate {
    transform: rotate(90deg);
  }
}

.abs-md-list.style-a .dropdown-submenu .dropdown-menu {
  margin-left: 4px;
  margin-top: -3px;
}

.abs-dropdown .dropdown-menu li {
  .dropdown.open {
    .dropdown-toggle.btn {
      background: $color-brand-primary-utility-1;
      border-radius: 2px;

      span, .abs-icon {
        color: $color-brand-primary-dark;
      }
    }
  }

  a, span.menuitem {
    font-size: 13px;
    padding: 6px 7px 6px 7px;
    color: $color-neutral-100;
    min-width: 160px;

    white-space: unset;
    word-break: break-word;
    max-width: 250px;
  }
}

.abs-list-table .dropdown-submenu .dropdown-menu {
  left: calc(100% + 2px);
}

@media screen and (min-width: $desktop-mode-min) {
  .abs-list-table {
    border-left: 1px solid $color-neutral-20;
    border-right: 1px solid $color-neutral-20;

    &.with-right-border {
      border-right: 1px solid $color-neutral-20;
    }

    .header .col {
      background: $color-neutral-4;

      &.sortable .checkbox-label {
        width: 100%;
      }

      span.checkbox-label {
        padding: 9px 0 9px 10px;
      }
    }

    &.not-selectable {
      .header .col {
        background: $color-neutral-4;

        &:first-child {
          display: table-cell;
        }
      }

      .info .fw_info {
        padding-left: 15px;
      }
    }

    .abs-list-row {
      .col {
        border-color: $color-neutral-8;

        .nr.abs-label, .abs-list-row .nr.label {
          font-weight: bold;
          font-size: 13px;
          min-height: 17px;
          padding-right: 45px;
        }

        &:first-child {
          border-color: $color-neutral-8;

          .flex-col {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hamburger {
              position: static;
            }

            .hint.with-padding {
              padding-right: 55px;
            }

            &.with-dropdown {
              .hint.with-padding {
                padding-right: 20px;
              }

              .nr.abs-label, .abs-list-row .nr.label {
                padding-right: 20px;
              }
            }
          }
        }
      }
    }

    .dropdown-menu {
      border: 1px solid $color-neutral-33;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.35);
      border-radius: 2px;
      min-width: 180px;

      li {
        min-height: 26px;

        &:not(.dropdown-submenu) {
          padding: 3px;
        }

        > .dropdown.btn-group {
          height: 100%;

          padding: 3px;

          > .dropdown-toggle {
            height: 100%;
            padding: 0 5px;
            border-radius: 2px;
          }
        }

        > a {
          min-height: 26px;
          height: auto !important;
          white-space: unset;
          word-break: break-word;

          padding: 0 5px;
          display: flex;
          align-items: center;
          margin: 0;
        }
      }

      .dropdown-submenu {
        .dropdown-menu {
          min-width: 150px;
          border: 1px solid $color-neutral-33;
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.35);
          border-radius: 2px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-mode-max) {
  .abs-list-table.not-selectable .col:first-of-type {
    .info {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .abs-list-table .before-dropdown {
    border-bottom: none;
    display: flex;
    width: 100%;
    position: static;

    .info {
      cursor: pointer;
    }
  }
  .abs-list-table .before-dropdown + .dropdown {
    border-left: 1px solid $color-neutral-8;
  }
  .abs-list-table .abs-list-row .col .hamburger button.btn {
    justify-content: flex-end;
  }
  .abs-list-table .abs-list-row, .abs-list-table.abs-list-table-expandable .collapse-container {
    margin-top: 10px;
    border-bottom: 1px solid $color-neutral-16;
    border-left: 1px solid $color-neutral-20;
    border-right: 1px solid $color-neutral-20;
  }
  .abs-list-table-body, .abs-list-table-tbody {
    border: none;

    .abs-list-row .dropdown.abs-dropdown {
      flex-direction: row;
      height: 100%;
      width: 54px;
    }
  }

  .abs-list-table .abs-list-row .col:first-child, .abs-list-table .abs-list-table_row .col:first-child, .abs-list-table .block-switch, .abs-list-table-expandable .collapse-container .collapse-row {
    background-color: $color-neutral-2;
  }
  .abs-list-table .abs-list-row .col .hamburger button {
    border-bottom: 1px solid $color-neutral-8;
  }
  .abs-list-table .block-switch {
    top: 0;
    padding: 20px 37px 10px 18px;
  }
  .abs-list-table .col:first-child .abs-label a {
    font-size: 16px;
    font-weight: 500;
  }
  .nr > a:after {
    content: none;
  }
  .nr:after {
    content: none;
  }
  .abs-list-table .abs-list-row, .abs-list-table .abs-list-table_row {
    .col:first-child {
      height: 56px;
      border-bottom: 1px solid $color-neutral-8;
    }
  }
  .abs-list-table .fw_info {
    padding-top: 10px;
  }
  .abs-list-table .abs-list-row .col .info, .abs-list-table .abs-list-table_row .col .info {
    @include flex-space-between();
    align-items: center;

    .details-arrow {
      font-size: 24px;
      color: $color-neutral-33;
    }
  }
  .abs-list-table .col:first-of-type .dropdown-menu:first-of-type {
    position: absolute;
    width: 50vw;

    a[role="menuitem"] {
      white-space: normal;
    }

    .dropdown-submenu {
      .dropdown-toggle {
        height: 30px;
      }

      .dropdown-menu {
        top: 0;
        left: auto;
        right: 90%;
        width: 45vw;
      }
    }
  }
  .abs-md-list.style-a .collapse-row .collapse-panel-section.container-box-flex.col-md-3 {
    flex-direction: row;
  }
  .abs-list-table .abs-list-row .col:not(:first-child), .abs-list-table .abs-list-table_row .col:not(:first-child) {
    padding-left: 20px;
  }
  .abs-list-table .col:not(:first-of-type) .abs-label {
    display: flex;

    &:before {
      margin-right: 5px;
      white-space: nowrap;
    }

    > div {
      word-break: break-word;
    }
  }
  .abs-list-table .col:first-of-type .abs-label:before, .abs-list-table.style-a .col:first-of-type .abs-label:before {
    content: attr(data-label) " ";
  }
  .abs-list-table .abs-list-row {
    &--selected, &--selected .col:first-child, &--selected .block-switch {
      background-color: $color-warning-light-1;
    }
  }
  .abs-list-table .abs-list-table_row .info {
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
  }
  .abs-list-table-expandable .collapse-container .collapse-row.abs-list-table_row {
    cursor: auto;
    border-top: 2px solid $color-neutral-20;
    padding: 0;
  }
  .abs-list-table.abs-list-table-expandable .abs-list-table-tbody .collapse-container {
    .collapse, .collapsing {
      border-top: none;
      margin: 0 10px 10px 10px;
    }
  }
  .abs-list-table.abs-list-table-expandable .abs-row-expandable-zone {
    height: 22px;
    @include flex-all-center();
    background-color: $color-neutral-0;
    cursor: pointer;

    &__arrow {
      color: $color-brand-primary-dark;
      font-size: 22px;
    }

    &--expanded {
      border-bottom: 1px solid $color-neutral-8;
    }
  }
  .abs-list-table-expandable .abs-list-table-tbody .collapse-in-data .container-box-flex.abs-agreement-main-data-section {
    flex-direction: row;

    .hamburger {
      width: auto;
    }
  }
  .abs-list-table.abs-list-table-expandable .abs-list-table-tbody .dropdown-menu {
    position: absolute;
  }
  .abs-list-table .abs-list-row .col .info .hint {
    display: block;
  }
  .abs-list-table .abs-list-row .col .info .abs-list-table_block {
    display: none;
  }
  .abs-list-table .abs-label {
    word-break: break-all;
  }
  .load-more {
    max-width: 100%;
    margin-top: 15px;
  }

  .abs-list-table .col .values-sequence {
    text-align: left;
  }
}

.fw-list-table_block_truncate {
  max-width: 300px;
  word-break: break-word;
  word-wrap: break-word;
  text-align: justify;
}

.fw-import-form .abs-list-table .abs-list-row .col:first-child, .fw-import-form .abs-list-table .abs-list-table_row .col:first-child {
  height: auto;
  min-height: 56px;

  @media (max-width: $mobile-mode-max) {
    .abs-boolean {
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.abs-list-table .abs-list-row .btn-type-d {
  color: $abs-list-dropdown-color;
}

@media (min-width: $desktop-mode-min) {
  .data-table-virtualized {
    display: block !important;
  }
}

.data-table-virtualized__cell {
  padding: 0 !important;
}

.data-table-virtualized__cell .abs-label::before {
  content: none !important;
}

.data-table-virtualized-invoice-amount {
  height: 100%;
  border-left: 1px solid #e3e3e3;

  .abs-list__price-bold {
    white-space: nowrap;
  }
}

.data-table-virtualized-invoice-date {
  margin-bottom: 0;
}

.data-table-virtualized-invoice__label {
  font-weight: bold !important;
}

.data-table-virtualized-header {
  background-color: #f5f5f5;
  border-bottom: 2px solid #ccc !important;;
}

.data-table-virtualized-header__cell {
  border-bottom: none !important;
  height: auto !important;
}

.data-table-virtualized-header__cell:not(:first-of-type) {
  border-left: 1px solid #ccc;
}

.unsent-invoice-accordion-grid .data-table-virtualized-header__cell,
.unsent-invoice-accordion-grid .data-table-virtualized-header__cell * {
  cursor: default !important;
}

.unsent-invoice-accordion-grid .data-table-virtualized-header__cell .data-table-virtualized-checkbox,
.unsent-invoice-accordion-grid .data-table-virtualized-header__cell .data-table-virtualized-checkbox * {
  cursor: pointer !important;
}

.data-table-virtualized-checkbox {
  width: 18px;
  margin: 0 auto;
}

.data-table-virtualized__cell .data-table-virtualized-checkbox {
  margin: 0 auto;
  padding-left: 0;
}

.data-table-virtualized-checkbox .absui-checkbox .absui-custom-control-label {
  &::after {
    color: #24708F !important;
    background: #E4F3F6;
    border: 1px solid #24708F;
    left: 0 !important;
  }
}

.data-table-virtualized-checkbox .absui-data-table__check-block {
  padding: 0 !important;
}

.data-table-virtualized-checkbox .absui-checkbox .absui-custom-control-input:not(:checked) + .absui-custom-control-label::after {
  background-color: #FFF !important;
  border-color: #BABABA !important;
}

.data-table-virtualized-checkbox .absui-checkbox .not-all-checked + .absui-custom-control-label {
  &::before {
    background-color: #FFF !important;
    border-color: #BABABA !important;
    width: 16px !important;
    height: 16px !important;
  }

  &::after {
    color: #666 !important;
    border: none !important;
    height: 14px !important;
    width: 14px !important;
    top: 1px !important;
    left: 1px !important;
  }
}


@media (max-width: 767px) {
  .abs-boolean {
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .data-table-virtualized-checkbox {
    z-index: 10;
    margin: 0 auto;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .data-table-virtualized-due-date {
    display: flex;

    .list {
      display: flex;
    }
  }

  .data-table-virtualized-issue-date {
    display: flex;
  }

  .data-table-virtualized-row {
    padding-bottom: 20px;
    border-bottom: 1px solid #d6d6d6 !important;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-top: 2px solid #cccccc;
  }

  .data-table-virtualized-row-special {
    background-color: #fffceb;
  }

  .data-table-virtualized__cell {
    padding-left: 20px;
    padding-right: 10px;
  }

  .data-table-virtualized__cell:not(:first-of-type) {
    margin-top: 16px;
  }

  .data-table-virtualized__cell-content {
    padding-left: 20px;
  }
  .data-table-virtualized__cell-content--invoice {
    padding-left: 58px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }
}

.data-table-virtualized__box .ReactVirtualized__Grid {
  outline: none;
}

.data-table-virtualized-invoice-amount--mobile .abs-list__price--a {
  width: auto !important;
}

.absui-data-table__label .col {
  display: block;
}

.data-table-virtualized__invoice-date {
  margin-bottom: 0;
}

.data-table-virtualized-no-data {
  margin: 0 auto;
  padding: 20px 0;
}