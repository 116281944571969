.tooltip {

  opacity: $tooltip-opacity;

  &.tooltip-hidden {
    display: none;
  }

  &.top {
    .tooltip-arrow {
      border-top-color: $tooltip-bg;
    }
  }

  &.bottom {
    .tooltip-arrow {
      border-bottom-color: $tooltip-bg;
    }
  }

  &.left {
    .tooltip-arrow {
      border-left-color: $tooltip-bg;
    }
  }

  &.right{
    .tooltip-arrow {
      border-right-color: $tooltip-bg;
    }
  }

  .tooltip-inner {
    background-color: $tooltip-bg;
    @include box-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
    padding: 6px 7px;
    @include radius(0);
  }
}
