.navbar-logo {
  background: url("../image/menu-logo.svg");
  background-size: 120px 40px;
  height: 40px;
  width: 120px;
}

.login-container {
  .login-box {
    @media (max-width: 890px) {
      background: url("../image/login-panel-bg-desktop.png") 0 0 no-repeat #FFFFFF;
    }

    @media (min-width: $desktop-mode-min) {
      background-image: url("../image/login-panel-bg-desktop.png");
    }

    @media (max-width: $mobile-mode-max) {
      background: url("../image/login-panel-bg-mobile.png") -56px 0px no-repeat #FFFFFF;
      height: 345px;

      .login-box-panel h1 {
        color: #FFFFFF;
      }
    }
  }

  @media (max-width: $mobile-mode-max) {
    .login-box-triangle {
      margin-left: 0;
    }
  }
}

a {
  &:hover, &:focus {
    color: $color-brand-primary;
  }
}

.abs-menu > .container-fluid > .navbar-header > .navbar-brand {
  padding: 0 15px;

  @media (max-width: $mobile-mode-max) {
    position: relative;
    z-index: 1;
  }

  &:hover {
    background: transparent;
  }
}

.abs-menu.navbar-default .navbar-nav > .dropdown > a:focus {
  background: $color-neutral-4;
  border-color: $color-brand-primary-utility-1;
}

.container-details-panel {
  border-top: 1px solid #E1F1F5;
}

.footer-content--right {
  color: $color-neutral-46;
}

.footer p {

  > a {
    color: $color-neutral-46;

    &:hover, &:active {
      color: $color-neutral-46;
      text-decoration: underline;
    }
  }

  &.helpdesk-name {
    color: $color-neutral-70;
  }
}

.btn-type-d {
  &:active, &.active {
    background: #E4F3F6;
    border-color: #E4F3F6;
  }
}

.abs-card-panel {
  @include box-shadow(none);
}

.abs-menu.abs-menu-type-a .nav > li.dropdown.open .dropdown-menu > div.actions-panel li .btn.btn--uppercase .btn-label {
  text-transform: none;
}

.fw-alerts__mark-all-as-read-btn {
  color: $color-brand-primary;
}

.login-box {
  @include radius(8px, 8px, 8px, 8px);

  .login-box-panel {
    label {
      font-weight: bold;
    }

    input:focus {
      background: $color-neutral-0;
      border-color: $color-neutral-0;
    }
  }
}

.login-box-triangle {
  svg {
    margin-top: 15px;
    margin-left: 25px;

    &.logo-right {
      margin: 35px 30px 0 30px;
    }
  }
}

.logo-right {
  margin: 35px 30px 0 30px;
}

.abs-menu .navbar-toggle {
  background: $color-brand-secondary !important;
}

@media (max-width: $mobile-mode-max) {
  .login-container {
    &:before {
      height: 345px;
      position: absolute;
      width: 100%;
      background: rgba(0, 0, 0, .1);
      z-index: 1;
    }

    .login-box {
      background: url("../image/login-panel-bg-mobile.png") -56px 0px no-repeat #FFFFFF;
      @include border-radius(0);
      height: 345px;

      &:before {
        background: rgba(0, 0, 0, .5);
      }

      .login-box-panel {
        z-index: 100;
        position: relative;
        float: none;
        margin: 0 auto;

        h1, label {
          color: #FFFFFF;
          font-weight: normal;
        }

        input:not([type='checkbox']), .k-textbox {
          height: 30px;
        }
      }

      .login-box__title-container .login-box__subtitle, .loggedin-box {
        color: #FFFFFF;
      }
    }

    .login-box-triangle {
      margin-left: 0;

      & + a, .login-box-triangle-abs-logo {
        display: none;
      }
    }

    .authentication-form {
      .fw-reset-password__link, .authentication-btn__cancel {
        color: #FFFFFF;
      }

      .btn {
        height: 30px;
      }
    }

    .loader-mask-style-b.login-box-panel-loader {
      &:after {
        border-right-color: #FFFFFF;
      }
    }
  }
}

.error-page-header-logo {
  margin-top: 20px;
}

.timeline-type-a .timeline-block.non-active > .timeline-desc .inner-circle {
  display: block;
  background: $timeline-nonactive-color;
}

.footer-container--vertical {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  width: 60%;
}

.footer-item--vertical {
  padding-left: 25px !important;
  line-height: 18px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-icon--vertical {
  top: 0 !important;
}

.footer-content--vertical {
  align-items: flex-start !important;
  margin: 16px 0;
}

html, body {
  background: #ffffff;
  color: #000000;
}

a {
  color: #000000;
}

.notifications {
  .absui-btn--b {
    background-color: #CC0000;
    border: 1px solid #CC0000;

    &:hover {
      background-color: #000;
      border: 1px solid #000;
    }
  }

  .absui-form-footer.on-scroll {
    background: #ebebeb;
    border-top: 2px solid #CC0000;
  }
}