$login-page-loader-color-primary: $color-neutral-0 !default;
$login-page-loader-color-secondary: rgba(255, 255, 255, 0.3) !default;

.authentication-app-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-page-abs-logo {
  display: inline-block;
  height: 43px;
  width: 137px;
}

.login-container {
  .login-box {
    background-image: url("../../../img/login-panel-bg.png");

    @media (max-width: $mobile-mode-max) {
      background-size: auto;
    }

    .login-box-panel {
      min-height: 239px;

      @media (max-width: $mobile-mode-max) {
        height: auto;
      }

      h1 {
        font-size: 18px;
      }
    }

    .login-language-select {
      float: right;
      top: -5px;
    }

    .login-language-select-list {
      min-width: 53px;
    }

    .login-box__title-container {
      padding-bottom: 30px;

      .login-box__title {
        margin-top: 0;
        padding-bottom: 0;
      }

      .login-box__subtitle {
        color: $abs-login-font-color;
        font-size: 13px;
        font-weight: 500;
        padding-top: 10px;
        margin: 0;
      }
    }

    &--loading {
      height: 319px;
    }

    .loggedin-box {
      color: $abs-login-font-color;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .loggedin-box__icon {
        font-size: 40px;
      }

      .loggedin-box__text {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        padding-top: 5px;
      }
    }
  }

  .message-box-container {
    height: 48px;

    .abs-alert {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      span .fw-i18n {
        padding: 0;
      }
    }
  }

  .loader-mask-style-b.login-box-panel-loader {
    .login-box-panel-content {
      visibility: hidden;

      .btn {
        @include transition(none);
      }
    }

    &:before {
      background: transparent;
      @include opacity(1);
    }

    &:after {
      width: 32px;
      height: 32px;
      border: 3px solid $login-page-loader-color-primary;
      border-bottom-color: $login-page-loader-color-secondary;
      border-left-color: $login-page-loader-color-secondary;
      border-top-color: $login-page-loader-color-secondary;
      animation: loading 1.0s infinite linear;
    }
  }

  .form-group {
    .loader-mask-style-b {

      &:after {
        width: 20px;
        height: 20px;
        border-width: 2px;
        margin: -10px 0 0 -20px;
      }
    }

  }

  .authentication-form {
    height: 176px;
    position: relative;

    .login-btn {
      right: 0;
    }

    .btn {
      position: absolute;
      height: 26px;
      line-height: 15px;
      padding: 3px 17px;
      font-size: 13px;
      margin-top: 15px;

      @media (max-width: $mobile-mode-max) {
        position: relative;
      }
    }

    .fw-reset-password__link {
      color: $abs-login-font-color;
    }

    .authentication-btn__cancel {
      left: 0;
      text-transform: none;
      background: none;
      border: none;
      color: $abs-login-font-color;
      line-height: 16px;
      font-weight: 500;
      font-size: 13px;
      padding: 3px;
      @include box-shadow(none);

      .abs-btn-icon_title {
        padding-left: 5px;
      }

      @media (max-width: $mobile-mode-max) {
        float: left;
      }
    }

    .authentication-btn__submit {
      right: 0;
      min-width: 114px;

      @media (max-width: $mobile-mode-max) {
        float: right;
      }
    }

    &.change-password-form {
      height: 205px;

      .form-group {
        position: relative;
      }

      .abs_visibility, .abs_visibility_off {
        position: absolute;
        top: 27px;
        right: 5px;
        font-size: 18px;
        cursor: pointer;
        color: $color-neutral-60;
      }

      .fw-change-password-input__new-password {
        .abs_visibility, .abs_visibility_off {
          top: 4px;

          @media (max-width: $mobile-mode-max) {
            right: 35px;
          }
        }
      }

      @media (max-width: $mobile-mode-max) {
        height: auto;
      }
    }
  }

  .r-ss-trigger {
    background: $color-neutral-0;
    border: none;
    @include border-radius(2px);
    @include font-style($roboto-font-family, 500, normal);
    margin: 0;
    height: 28px;
    padding: 0 8px;
  }

  .r-ss-dropdown {
    border: none;
    margin: 4px 0 0 0;
    width: 240px;

    .r-ss-options-wrap {
      max-height: 144px;

      .r-ss-dropdown-options .r-ss-dropdown-option {
        min-height: 26px;
        height: auto;
        margin-top: 2px;
        padding: 4px 8px;
      }
    }
  }
}

.fw-password-policy-tooltip {
  background: $color-neutral-80;
  color: $color-neutral-0;
  @include border-radius(3px);
  max-width: 350px;
  width: 350px;

  &.popover.top > .arrow {
    border-top-color: $color-neutral-80;

    &:after {
      border-top-color: $color-neutral-80;
    }
  }

  &.popover.bottom > .arrow {
    border-bottom-color: $color-neutral-80;

    @media (max-width: $mobile-mode-max) {
      right: 6px;
      left: auto;
    }

    &:after {
      border-bottom-color: $color-neutral-80;
    }
  }

  &.collapsing {
    position: static;

    &.popover.bottom > .arrow {
      @media (max-width: $mobile-mode-max) {
        right: 12px;
        left: auto;
        top: 26px;
      }
    }
  }

  @media (max-width: $mobile-mode-max) {
    position: relative;
    max-width: none;
    width: 100%;
  }

  &.fw-password-policy-tooltip--hidden.collapse:not(.in) {
    visibility: hidden;
    height: 0;
  }

  .fw-password-policy-list {
    list-style-type: none;
    padding: 0;

    .abs-icon {
      margin-right: 5px;
    }

    .fw-password-policy-list__item--valid {
      .abs-icon {
        color: $color-positive-dark;
      }

      .fw-password-policy-list__item-desc {
        color: $color-neutral-46;
      }
    }

    .fw-password-policy-list__item--invalid {
      .abs-icon {
        color: $color-negative-dark-3;
      }

      .fw-password-policy-list__item-desc {
        color: $color-neutral-0;
      }
    }
  }

  .fw-password-strength-level {

    &__item {
      height: 5px;
      width: 40px;
      @include border-radius(3px);
      display: inline-block;

      margin-left: 2px;
      border-radius: 0;

      &:last-child {
        border-radius: 0 2px 2px 0;
      }

      &:first-child {
        margin-left: 0;
        border-radius: 2px 0 0 2px;
      }

      &--off {
        background: $color-neutral-60;
      }
    }

    &--0 {
      .fw-password-strength-level__item--on {
        background: $color-negative-dark-2;
      }
    }

    &--1 {
      .fw-password-strength-level__item--on {
        background: $color-warning-dark-3;
      }
    }

    &--2 {
      .fw-password-strength-level__item--on {
        background: $color-warning-dark-1;
      }
    }

    &--3 {
      .fw-password-strength-level__item--on {
        background: $color-brand-primary-utility-1;
      }
    }

    &--4 {
      .fw-password-strength-level__item--on {
        background: $color-positive-dark;
      }
    }
  }
}

.login-box .login-box-panel .fw-change-password-input__new-password .k-textbox {
  @media (max-width: $mobile-mode-max) {
    flex-basis: 240px;
    margin-left: 30px;
  }
}

body .abs-container .fw-change-password-form {
  .container {
    margin: 0 auto;
    padding-bottom: 30px;
    padding-top: 30px;

    @media (min-width: $screen-sm-min) {
      width: 667px;
    }
    @media (min-width: $screen-md-min) {
      width: 667px;
    }
    @media (min-width: $screen-lg-min) {
      width: 667px;
    }

    &.fw-change-password-form__button-wrap {
      padding: 0;
    }

    &.validation-header {
      padding: 13px 0;
      margin-bottom: -3px;
    }
  }

  .fw-change-password-input {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;

    .abs_visibility, .abs_visibility_off {
      position: absolute;
      right: 10px;
      top: 4px;
      font-size: 16px;
      cursor: pointer;
    }

    @media (max-width: $mobile-mode-max) {
      &.col-sm-5 {
        margin-right: 30px;
        margin-left: 10px;

        .form-control {
          margin-right: 30px;
        }
      }

      &.fw-change-password-input__new-password {
        margin-right: 0;

        .abs_visibility, .abs_visibility_off {
          right: 40px;
        }
      }
    }
  }
}

.fw-change-password-input__new-password {
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: $mobile-mode-max) {
    width: auto;
    flex-wrap: wrap;
    position: relative;
    margin-left: -30px;
    margin-right: -30px;
  }

  .change-password-input-loader {
    flex-grow: 1;
  }

  .change-password-help-icon {
    color: $color-warning-dark-1;
    font-size: 16px;
    margin-left: 4px;
    position: absolute;
    right: -20px;
    top: 6px;

    @media (max-width: $mobile-mode-max) {
      right: 10px;
    }
  }

  &.col-sm-5 {
    .change-password-help-icon {
      @media (max-width: $mobile-mode-max) {
        right: 14px;
      }
    }
  }
}

.authentication-success-page {
  .login-box-panel {
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box-panel-content {
      text-align: center;

      .abs-icon {
        color: $abs-login-font-color;
        font-size: 40px;
      }

      .authentication-btn__submit {
        margin: 0;
        position: relative;
        float: none;
      }
    }
  }

  &.login-container {
    .authentication-form {
      height: auto;
    }
  }
}

.triangle-login-field{
  color:$sub-nav-bg;
  fill:currentColor;
  display:inline;
  @media (max-width: $grid-float-breakpoint-max) {
    margin-left:-38px;
  }
}

.logo-right{
  float:right;
  margin:32px 30px;
  @media (max-width: $grid-float-breakpoint-max - 300) {
    display:none;
  }
  #suffix {
    fill: currentColor;
    color: $abs-login-page-color-top;
  }

}