.bold{
  font-weight:bold;
}
.italic{
  font-style:italic;
}

.inherit-color a {
         color: inherit;
}

.abs-tree-popup{  
  width:340px;
}
    .comboTree{
      min-height:235px;
    } 
.k-animation-container > .k-popup.abs-tree-popup {
  // width:340px;
  background:$abs-k-filter-bg;
  border:2px solid $abs-login-page-border-top-color;  
  padding:0px;
  > div{



  .common-search-div{
    background: $window-title-bg;
    width: 100%;
    position: relative;
    height: 46px;
    border-bottom: 1px solid $window-title-border-color;
    > input{
      margin: 0px auto;
      width: 90%;
      position: absolute;
      left: 0px;
      right: 0px;
      top: .5em;
      padding-right:35px;
    }
    .k-icon{
      font: normal normal normal 14px/1 Lumen-Icon-Font;
      position: absolute;
      background-image: none;
      right: 19px;
      top: 9px;
      height: 1.6em;
      vertical-align: middle;
      line-height: 1.6em;
      padding-right: 11px;
      padding-left: 11px;
      border-radius: 2px;
      cursor:pointer
      /*right: 8%;
      top: 14px;*/
    }
    .k-icon.k-i-search {        
      background: $btn-type-b-bg;      
      &:hover{
        background:$btn-type-b-hover-bg;
      }
      &:before{
        content: "\e8b6";
        color:$abs-k-filter-search-icon;
      }
    } 
    .k-icon.k-i-cancel {      
      background: $btn-type-a-bg;
      &:hover{
        background:$btn-type-a-hover-bg;
      }
      &:before{
        content: "\e5cd";
        color:$abs-k-filter-close-icon;
      }
    } 


  }
  .filter-box{
    padding: 7px 17px;
    line-height: 20px;
    border-bottom: 1px solid $window-title-border-color;
      .k-checkbox-label{
        font-weight:normal;
        padding-left: 24px;
        padding-top: 1px;
        margin-bottom:2px;
        &:before{
          top:1px;
        }
      }    
  }
  .k-widget.k-treeview{
    max-height:310px;
    overflow-y:auto;
    padding: 10px 15px;
    white-space:normal;    

    &.search-mode{
      padding-left:0px;
      margin-left:-6px;
      .k-treeview-lines >.k-item{
        padding-left:16px;
      }
    }
    span.item-text{
      color:$abs-k-filter-text-color;
    }

    
    .k-item{ 
      padding:0 0 0 16px;


      .k-icon.k-i-expand,.k-icon.k-i-collapse{
        font: normal normal normal 14px/1 Lumen-Icon-Font;
        background-image: none;
        margin-top:6px;
        display: block;
        float: left;        
      }
      .k-top .image{
        float: left;
        margin: -1px 5px 0px 2px;
      }
      .k-icon.k-loading{
        display: block;
        float: left;
        margin-left: -17px;
        margin-top: 5px;
        background-image: url('../../../css/loader.gif');
        background-position: 0px 0px;
        background-size: 16px 16px;
        &:before,&:after{
          content:none !important;
        }
      }
      .k-icon.k-i-collapse , .k-icon.k-i-expand{
        margin-left:0px;
      }
      .k-icon.k-i-collapse:before {
        content: '\25BC';
        color: $abs-k-filter-collapse-ico;
        font-size: 10px;

      }
      .k-icon.k-i-expand:before {
        content: '\25B6';
        color: $abs-k-filter-collapse-ico;
        font-size: 10px;
        html.k-ie & { 
            font-size:inherit;
         }
      }
      >div:after{
        clear:both;
        content: "";
        display: table;

      }

      >div:hover > .k-in , >div > .k-in.k-state-selected{
        @include border-radius(2px);
        background:$abs-k-filter-field-hover-bg;
        cursor:pointer;
        .item-text{
          color:$abs-k-filter-field-hover-text;
        }
      }
      >div .k-icon:hover:before{        
        color:$abs-k-filter-tree-icon-hover;
      }
      >div .k-icon:hover + .k-in{        
        background:$abs-k-filter-bg;
        .item-text{
          color:$abs-k-filter-text-color;
         } 
      }
      .k-in{
        line-height: 1em;
        padding-left:1.3em;
        display:block;
        margin-left:0px;
        
        .highlight{
          background:$abs-k-filter-highlight-bg;
        }
        .image.undefined{
          float:left;
          margin-right:5px;
        }
      }
      .k-in.k-state-focused{
        @include box-shadow(none);
      }

      .abs-tree-item.icon{
        margin-left:-6px;
        
        .material-icons{
          font-size: 19px;
          vertical-align: bottom;
          line-height: 1em;
          height: .9em;
        }
        .item-text{
          font-weight:bold;
        }

      }
      .abs-tree-item.search-result{
        //margin-left:-26px;
        > .item-text {
            display: block;
            width: 100%;
        }
        > .item-path {
          color: $abs-k-filter-text-secondary-color;
          font-size: 11px;
        }
  
      }
    }
    .k-treeview-lines >.k-item{
      padding-left:2px;
    }
    
  }
  
  }
  .popup-footer{
    padding:8px 10px 8px 20px;
    text-align:right;
    background-color:$window-title-bg;
    border-top:1px solid $window-title-border-color;
      .k-button:nth-of-type(1){
        background:none;
        border:1px solid transparent;
        margin-left:-10px;
        padding:1px 7px;
        float:left;
        position:relative;
        color: $abs-link-color;
        &:hover  .icon-filter-clear:before , &:hover  .icon-filter-clear:after{
          color:$abs-k-filter-icon-hover;
        }  
        .icon-filter-clear:before{
         // content: "\f00d";
        }
        .icon-filter-clear:after {
        content: "x";        
        font-family: roboto, arial;
        font-weight: bold;
        font-size: 14px;
        position: absolute;
        top: 11px;
        right: 2px;
      }
      }
      .k-button{
        margin-left:10px;
      }
  }
}

.qs-dropdown-content{
  width:320px;
}
.k-animation-container > .k-popup.qs-dropdown-content{
  background: $abs-k-filter-bg;
  border:2px solid $abs-login-page-border-top-color;  
  padding: 0;
  // margin-left: 10px;
  //width:320px;
  >div {
    .qs-dropdown-content-header{
      font-size: 14px;
      font-weight: 700;
      padding: 10px 0 10px 15px;
      border-bottom: 1px solid $window-title-border-color;
      background: $window-title-bg;
     } 
    ul{
      list-style:none;
      padding:2px 2px 10px;
      margin:0px;
      li{
        @include border-radius(2px);
        min-height: 26px;
        padding: 1px 10px;
        span{
          @include flexbox();
          @include align-items(center);
          .k-ff &{          
            min-height:26px;
          }
        }
        label{                    
          padding-top: 1px;
          font-weight:normal;
          padding: 0px 0px 0px 5px;          
          margin: 0px;
          .k-ff &{
            padding-left:20px;
           
          }
        }
        input {
          margin:0px;
          flex-shrink:0;              
          height: 26px;
          line-height: 26px;
          .k-edge &{
          height:auto;
          }
        }
        &:hover{
          background:$abs-k-filter-qs-li-hover;
        }
      }
    }
  }
}


.sub-nav > .to-right > .k-widget.k-dropdown{
  margin:10px 0px 10px 10px;
  width:250px;
  //float:right;
}
.sub-nav > .to-right > .k-dropdown  > .k-dropdown-wrap  {
  
  height:44px;

  &.k-state-default{
    border-color:$sub-nav-dropdown-border;
  }
  &.k-state-default.k-state-hover,&.k-state-default.k-state-focused{
    border-color:$forms-elements-hover-color;
  }
  &.k-state-default.k-state-focused{
    >.k-select{

    }    
  }
  > .k-input{
    
    padding:0px;
  }
  > .dropdown-title{
    display: block;
    font-weight: 700;
    height: 1em;
    padding-left: 8px;
    padding-top: 5px;
  }
  > .k-input {
    //padding-left:10px;
    color:$forms-elements-hover-color;
    display:block;
    //height:1.4em;
    text-overflow: ellipsis;
    overflow: hidden;
    background:transparent;
    padding-top:4px;

  }
  >.k-select{
    border-width:0px;
    background:transparent;
  }
  >.k-select > .k-icon{
    margin-top: 10px;
    font-size: 20px;
    margin-right: 0px;    
    &:before{
      color:$forms-elements-hover-color;
    }
  }
}


.items-button{
  border-right:1px solid $items-button-border;
  margin-right:10px;  
  height: 2.2em;
  float:left;
  vertical-align:middle;
  text-align:left;
  &.reordered-items{
    padding-right:10px;
  }

  i{
    vertical-align: middle;
    color: $items-button-i-color;
    font-size: 29px;
    cursor:pointer;
    margin-left:-8px;
  }
  span:nth-of-type(1){
    vertical-align: middle;
    color:$items-button-span-color;
    font-size:14px;
  }
  .selected-items{
    @include border-radius(3px);
    background: $items-button-selected-bg;
    border: 1px solid $items-button-selected-border;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 3px;
    margin-left:10px;
    margin-right:10px;
    vertical-align: middle;
  }
}
.items-button  + .toolbar-content-left{
  width:auto !important;
  float:left;
}
.items-button.hidden  + .toolbar-content-left{
  width:calc(100% - 370px) !important;
}

//ukrywanie scrolla pionowego
.abs-grid.k-grid .k-grid-content{
  overflow-y:hidden;
}

.abs-grid.k-grid .k-grid-header th.k-header .k-link {
    margin-top: 13px;
    margin-bottom: 10px;    
    display: inline-block;
    float:left;
}
.abs-grid.k-grid .k-grid-header th.k-header .filter-counter{
    @include border-radius(2px);
    display: inline-block;
    line-height: 18px;
    margin-top: 11px;
    background: $abs-grid-filter-counter-bg;    
    padding: 2px 7px;
    color: $abs-grid-filter-counter-color;
    margin-left: 8px;
}


// borderek dla ie grid-checkbox-th
.abs-grid.k-grid .k-grid-header th.grid-checkbox-th {
  .k-ie &{
        border-right:1px solid $abs-grid-header-bg-color;    
  }
}  


// gradienty dla nagłowków grida
.abs-grid.k-grid .k-grid-header th.k-header{

  &:after{
      content: "";
    display: block;
    position: absolute;
    width: 45px;
    background: linear-gradient(to right,transparent,$abs-grid-header-bg-color,$abs-grid-header-bg-color);
    background: -webkit-linear-gradient($abs-grid-bg);
    height: 50px;
    bottom: 0;
    right: 0;
  }
  &:hover{
    &:after{
      background: linear-gradient(to right,transparent,$abs-grid-header-hover-bg-color,$abs-grid-header-hover-bg-color);
    }
  }
  &.k-state-active{
    &:after{
      background: linear-gradient(to right,transparent,$abs-grid-header-active-bg-color,$abs-grid-header-active-bg-color);
     } 
  }
      
}



// kolorowanie komórek
.abs-grid.k-grid .k-grid-content tr {

  &:hover{
    td .styleColumn {
      background:$forms-elements-mouseover-bg !important;
    }
  }

  td{
  position:relative;
  word-break: break-all;
  // white-space:initial;
  white-space:nowrap;
  &.main-column{
    white-space:initial;
    word-break: break-word;
  }
  &.multiline-column {
    
   .k-ie &{
    >.horizontal-container > .vertical-container{
      display: inline-block; 
      white-space: pre-line;
      > a{
          display:table;
       }
    }
    }

    .styleColumn {    
      padding: 100px .4em 100px 12px;
      margin: -100px -.4em -100px -12px;
      display:block;
      width:auto;
      top:initial;
      bottom:initial;
      left:initial;
      position:relative;

    }
  }
  .styleColumn {    
      padding: 100px .4em 100px 12px;
      margin: -100px -.4em -100px -12px;
      display:block;
      width:auto;
      top:initial;
      bottom:initial;
      left:initial;
      position:relative;
  }
} 
}



.abs-grid.k-grid{
  @include radius(0px);
  border-top-width:0px;
  margin-top:0px;
  -webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
  //margin-left:-1px;
  //margin-right:-1px;
  //margin:5px 0px 10px 0px;

  @media (max-width: $grid-float-breakpoint-max) {
    margin:0px -1px 5px -1px
  }  



  .k-header.k-grid-toolbar{
    @include border-radius(0px);
    margin-left:-1px;
    margin-right:-1px;
    border-top:0px;    
    padding-left:0px;
    margin-top:0px;
    padding-top:3px;
    padding-right:0px;
  }



}
.k-toolbar button.k-button {
    height: 26px;
    line-height: 100%;
}
.abs-grid-toolbar{
    @media (max-width: $grid-breakpoint-desktop-large - 1) {
      margin:0px 20px;
    } 
    -webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
  
    .k-header.k-grid-toolbar{
    @include border-radius(0px);
    margin-left:0px;
    margin-right:0px;
    border-top:0px;    
    padding-left:0px;
    margin-top:0px;
    padding-top:3px;
    padding-right:0px;
    .toolbar-content-right > .buttons-group li:nth-of-type(2){
      border-right:1px solid $abs-grid-buttons-group-separator-color;  
      margin-right:5px;
      padding-right:10px;
    }
  }
    
    .k-toolbar .range-button{
      position: relative;
      margin-right: 1.25em;
      &:after{
        border-right: 1px solid $abs-grid-range-button-separator-color;    
        height: 24px;
        content: '';
        width: 1px;    
        right: -.85em;
        position: absolute;
        top: 0em;
      }
    }
    .k-toolbar .range-label{
      padding-right:10px;
    }


    //todo
  .k-grid-toolbar .filter-panel{
    background:$abs-filter-background;
    border-top:1px solid $abs-filter-border;
    border-left:1px solid $abs-filter-border;
    border-right:1px solid $abs-filter-border;
    //width:calc(100% - 18px);
    //padding:0px 1px 8px 10px;
    padding:0 1px 0px 15px;
    display:table;
    width:100%;
    // .k-splitter &{
    //   width:calc(100% - 18px);
    // }
    .col-md-10, .col-md-2{
      padding:0px;
    }
    .col-md-2{
      position:absolute;
      right:0px;
      top:0px;
      height:100%;
    }
    .col-md-2 > button{
      background: $grid-filterable-bg;
      border-top: 0px;
      border-right: 0px;
      border-bottom:0px;
       border-left:1px solid $abs-filter-border;
        //line-height: 43px;
        float: right;
        padding: 0px 10px;
        height:100%;
          -webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
        > .abs-icon{
          color:$abs-filter-close-icon;
          font-size:16px;
        }
    }

    .additionalPanel{
      float:left;      
      background: $abs-filter-additional-filters-bg;
      // padding: 8px 15px 9px;
      padding:0px;
      margin-right: 15px;
      border-right: 1px solid $abs-filter-border;
      // border-bottom: 1px solid $abs-filter-border;
      margin-bottom:-1px;
      margin-left:-15px;
      > *{
        margin-top:8px;
        margin-bottom:9px;        
        &:last-child{
          margin-right:15px !important;
        }
        &:first-child{
          margin-left:15px;
        }  
      }

      .k-dropdown {
        width:auto;
        margin-right:8px;
        // &:last-child{
        //   margin-right:0px;
        // }
      }
      .k-dropdown > .k-dropdown-wrap {
        // height:24px;
        &.k-state-active.k-state-border-down{
          border-color:$forms-elements-active-border-color;
        }
        &.k-state-default.k-state-hover{
          > .dropdown-title{
            color:$forms-elements-hover-color !important;
          }   
        }
      }
      .k-dropdown > .k-dropdown-wrap > .dropdown-title{
        margin: 0 0 0 9px;
        display: inline-block;
        font-weight: 700;
        color: $abs-filter-text-color;
        float: left;
        line-height:24px;
        background:transparent;

      }
      .k-dropdown > .k-dropdown-wrap> .k-input{
        padding:0px;
        line-height:24px;
        background:transparent
      }
      .k-dropdown > .k-dropdown-wrap> .k-select{ 
        background:transparent;
        > .k-icon{
          margin-top:-2px;
        }
        > .k-icon .k-ie &{
         margin-top:-4px; 
        }
      }
    }

    .additionalPanel + div > .neon-filter:nth-of-type(1){
      //margin-left:2px;
    }
    .neon-filter{
      @include border-radius(3px);      
      
      background:$abs-filter-btn-bg;
      color: $abs-filter-text-color;
      margin:8px 2px 6px;
      padding:0px;
      line-height:24px;
      display:inline-block;
      &:nth-of-type(1){
        //margin-left:15px;
      }
      &.k-state-active{
        &:hover{
          .k-grid-filter{
            border-color:$abs-filter-active-hover-border;
          }  
          > .k-grid-filter > .filter-header{
            color:$abs-filter-text-color;
          }                
          .filter-dropdown > .fa-angle-down:before{
            color:$abs-filter-ico-color;
          }  
        } 
        &:focus{
          @include box-shadow(0px 0px 5px 0px $abs-filter-focus-shadow-color);   
        }          
      }

      &:hover{
        .k-grid-filter{
          border-color:$forms-elements-hover-color;
        }  
        > .k-grid-filter > .filter-header , .filter-dropdown > .fa-angle-down:before{
          // color:$forms-elements-hover-color;
        }        
      }
      &:focus{
        @include box-shadow(0px 0px 5px 0px $abs-form-input-focus-shadow-color);  
        outline:0; 
      }      
      .k-grid-filter{
        line-height: 24px;
        vertical-align: middle;
        border:1px solid $color-neutral-27;
        cursor:pointer;
        &:after{
          content: "";
          display: table;
          clear:both;
        }
        @include border-radius(3px);      
        

        &.k-state-active{

          @include box-shadow(none);
          background:$abs-filter-activated-bg;
           border-color:$abs-filter-k-grid-filter-border;
        }
        &.k-state-border-down{
          background:$forms-elements-pressed-bg; 
           border-color:$forms-elements-active-border-color;
        }
        &.k-state-border-down .filter-dropdown{
          .abs-icon{
            transform:rotate(180deg);
            transition-duration: 0.2s;
          }  
        }
      }
      /*&:focus{
        outline:0;
      }*/
      .filter-values {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        display: inline-block;
        float: left;
      }
      .filter-clear{
          margin: 0;
          padding: 0px 6px;
          border-left: 1px solid $abs-filter-k-grid-filter-border;
          float: right;
          color: $abs-filter-close-icon;
          font-size:16px;
          > .abs-icon{
            display: block;
            height: 24px;
            padding: 4px 0px 0px 0px;
          }
      }
      
      .filter-dropdown{
        padding: 0;
        margin: 0px 6px 0px 6px;
        display: inline-block;
        position:relative;
        float:left;
        font-size:16px;
        .abs-icon{
          display: block;
          height: 24px;
          line-height:24px;
          padding: 0;
          font-size:17px;
          transform:rotate(0deg);
          transition-duration: 0.2s;
        }
        .fa-angle-down:before {         
          position: absolute;
          top: 4px;
          font-size: 17px;
          color:$abs-filter-ico-color;
          transition-duration: 0.2s;
          transform:rotate(0);
          } 
        }  
      .filter-header{
        margin:0px 0px 0px 9px;
        display:inline-block;
        font-weight:bold;                          
        color:$abs-filter-text-color;
        float:left;
      }
    }


  }
}
  


.details-panel [role="listbox"]{   
    
    @media (max-width: $screen-lg-min) {
       float:left;
       width:100%;       
    } 
    .detail-title.title-primary
    {
      font-size:20px;
      color:$details-panel-color;
    }
    .detail-title{
      white-space: pre-line;    
      margin:0px;   
      padding:0px;

    }
    &.col-md-6{
      width:50%;
    }
    

    .icon-placeholder{
      @media (max-width: $grid-float-breakpoint-max){     
        margin-top:15px;
      } 
      position:absolute;
      top:0px;
      left:0px;
      line-height:68px;
      text-align:center;



      img{
        width: 70%;
        height: auto;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
        -ms-filter: brightness(0) invert(1);
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        margin: auto;
      }
    }

    .simple-view{
      padding-left:0px;
      margin-left:-15px;
      position:relative;
      float:left;
    }
    .simple-view .detail-section-container{      
      .detail-section:nth-of-type(1){
        min-height:auto;
        margin:25px 0px 25px 45px;
      }

      .detail-section {
        align-self:center;
        @media ( max-width: $grid-float-breakpoint-max){
          align-self:flex-start;
        }  
        margin:0px;
        .toolbar{
          margin:0px;
          min-height:auto;
        }
      }

      .icon-placeholder{
        width:35px;
        height:35px;
        line-height:35px;
        > .fa {
          font-size:1.4em;
        }
      }
    }

    .detail-section-container{
      
      @media ( min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min){
        //width:  calc(100% - 290px);
        
      }
      @media ( max-width: $grid-float-breakpoint-max){
        width:100% !important;
        clear:left;
      } 

      @media (max-width:   $grid-breakpoint-desktop-large) {
          margin-left:0px;
     
        }
    }

    
    .detail-section{      
      align-self:stretch;
      float:left;
      line-height:1.4em;
      > div{
        &:after{
          content:"";
          display:table;
          clear:both;
        }
      }
       > .icon-placeholder + div {
         width: 293px;
          @media ( min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min){
              width:206px;          
          }
      }
      &:nth-of-type(1)
      {
        float:left;
        // width:303px;
        margin:21px 0px 0px 87px;
        min-height:77px;
        
          // @media ( min-width: $grid-float-breakpoint-max) and (max-width: $screen-lg-min){
          //     width:213px;          
          // }
          @media ( max-width: $grid-float-breakpoint-max) {
            width:auto;
          }

        .detail-title{
          color:$details-panel-h2-color;
          margin:0px;
        }  
        
        .detail-title.title-primary
        { 
          font-size:20px;
          color:$details-panel-color;
        } 
      }
      
      @media  (max-width: $grid-float-breakpoint-max) {
        margin-top:10px;
        //min-width:48%;
        //max-width:48%;
      } 
      &:nth-of-type(odd){
        @media (max-width: $grid-float-breakpoint-max) {
            clear:left;
        } 
       }
      margin:23px 10px 10px 0px;
      color:$details-panel-color;
      //min-width:180px;
      float:left;
      //min-width:195px;
      //max-width:240px;
      padding-right:10px;
      width:auto;
      
      .detail-title{
        //font-size:13px;
        //font-weight:bold;       
        display:block;
        margin-bottom:2px;
      }
      .detail-label{
        padding-right:4px;
        //word-break:   break-all;
        //float:left;
        display:inline;
        
      }
      .detail-value{
        //float:left;
        display:inline;
        &:after{
          content:"";
          display:table;
          clear:both;
        }
      }
      a{
        word-break:   break-all;
      }
    }

    
  }


  //grid popover
  .row-context-menu-window{
    @include border-radius(0px);
    background:$abs-grid-context-menu-bg;
    border:1px solid $abs-grid-context-menu-border;
    padding:2px;
    min-width:110px;
    &.k-state-border-up{
      margin-top:2px;
    }
    &.k-state-border-down{
      margin-bottom:2px;
    }
    .n-row-context-menu-div{
      list-style:none;
      padding:0px;
      margin:0px;
      li .k-button{
        width: 100%;
        text-align: left;
        border: 0px;
        padding: 3px 10px;
        &:hover{
          background:$context-menu-hover-bg;
        }
      }
    }
  }





.k-tabstrip-wrapper .k-content .container-box{
  max-width:100%;
  background:transparent;
}

.k-listview .dl-horizontal{
  margin-bottom:10px;
  dt{
    white-space:pre-line;
  }
}


.k-widget.k-tabstrip > .k-content  .container-box .abs-grid-toolbar{
  margin:5px 10px 0px 10px; 
  border-bottom:0px;
  .k-header.k-grid-toolbar{
    background:$abs-grid-bg;
  }
  .toolbar-content{
    // margin-right:-17px;
    background:$abs-grid-bg;
  }
}
.k-widget.k-tabstrip > .k-content  .container-box .abs-grid.k-grid{
 border-top:1px solid $abs-grid-border-color;
  margin:-1px 10px 10px 10px;
  &.tile-view{
    
      // notes  
    tr td{
      white-space:initial;      
      word-break:break-all;
      // padding:10px 20px;
    }
  
  }
   &.list-tiles{
          tr td{
            // width:10%;  
            .right-actions{
              align-self:flex-start;
            }    
    }  
   }

  @media (max-width: $grid-float-breakpoint-max) {
    margin:0px -1px 5px -1px
  }  
  .k-header.k-grid-toolbar{    
    background:$abs-grid-bg;    
  }

}

.k-ff .abs-grid-toolbar .k-grid-toolbar .filter-panel .additionalPanel{
  padding:1px 0px 0px 0px;
  .k-dropdown{
    width:200px;
  }
}
.k-ff .abs-grid-toolbar .k-grid-toolbar .filter-panel .col-md-2 > button,
.k-ie .abs-grid-toolbar .k-grid-toolbar .filter-panel .col-md-2 > button{
  border-right:1px solid $abs-grid-border-color;
  border-top:1px solid $abs-grid-border-color;
}

//widok kafelkowy dla grida

.tile-view.abs-grid{
  border: 1px solid transparent;
  background: transparent;
    
&:not(.abs-grid-group){
  .k-grid-content table td.main-column{
    display:block;
    width:97%;
  }
}
  .k-ie &{
    .k-grid-content table tr:nth-last-of-type(1):after{
      content:'';
      display:table;
      clear:both;
      background:transparent;
      width:100%;
      height:1px;
    }
  }


   &.list-tiles{
      .k-ie &{
        .main-column  .horizontal-container{
          display:block;   
          white-space:pre-wrap;     
        }
        .main-column > .horizontal-container > .vertical-container{
          display:block;
          width:89%;
          float:left;
          white-space:pre-line;
        }
         .main-column > .horizontal-container .right-actions{
          float:right;
          width:10%;
          white-space:pre-line;
         }

      }
   }  


  //  .tile-view.abs-grid-group .k-grid-content table tr{
  // margin:.7% .3% 0 .3% !important;
  //   &:nth-of-type(3n+2) , &:nth-last-of-type(1){
  //     margin:.7% .3% 0 .3% !important;
  //   }
  //   &.k-grouping-row{
  //     width:100%;
  //   }
  //   &.k-grouping-row>td p{
  //     margin-left: -1.4em;
  //   }
  // }


   // &.grid-group{
   //    .k-grid-content table tbody tr{    
   //      margin:.7% .3% 0 .3% !important;
   //        &:nth-of-type(3n+2) , &:nth-last-of-type(1) , &:nth-of-type(3n+1){
   //          margin:.7% .3% 0 .3% !important;
   //        }
   //        &.k-grouping-row{
   //          width:100%;
   //        }
   //        &.k-grouping-row>td p{
   //          margin-left: -1.4em;
   //        }        
   //     } 
   // }

   .k-grid-header {
    display:none;
  
  }
  .k-grid-content{
    overflow:hidden;
    .k-ie &{
      overflow:auto;
    }
    table{
      display:block; 
      background: transparent;     
      colgroup{
        display:none;
      }
      tbody{
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(inherit);
        @include align-items(stretch);
        @include flex-wrap(wrap);        
        @include align-content(stretch);  
        @include border-radius(2px);      
        width: 100%;        
        border:0px;        
      }
      

      tr {
        display: block;
        width: 32.5%;                
        margin:1% 0 0 0;
        background: $abs-grid-bg;
        border: 1px solid $grid-border-color-elements;
        padding:8px 0px;
        position:relative;
        &:nth-of-type(3n+2){
          margin:1% auto 0 1%;
        }
        &:not(.k-alt):nth-last-of-type(1) , &.k-alt:nth-last-of-type(1){
          margin:1% 0 0 1%;
          // margin:1% auto 0;
        }
        &:not(.k-grouping-row):nth-of-type(3n+1){
          margin:1% 0 0 0 !important;
        }




        .k-ie & {
           &:not(.k-grouping-row){
             margin-top:1% !important;
            }
        }
        .k-ff &{
           &:not(.k-grouping-row){
             margin-top:10px !important;
            }
        }
          @media (max-width: $grid-float-breakpoint-max){             
            width: 98%;
            margin: 1% auto 0 auto;
          }  
          &.n-state-selected {
             background: $abs-grid-tr-selected-bg !important;
          }
        &.k-state-selected{
          
          background:transparent;
          &:hover{
            td{
              background:transparent;
            }  
          }
        }


        &:hover{            
          background:$forms-elements-mouseover-bg;
            td.grid-checkbox-td {    
              display:table-cell;
              visibility:visible;
              input{
                display:block;
                position: absolute;
                left: 19px;              
                top: 19px;
                z-index:2;
              }
          }
      } 

      }
      tr.k-grouping-row{
        width: 99.4%;
        margin: 0px;
        padding: 0px;
        background: transparent;    
        border-width: 0px 0px 1px 0px;
        margin-bottom: 8px;
        height: 24px;
        > td{
          width: 100%;
          display: block;
          background: transparent;
        }
        > td p{
          display: inline-block;
          vertical-align: middle;
          margin-left: -.9em;
          padding: 0 .6em 0 0;    
          margin-bottom: -14px;
          background: $window-title-bg;
          text-transform: uppercase;
          color: $abs-grid-grouping-row-title;
          cursor:pointer;
          a:focus{
            outline:0;
          }
          .k-tabstrip &{
            background:$abs-grid-bg;
          }
        }
      }
      tr td{
        border-width:0px;
        white-space:initial;
        position:initial;
        .k-ie &{
          position:static;
        }
        .horizontal-container >.vertical-container > .horizontal-container > .vertical-container > .horizontal-container{
          @include flex-wrap(wrap);
        }
        &.group-column{
          visibility:hidden !important;
          display:table-cell !important;
          padding:0px;
          font-size:1px;
        }
      }

      tr td .c100 > span{
        @include border-radius(100%);
        width: 68px;
        height: 68px;        
        background: $abs-grid-bg;      
        vertical-align: middle;
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto;
        left: 0;
        right: 0;
        line-height: 68px;
      }
      tr td .row-info i{
        color: $abs-link-color;
        margin-right: 15px;
        // margin-top: 26px;
        cursor: pointer;
      }

      tr td .image{
        width:82px;
        height:82px;
        overflow:hidden;
        margin-right:15px;
        position:relative;
            

            &:empty{
              border-width: 1px !important;
              border: 1px solid $abs-grid-empty-img-border;  
              &:before {
                  content: "\e24d";
                  font-family: "Material icons";
                  color: $abs-grid-empty-img-icon;
                  font-size: 64px;
                  bottom: 32px;
                  position: absolute;
                  left: 8px;
              }
            }


      @each $image-type in png,jpg,bmp,gif,svg{
        &.#{$image-type}
        {
          &:empty:before{            
               content: "\e251";            
          }
        }
      }
      @each $video-type in asf,avi,dv,dvd,flv,m2ts2,mkv,mov,mp4,mpg,ogg,smv,svcd,ts,wmv,vcd,flv,mp4{
        &.#{$video-type}
        {
          &:empty:before{            
               content: "\e02c";                        
          }
        }
      }
      



      }
      td.main-column{
        width: 100%;
        border-right: 0px;
        // word-break: break-all;
      }
      td.k-group-cell{
        display:none;
      }
      
      td.grid-checkbox-td {    
                 
        padding: 0!important;
        width: 20px;
        visibility:hidden;
        > input{
          display:none;
        }
      }

    }
  }

}
.tile-view.abs-grid-group .k-grid-content table tr{
  margin:1% .3% 0 .3% !important;
    &:nth-of-type(3n+2) , &:nth-last-of-type(1) ,&:not(.k-grouping-row):nth-of-type(3n+1){
      margin:1% .3% 0 .3% !important;

      .k-ff &{
          margin:10px .3% 0 .3% !important;        
      }
    }
    &.k-grouping-row{
      width:100%;
      margin-top:.1% !important;
      margin-bottom: 5px !important;
      .k-ff &{
          marign-bottom:10px !important;
      }
      .k-ie &{
        width:calc(100% - 21px);
      }
    }
    &.k-grouping-row>td p{
      margin-left: -1.4em;
    }
  }



.menu-items 
{
    margin-top:2px;
}
 


.btn-menu{
  position: relative;
  padding: 1px 19px 1px 6px !important;
  line-height:inherit !important;
  &.k-state-border-down{
    &:before{
      transform: rotate(180deg);
      transition: transform .2s ease-in;
    }  
  }
  &:before{
    content: "\e313";
    font: normal normal normal 14px/1 Lumen-Icon-Font;
    position: absolute;
    right: 0px;
    top: 4px;
    transform: rotate(0deg);
    transition: transform .2s ease-out;
    font-size:16px;
  }
}






.xs-col-width{min-width:35px;}
.md-col-width{min-width:90px;}
.sm-col-width{min-width:160px;}
.lg-col-width{min-width:190px;}


.abs-tree{    
  padding:0px 20px 0px 0px;  
  
  .common-search-div{    
    width: 100%;
    position: relative;
    height: 52px;
    border-bottom: 1px solid $window-title-border-color;
  
    > input{
      margin: 0px auto;
      width: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      top: .5em;
      padding-right:35px;
    }
    .k-icon{
      font: normal normal normal 14px/1 Lumen-Icon-Font;
      position: absolute;
      background-image: none;
      right: 2px;
      top: 9px;
      height: 1.6em;
      vertical-align: middle;
      line-height: 1.6em;
      padding-right: 11px;
      padding-left: 11px;
      border-radius: 2px;
      cursor:pointer
      /*right: 8%;
      top: 14px;*/
    }
    .k-icon.k-i-search {        
      background: $btn-type-b-bg;      
      &:hover{
        background:$btn-type-b-hover-bg;
      }
      &:before{
        content: "\e8b6";
        color:$abs-k-filter-search-icon;
      }
    } 
    .k-icon.k-i-cancel {      
      background: $btn-type-a-bg;
      &:hover{
        background:$btn-type-a-hover-bg;
      }
      &:before{
        content: "\e5cd";
        color:$abs-k-filter-close-icon;
      }
    } 
  }

  
  .filter-box{
    padding: 7px 17px;
    line-height: 20px;
    border-bottom: 1px solid $window-title-border-color;
      .k-checkbox-label{
        font-weight:normal;
        padding-left: 24px;
        padding-top: 1px;
      }    
  }

  .k-widget.k-treeview{
    max-height:226px;
    overflow-y:auto;
    padding: 10px 0px;
    white-space:normal;
    
    .k-ie .k-splitter &{
      padding:0px 0px 10px 0px;
    }
      .k-treeview-lines >.k-item   .item-path{
            
          display:none;
      }
    &.search-mode{
      padding-left:0px;
      margin-left:-6px;
      .k-treeview-lines >.k-item .search-result > a > .item-path{
            color:$abs-k-filter-text-secondary-color;
            font-size: 11px;
          display:block;
      }
      .k-treeview-lines >.k-item .search-result > a > .item-text{
        color:$abs-k-filter-text-color;
      }
    }
    span.item-text{
      color:$abs-k-filter-text-color;
    }

    
    .k-item{ 
      padding:0 0 0 16px;


      .k-icon.k-i-expand,.k-icon.k-i-collapse{
        font: normal normal normal 14px/1 Lumen-Icon-Font;
        background-image: none;
        margin-top:5px;
        margin-left:0px;
        display: block;
        float: left;        
      }
      .k-top .image{
        float: left;
        margin: -1px 5px 0px 2px;
      }
      .k-icon.k-loading{
        display: block;
        float: left;
        margin-left: -17px;
        margin-top: 5px;
        background-image: url('../../../css/loader.gif');
        background-position: 0px 0px;
        background-size: 16px 16px;
        &:before,&:after{
          content:none !important;
        }
      }
      .k-icon.k-i-collapse:before {
        content: '\25BC';
        color: $abs-k-filter-collapse-ico;
        font-size: 10px;

      }
      .k-icon.k-i-expand:before {
        content: '\25B6';
        color: $abs-k-filter-collapse-ico;
        font-size: 10px;
        html.k-ie & { 
            // font-size:inherit;
         }
      }
      >div:after{
        clear:both;
        content: "";
        display: table;

      }

      >div:hover > .k-in , >div > .k-in.k-state-selected{
        @include border-radius(2px);
        background:$abs-k-filter-field-hover-bg;
        cursor:pointer;
        .item-text{
          color:$abs-k-filter-field-hover-text;
        }
      }
      >div .k-icon:hover:before{        
        color:$abs-k-filter-tree-icon-hover;
      }
      >div .k-icon:hover + .k-in{        
         background:transparent;
        .item-text{
          color:$abs-k-filter-text-color;
         } 
      }
      .k-in{
        line-height: 1em;
        padding-left:1.3em;
        display:block;
        margin-left:0px;
        
        .highlight{
          background:$abs-k-filter-highlight-bg;
        }

      }
      .k-in.k-state-focused{
        @include box-shadow(none);
      }
      .abs-tree-item a{
        display:block;
        text-decoration:none;
         > span {color:$abs-link-color;}
      }
      .abs-tree-item > div > .item-text{
        font-weight:bold;
      }
      .abs-tree-item.icon{
        margin-left:-6px;
        
        .material-icons{
          font-size: 19px;
          vertical-align: bottom;
          line-height: 1em;
          height: .9em;
        }
        .item-text{
          font-weight:bold;
        }

      }
      .abs-tree-item.search-result{
        //margin-left:-26px;
        > .item-text {
            display: block;
            width: 100%;
        }
        > .item-path {
          color: $abs-k-filter-text-secondary-color;
          font-size: 11px;
        }
  
      }
    }
    .k-treeview-lines >.k-item{
      padding-left:2px;
    }
    
  }

}
  
// kendo drag and drop
.k-hint{


  &.hint-tile-view{
    tr td.main-column{
      width:auto;        
    }
    tr td.k-group-cell{
      padding:0px;
      width:1px;
    }
    tr td.grid-checkbox-td{
      padding:0px;
      width:1px;
      input{
        visibility:hidden;
      }
    }


          tr td .image{
        width:82px;
        height:82px;
        overflow:hidden;
        margin-right:15px;
        position:relative;
            

            &:empty{
              border-width: 1px !important;
              border: 1px solid $abs-grid-empty-img-border;  
              &:before {
                  content: "\e24d";
                  font-family: "Material icons";
                  color: $abs-grid-empty-img-icon;
                  font-size: 64px;
                  bottom: 32px;
                  position: absolute;
                  left: 8px;
              }
            }


      @each $image-type in png,jpg,bmp,gif,svg{
        &.#{$image-type}
        {
          &:empty:before{            
               content: "\e251";            
          }
        }
      }
      @each $video-type in asf,avi,dv,dvd,flv,m2ts2,mkv,mov,mp4,mpg,ogg,smv,svcd,ts,wmv,vcd,flv,mp4{
        &.#{$video-type}
        {
          &:empty:before{            
               content: "\e02c";                        
          }
        }
      }
      



      }
  }

   max-width:1600px !important;  
   border:1px solid $abs-k-hint-border-color !important;   
   tr{
    position:relative;
    &.k-state-selected{
      background:$abs-k-hing-bg;
    }
    &:before{
      font-family:"Material icons"; 
      color:$abs-k-hint-border-color;
      content:"\e5d4" ;    
      font-size:18px;  
      padding:0px;
      margin:-9px 0px 0px -3px;    
      position:absolute;
      top:50%;
    }

    td .image {
      width: 82px;
      height: 82px;
      overflow: hidden;
      margin-right: 15px;
      position: relative;
    }   
    td .image:empty {
      border-width: 1px!important;
      border: 1px solid #f7f7f7;
    }
    td.k-group-cell{
      padding:0px;
    }
    // td.grid-checkbox-td {      
    //   padding: 0!important;
    //   width: 20px;
    //   visibility: hidden;
    // }

    td, td.k-state-selected{
      background:$abs-k-hing-bg !important;
      color:$abs-k-hint-text-color;
      box-shadow:none !important;      
      border-width: 1px 0 0;
      line-height: 1.3em;
      padding-top: 8px;
      padding-bottom: 8px;
      border-color: $abs-k-hint-border-color;
      padding-left: 12px;
      text-overflow: ellipsis;
      padding-right: .4em;
    }
    td.main-before-column {  
      width:72px;
    }
    td.grid-checkbox-td{
       width:23px;
    }
    td.main-column{
        width:333px;
        border-right:1px solid $grid-border-color-elements;
    }
      .dropdown{
      @include border-radius(0px);
      //position:absolute;
      display:inline-block;
      padding:0px;      
      &+.row-info{
        margin-top:26px;
      }
      >button{
        @include border-radius(2px);
        background:none;
        border:0px;
        padding:0px 19px 0px 7px;
        &:hover{
            background:$abs-k-hint-actions-bg;  
        }
        &.k-state-border-down{
          background:$abs-k-hint-actions-hover;

          i, i:after{
            color:$abs-k-hing-bg;
          }
        }
      }

    }
    .dropdown.open{
      >button{
          background:$abs-k-hint-actions-bg;
        }
    }
    .dropdown.open > .dropdown-menu{
      @include box-shadow(none);
      @include border-radius(0px);
      border-color:$abs-k-hint-dropdown-menu-border;
      > li{
        margin:0px 2px;
      }
      .k-button{
        border:0px;
        text-align:left;
        padding:4px 10px;
        width:100%;
        margin:0px;
        &:hover{
          background:$abs-k-hint-dropdown-menu-btn-hover;
        }
      }
    }
    .dropdown .btn-transparent i{
      color:$abs-link-color;
      text-shadow:none;
      font-size:1.8em;
      position:relative;
      top:1px;
      &:after{
        content: "\e313";
        color: $abs-link-color;    
        position: absolute;        
        bottom: 2px;
        right: -14px;
        font: normal normal normal 15px/1 Lumen-Icon-Font;
      }
    }


  }
  .abs-disabled {
    input, &.dropdown{
      opacity:.3;
    }  
  }
}

.abs-grid-reorder{
  .toolbar-content-right , .neon-filter{
      opacity:.3;
      pointer-events: none;
      cursor: default!important;
  }
  tr.placeholder{    
    display:block;
    width:1600px;
    border-width: 1px;
    border-style: dashed;
    border-color: $abs-grid-drag-placeholder-border;
  }
    .abs-disabled {
    input , &.dropdown{
      opacity:.3;
    }  

  }
}



.abs-grid-reorder.abs-grid .k-grid-content table tr:not(.k-grouping-row) td:nth-of-type(1):before{
  font-family:"Material icons"; 
  color:$abs-grid-drag-icon;
  content:"\e5d4" ;    
  font-size:18px;  
  padding:0px;
  // margin:-9px 0px 0px -15px;  
  position:absolute;
  // top:50%;
  left:-3px;
  // .k-ie &{
  //   margin-top:4px;
  // }
}  

.k-widget[aria-disabled="true"] {
    opacity: .5;
}

.k-animation-container, .k-animation-container *, .k-animation-container :after, .k-block .k-header, .k-list-container, .k-widget, .k-widget *, .k-widget :before{
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.k-nodata {    
    text-transform: none;
    font-size: 13px;
    font-weight: normal;
    color: $color-neutral-60;
}