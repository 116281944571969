.k-input, input.k-textbox, textarea.k-textbox, input.k-textbox:hover, textarea.k-textbox:hover, .k-textbox>input, .k-multiselect-wrap,
.k-dropdown .k-input, .k-dropdown .k-state-focused .k-input, .k-menu .k-popup{
  color:$abs-form-input-color;
}

//checkbox/radio

.k-checkbox-label:before, .k-checkbox-label:after, .k-radio-label:before, .k-radio-label:after {
    box-sizing: border-box;
}

//keno buttons
.k-button:not(.k-overflow-anchor){
     transition: all 0.2s ease-in-out 0s;
   -webkit-transition: all 0.2s ease-in-out 0s;
}

// kendo buttons
.btn-type-c.k-button:focus:not(.k-state-disabled):not([disabled]){  
  @include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.1));  
}
.btn-type-c.k-button:focus:active:not(.k-state-disabled):not([disabled]){
 @include box-shadow(none);  
 background-color:$btn-white-pressed-bg;
}

.btn-type-a.k-button:focus:not(.k-state-disabled):not([disabled]){  
  @include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5));   
}
.btn-type-a.k-button:focus:active:not(.k-state-disabled):not([disabled]){
 @include box-shadow(none);  
  background:$btn-type-a-states-bg;
}

.btn-type-b.k-button:focus:not(.k-state-disabled):not([disabled]){

  @include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5));
}
.btn-type-b.k-button:focus:active:not(.k-state-disabled):not([disabled]){
 @include box-shadow(none);  
  @include borders($btn-type-b-hover-border-color, 2px, 2px ,2px, 2px);
  background:$btn-type-b-hover-bg;
   
}
.k-button:focus:active:not(.k-state-disabled):not([disabled]){
  @include box-shadow(none);  
}
.k-toolbar:not(.k-spreadsheet-toolbar) .k-button-group .k-button:focus, .k-toolbar:not(.k-spreadsheet-toolbar) .k-button-group .k-group-start  {
    border-left-color: transparent;
}

.k-button:focus:not(.k-state-disabled):not([disabled]){
  @include box-shadow(none);  

}
.k-column-menu, .k-column-menu .k-item, .k-overflow-container .k-overflow-group{
  border-color:$k-column-border-color;
}

.k-split-container .k-button{

}
.k-overflow-container .k-overflow-button{
  border-width:1px 0px 1px 0px;
}
.k-overflow-container .k-button:active,
.k-overflow-container .k-state-active, .k-overflow-container .k-button.k-state-active:hover{
    color:$forms-elements-hover-color;
    border-color: $forms-elements-pressed-bg;
    background:$forms-elements-pressed-bg;  
}
.k-overflow-container .k-button:hover , .k-overflow-container .k-button.k-state-hover {  
    @include border-radius(0px);
    border-width:1px 0px 1px 0px;
    border-color:$forms-combo-hover-border-color;
    color:$abs-font-color;
    background:$forms-elements-pressed-bg; 
}
.k-overflow-container .k-button:focus{
  @include box-shadow(none);  
} 



.k-overflow-container{
  
  .k-button-icontext{    
    border-bottom:1px solid $forms-combo-hover-border-color;
    padding: 10px 15px 9px;
    display:block;    
    color:$context-menu-link-color;
    &:nth-last-of-type(0){
      border-bottom:0px;
    }
    .abs-icon{
      margin-right: 5px;
      margin-left: -7px;
    }
    &:hover{
      border-color:$forms-combo-hover-border-color;
      color:$abs-font-color;
      background:$forms-elements-mouseover-bg;
    }
  }
  .k-item{
    padding:0px;
    margin:0px;
  }
  .k-overflow-group+.k-overflow-group , .k-overflow-group {
    @include box-shadow(none);
    margin:0px;
    padding:0px;
    border-width:0px;
  }
  .k-overflow-button {
    border-width: 0px 0px 1px 0px;
    border-color: $forms-combo-hover-border-color;
    padding:6px 15px 6px;
    &:active, &:active:hover{
      border-width: 0px 0px 1px 0px;
      border-color: $forms-combo-hover-border-color;
    }
  }
  .k-button:hover, .k-overflow-container .k-button.k-state-hover{
    border-width: 0px 0px 1px 0px;
    background:$forms-elements-mouseover-bg;
  }    
}

.k-split-container{
  .k-button{   
    border-width: 0px 0px 1px 0px;
    border-color: $forms-combo-hover-border-color;
    padding: 6px 15px 6px;
    &:hover{
      border-width: 0px 0px 1px 0px;
      background: $forms-elements-mouseover-bg;
    }
    &:active, &:active:hover{
      border-width: 0px 0px 1px 0px;
      color:$forms-elements-hover-color;
      border-color: $forms-combo-hover-border-color;      
      background:$forms-elements-pressed-bg;  
    }
  }  
}

//k-toolbar
.k-toolbar{
  @include box-shadow(none);
  background:transparent;
  border-color:transparent;
   .k-button:active:focus, .k-button:focus{
    background:$btn-type-a-active-bg !important;
   }
  >.k-button{
    // float:right;
  }
  .left-side{
    float:left;
  }
  .k-separator{
    display:none !important;
    &.vertical-separator{
      display: inline-block !important;
      color: $details-panel-inner-border;
      margin-left: .5em;
      margin-right: .5em;
      background: transparent;
    }
  }

  .k-button-group > .k-button:not(:first-child){
    border-left-color:$btn-type-a-active-border-color;
  }
 .k-overflow-anchor{
    @include border-radius(2px);
    border-width:1px;
    width:auto;
    height:auto;
    line-height:1.72em;
    padding:1px 10px;
    font-size:13px;

    > .k-icon.k-i-arrow-s, .k-i-arrow-60-down{
      @extend %lumen-icon-font-placeholder;
      background-image:none;
      margin-top: -4px;
      margin-left: -3px;
      &:before{
        content: "\e313";
        color: $k-toolbar-anchor-icon-color;
        font-size: 1.3em;
      }

    }
  }
  .k-button.k-primary{
    background:$abs-link-color;
    border-color:$abs-link-color;
  }
  .k-button , .k-overflow-anchor{
    @include button-variant($btn-type-a-color, $btn-type-a-bg, $btn-type-a-border-color);    
    @include box-shadow(none);
    @include border-radius(2px);
    height: 26px;
    line-height: 1.65em;
      &.k-state-active{
      @include box-shadow(none);
    }
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      background:$btn-white-hover-bg;      
      outline: 0;      
    }
  }  
  &:hover{
    @include borders($btn-type-a-hover-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow(none);
    background:$btn-type-a-hover-bg;
    color: $btn-type-a-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-a-focus-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5)); 
    background:$btn-type-a-focus-bg;
     color: $btn-type-a-color;
    text-decoration: none;    
  }
  &:active,
  &.active {
    @include borders($btn-type-a-active-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;    
    background:$btn-type-a-active-bg;
    color: $btn-type-a-color;    

    &:hover{
          @include borders($btn-type-a-states-border-color, 1px, 1px ,1px, 1px);
          @include box-shadow(none); 
          background:$btn-type-a-states-bg;
          color: $btn-type-a-states-color;    
    }    
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
  }
  }
}


.k-toolbar .k-button.btn-type-a.k-state-active:hover{
  color: $btn-type-a-states-color;   
}
.k-tool.k-group-start, .k-toolbar .k-split-button .k-button, .k-toolbar .k-button-group .k-group-start{
  border-radius:2px 0px 0px 2px;
}
.k-tool.k-group-end, .k-toolbar .k-button-group .k-group-end, .k-toolbar .k-split-button .k-split-button-arrow{
 border-radius:0px 2px 2px 0px; 
}

.abs-col-1 .k-dropdown,.abs-col-1 .k-combobox , .abs-col-1 .k-multiselect{  
  @media (min-width: $screen-sm-min) {
    width:16.8em;
  }
  @media (min-width: $screen-md-min) {
  width: 16.25em;    
  }
  @media (min-width: $screen-lg-min) {
      width:20.1em;
  }

  
}



html .k-upload-empty {
  @include border-radius(0px);
  border-width: 1px;
  background: $k-upload-empty-bg;


}

.k-upload{
  @include border-radius(0px);  
}
.k-dropzone em{
  display:inline-block;
  font-style:normal;
  visibility:visible;
  color:$k-upload-dropzone-color;
}
.k-upload-files{
  margin-bottom:0px;
}



// grid _grid.scss



@mixin grid-header-font(){
    font-weight:bold;
    font-size:13px;
    color:$abs-grid-header-font-color;    
}


@mixin grid-borders-desktop(){
  /*@media (min-width: $grid-breakpoint-desktop-large) {
    border-left:1px solid $grid-border-color;
    border-right:1px solid $grid-border-color;
  } */ 

    border-left:1px solid $grid-border-color;
    border-right:1px solid $grid-border-color;
  
}

.k-content .abs-grid.k-grid{
  margin:0px;
}



.abs-grid.k-grid.grid-type-a{
  margin-top:0px;
}

.abs-grid.k-grid{
  border-radius:0px;
  font-size:$abs-grid-font-size; 
  box-shadow:none;
  -webkit-box-shadow:none;
  margin:15px 20px;
  border-width:0px 1px 1px 1px;

  &.abs-grid-checkbox-first tr td{      
    @media (max-width: $grid-float-breakpoint-max) {
      padding-left:64px;
      &.grid-checkbox-td{
        position: absolute;
        padding-left: 20px;
        padding-top: 0px;
        z-index: 1;
      }
    }
  }


  @media (min-width: $grid-breakpoint-desktop-large) {
      margin-left:0px;
      margin-right:0px;
  }  
  //height:400px;
  @media (max-width: $grid-float-breakpoint-max) {
    border-width:0px;
    margin-left:0px;
    margin-right:0px;
  }     
  

  .mobile-title{
    display:none;
    @media (max-width: $grid-float-breakpoint-max) {
      display:inline-block;
      color:$abs-grid-mobile-title;
      padding-right:5px;
      float:left;
    }  
  }
  .k-grid-norecords{
    width: auto;
    height: 200px;
    text-align: center;    
    line-height: 200px;
    font-size: 15px;
    color: $abs-grid-norecords-color;    
  }

  .k-grid-content  {
    overflow-y:auto;
    @media (max-width: $grid-float-breakpoint-max) {
      overflow:hidden;
      // border-top:4px solid $abs-grid-border-top-mobile;
    }  
  }
  
  .k-alt{
    background:$abs-grid-alt-tr-bg;        
    @media (max-width: $grid-float-breakpoint-max) {
      background:$abs-grid-alt-tr-bg;          
    }
  }

  table{   
    background:$abs-grid-bg;
    @media (max-width: $grid-float-breakpoint-max) {
      background:$abs-container-box-bg;
      //width:auto !important;
    }  
  }
  
  table th.k-state-focused, table td.k-state-focused {
  webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  }

  tbody{
    @include grid-borders-desktop();    
  }

  .k-grid-header , colgroup{ 
    @media (max-width: $grid-float-breakpoint-max) {
      display:none;
    }     
  }
  
  .k-grid-header{   
    padding-right:0px !important;
    border-bottom-width:2px;
    &:hover{
      background:$abs-grid-header-bg-color;
    }
  }

  .k-grid-header .k-grid-header-wrap{
    position: relative;
    width: 100%;
    overflow: hidden;
    border-style: solid;    
    zoom: 1;
    border-color:$abs-grid-header-bg-color;
    background:$abs-grid-header-bg-color;
  }
  
  .k-grid-header th.k-header[aria-sort] .k-link > .k-icon {
    position: absolute;
    left: 9px;
  }
  .k-grid-header th.k-header{
    // border-collapse: collapse;
    //white-space:pre-line;  
     padding-left:12px;
     position:relative;
    overflow: hidden;
    vertical-align:middle;
    background:$abs-grid-header-bg-color;


    >.k-icon.k-i-arrow-n, >.k-icon.k-i-arrow-s{      
      background-image:none;
      color:$abs-grid-header-icon-color;
      font-family: 'Material Icons';
      font-feature-settings: 'liga';
      height:.8em;
      line-height: .8em;
      font-size: 1em;
    }
    >.k-icon.k-i-arrow-n:before{
      content:"arrow_upward";
    }
    >.k-icon.k-i-arrow-s:before{
      content:"arrow_downward";
    }

    > .sort-index{
      height:.8em;
      color:$abs-grid-header-sort-index-color;
    }


    &.grid-checkbox-th{
      .grid-checkbox-header{
        cursor:pointer;
      }
      &:hover:after,  &:after, &.k-state-active:after{
        content:none;
      }
    }  

    &.grid-checkbox-th + th{
        border-color: transparent;
    }

    &.main-before-column{
      width:$main-before-column-width;
        border-color: transparent;
    }
    &.main-column{
      width:$main-column-width;
    }
    .k-link{
      margin-top:13px;
      margin-bottom:13px;
      padding-top:0px;
      padding-bottom:0px;
      text-overflow: initial;
      text-decoration:none;
      outline:0;

      &:focus{
        text-decoration:none;
      }

      .k-icon{
        @extend %lumen-icon-font-placeholder;
        background-image:none;
        color:$abs-grid-header-sort-ico-color;
        padding-right:1px;
         &.k-i-arrow-n:before, &.k-i-sort-asc-sm:before{//up
          content: "\e5d8";
          font-size:1em;
          margin-top:1px;
         }
        &.k-i-arrow-s:before ,  &.k-i-sort-desc-sm:before{//down
          content: "\e5db";
          font-size:1em;
          margin-top:1px;
         }
      }
    }


    @include grid-header-font();
    //border-color:$abs-grid-header-bg-color;
    padding-top:0px;
    padding-bottom:0px;
    //border-width:1px 0 0px 0px;    
    //border-width:1px;
     //   border-width: 0 0 1px 1px;
    padding-right:.4em;
    border-color:$grid-border-color;
    &:hover{
      background:$grid-filterable-bg-on-hover;
      .k-link{
        background:$grid-filterable-bg-on-hover;
      }
    }

      
    @media (max-width: $grid-float-breakpoint-max) {
      display:none;
    }
    
    &.k-state-active{
      background:$grid-filterable-bg;
      border-bottom:1px solid $grid-filterable-border-color;
      //border-bottom:1px solid $grid-filterable-bg;
      //border-right:1px solid $grid-border-color;
      border-left:1px solid $grid-border-color;      

      .k-grid-filter >.k-icon.k-filter:hover{
        background:$grid-filterable-bg;
      }

    } 

    &.k-state-active:hover{
      .k-link{
        background:$grid-filterable-bg;
        text-decoration:none;
      } 
      .k-grid-filter{
        background:$grid-filterable-bg;
      } 
      .k-grid-filter.k-state-border-down{
        background:$grid-filterable-bg;
      }

    }

    &:not(:last-child):hover{}
    &:hover{

    }

    .k-grid-filter{ 
        
      >.k-icon.k-i-filter{
      @extend %lumen-icon-font-placeholder;    
      background-image:none;      
        &:before{
          content: "\e313";
          color:$grid-filterable-icon-color;
          font-size:20px;
          left:-4px;
        }
        &:hover{
          background:$grid-filterable-icon-bg-hover;
        }
      }
      &.k-state-active{
        @include box-shadow(none);
        border-color:$grid-filterable-bg;
        background:$grid-filterable-bg;
      }
      &:focus{
        outline:0;      
      }
      &:hover{
        opacity:1; 
        background:$grid-filterable-icon-bg-hover; 
      }

    }

  }

  .k-grid-header th.k-header.k-filterable{    
    @media (max-width: $grid-float-breakpoint-max) {
      display:none;
      width:94%;
    }
    .k-link{
      padding-left:0px;
      margin-left:0px;
      outline:0;
      text-decoration:none;
      //white-space:pre-line;


      &:after{
        content: "";
        display: block;
        position: absolute;
        width: 25px;
        background: linear-gradient(to right, transparent, $abs-grid-header-bg-color, $abs-grid-header-bg-color);
        background: -webkit-linear-gradient($abs-grid-header-bg-color);
        height: 50px;
        bottom: 0px;
        right: 0px;
      }

    }
      
    .k-grid-filter{
      position:absolute;
      bottom:0px;
      right:0px;
      visibility:hidden; 
      border-left: 1px solid $grid-border-color;
      border-top: 1px solid $grid-border-color;
      padding:14px 5px;
      margin:0px;      
      >.k-icon{
        margin-top:0px;
        margin-bottom:2px;
        top:2px;
        // font-size:0px;
      }
    }
    .k-grid-filter.k-state-border-down{
      visibility: visible;
      background:$grid-filterable-icon-bg-hover;
    }

    &:hover{
      .k-grid-filter{
        visibility: visible;        
        border-left: 1px solid $grid-border-color;
        border-top: 1px solid $grid-border-color;
        padding:14px 5px;
        margin:0px;
      }    
      .k-link:after{
        background: linear-gradient(to right, transparent, $grid-filterable-bg-on-hover, $grid-filterable-bg-on-hover);
        background: -webkit-linear-gradient( $grid-filterable-bg-on-hover);
        width:60px;
      }
    }
    &.k-state-active{
      .k-link:after{
        background: linear-gradient(to right, transparent, $grid-filterable-bg, $grid-filterable-bg);        
        background: -webkit-linear-gradient($grid-filterable-bg);    
      }
      
    }
    
  }    
  
  tr{



    @media (max-width: $grid-float-breakpoint-max) {
      @include border-radius(0px);
      //@include borders($grid-border-color, 1px, 1px, 1px, 1px );      
      border-top:2px solid $grid-border-color;
      /*border-bottom:1px solid $grid-border-color;
      border-left:1px solid $grid-border-color;
      border-right:1px solid $grid-border-color;*/
      border-left:0px;
      border-right:0px;
      border-bottom:0px;
      position:relative;
      display:block;
      width: auto;
      margin-bottom:0px;
      background-color:$abs-grid-tr-bg;
      padding:7px 0px;
    }  


    // &:first-child{
    //   // @media (max-width: $grid-float-breakpoint-max) {    
    //   //   border-top:0px;
    //   // }  
    // } 
    &:nth-of-type(1){         
      td{
        border-width:0px;
      //  border-color:$grid-border-color;        
       }   
    }
    &:hover{
      background:$forms-elements-mouseover-bg;
      @media (max-width: $grid-float-breakpoint-max) {
        background:$abs-grid-tr-bg;
     }

     /*td:after{
        @include element-gradient($forms-elements-mouseover-bg , 20px , 100%);
     }*/

    }
    &.k-state-selected{
      @include box-shadow(none);
      background:$grid-selectable-row-bg;
      color:inherit;
      /*td:after{
        @include element-gradient($grid-selectable-row-bg, 20px , 100%);
      }*/
      &:hover{
        background:$grid-selectable-row-bg;
        td{
          background:$grid-selectable-row-bg;
        }
        /*td:after{
          @include element-gradient(darken($grid-selectable-row-bg, 1), 20px , 100%);
        }*/
      }  
    }
  }
  .pre-line-style{
        white-space:pre-line;
        word-break:break-all;
        text-overflow: ellipsis;
      }    
  tr td{    
    white-space:nowrap;  
    border-width:1px 0 0px 0px;
    border-right-width:0px;
    line-height:1.3em;
    padding-top:8px;
    padding-bottom:8px;
    text-overflow:initial;
    border-color:$grid-border-color-elements;
    padding-left:12px;  
    padding-right:0px;
    color:$abs-font-color;
    //position:relative;
    text-overflow: ellipsis;
    padding-right:.4em;
    .hint{
      display:block !important;
      color: $icon-pin;
      @media (max-width: $grid-float-breakpoint-max) {
        display:none !important;
      }  
    }
    &.grid-checkbox-td:before{
      content:none !important;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      display:block;
      width:auto;
      border-width:0px;
      line-height:1.3em;    
      padding-left:20px;
      padding-top:2px;
      padding-bottom:2px;
      white-space:pre-line !important;
      word-break:break-word;



      &:before{
        display: inline-block;
        font-weight: bold;        
        padding: 0 4px 0 0;
        font-size: 100%;
        content: attr(data-label) ": ";
      }

    }








    &.main-before-column{
       width:$main-before-column-width + 7px;
      //width:$main-before-column-width + 2px;
      & + .main-column{
         width:$main-column-width + 5px;
        //width:$main-column-width;
      } 
    }
    
    &.main-column{
      width:$main-column-width + 6px;
      // border-right:1px solid $grid-border-color-elements;
      &+td{
        border-left:1px solid $grid-border-color-elements;
      }
      >a:nth-of-type(1){
        font-size:14px;
        font-weight:700;
      }
      .multiline-column{
        @include flexbox;
        @include align-items(center);
         > .col-md-10  div:nth-of-type(1) a , > div:nth-of-type(1) a{
          font-size:14px;
          font-weight:700;
        }
      }

      /*.col-md-10  div:nth-of-type(1) a{
        font-size:14px;
        font-weight:700;
      }*/
    }
    
    .grid-checkbox{
      height:auto;
      cursor:pointer;
    }

    //centralka komorka wielowierszowa - przeniesc

    .abs-cell-title{
      font-weight:bold;
      font-size:14px;
    }
    // .cell-info{
    //   color:#6e6e6e;
    // }
    // .cell-info2{
    //   color:#6b6899;
    // }  

    .col-md-10{
      padding:0px;
      white-space:pre-line;
      margin-right:5px;
      word-break: break-all;

    }
    .dropdown{
      @include border-radius(0px);
      //position:absolute;
      display:inline-block;
      padding:0px;      
      &+.row-info{
        margin-top:26px;
      }
      >button{
        @include border-radius(2px);
        background:none;
        border:0px;
        padding:0px 19px 0px 7px;
        &:hover{
            background:$abs-grid-dropdown-menu-hover-bg;  
        }
        &.k-state-border-down{
          background:$abs-grid-dropdown-menu-open-bg;

          i, i:after{
            color:$abs-grid-dropdown-menu-open-icon-color;
          }
        }
      }

    }
    .dropdown.open{
      >button{
          background:$abs-grid-dropdown-menu-hover-bg;
        }
    }
    .dropdown.open > .dropdown-menu{
      @include box-shadow(none);
      @include border-radius(0px);
      border-color:$context-menu-border;
      > li{
        margin:0px 2px;
      }
      .k-button{
        border:0px;
        text-align:left;
        padding:4px 10px;
        width:100%;
        margin:0px;
        &:hover{
          background:$context-menu-hover-bg;
        }
      }
    }
    .dropdown .btn-transparent i{
      color:$abs-link-color;
      text-shadow:none;
      font-size:1.8em;
      position:relative;
      top:1px;
      &:after{
        content: "\e313";
        color: $abs-link-color;    
        position: absolute;        
        bottom: 2px;
        right: -14px;
        font: normal normal normal 15px/1 Lumen-Icon-Font;
      }
    }

    .status-client{
      font-size: 1.3em;
      margin-bottom: 0px;
      color: $status-client;
    }

    span:empty , .empty , &:empty{
        @media (max-width: $grid-float-breakpoint-max) {
            display:none !important;
        } 
    }

    >.boolean{
      color:$boolean-color;
      font-size:10px;
    }
    >.boolean > .active , >.boolean > .inactive{
      @extend %lumen-icon-font-placeholder;    
      font-size:1.15em;
    }


    >.boolean > .inactive:before{
      content: "\f00d";
      color:$abs-grid-icon-red;      
    }

    >.boolean > .active:before{
      content: "\f00c";
      color:$abs-grid-icon-green;
    }
    >.boolean > .fa-usd{
        color:$boolean-ico-color;
       
        font-size: 12px;
    }
    .fa-square, .fa-type{
      margin-right:3px;
    }
    .fa-type{
      color:$abs-link-color;
      font-size:1.1em;
    }
    &:nth-of-type(1){      
      
      // @media (max-width: $grid-float-breakpoint-max) {
      //   text-align:left;        
      //   padding-top:10px;
      //   padding-bottom:0px;
      //   font-size:13px;
      // }
    }
    > a {
      color:$abs-grid-link-color;
    }


    &:last-child{
      @media (max-width: $grid-float-breakpoint-max) {
        // padding-bottom:10px;
        //border-bottom:1px solid $abs-grid-bg-border-color;
      }
    } 

    > .fa-times{
      color:$abs-grid-icon-red;
    }

    > .fa-check{
      color:$abs-grid-icon-green;
    }
    .fa-green{color:$abs-grid-icon-green;}
    .fa-orange{color:$abs-grid-icon-orange;}
    .fa-blue{color:$abs-grid-icon-blue;}
    .fa-red{color:$abs-grid-icon-red;}
    .fa-yellow{color:$abs-grid-icon-yellow;}
  }

}


.k-widget.k-tabstrip > .k-content .abs-grid.k-grid{ 
  margin:5px 10px 10px 10px;
  @media (max-width: $grid-float-breakpoint-max) {
    margin:0px -1px 5px -1px
  }  
  .k-header.k-grid-toolbar{    
    background:$abs-grid-toolbar-bg-in-tab;    
  }

}


.k-grid-toolbar{
  @include border-radius(0px);
  border-radius:0px !important;
  margin-bottom:0px;
  padding-bottom:0px;
  background:$abs-body-desktop-bg;
  margin-left:-1px;
  margin-right:-1px;

  &:empty{
    padding:0px !important;
  }
  .toolbar-content{
    .toolbar-content-left{
      width:auto; 
      float:left;  
      width:calc(100% - 370px);
      margin-left:-3px;
        @media (max-width: $grid-float-breakpoint-max) {
          margin-left:10px;
        }  
    }
    .toolbar-content-right{
      float:right;          
    }
    &:after{
      content:"";
      display:table;
      clear:both;
    }
  }
  .toolbar{
    line-height:1em;
  }
  .toolbar .k-button-group , .toolbar > .k-button{
    float:none;        
    &:nth-of-type(1){
      margin-left:0px;
    }
  }
  .toolbar > .k-button.k-overflow-anchor{
    float:right;    
  }
  .buttons-group{
    margin-top:0px;
  }

}




//grid with sparklines,gauges

.abs-grid.k-grid{
  &.abs-chart-grid{
    border-color:transparent;
    .k-header.k-grid-toolbar{
      border-color:transparent;
      display:none;
    }
     .k-grid-header .k-grid-header-wrap{
        border-color:$abs-grid-bg;
        background:$abs-grid-bg;
     }
    .k-grid-header th.k-header{
      border-color:transparent;
      background:$abs-grid-bg;
      padding:6px 12px 2px 0px; 
      vertical-align: bottom;
      .k-link{
        margin-top:0px;
        margin-bottom:0px;
      }
      &:empty{
        display:none;
      }
      &:after{
        content:none;
      }
      &:hover{
        background:$abs-grid-bg;
        cursor:pointer;
        .k-link{
          background:transparent;
        }
      }
    }  
    tr td{
      border-color:transparent;
      padding-top:4px;
      padding-bottom:4px;
      padding-left:0px;
      padding-right:12px;
      white-space:pre-line;
    }
  
    .k-grid-footer{
      /*border-color:transparent;*/
      background:$abs-grid-bg;
      border-bottom: 1px solid transparent;
      border-top: 1px solid $abs-grid-footer-border-color;
      .k-grid-footer-wrap{
        border-color:transparent;
      }
      tr td{
        background:$abs-grid-bg;
      }
    }
  }
}








//default pager
 .k-grid-pager{
    @include border-radius(0px);  
    //@include grid-borders-desktop();
    @include box-shadow(none);
    background:$grid-footer-bg;
    border-bottom:1px solid $abs-grid-bg-border-color;
    min-height:28px;
    border-bottom:0px;
    -webkit-box-shadow:none;
    padding:7px 5px 6px 5px;
     color:$color-neutral-100;

    &.k-floatwrap{

      .load-more-button{        
        border:0px;
        padding:0px 0px;
        color:$abs-link-color;
        font-weight:bold;
        width:100%;
        background:transparent;        
        width:100%;
        height:100%;
        &:focus{
          @include box-shadow(none);
          outline:0;
        }
      }
    }

    @media (max-width: $grid-float-breakpoint) {
        border-color:$grid-border-color;
        border-width:1px;
        border-bottom:1px solid $grid-border-color;
     }   
    .k-pager-wrap{

      @media (max-width: $grid-float-breakpoint-max) {
        @include border-radius(3px);  
        width:97.8%;
        border-color:$grid-border-color;
        border-width:1px;
      }  
    }  

     .k-pager-refresh{
      border-radius:4px;
      background:$grid-footer-bg;
      .k-i-reload:before {
        content: "\e5d5";
        font-family: Lumen-Icon-Font;
        color:$btn-type-d-color;
        font-size: 18px;
        margin-top: -2px;
      }
      &:hover{
        background:$btn-type-d-hover-bg;
        
      }
     }
     .k-pager-info{
      padding:0 .4em;
     }  

    .k-dropdown-wrap .k-input{
      padding:0px;
      border-color: $forms-element-border-color;
    }
    .k-dropdown-wrap .k-icon.k-i-arrow-s {
      margin-top: -5px;
    }
    .k-pager-sizes .k-dropdown-wrap .k-input {
      line-height: 26px;
      height: 26px;
    }
    .k-pager-numbers{
      
      @media only screen and (max-width: 1024px){
        display:none !important;
      }

      .k-link{
        @include border-radius(0px);
        background:$grid-footer-bg;
        border-color:$grid-footer-bg;
        border-left:0px;
        border-right:0px;
        border-bottom:0px;
        color:$abs-grid-pager-numbers-link-color;
        
        padding:6px 0px 0px 0px;
        margin:-7px 3px 0px 3px;
        
        border-top:3px solid $grid-footer-bg;
        &:hover{
          color:$abs-grid-pager-numbers-link-hover;
        }
        &:active, &:focus{
          @include box-shadow(none);
          outline:none;
        }
      }
      .k-state-selected{
       @include border-radius(0px);
       @include box-shadow(none);   
        background:$grid-footer-bg;
        border-color:$grid-footer-bg;
        border-style:inherit;
        color:$abs-grid-pager-numbers-link-selected; 
        border-top:3px solid $sub-nav-bg;
        border-left:0px;
        border-right:0px;
        border-bottom:0px;
        padding:6px 0px 0px 0px;
        margin:-7px 3px 0px 3px;
        
      }
    }

    .k-link{   
      &.k-state-disabled{
        opacity:.3;
        &:hover{
          background-color:$sub-nav-bg;
        }
      }
    }

    .k-link.k-pager-nav{
      @include border-radius(3px);
      background:transparent;
      border:1px solid transparent;
      border-color:transparent;    
      text-decoration:none;
      position:relative;
      line-height:2em;
      // height:1.9em;
      height:30px;
      margin:0px 5px;
        &:hover{
          background:$btn-type-d-hover-bg;
          border-color:$btn-type-d-hover-bg;
        }
        &.k-state-disabled{
         pointer-events: none; 
         .k-icon:after, .k-icon:before{
            color:$abs-grid-pager-numbers-link-color;
          }
        }
        &.k-state-disabled:hover{
          background:$btn-type-d-bg;      
          border-color:$btn-type-d-bg;    

        }  
        &:focus{
          @include borders($btn-type-d-focus-border-color, 1px, 1px ,1px, 1px);
          @include box-shadow( none); 
          background:$btn-type-d-focus-bg;
          border-color:$btn-type-d-focus-bg;
          outline:none;
        }
    }  
    .k-link.k-pager-nav{
      .k-icon{
        position:relative;
        background-image:none;
        text-indent: initial;

      }
      .k-i-arrow-e:before{
          display:none;
       } 
      .k-i-arrow-e:after{
        @extend %lumen-icon-font-placeholder;
        content: "\e318";
        position:absolute;
        right:0px;
        top:0px;
        color:$btn-type-d-color;
        font-size:17.5px;
      }
      .k-i-arrow-w:before{
        @extend %lumen-icon-font-placeholder;
        content: "\e317";
        position:absolute;
        left:-2px;
        top:1px;
        color:$btn-type-d-color;
        font-size:17.5px; 
      }
      .k-i-seek-e:after{
        @extend %lumen-icon-font-placeholder;
        content: "\f101";
        position:absolute;
        right:2px;
        top:-2px;
        color:$btn-type-d-color;
        font-size:18px;
      } 
      .k-i-seek-e:before{
        content:'';
      } 
      .k-i-seek-w:after{
        @extend %lumen-icon-font-placeholder;
        content: "\f100";
        position:absolute;
        right:4px;
        top:-2px;
        color:$btn-type-d-color;
        font-size:18px;
      }  
      .k-i-seek-w:before{
        content:'';
      } 


      &.k-pager-first+.k-link{
        @include radius(4px);
        border-right:0px;
      }
    }

  
  .k-pager-sizes{      
      margin-top:0px;          
      padding:0px 8px 0px 0px;  
      margin-left:10px;
      @media (max-width: $grid-float-breakpoint-max){  
          font-size:1px;
          color:$grid-footer-bg;
      }  
      > .k-widget.k-dropdown{
        margin-top:1px;
        float:right;
        @media (max-width: $grid-float-breakpoint-max){  
            font-size:13px;
            color:$abs-form-input-color;
        }
          
      }    
      .k-dropdown-wrap{
        padding-right:1.7em;

        .k-select > .k-icon{
          top:0px;
        }
      }
      @media (max-width: $grid-float-breakpoint-max){  
        float:right;
      }  
    }
   .k-pager-info, .k-pager-refresh{
      @media (max-width: $grid-float-breakpoint-max){   
        display:none;
      }  
   } 
} 



// pager wersja nr 1


// .pager-lite .k-grid-pager{
//         .k-pager-sizes{
//       float:right;      
//       //border-right:1px solid $grid-border-color;
//       margin-top:2px;          
//       padding:0px 8px 0px 0px;  
//       //margin-right:15px; 
//       @media (max-width: $grid-float-breakpoint-max){   
//         //display:none;
//       }       
//       > .k-widget.k-dropdown{
//         float:right;
//       }
//     } 
//     .k-pager-nav.k-pager-last{
//       @include radius(0px,2px,2px,0px);
//     }
//     .k-pager-nav.k-pager-first{
//       @include radius(2px,0px,0px,2px);
//     }
// }


// .pager-lite .k-grid-pager > .k-link.k-pager-nav {    
//     @include border-radius(2px);
//     border-right: 0;
//     //float:right;
//     background:$btn-type-a-bg;
//     border:1px solid $btn-type-a-bg;
//     border-color:$btn-type-a-bg;
//     min-width:30px;
//     @media (max-width: $grid-float-breakpoint-max){   
//       min-width:35px;
//     }  
//     text-decoration:none;
//     position:relative;
//     line-height:2em;
//     height:1.9em;
    



//     >.k-icon{
//       //display:none;
//       font-size:13px;
//       color:$abs-grid-pager-nav-icon;
//       background-image:none;
//       width:auto;
//       height:100%;
//       line-height:24px;
//       padding:0px 15px;
//       text-indent: 0px;
//       @media (max-width: $grid-float-breakpoint-max){  
//         font-size:0px;
//         padding:0px 4px;
//       }  
//       &.k-i-arrow-e{
//         padding:0px 15px 0px 5px;
//         @media (max-width: $grid-float-breakpoint-max){  
//           padding:0px 5px 0px 5px;
//         }  
//       }
//       &.k-i-arrow-w{
//         padding:0px 5px 0px 15px;
//         @media (max-width: $grid-float-breakpoint-max){  
//           padding:0px 5px 0px 5px;
//         }  
//       }
//     }

//     .k-i-arrow-e:after{
//         @extend %lumen-icon-font-placeholder;
//         content: "\e318";
//         position:absolute;
//         right:6px;
//         top:3px;
//         color:$abs-grid-pager-nav-icon;
//         font-size:17.5px;
//       }
//       .k-i-arrow-w:before{
//         @extend %lumen-icon-font-placeholder;
//         content: "\e317";
//         position:absolute;
//         left:0px;
//         top:-1px;
//         color:$abs-grid-pager-nav-icon;
//         font-size:17.5px; 
//       }

//     > .button-text{
//       color:$abs-grid-pager-nav-button-text-color;
//       text-align:center;
//     }
//     &.k-pager-last, &.k-pager-first{
//       display:none;
//     }
//     &:nth-of-type(2){
//       //prev button
//       //margin-right:130px;      
//         > .button-text{
//           margin-left:10px;
//         }
//         > .button-text:before{
//           @extend %lumen-icon-font-placeholder;
//           content: "\e317";
//           position:absolute;
//           left:11px;
//           top:3px;
//           color:$abs-grid-pager-nav-button-text-color;
//           font-size:17px;
//       }

//     }
//     &:nth-of-type(3){
//       // next button
//       //margin-right:-235px;
//               > .button-text{
//           margin-right:5px;
//         }
//       > .button-text:after{
//         @extend %lumen-icon-font-placeholder;
//         content: "\e318";
//         position:absolute;
//         right:11px;
//         top:3px;
//         color:$abs-grid-pager-nav-button-text-color;
//         font-size:17px;
//       }
//     }

//     &:hover{
//       background:$btn-type-a-hover-bg;
//       border-color:$btn-type-a-border-color;
//     }
// }




.k-filter-menu.k-popup{
  background:$abs-k-filter-bg;
  border:2px solid $abs-login-page-border-top-color;
  //padding:10px 15px 10px 15px;
  padding:0px;

}


.k-calendar-container.k-state-border-up, .k-list-container.k-state-border-up, .k-autocomplete.k-state-border-up, .k-multiselect.k-state-border-up, .k-dropdown-wrap.k-state-border-up, .k-picker-wrap.k-state-border-up, .k-numeric-wrap.k-state-border-up, .k-window-content, .k-filter-menu{
  border-radius:0px 0px 2px 2px;
}
.k-block, .k-button, .k-textbox, .k-drag-clue, .k-touch-scrollbar, .k-window, .k-window-titleless .k-window-content, .k-window-action, .k-inline-block, .k-grid .k-filter-options, .k-grouping-header .k-group-indicator, .k-autocomplete, .k-multiselect, .k-combobox, .k-dropdown, .k-dropdown-wrap, .k-datepicker, .k-timepicker, .k-colorpicker, .k-datetimepicker, .k-notification, .k-numerictextbox, .k-picker-wrap, .k-numeric-wrap, .k-colorpicker, .k-list-container, .k-calendar-container, .k-calendar td, .k-calendar .k-link, .k-treeview .k-in, .k-editor-inline, .k-tooltip, .k-tile, .k-slider-track, .k-slider-selection, .k-upload{
  border-radius:2px;
}



.k-widget.k-window{  
    padding-top:40px !important;
  @include box-shadow( 0px 2px 5px 0px rgba(0,0,0,0.50));
  @include border-radius(4px);
  border-color:$sub-nav-bg;
  border-width:2px;
    .k-window-titlebar.k-header{
      @include border-radius(4px);
      //border-color:$window-title-border-color;
      background-color:$window-title-bg;
      border-width:1px;
      //border-bottom:1px solid $window-title-border-color;
      border:1px solid $window-title-bg;
      margin-top:-40px !important;
      padding:10px 0px;
      width: calc(100% - 2px);
      height:auto;

    }
    .k-window-title{
      color:$window-title-color;
      font-size:14px;
      font-weight:bold;
      padding-left:10px;
    }
    @media (max-width: $grid-float-breakpoint-max){
      left:5% !important;
      right:5% !important;
      width:90% !important;
      min-width: 90% !important;
    }


    .window-footer{
      border-top: 1px solid $window-title-border-color;
      background-color:$window-title-bg;      
      padding:0px;
      border-width:1px 0px 0px 0px;
      clear:both;
      text-align:right;
      min-width:200px;
    }
}


//ihd temporary confirmDialog buttons style
//  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
#confirmAction{
  margin:10px 10px 10px 0px;
  //float:right;
   @include button-variant($btn-type-b-color, $btn-type-b-bg, $btn-type-b-border-color);
   @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
  @include box-shadow(none);
  @include border-radius(2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      background:$btn-type-b-hover-bg;      
      outline: 0;      
    }
  }  
  &:hover{
    @include borders($btn-type-b-hover-bg, 1px, 1px ,1px, 1px);
    @include box-shadow(none);
    background:$btn-type-b-hover-bg;
    color: $btn-type-b-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-b-focus-border-color, 1px, 1px ,1px, 1px);
        @include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5)); 
        //@include box-shadow(none); 
    background:$btn-type-b-focus-bg;
    color: $btn-type-b-color;        
  }
  &:active,
  &.active {
    @include borders($btn-type-b-active-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;    
    background:$btn-type-b-active-bg;
    color: $btn-type-b-color;    

    &:hover{
          @include borders($btn-type-b-hover-border-color, 1px, 1px ,1px, 1px);
          @include box-shadow(none); 
          background:$btn-type-b-hover-bg;
          color: $btn-type-b-color;    
    }    
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
  }
}
#cancelAction{
    margin:10px 10px 10px 0px;
  float:right;    
      @include button-variant($btn-type-a-color, $btn-type-a-bg, $btn-type-a-border-color);
      @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
  @include box-shadow(none);
  @include border-radius(2px);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include box-shadow(none);
      //@include tab-focus;
      background:$btn-white-hover-bg;      
      outline: 0;      
    }
  }  
  &:hover{
    @include borders($btn-type-a-hover-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow(none);
    background:$btn-type-a-hover-bg;
    color: $btn-type-a-color;
  }
  &:focus,
  &.focus {
    @include borders($btn-type-a-focus-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow( 0px 0px 3px 0px rgba(0, 0, 0, 0.5)); 
    background:$btn-type-a-focus-bg;
    color: $btn-type-a-states-color;    
    text-decoration: none;    
  }
  &:active,
  &.active {
    @include borders($btn-type-a-active-border-color, 1px, 1px ,1px, 1px);
    @include box-shadow(none);
    outline: 0;
    background-image: none;    
    background:$btn-type-a-active-bg;
    color: $btn-type-a-color;    

    &:hover{
          @include borders($btn-type-a-states-border-color, 1px, 1px ,1px, 1px);
          @include box-shadow(none); 
          background:$btn-type-a-states-bg;
          color: $btn-type-a-states-color;    
    }    
  }
  &.disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    @include opacity(.35);
    @include box-shadow(none);
  }
}



.k-widget.k-window .k-window-content{
  visibility: visible;
}



//dla kendo window
.k-window-titlebar .k-window-action{
  margin-top:1px;
  width: 25px;
  height: 25px;
}

.k-window-actions > .k-window-action.k-link{
  
}
.k-window-actions > .k-window-action.k-link > .k-icon{
  //@extend %font-material-placeholder;  
  background-image:none;  
  margin-top:-2px;
  margin-left:0px;
  width: 26px;
  height: 26px;
  border-radius:2px;
  color:$color-neutral-60;
  font-size:0px;

}
.k-window-actions > .k-window-action.k-link.k-state-hover{
  @include box-shadow(none);
  background:none;
  border:1px;
  border-color:$window-title-bg;
  opacity:1;  
  padding:2px;
  > .k-icon{
    color:$color-neutral-100;
    background:$color-neutral-11;
  }
  //margin-top:4px;
}

.k-window-content.with-action-bar{
  padding:0px 0px 0px 0px;
  overflow:hidden;  
  > div:nth-of-type(1){
    // height:100%;
    // max-height:420px;
    height:calc(100% - 60px);
    overflow:auto;
    padding:0px 0px 20px 0px;
    @media (max-width: $grid-float-breakpoint-max){   
      padding:0px 10px 20px 10px; 
    }
  }
  // > div > div{
  //   overflow: auto; 
  //    height: calc(100% - 60px) !important; 
  //    padding: 0px 10px 20px 10px;
  // }
  .panel-action{
    width: 100%;
    background: $window-button-wrap-bg;
    border-top: 1px solid $window-button-wrap-border;
    padding:0px;     
  }
}


.k-window-titlebar .k-window-action.k-state-hover {
  border-color: transparent;
  background: none;
  box-shadow: none;
}

.k-window-titlebar  .k-window-action >.k-icon.k-i-close,
.k-window-titlebar  .k-window-action.k-state-hover >.k-icon.k-i-close {
  //background-image: url(../image/icon-set-red.png);
  background-image:none;
  @extend %lumen-icon-font-placeholder;
  color:$sub-nav-bg;
  font-size:0px;
  &:before {
    // font-family: "Material icons";
    content: "\e5cd";    
    color:$window-close-icon;    
    font-size: 19px;
    
  }  
}








/* test mobiel filter*/
#mobile-filter{
         display:none;
         @media (max-width: $grid-float-breakpoint) {
          display:inline-block;
          padding:1px 10px;
         }
}

.k-filter-help-text{
      margin-bottom:10px;
      font-size:14px;
      font-weight:bold;
      padding:10px 0px 10px 15px;
      border-bottom:1px solid $window-title-border-color;
      background:$window-title-bg;
}

/* tooltip */
.k-tooltip.k-chart-tooltip{
    background-color:$tooltip-bg !important; 
    border-color:$tooltip-border-color !important;
    color:$tooltip-color !important;
}
.k-widget.k-tooltip, .k-tooltip.k-chart-tooltip{  
  background-image:none;
  background-color:$tooltip-bg;
  border-color:$tooltip-border-color;
  // opacity: .8 !important;
  // filter: alpha(opacity=80) !important; //IE8
  // transition:all 0s linear !important;
  .k-callout-s {
    border-top-color: $tooltip-border-color;
    //opacity: .8 !important;  
    //filter: alpha(opacity=80) !important; //IE8
    
  }  
}
.k-callout-s{
  bottom:-10px;
  
}

.k-tooltip.validation-tooltip-icon-hide{
  background-color:$forms-validation-border-color;
  border-color:$forms-validation-border-color;
  .k-callout-s {
    border-top-color: $forms-validation-border-color;
  }
}

/* buttons */
.k-button{
  
    @include button-size($padding-xs-vertical, 6px, $font-size-small, $line-height-small, $btn-border-radius-small);

  &.btn-xs {
    @media (max-width: $mobile-mode-max) {
      padding: 4px 6px;
    }
  }
}

/* checkbox*/
.k-checkbox-label:empty{
  &:after{    
    margin-top:-8px !important;
  }  
}

.k-checkbox-label:after, .k-checkbox:checked+.k-checkbox-label:after{
  margin-top:-3px;
  width:16px;
  height:16px;
  border-color:$forms-element-border-color;

    &:hover {
    @extend %input-hover-style;   
  } 
  &:focus {
    @extend %input-focus-style;   
  }
    &:active {
      @extend %input-active-style;      
    } 

}

.k-checkbox:checked+.k-checkbox-label:after{
  @extend %lumen-icon-font-placeholder;      
  content: "\f00c";
  color:$forms-checkbox-color;
  font-size:.9em;
  padding-top:2px;

}

/* checkbox validation 

*/


.k-checkbox-label.k-invalid:after, .k-checkbox:checked+.k-checkbox-label.k-invalid:after{
  margin-top:-3px;
  width:16px;
  height:16px;
  border-color: $forms-validation-border-color;
  background:$forms-validation-bg;

    &:hover {
    @extend %validation-style;  
    //@extend %input-hover-style;   
  } 
  &:focus {
    
    @extend %validation-style; 
    @include box-shadow(0px 0px 5px 0px $cancel-color);  
    //@extend %input-focus-style;   
  }
    &:active {
      @extend %validation-style; 
      //@extend %input-active-style;      
    } 

}

/* radio buttons */

.k-radio-label.k-invalid:before{
  
  border-color: $forms-validation-border-color;
  background:$forms-validation-bg;

    &:hover {
    @extend %validation-style;  
    //@extend %input-hover-style;   
  } 
  &:focus {
    
    @extend %validation-style; 
    @include box-shadow(0px 0px 5px 0px $cancel-color);  
    //@extend %input-focus-style;   
  }
    &:active {
      @extend %validation-style; 
      //@extend %input-active-style;      
    } 

}

.k-radio:checked+.k-radio-label:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
}


//buttongroup
.km-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-origin: border-box;
    position: relative;
    display: inline-block;
    overflow: visible;
    text-decoration: none;
  }
.km-buttongroup{
    -webkit-margin-collapse: separate;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-origin: border-box;
    position: relative;
    display: inline-block;
    padding: .4em .7em;
    margin: .1rem;
    overflow: visible;
    text-decoration: none;
    vertical-align: middle;    
    margin: 0;
    padding: 0;
    border-color: transparent;
    background: 0;
    white-space: nowrap;
    display: table;
}

.km-widget.km-buttongroup>*:last-child, .km-widget.km-buttongroup>*.km-state-active {
    border-right-width: 1px;
}
.km-widget.km-buttongroup .km-button {
    margin: 0;
    border-width: 1px 0 1px 1px;
    //padding: .48em .9em .44em;
    padding:1px 10px;
}
.km-buttongroup>*:first-child {
    border-radius: 4px 0 0 4px;
}
.km-buttongroup>*:last-child {
    border-radius: 0 4px 4px 0;
}
.km-widget.km-buttongroup>*.km-state-active+* {
    border-left-width: 0;
}
.km-buttongroup>*:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.km-buttongroup>.km-button {
    display: table-cell;
}
.km-widget.km-buttongroup>*.km-state-active+* {
    border-left-width: 0;
}
.km-widget.km-buttongroup>*:last-child, .km-widget.km-buttongroup>*.km-state-active {
    border-right-width: 1px;
}
.km-widget.km-buttongroup> .km-state-active {
  background:$km-buttongroup-active-bg;
}



// filtry
// .k-animation-container > .k-filter-menu.k-popup{
//   width:auto;
// }





.k-animation-container > .k-filter-menu.k-popup > div {
  //width:350px;

  .k-header{
    // background:$abs-menu-bg;
    // border-color:$abs-menu-bg;
  } 

  >label{
    min-width:70px;
    >input{
      height:13px;
      margin-right:5px;
    }
  }


  .form-group{
    float:left;

  }

  .k-widget.k-dropdown{  
    
    &.value{
      width:180px;
      margin-right:0px; 
      float:left;
      > .k-dropdown-wrap{
        @include radius(2px, 0px, 0px, 2px);  
      }
    }  
    > .k-dropdown-wrap{
        @include radius(2px, 2px, 2px, 2px);    
    }
    float: left;
    width: 132px;
    margin-top:0px;
    margin-right:-1px; 
    //height:21px;
    &:hover, &:focus{
        z-index:1000;
        position:relative;
    }
  } 
  .k-widget.k-dropdown.abs-filter-menu-dropdown-listbox{
        width: 200px;
    margin-left: 18px;
    margin-bottom: 10px;
    margin-right: 18px;
    clear:left;
  }
  .k-widget.k-dropdown:nth-of-type(1){    
      > .k-dropdown-wrap{
          @include radius(2px, 2px, 2px, 2px);    
      }
      //width: 165px;
      //float: left;    
         width:200px;
         float:none; 
         margin:0px 18px 5px 18px;
      &:hover, &:focus{
        z-index:1000;
        position:relative;
      }
  }

   > input:nth-of-type(1) {    
    @include radius(2px, 2px, 2px, 2px );    
    margin-left:18px;
    margin-right:18px;
    width: 200px;
    float: left;
    margin-bottom:10px; 
    margin-top:0px;   
  } 

  >.k-datepicker{    
    > .k-picker-wrap{
      @include radius(2px, 2px, 2px, 2px );    
      height:2.25em;
      > .k-input{
        padding:2px;
      }
    }  
    margin-left:18px;
    margin-right:18px;
    width: 200px;
    float: left;
    margin-bottom:10px; 
    margin-top:0px;   
    clear:left;
  } 
  

  .k-numerictextbox{
    > .k-numeric.wrap{
      @include radius(2px, 2px, 2px, 2px );
      &.k-state-fefault .k-input{
        box-shadow:none;
      }
    }
    //margin-left:-1px;
    width: 200px;
    float: left;
    margin-bottom:10px; 
    margin-top:0px; 
    padding:0px;  
    margin-left:18px;
    margin-left:18px;
    clear:left;

  }
  

  > input:nth-of-type(2) {
    @include radius(2px, 2px, 2px, 2px );
    margin-left:18px;
    margin-right:18px;
    width: 200px;
    margin-top:0px;    
    
    display:block;
    clear:left;
 }

  .k-dropdown.k-filter-and{
    > .k-dropdown-wrap{
      @include radius(2px, 2px, 2px, 2px ); 
    }  
    width: 64px;
    float: left;
    display: inline-block;
    margin-top:0px;
    margin-right:4px;
    margin-bottom:5px;
    margin-left:18px;
    clear:left;
  }




 >div:nth-of-type(2){
  clear:both;
  border-top:1px solid $window-title-border-color;
  margin-top:12px;
  padding-top:0px;
  text-align:right;
  > .k-button{
    width:auto;
    margin:10px 10px 10px 0px;
  }
 }

>.popup-filter-footer{
    background-color:$window-title-bg;
    border-top:1px solid $window-title-border-color;
    margin-top:10px;
    clear:both;
    padding:3px 0px 10px 10px;
    text-align:right;
    > .k-button{
      width:auto;
      margin:.5em 13px 0 0;
    }
    > .btn-icon-only{
      padding:1px 5px;
      float:left;
      background:none;
      border:0px;
    }
 }





.icon-pin{
  @extend %lumen-icon-font-placeholder;
  &:before {
    content: "\f08d";
    font-size: 20px;
    color: $abs-k-filter-pin-icon;
    }
 }

}




//absquicksearch
.icon-filter-clear{
  
@extend %lumen-icon-font-placeholder;
  &:before{
    content: "\f0b0";
    font-size:20px;
    color:$abs-link-color;  
  }
}

.icon-pin{
@extend %lumen-icon-font-placeholder;
  &:before{
    content: "\f08d";
    font-size:20px;    
    color:$abs-link-color;  
  } 
}





.btn-1-action{    
  float: right;
  margin:10px
  //margin-right: 10px;
  //margin-bottom: 2px;
}  

.abs-grid.k-grid-column-resizing{
  -webkit-tap-highlight-color:red;
  .k-grid-header{
    
  }
.k-grid-header th.k-header{
    }
}




.k-widget.k-window{

  .k-window-content.k-content {
    @include radius(0px, 0px, 4px,4px);
    padding: 0px;
    border-top:1px solid $window-title-border-color;  
    margin-top:-1px;  
    .form-horizontal{
      padding:20px
    }


     .warning-icon{
          display:inline-block;
          float:left;
          margin:20px 0px 20px 20px;
                > i{
           color:$abs-menu-bg;
            font-size:40px;
      }
      &:empty{
        display:none;
      }
      
     } 
     .warning-message{
      padding:20px;
      line-height:1.4em;
      float:left;
      max-width:400px;
      min-width:250px;

     }

  } 
    .container-box.container-box-a{
      height: 100%;
      background: $window-inside-container-bg;
      padding: 0px;
    }
  .abs-col-1.container{
    @include border-radius(0px);
    @include box-shadow(none);
    height: calc(100% - 150px);
    border:0px;
    padding:10px 0px 0px 0px;
    max-width:690px;
    @media (max-width: $grid-float-breakpoint-max){ 
      width:auto;
    }  

    h2:empty{
      display:none;
    }
    .col-sm-2{
      text-align:left;
      //padding-left:0px;
     // @media (max-width: $grid-float-breakpoint-max){ 
        padding-left:15px;
        margin-bottom:5px;
      //}  
    }
    .form-horizontal .form-group{
      margin:5px 0px 0px;
    }
    .form-control{
      width:95%;
    }
  } 
  .form-horizontal.form-widgets.col-sm-12 {
    //padding-left:0px;
    //padding-right:20px;
    //@media (max-width: $grid-float-breakpoint-max){ 
      padding:0px 0px;
    //}  
  }
  .form-horizontal.control-label{
    text-align:right;
  }
  .container.button-wrap{
        width: 100%;
    background: $window-button-wrap-bg;
    border-top: 1px solid $window-button-wrap-border;
    padding:0px;      
    height: 40px;    
    margin-top: 19px;
    margin-bottom:0px;
    border-bottom:0px;
    border-left:0px;
    border-right:0px;
    position:relative !important;
        > .button-group-right {
            margin-right:0px;
        }    
  }

    .validation-header{
      width:auto !important;
      border-radius: 0px;
      border-width: 1px 0px;      
    }


  .abs-form-window {
    .form-group{
      margin-bottom:10px;
    }
    h2{
          text-transform: uppercase;
          color: $color-neutral-60;
          font-size: 14px;
          font-weight: bold;
          margin-top: 25px;
          margin-left: 15px;
          margin-bottom: 20px;
    }
    hr{
      border-top: 1px solid $color-neutral-16;
      clear:both;
      &:nth-last-of-type(1){
        display:none;
      }
    }
    .form-group > .col-sm-10 > input{
      width:100%;
    }
    .form-group .col-sm-10 > textarea{
      width:100%;
    }
    .col-sm-9, .col-sm-10 {
       width: 80%;
    }
    .col-md-6{
      width:60%;
    }
    .k-dropdown, .k-datepicker , .k-numerictextbox{
      width:100%;
    }    
      .form-horizontal{
      padding:0px !important;
    }
    .form-horizontal .form-group {
    margin-left: 0px; 
    margin-right: 0px; 
    }
  }
}    




// przypinanie header grida

.k-grid-header-fixed{
  max-width: 1600px;
    margin: 0px auto;
    position: fixed;
    top: 0px;
    z-index: 1090;
    border-right:1px solid $abs-grid-bg-border-color;
    border-left:1px solid $abs-grid-bg-border-color;
    left:0px;
    right:0px;
    @media (max-width: $grid-breakpoint-desktop-large) {
      margin:0px 15px;
    } 
}
.k-grid-content-with-fixed-header{
  margin-top:42px;
}
.k-ie .k-grid-header-fixed{
  left:auto;
  right:auto;
  width:100%;
}
.fixed-grid-scroll{
  display:none;
  position:fixed !important; bottom:0px; z-index:1060; left:0px; right:0px; width:100%; margin:0px auto; padding:0px;
  @media (max-width: $grid-float-breakpoint-max){
    display:none !important;
  }  
  > #source{
     overflow-x:scroll; 
     
     border-left:1px solid $abs-grid-bg-border-color;
     border-right:1px solid $abs-grid-bg-border-color;
     > p{       
       margin:0px 0px 1px 0px;
       height:1px;
       background:transparent;
     }
         @media (max-width: $grid-breakpoint-desktop-large) {
      margin:0px 15px;
      width:auto;
    } 

  }
}

.mac{
  .fixed-grid-scroll > #source > p{
    height:10px;
    background:transparent;
    opacity:.1;
      -khtml-opacity: 0.1;
  }
  .abs-grid.k-grid .k-grid-header th.k-header.k-filterable .k-link:after , .abs-grid.k-grid .k-grid-header th.k-header:after{
    // background:transparent;
     background:$abs-grid-header-bg-color;
    
  }
  .abs-grid.k-grid .k-grid-header th.k-header.k-filterable:hover .k-link:after , .abs-grid.k-grid .k-grid-header th.k-header:hover:after{
    background:$abs-grid-header-hover-bg-color;
  }

  .k-numeric-wrap .k-input , .k-dropdown-wrap .k-input{
    padding:.165em 0 0 0;
    height:1.94em;    
  }
}

.iphone{
  .k-input, .k-dropdown-wrap .k-input, .k-picker-wrap .k-input{
    padding:.1635em 0 0 0;
  }
  .k-numeric-wrap .k-input{
    height:2.05em;

  }
}

.k-ie .abs-grid.k-grid .k-grid-toolbar .filter-panel .col-md-2, 
.k-ff .abs-grid.k-grid .k-grid-toolbar .filter-panel .col-md-2 {
    height: 98%;
    position: absolute;
    right: 1px;
    top: 1px;
}




/* checkboxy dla podwyzszonej wersji - do wywalenia*/
.k-checkbox-label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-width: 1px;
    border-style: solid;
    width: 16px;
    height: 16px;
    font-size: .9em;
    line-height: 1.1em;
    text-align: center;
    }


    .k-checkbox:checked + .k-checkbox-label:after {
    content: "\f00c";
    color: $forms-checkbox-color;
    font-size: .9em;
    padding-top: 2px;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid $forms-element-border-color;
    background: $forms-element-bg;
    border-radius: 2px;
    padding-left:1px;
    padding-top:2px;
  
}
.k-checkbox-label {
  line-height:1.4em;
}
.k-ie .k-radio-label{
 //line-height:1.4em;
 padding-top:0px;
}
.k-ie input[type='checkbox'], .k-ie input[type='radio']{
  margin:0px;
  height:auto;
}
.k-checkbox-label:empty {
    // margin-top: -10px !important;
}
.k-checkbox-label:after, .k-checkbox:checked + .k-checkbox-label:after {
    margin-top: 0px;
    
}
.k-checkbox-label.k-invalid:after, .k-checkbox:checked + .k-checkbox-label.k-invalid:after {
    margin-top: 0px;
    
}
.k-checkbox-label:empty:after {
    margin-top: 0px !important;
}


.k-ie {
  @media (max-width: 767px){
    body{
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .abs-grid.k-grid .k-grid-content{
      overflow:auto !important;
    }
    .abs-grid.k-grid tr td{
      width:100%
    }
    #issueList > .k-grid-toolbar > .btn.btn-icon-only,.abs-quicksearch{
      margin-top:10px;
    }
  }
  @media screen and (orientation:landscape) {
    .k-grid-header-fixed{
      left:0px;
      right:0px;
      width:100%;
    }
  }
}
@supports (-ms-accelerator:true) {
  html{
    @media (max-width: 767px) {
      -ms-overflow-style: -ms-autohiding-scrollbar;
      .abs-grid.k-grid .k-grid-content {
        overflow: auto !important;
      }
      .abs-grid.k-grid tr td{
        width:100%
      }
      #issueList > .k-grid-toolbar > .btn.btn-icon-only,.abs-quicksearch{
        margin-top:10px;
      }
    }
  }
}


//loaderek

.k-loading-image {
    background-image: url('../../../css/loader.gif');
    background-size: 40px 40px;
}    



.k-animation-container > .k-menu-vertical.k-context-menu{
  @include border-radius(0px);
  border:1px solid $context-menu-border;
  background:$context-menu-bg;
  min-width:150px;  
  //padding: 2px;

  .k-menu-group.k-popup{
    border:1px solid $context-menu-border;
    // margin-left:0px;
    min-width:150px;
    .k-ie &{
      margin-top:1px;
    }
  }

  .k-item{
    @include border-radius(2px);
    > .k-link{
      color:$context-menu-link-color;
    }
    .k-icon.k-i-arrow-e , .k-icon.k-i-arrow-60-right{
      @extend %lumen-icon-font-placeholder;
      background-image:none;
      position: absolute;
      right: 5px;
      top: 0px;
      bottom: 0px;
      margin: auto;
      &:before{
        content: "\e318";
        color:$context-menu-ico;
        }
      }  
    &.k-state-hover > .k-link{
      @include box-shadow(none);    
      background:$context-menu-hover-bg;
      color:$abs-k-filter-text-color;
    }
  }
  
  .k-state-active, .k-state-hover{
    @include box-shadow(none);    
      background:$context-menu-hover-bg;
      color:$abs-k-filter-text-color;
  }
  .k-state-border-left , .k-state-border-right{
     @include box-shadow(none);  
     background:$context-menu-bg;
  }
}

.k-menu-horizontal .k-animation-container{
    .k-menu-group.k-popup{
    border:1px solid $context-menu-border;
    // margin-left:0px;    
  }

  .k-item{
    @include border-radius(2px);
    > .k-link{
      color:$context-menu-link-color;
    }
    .k-icon.k-i-arrow-e , .k-icon.k-i-arrow-60-right{
      @extend %lumen-icon-font-placeholder;
      background-image:none;
      &:before{
        content: "\e318";
        color:$context-menu-ico;
        }
      }  
    &.k-state-hover > .k-link{
      @include box-shadow(none);    
      background:$context-menu-hover-bg;
      color:$abs-k-filter-text-color;
    }
  }
  .k-state-active, .k-state-hover{
    @include box-shadow(none);    
      background:$context-menu-hover-bg;
      color:$abs-k-filter-text-color;
  }
  .k-state-border-left , .k-state-border-right{
     @include box-shadow(none);  
     background:$context-menu-bg;
  }
}



//
.k-dropdown-wrap {
  .k-combobox &{
    height: 26px;
    .k-input{
      height: 1.81em;
    }
  }
  .k-select{
    min-height: 0em;
    line-height: 26px;
  }
  .k-input{
    padding:0;
    text-indent: 8px;
    height:1.85em;
    line-height:1.85em;
  }
}

//ikonka sortowania po lewej
.abs-grid.k-grid .k-grid-header th.k-header[aria-sort] .k-link {
    padding-left: 16px;
    > .k-icon{
      position: absolute;
    left: 9px;
    }
}
