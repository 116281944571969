//timeline

%timeline-h3{
  font-size:1em;
  font-weight:bold;
}

.timeline{
  padding-top:25px;
  height:auto;
  padding-left:10px;
  padding-right:10px;
  .timeline-block{
    position:relative;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .timeline-block  .timeline-date{
    position:absolute;
    top:0px;
    left:0px;
    width:100px;
    @media (max-width: $screen-md-min) { width:70px; }
    @media (max-width: $grid-float-breakpoint-max){	width:90px;}
    > span{
      text-align:right;
      color:$timeline-date-color;
      font-size:.9em;
      padding-right:20px;
      float:right;
      display:block;
      margin-top:-2px;
    }

  }
  .timeline-block  .timeline-desc{
    margin-left:100px;
    @media (max-width: $screen-md-min) { margin-left:70px}
    @media (max-width: $grid-float-breakpoint-max){	margin-left:90px;}
    border-left:$timeline-line-width solid $sub-nav-bg;
    position:relative;
    min-height:100px;


    > h3{
      @extend %timeline-h3;
      margin-top:0px;
      margin-bottom:0px;
      padding:0px 0px 5px 20px;

      > span{
        font-weight:normal;
      }
    }
    >p{
      margin-bottom:0px;
      padding:2px 0px 0px 20px !important;
      line-height:1.1;

      &:nth-of-type(2){
        color:$timeline-second-desc-color;
        padding-bottom:20px !important;
      }
    }
    .abs-icon{
      padding-right:5px;
      font-size:14px;
    }
    .abs_long_arrow_right {
      color:$timeline-arrow-right-color;
    }
    .abs_long_arrow_left{
      color:$timeline-arrow-left-color;
    }

    .timeline-circle{
      @include border-radius(50%);
      position:absolute;
      left:-8px;
      top:-1px;
      width:14px;
      height:14px;
      background:$timeline-circle-bg-color;
      border:$timeline-line-width solid $sub-nav-bg;
    }
  }
  .timeline-block:last-of-type .timeline-desc{
    border-left:$timeline-line-width solid transparent;
  }
}

.tabs-to-panel .timeline{
  padding-left:2%;
  padding-right:2%;
  padding-top:40px;
}



.timeline-type-a{
  padding-top:25px;
  height:auto;
  padding-bottom:30px;
  .timeline-block{
    position:relative;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .timeline-block  .timeline-date{
    position:absolute;
    bottom:0px;
    left:0px;
    width:100px;
    @media (max-width: $screen-md-min) { width:70px; }
    @media (max-width: $grid-float-breakpoint-max){	width:90px;}
    > span{
      text-align:right;
      color:$timeline-date-color;
      font-size:11px;
      padding-right:20px;
      float:right;
      display:block;
      margin-top:-2px;
    }

  }
  .timeline-block.non-active{
    .timeline-desc{
      border-left:$timeline-line-width solid $timeline-nonactive-color;
    }
  }
  .timeline-block.non-active{
    .timeline-desc h3{
      font-weight:normal;
    }
  }
  .timeline-block  .timeline-desc{
    margin-left:100px;
    @media (max-width: $screen-md-min) { margin-left:70px}
    @media (max-width: $grid-float-breakpoint-max){	margin-left:90px;}
    border-left:$timeline-line-width solid $sub-nav-bg;
    position:relative;
    min-height:70px;


    > h3{
      @extend %timeline-h3;
      margin-top:0px;
      margin-bottom:0px;
      padding:0px 0px 0px 20px;
      position:absolute;
      bottom:0px;

      > span{
        font-weight:normal;
      }
    }
    >p{
      margin-bottom:0px;
      padding:2px 0px 0px 20px;

      &:nth-of-type(2){
        color:$timeline-second-desc-color;
        padding-bottom:20px;
      }
    }
    .abs-icon{
      padding-right:5px;
      font-size:14px;
    }
    .abs_long_arrow_right {
      color:$timeline-arrow-right-color;
    }
    .abs_long_arrow_left{
      color:$timeline-arrow-left-color;
    }

    .timeline-circle{
      @include border-radius(50%);
      position:absolute;
      left:-8px;
      bottom:0px;
      width:15px;
      height:15px;
      background:$timeline-circle-bg-color;
      border:$timeline-line-width solid $sub-nav-bg;
    }

    .inner-circle{
      @include border-radius(50%);
      background:$sub-nav-bg;
      position: relative;
      width: 7px;
      height: 7px;
      background: $sub-nav-bg;
      margin-top: 2px;
      margin-left: 2px;

    }

  }
  .timeline-block.non-active >.timeline-desc > .timeline-circle{
    border:$timeline-line-width solid $timeline-nonactive-color;
  }
  .timeline-block.non-active >.timeline-desc  .inner-circle{
    display:none;
  }

  .timeline-block:first-of-type{
    margin-top:-55px;
    .timeline-circle{
      left:-6px;
    }
    .timeline-desc{
      border-left:0px
    }
  }
}


.timeline-type-a .timeline-block .timeline-desc .timeline-circle {
  border-color: $timeline-main-color;
}

.timeline-type-a .timeline-block .timeline-desc .inner-circle {
  background: $timeline-main-color;
}

.timeline-type-a .timeline-block .timeline-desc {
  border-left-color: $timeline-main-color;
}