

  .abs-multi-combo-box-trigger {
    display: flex;
    border-radius: 2px 2px 2px 2px;
    padding: 1px 25px 1px 10px;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid $abs-multi-combo-box-border-color;
    background: $abs-multi-combo-box-bg-color;
    line-height: 24px;
    position: relative;
    height: 28px;
    margin-left: 7px;
    outline: none;
    cursor: pointer;
  }

  .abs-multi-combo-box-dropdown {
    background: $abs-multi-combo-box-bg-color;
    border: 1px solid $abs-multi-combo-box-dropdown-border-color;
    font-family: "Roboto", sans-serif;
    height: auto;
    margin-top: 4px;
    overflow: hidden;
    position: absolute;
    width: 266px;
    z-index: 100;

    .abs-multi-combo-box-search-wrap {
      padding: 9px;

      .abs-multi-combo-box-search-inner {
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          font-size: 13px;
          height: 2.43em;
          text-indent: .8em;
          line-height: 1.6em;
        }
      }
    }
    .abs-multi-combo-box-options-wrap {
      max-height: 350px;
      overflow-y: auto;

      .abs-multi-combo-box-dropdown-options {
        padding: 0;
        margin: 0;

        li[aria-selected=true] {
          background: #eff9ff;
          outline: none;
        }

        .abs-multi-combo-box-dropdown-option {
          background: $abs-multi-combo-box-bg-color;
          height: 50px;
          cursor: pointer;
          outline: none;

          &.abs-multi-combo-box-option-selected {
            background: $abs-multi-combo-box-dropdown-option-selected-bg;
            outline: none;
          }

          &:hover, &:active {
            background: $abs-multi-combo-box-dropdown-option-hover;
            outline: none;
          }

          .abs-multi-combo-box-dropdown-multiselect-option {
            display: flex;
            cursor: pointer;
            width: 100%;
            padding-left: 13px;
            height: 100%;
            padding-top: 13px;


            > input[type="checkbox"] {
              cursor: pointer;
            }

            .option-with-select {
              margin-left: 25px;
            }

            .abs-multi-combo-box-dropdown-option-additional-info {
              font-weight: 400;
              font-size: 11px;
              color: $abs-multi-combo-box-dropdown-option-additional-info;
              text-align: left;
              display: flex;
              align-items: center;

              .abs-multi-combo-box-dropdown-option-additional-info-separator {
                margin: 0 4px;

                &:after {
                  content: "●";
                }
              }
            }
          }
        }

        > li {
          border: none;
          border-top: 1px solid $abs-multi-combo-box-dropdown-option-list-border;
          display: flex;
          align-items: center;
        }
      }
    }
  }
