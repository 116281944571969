.fw-settings-container {
  .abs-fixed-bottom {
    width: calc(100% - 30px);
    max-width: 1320px;
  }
  .container.abs-fixed-bottom.on-scroll {
    bottom: 40px;
    width: 100%;
  }
}

.fw-settings-content {
  &.box {
    padding-bottom: 100px;
    padding-top: 30px;
    margin: 0 0 60px 0;
    position: relative;

    @media (max-width: $mobile-mode-max) {
      padding: 30px 9px 80px;
    }
  }
}

.fw-settings-table {
  width: 503px;
  @media (max-width: $mobile-mode-max) {
    width: 100%;
  }
}

.fw-settings-table-header {
  @include flex-space-between();
  border-bottom: solid 2px $color-neutral-27;
  padding-bottom: 10px;
  font-weight: 500;
  .fw-settings-table-header_alert-type {
    width: 195px;
    text-align: left;
    margin-left: 15px;
  }
  .fw-settings-table-header_visibility {
    width: 140px;
    margin-right: 120px;
    @media (max-width: $mobile-mode-max) {
      margin-right: 10px;
    }
  }
}

.fw-settings-table-body {

  .fw-settings-table-body-row {
    @include flex-space-between();
    height: 46px;
    align-items: center;
    border-bottom: solid 1px $color-neutral-8;
    input {
      margin-top: 0;
    }
    .fw-settings-table-body-row_alert-type {
      @include flex-align-items-center();
      width: 195px;
      height: 100%;
      margin-left: 15px;
      justify-content: flex-start;
    }
    .fw-settings-table-body-row_visibility {
      @include flex-align-items-center();
      width: 140px;
      height: 100%;
      position: relative;
      margin-right: 120px;
      @media (max-width: $mobile-mode-max) {
        margin-right: 10px;
        width: auto;
        margin-left: 10px;
      }
      .days-input-container {
        position: relative;
      }
      .days-input {
        width: 80px;
        height: 26px;
        padding-right: 40px;
        text-align: right;
        margin-left: 8px;
        @media (max-width: $mobile-mode-max) {
          padding-right: 30px;
        }
      }
      .days-input-text {
        position: relative;
        right: 40px;
        @media (max-width: $mobile-mode-max) {
          right: 30px;
        }
      }

      .days-input[disabled] + .days-input-text {
        opacity: .35;
      }
    }
  }
}

.notifications {
  max-width: 500px;

  .absui-btn--b {
    background-color: #017EBB;
    border: 1px solid #017EBB;
    border-radius: 4px;

    &:hover {
      background-color: #000;
      border: 1px solid #000;
    }
  }

  .absui-input-group > .absui-form-control {
    width: 70px;
    padding: 0 8px;
    text-align: center;
  }

  .absui-form-footer.on-scroll {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    width: 100%;
    background: #ebebeb;
    border-top: 2px solid #00a4e1;

    @media (max-width: $mobile-mode-max) {
      height: 58px;
    }

    .absui-form-footer__container {
      padding: 0;
    }
  }
}

.notifications-form-panel {
  .absui-form-section-header {
    display: none;
  }

  .absui-control-label {
    margin-top: 4px;
    padding-left: 15px!important;
    width: 226px!important;
    text-align: left!important;
    color: #000;
  }

  .absui-form-group {
    margin: 0;
    padding: 8px 0;
    border-bottom: solid 1px #ebebeb;
  }

  .absui-input-group-text {
    color: #000;
    padding: 0px 6px;
  }

  .absui-with-checkbox {
    flex-direction: row-reverse;
  }

  [disabled] + .absui-input-group-append {
    opacity: 0.4;
  }

  .absui-control-wrap {
    width: auto!important;

    @media (max-width: $mobile-mode-max) {
      margin-left: auto;
    }
  }
}
