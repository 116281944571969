


%font-material-placeholder{
    font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
%font-awesome-placeholder{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;    
}


%lumen-icon-font-placeholder{
      display: inline-block;
    font: normal normal normal 14px/1 Lumen-Icon-Font;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;  
}


.material-icons {
  @extend %font-material-placeholder;  
}

    
%wyceny-icon{
      @include radius(2px);             
      color:#fff;
      padding:2px 5px;
      font-size:11px;
      margin-top:-2px;
      margin-right:5px;
      display:block;
      float:left;  
}

    .fa-usd{      
      @extend %wyceny-icon;
        
    }     
    .fa-usd.do-wyceny{
      background:$status-do-wyceny;
    }
    .fa-usd.zaakceptowany{
      background:$status-zaakceptowany;
    }
    .fa-usd.odrzucony{
      background:$status-odrzucony;
    }
    