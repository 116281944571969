.abs-form-header {
  margin-bottom: 6px;
  text-align: left;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $mobile-mode-max) {
    text-align: center;
    padding: 0;
  }
  .abs-form-header-title {
    color: $color-neutral-60;
    font-size: 13px;
    font-weight: 500;
    &.fill-form {
      font-size: 14px;
    }
    &--no-margin {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .abs-form-header-title-additional {
    font-size: 13px;
    color: $color-neutral-60;
    font-weight: 400;
    &--no-margin {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.abs-bottom-btn-panel {
  display: flex;
  justify-content: space-between;
  padding: 9px 11px;
  .abs-add-next-field {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
    .abs-add-next-field-checkbox-opaque {
      margin-right: 20px;
      margin-bottom: 0;
    }
    .abs-add-next-label {
      &:hover {
        cursor: pointer;
      }
    }
    &--low-visible {
      opacity: 0.3;
    }
    input[type=checkbox] {
      margin: 0;
      height: auto;
    }
  }
  .btn-type-a {
    background-color: $color-brand-secondary;
    border: 2px solid $color-brand-secondary;
    min-width: 69px;
    &:hover {
      @extend .btn-type-a, :hover;
    }
  }
  .btn-type-b {
    min-width: 69px;
  }
  .btn-type-a, .btn-type-b {
    height: 26px;
    @media (max-width: $mobile-mode-max) {
      height: 30px;
      line-height: 20px;
    }
  }
  .package-button {
    width: auto;
    margin-left: 10px;
  }
}

.abs-form-panel {
  @extend .abs-filter-panel;
  display: block;
  width: auto;
  height: auto;
  text-align: center;

  .abs-filter-form-container {
    margin-top: 4px;
    margin-left: 0;
  }

  .loader-mask-style-b {
    &:before {
      left: 0;
    }
  }
  .custom-select-height {
    height: 40px;
  }
  .custom-select-placeholder {
    @extend .custom-select-height;
    display: flex;
    align-items: center;
  }
  .r-ss-wrap {
    margin-right: 0px;
    @media (max-width: $mobile-mode-max) {
      position: relative;
      width: 100%;
    }
    &.k-dropdown {
      @media (max-width: $mobile-mode-max) {
        font-size: 13px;
      }
    }
    .r-ss-dropdown {
      width: $select-width;
      margin-left: 0;

      background: $color-neutral-0;
      border: 1px solid $color-neutral-33;
      @include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.35));
      @include border-radius(2px);

      @media (max-width: $mobile-mode-max) {
        position: absolute;
        width: 100%;
        margin-top: 5px;
      }
    }
    .r-ss-trigger {
      width: $select-width;
      height: 26px;
      justify-content: space-between;
      margin-left: 0px;
      display: flex;
      @include radius(2px);
      padding: 1px 1px 1px 8px;
      font-size: 13px;
      border: 1px solid $color-neutral-27;
      background: $color-neutral-0;
      position: relative;

      @media (max-width: $mobile-mode-max) {
        margin: 0;
        width: 100%;
      }
      &.r-ss-placeholder {
        font-weight: 400;
        @media (max-width: $mobile-mode-max) {
          justify-content: space-between;
        }
      }

      .r-ss-selection-clear {
        background: none;
        position: absolute;
        color: $abs-filter-close-icon;
        right: 3px;
        border: none;
        border-left: 1px solid rgb(185, 185, 185);
        height: 24px;
        padding: 0px 3px 0px 3px;
        outline: none;
        margin-right: -4px;
        display: flex;
        > span {
          @extend .abs-icon;
          @extend .abs_close;
          &:before {
            font-size: 18px;
          }
        }
      }

      .carat {
        @extend .abs-icon;
        @extend .abs_expand_more;

        margin-left: auto;

        padding-right: 0;

        transform: rotate(0deg);
        transition: transform .2s linear;

        &:before {
          transform: none;
        }
      }

      &.r-ss-open {
        .carat {
          transform: rotate(-180deg);
          transition: transform .2s linear;

          &:before {
            transform: none;
          }
        }
      }

      &:after {
        display: none;
      }
    }
  }
  .selected-value-template-view {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 7px;

    .values-sequence {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .option-template-view {
    text-align: left;
    &--align-center {
      text-align: center;
    }
    &--no-padding {
      padding: 0;
    }
    .agreement-number-select-item {
      font-weight: 500 !important;
    }
  }
  h2 {
    text-transform: uppercase;
    &.required {
      padding-right: 15px;
      position: relative;
    }
  }
  .filter-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &--with-textarea {
      @media (min-width: $desktop-mode-min) {
        width: fit-content;
        align-items: flex-start;
        margin: auto;
      }
      .col-sm-6 {
        @media (min-width: $desktop-mode-min) {
          width: auto;
        }
        .text-area {
          @media (min-width: $desktop-mode-min) {
            align-items: unset;
          }
          .label-position {
            &--text-area-label {
              padding-top: 10px;
            }
          }
        }
      }
      .col-sm-4 {
        @media (min-width: $desktop-mode-min) {
          width: 101px;
        }
      }

      textarea {
        min-height: 171px;
        max-height: 171px;
        min-width: 100%;
        max-width: 100%;
        @media (min-width: $desktop-mode-min) {
          min-width: 446px;
          max-width: 446px;
        }
        @media (min-width: 992px) {
          min-width: 670px;
          max-width: 670px;
        }
      }
    }
    .select-with-custom-height {
      .label-position {
        align-self: center;
      }
      .r-ss-wrap {
        .r-ss-trigger {
          height: 42px;
          line-height: 18px;
          &:after {
            position: unset;
            margin-bottom: 2px;
            margin-right: -17px;
          }
        }
      }
    }
    .control-label {
      color: #515151;
      @media (min-width: $desktop-mode-min) {
        padding-bottom: 8px;
        padding-top: 0px;
      }
    }
    .form-group {
      @media (min-width: $desktop-mode-min) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 991px;
      }

      &.attachment-form-group {
        @media (min-width: $desktop-mode-min) {
          flex-direction: column;

          .abs-form-field-error-message {
            margin-top: 5px;
          }
        }

        @media (max-width: $desktop-mode-min - 1) {
          .abs-form-field-error-message .icon-with-text {
            display: block;
          }
        }
      }

      &--more-margin-bottom {
        margin-bottom: 50px;
      }
      .label-position {
        padding-top: 0;
        padding-right: 12px;
      }
      .required {
        &:after {
          top: -2px;
          right: 0;
        }
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .abs-custom-input-block {
        position: relative;
        @media (min-width: $desktop-mode-min) {
          display: table;
          margin: auto;
        }
        .abs-input-field-currency {
          position: absolute;
          right: 15px;
          top: 5px;
          color: $color-neutral-60
        }
      }
      .abs-fill-input-field {
        width: 100%;
        @media (min-width: $desktop-mode-min) {
          width: $select-width;
          margin-left: 10px;
        }
        &--value-input {
          padding-right: 45px;
          text-align: right;
        }
      }
      .fw-dropzone {
        @media (max-width: $mobile-mode-max) {
          width: 80%;
          display: inline-block;
        }
      }
    }
    .calendar-form {
      .abs-dropdown-form {
        padding: unset;
        margin: 0px;
        @media (max-width: $mobile-mode-max) {
          margin-left: 0px;
          width: 100%;
        }
        .abs-custom-calendar {
          width: $select-width;
          @media (max-width: $mobile-mode-max) {
            width: 100%;
          }
          .abs-custom-calendar-input-field {
            font-weight: 500;
            width: $select-width;
            @media (max-width: $mobile-mode-max) {
              width: 100%;
            }
          }
          .abs-calendar-clear-icon {
            right: 8px;
          }
        }
        .dropdown-menu {
          @media (max-width: $mobile-mode-max) {
            margin-top: 5px;
          }
        }
      }
    }
    .col-sm-4 {
      @media (min-width: $desktop-mode-min) {
        width: 170px;
      }
      &.icon-position {
        padding-right: 0px;
        padding-left: 0px;
        @media (min-width: $desktop-mode-min) {
          width: auto;
        }
        &--end {
          align-self: flex-end;
        }
        &--center {
          align-self: center;
        }
      }
    }
    .col-sm-8 {
      @media (max-width: $mobile-mode-max) {
        padding-left: 15px;
      }
      @media (min-width: $desktop-mode-min) {
        width: auto;
        padding-right: 0px;
        padding-left: 0px;
      }
    }
    .col-sm-6 {
      @media (max-width: $mobile-mode-max) {
        width: 100%;
      }
    }
    .padding-none {
      padding: 0;
    }
  }
}

.invalid-field {
  position: relative;
  .r-ss-wrap {
    .r-ss-trigger {
      border-color: $color-negative-dark-4;
      background: $color-negative-light;
      &:hover {
        border-color: $color-negative-dark-4;
        background: $color-negative-light;
        color: $color-negative-dark-4;
      }
      &:focus {
        box-shadow: 0px 0px 5px 0px $color-negative-dark-4;
      }
    }
  }
  .abs-custom-calendar-input-field {
    border-color: $color-negative-dark-4;
    background: $color-negative-light;
    &:hover {
      border-color: $color-negative-dark-4;
      background: $color-negative-light;
      color: $color-negative-dark-4;
    }
    &:focus {
      box-shadow: 0px 0px 5px 0px $color-negative-dark-4;
    }
  }
  .tooltip {
    &.top {
      .tooltip-inner {
        background-color: $color-negative-dark-4;
        border-color: $color-negative-dark-4;
        color: $color-neutral-0;
        word-break: break-all;
        max-width: 300px;
      }
      .tooltip-arrow {
        border-top-color: $color-negative-dark-4;
      }
    }
  }
  input {
    border-color: $color-negative-dark-4;
    background: $color-negative-light;
    color: $color-negative-dark-4;
    &:focus {
      box-shadow: 0px 0px 5px 0px $color-negative-dark-4;
    }
  }

}

.hr-no-margin {
  hr {
    margin: 0;
  }
}

body {
  .abs-container {
    .container-box {
      overflow: visible;
    }
    .abs-col-1 {
      @extend .abs-col-1;
    }
    .container {
      @extend .container;
    }
    .abs-fixed-bottom {
      @extend .abs-fixed-bottom;
    }
  }
}

.abs-field-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0.8;
  left: 0;
  top: 0;
  background: $color-neutral-0;
}

.abs-flex-align-center {
  @media (max-width: $mobile-mode-max) {
    justify-content: center;
  }
}

.abs-margin-select-column {
  @media (min-width: $desktop-mode-min) {
    margin-left: 10px;
  }
}

.container.button-wrap, body .abs-container .button-wrap.container {
  background: $color-neutral-8;
  padding-left: unset;
  padding-right: unset;
  border-top: 2px solid $color-brand-primary;
  &--to-top {
    z-index: 100;
  }
}

.fw-unsent-block {
  position: relative;
  .tooltip {
    left: auto !important;
    right: 0px !important;
    top: -141px !important;
    .tooltip-arrow {
      left: 319.5px !important;
      border-top-color: $color-brand-primary;
      border-width: 13px 10px 0;
    }
    .tooltip-inner {
      text-align: left;
      padding: 6px 10px;
      margin-bottom: 8px;
      background-color: $color-neutral-0;
      border: 2px solid $color-brand-primary;
      border-radius: 0;
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      color: $color-neutral-100;
      width: 359px;
      max-width: 359px;
      min-height: 117px;
      .fw-text-block {
        padding: 15px;
      }
      .fw-button-block {
        display: flex;
        justify-content: flex-end;
        .btn-type-a {
          font-weight: 600;
          margin-right: 10px;
          width: auto;
        }
        .btn-type-b {
          font-weight: 600;
          width: auto;
        }
      }
    }
  }
}

.abs-fixed-bottom.on-scroll, body .abs-container .on-scroll.abs-fixed-bottom {
  margin-top: -66px;
  @media (max-width: $mobile-mode-max) {
    margin-top: -70px;
  }
}
