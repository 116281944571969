.modal-backdrop {
  background: $abs-backdrop-bg;

  &.in {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}

.modal-open .modal {
  display: flex;
  @include align-items(center);

  .modal-dialog {
    margin: 0 auto;
    width: auto;
    min-width: 444px;

    .modal-content {
      // @include box-shadow(0px 2px 3px $abs-prompt-box-shadow);
      @include border-radius(0);
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, .35);
      background-color: $abs-prompt-body-bg;
      border: 2px solid $abs-prompt-border-color;
      border-radius: 0;
      box-sizing: border-box;
      @include flexbox();
      @include flex-direction(column);
      max-width: 444px;
      min-height: 160px;

      .modal-body {
        color: $abs-prompt-body-color;
        line-height: 20px;
        text-align: center;
        font-size: 14px;
        @include flexbox();
        @include align-items(center);
        @include flex-direction(row);
        @include justify-content(flex-start);
        flex-grow: 2;
        height: 100%;
        padding: 20px 15px;
        text-align: left;

        .abs-icon {
          color: $abs-prompt-icon-color;
          display: block;
          font-size: 40px;
          margin-right: 16px;
        }

        > div {
          overflow-y: auto;
          max-height: 150px;
        }
      }

      .modal-header {
        background-color: $abs-prompt-footer-bg;
        border-bottom: 1px solid $color-neutral-11;
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        height: 36px;

        .modal-title {
          color: $color-neutral-100;
          font-size: 16px;
          font-weight: bold;
          line-height: inherit;
          flex-grow: 2;
        }

        .close {
          opacity: 1;
          margin-top: 1px;
          margin-right: -7px;
          font-size: 18px;
          color: $color-neutral-60;
          border-radius: 2px;
          padding: 3px 3px 2px;
          text-shadow: none;
          order: 2;

          &:hover {
            background: $color-neutral-11;
            color: $color-neutral-100;
          }
        }
      }

      .modal-footer {
        background-color: $abs-prompt-footer-bg;
        border-top: 1px solid $color-neutral-11;

        // @include box-shadow(0px -1px 0px $abs-prompt-footer-box-shadow);
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-end);
        height: 42px;

        .btn + .btn {
          margin-left: 10px;
        }
      }
    }
  }
}


.abs-filter-form-icon {
  border-radius: 50%;
  width: $label-icon-size;
  height: $label-icon-size;
  float: right;
  display: table;

  &.abs-check-icon-style {
    background: $color-positive-dark;
  }

  &.abs-edit-icon-style {
    background: $icon-placeholder-background;
  }

  > .abs-icon {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: $icon-placeholder-color;
    font-size: 16px;
  }

  .icon-initials {
    font-size: 16px;
  }
}

.modal-open .modal {
  display: flex !important;
}

.modal-open .file-request-modal .modal-dialog .modal-content {
  width: 620px;
  height: 346px;
  max-width: none;

  .modal-body {
    justify-content: space-between;
    width: 100%;

    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      word-wrap: break-word;
    }

    @media (max-width: $mobile-mode-max) {
      display: block;
      padding: 32px 15px;
      hr {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }
}

.modal-open .file-request-modal-no-mail .modal-dialog .modal-content {
  width: 450px;
  height: 260px;
  max-width: none;

  .modal-body {
    justify-content: space-between;
    width: 100%;

    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      word-wrap: break-word;
    }

    @media (max-width: $mobile-mode-max) {
      display: block;
      padding: 32px 15px;
      hr {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }
}

.modal-open .file-request-modal .modal-dialog .modal-content .modal-body .modal-body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0 42px;
  height: 100%;
  max-height: none;

  &:nth-child(1) {
    @media (min-width: $desktop-mode-min) {
      border-right: 1px solid #e3e3e3;
    }
  }

  > div {
    width: 100%;
  }

  input {
    width: 100%;
  }

  p, h4 {
    margin-bottom: 7px;
    line-height: 18px;
  }

  .abs-form-field-error-message {
    .abs-icon.abs_error {
      margin-right: 2px;
      font-size: 14px;

      &:before {
        color: $color-negative-dark-3;
        font-size: 14px;
      }
    }

    .icon-text {
      color: $color-negative-dark-4;
      font-size: 13px;
    }
  }

  .content-icon {
    @extend .abs-filter-form-icon;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;

    .abs-icon {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: #ffffff;
      font-size: 27px;
    }
  }

  .hint {
    color: $color-neutral-60;
    font-size: 13px;
    text-align: center;
  }

  .btn-send {
    margin-top: 7px;
    width: 80px;
    transition: none;
  }

  @media (max-width: $mobile-mode-max) {
    height: auto;
    padding: 0 31px;
    width: 100%;
  }
}

.modal-open .file-request-modal-no-mail .modal-dialog .modal-content .modal-body .modal-body-content-no-mail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 42px;
  height: 100%;
  max-height: none;

  > div {
    width: 100%;
  }

  input {
    width: 100%;
  }

  p, h4 {
    margin-bottom: 7px;
    line-height: 18px;
  }

  .abs-form-field-error-message {
    .abs-icon.abs_error {
      margin-right: 2px;
      font-size: 14px;

      &:before {
        color: $color-negative-dark-3;
        font-size: 14px;
      }
    }

    .icon-text {
      color: $color-negative-dark-4;
      font-size: 13px;
    }
  }

  .content-icon {
    @extend .abs-filter-form-icon;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;

    .abs-icon {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: #ffffff;
      font-size: 27px;
    }
  }

  .hint {
    color: $color-neutral-60;
    font-size: 13px;
    text-align: center;
  }

  .btn-send {
    margin-top: 7px;
    width: 80px;
    transition: none;
  }

  @media (max-width: $mobile-mode-max) {
    height: auto;
    padding: 0 31px;
    width: 100%;
  }
}

.modal-open .file-request-modal {
  @media (max-width: $mobile-mode-max) {
    &.modal {
      .modal-dialog {
        min-width: 304px;
      }

      .modal-content {
        width: 304px;
        height: 552px;
      }
    }
  }
}

.modal-open .modal .modal-dialog .modal-content {
  .modal-body {
    font-size: 13px;
  }

  .modal-footer .btn {
    height: 26px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    padding: 0 12px;
    min-width: 70px;
    transition: none;
  }
}

.prompt-body-header {
  font-weight: 700;
}

.prompt-body-footer {
  margin-top: 10px;
}

.abs-modal-post-action {
  font-weight: 500;
}

.abs-modal-post-action__footer {
  background-color: #fafafa;
  border-top: 1px solid #e3e3e3;
  height: 42px;
}

.abs-modal-post-action__content {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35) !important;
  background-color: #ffffff !important;
  border: 2px solid #6F7779;
  border-radius: 0 !important;
}

.abs-modal-post-action__button {
  height: 26px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  padding: 0 12px;
  min-width: 70px;
}

.abs-modal-post-action__body {
  color: #000000;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.abs-modal-post-action__icon {
  color: #6F7779;
  display: block;
  font-size: 40px;
  margin-right: 16px;
}
