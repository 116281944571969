/* Lumen error page
   ========================================================================= */

.absui-error {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0 20px;

  &__panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    height: 100%;
  }

  &__header-one {
    color: $token-theme-color-neutral-10;
    font-size: 1.692rem;
    margin-bottom: 0;
    margin-top: 70px;
    text-align: center;
  }

  &__header-two {
    color: $token-theme-color-neutral-70;
    font-size: 1.385rem;
    margin-top: 10px;
    font-weight: normal;
    text-align: center;
  }

  &__desc {
    color: $token-theme-color-neutral-10;
    font-size: 1.231rem;
    margin: 10px 0 5px;
    text-align: center;
  }

  &__link {
    display: block;
    margin-top: 40px;
    font-size: 1.154rem;
    font-weight: bold;
    text-align: center;
  }
}