



@keyframes slideHorizontal {
       0%, 15%  {
        transform: translateX(-600%);
    }
    
    25%, 100% {
        transform: translateX(0%);
    }
}


@keyframes slide {
    0%  {
        transform: scale(1, 1) translateY(-800%);
    }
    
    15%, 70% {
        transform: scale(1, 1) translateY(-800%);
    }
    
    90% {
        transform: scale(1, 1) translateY(0%);
    }
    
    100% {
        transform: scale(1, 1) translateY(0%);
    }
}

@keyframes zoom {
    0%, 25%  {
        transform: scale(0, 0);
        display:none;
    }
    
    35%, 100% {
        transform: scale(1, 1);
        display:block;
    }
}

@keyframes size {
    0%, 15%  {
        transform: scale(1, 0);
    }
    
    25%, 100% {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}



.error-page .login-box.login-box-ihd{
	min-height:345px;
	@media (max-width: $abs-login-panel-width + 20) {			
		background: url("../../../img/error-panel-ihd-bg.png") -110px bottom $sub-nav-bg no-repeat;
	}		
	background: url("../../../img/error-panel-ihd-bg.png") -110px bottom $sub-nav-bg no-repeat;
}


.error-page{
	.login-box.login-box-ihd{
		padding:50px 0px 40px;

	}
	.error-panel{
		border:0px;		
		padding:50px 50px 50px 30px;
		width:660px;
		//margin:0px auto;
		float:right;

 	
	animation-name: zoom;            
            animation-duration: 4s;
            animation-iteration-count: 1;
          animation-delay:0s;
            
            


		h1{
			color:$abs-login-font-color;
			text-transform: none;
			margin:0px;
			padding:0px 0px 10px 0px;
			font-size:16px;			
			font-weight:bold;

			@media (max-width: $grid-float-breakpoint-max) {
				text-align:center;

			}	
		}
		p{
			width:360px;
			font-size:1em;
			color:$abs-login-font-color;
			@media (max-width: $grid-float-breakpoint-max) {
				text-align:center;
				width:auto;
				padding:0px 5px;				
			}	
		}

		.btn{
			float:left;
			margin-top:8px;
			min-width:110px;
			font-size:1em;
			@media (max-width: $grid-float-breakpoint-max) {
				text-align:center;
				float:none;
				margin:0px auto;
				display:block;
				white-space: 	normal;

			}	
		}



		.error-circle{
			@include border-radius(50%);
			//background:$error-cirlce-bg;
			background:$error-circle-bg;
			width:100px;
			height:100px;
			display:table;
			text-align:center;

			animation-name: pulse;
  			animation-duration: 1.5s; 
  			animation-timing-function: ease-out; 
  			animation-delay: 1s;
  			animation-direction: alternate;
  			animation-iteration-count: infinite;
  			animation-fill-mode: none;
  			animation-play-state: running;


    
			@media (max-width: $grid-breakpoint-desktop) {
				float:right;
			}
			@media (max-width: $grid-float-breakpoint-max) {
				margin:20px auto 20px auto;
				float:none;
				//margin:0px auto;
			}	

		}
		.error-circle >h1{
			display:table-cell;
			vertical-align: middle;
			color:$abs-login-font-color;
						
		}
		.error-circle >h1>span:nth-of-type(1){
			font-weight:normal;
			font-size:16px;
		}
		.error-circle >h1>span:nth-of-type(2){
			font-size:28px;
			font-weight:bold;
			display:block;
		}
		.error-circle >h1>span:only-of-type{
			font-size:28px;
			font-weight:bold;
			display:block;	
		}
	

		@media (max-width: $grid-float-breakpoint-max) {
			padding:10px 20px 30px 20px;
			//top:180px;
			width:100%;
			float:none;
			margin:0px auto;
		}	
	}	
}

