/* Lumen data table - general styles
   ========================================================================= */

%cell-styles {
  display: table-cell;
  height: 100%;
  padding: $token-table-cell-padding;
  border-bottom: 1px solid $token-table-border-color;
  text-align: left;
  vertical-align: top;
  position: relative;
}

.absui-data-table-header__cell {
  @extend %cell-styles;
  padding: $token-table-header-cell-padding;
  color: $token-table-header-text-color;
  font: $token-table-header-text-font;
}

.absui-data-table {
  display: table;
  width: 100%;
  font: $token-table-text-font;
  color: $token-table-cell-text-color;

  &__cell {
    @extend %cell-styles;

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      word-break: break-all;
    }

    &--mid {
      vertical-align: middle;
    }

    &--multi {
      padding: 0;
    }

    &--no-sep:first-child {
      border-right: none !important;
    }
  }

  &__cell-part {
    padding: 10px 12px;

    &:not(:last-child) {
      border-bottom: 1px solid $token-table-border-color;
    }
  }

  .absui-data-table__check-block {
    margin: 0 20px 0 0;
  }

  &__label {
    padding: 0;
    text-align: left;

    &--positive {
      color: $token-theme-color-text-positive;
    }

    &--negative {
      color: $token-theme-color-text-negative;
    }
  }

  .absui-data-table__label--mobile {
    display: none;
  }

  &__check-label {
    display: inline-block;
    height: 100%;
  }

  &__data {
    display: inline-block;
    flex-grow: 1;
    padding-left: 0;
  }

  &__hero {
    color: $token-table-header-text-color;
  }

  &__hero-title {
    font-weight: 600;
  }

  &__hero-panel {
    display: inline-flex;
    min-height: 48px;

    .absui-custom-control {
      margin-top: 2px;
    }

    .absui-dropdown {
      position: absolute;
      right: 10px;
      padding: 0;
    }
  }

  &__hero-info {
    display: block;

    &--alt {
      color: $token-theme-color-neutral-40;
    }
  }

  &__hero-dropdown {
    position: relative;
    margin-left: 10px;
    float: right;

    .absui-dropdown__menu {
      font-size: 1rem;
      min-width: 160px;

      .absui-icon {
        position: absolute;
        right: 5px;
        top: 10px;
      }
    }
  }

  &__amount {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;

    &--alt {
      color: $token-theme-color-neutral-40;
    }
  }

  &__amount-part {

    &:first-child {
      font-weight: bold;
    }

    &:last-child {
      margin-left: 3px;
    }
  }

  .absui-custom-control {
    padding-left: 7px;
    margin-bottom: 0;
  }

  .absui-loader::before {
    z-index: 1048;
  }

  input {
    height: auto;
  }
}

.absui-data-table-row {
  display: table-row;

  &.selected {
    background-color: $token-table-row-background-selected;
  }

  &:hover {
    background-color: $token-table-row-background-hover;
  }

  &:focus {
    box-shadow: 0 0 0 2px $token-form-element-border-color-focus inset;
  }

  &.open {
    background-color: $token-table-row-background-selected;
  }
}

.absui-data-table-header {
  display: table-row;

  .absui-data-table__check-block {
    padding: 0;
    float: left;
  }
}

.absui-data-table-dlist {

  &__item {
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  &__term {
    display: inline-block;
    text-align: right;
    word-break: break-all;
    width: 30%;
    padding-right: 10px;
  }

  &__def {
    display: inline-block;
    width: calc(70% - 10px);
    margin-bottom: 0;
  }
}

.absui-data-table--comfort {

  .absui-data-table__cell,
  .absui-data-table-header__cell,
  .absui-data-table--flex .absui-data-table-header__cell:first-child {
    padding: 14px 12px;

    @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
      padding: 0 12px 8px;
    }
  }

  .absui-collapse-content-row {
    padding: 16px 42px;
  }
}

.absui-data-table-body--scrolable {
   overflow-x: scroll;
 }

.absui-data-table-list {

  &__item--inline {
    display: inline-block;

    &:not(:first-child):before {
      color: $token-theme-color-neutral-70;
      content: " • ";
      font-size: 1.85rem;
      font-weight: bold;
      vertical-align: sub;
      line-height: 1rem;
      padding-left: 5px;
    }
  }
}

.absui-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__ico {
    font-size: $token-table-icon-size;
  }

  &__desc {
    display: block;
  }

  &__detailes {
    display: flex;
    flex-direction: row;
    align-items: center;

    .absui-icon {
      font-size: 1.15rem;
      padding-right: 5px;
    }
  }

  .absui-icon {
    padding-right: 10px;
  }
}

.absui-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    color: $token-link-text-color;
    font-weight: $token-font-weight-semibold;
    height: 40px;
    padding: 20px 0;
  }
}

.absui-table-scroll-wrap {
  overflow-x: auto;

  .absui-data-table {
    min-width: 992px - 96;
    //min-width: map-get($absui-max-containers, xl) - 64;
  }
}

.absui-table-pagination {
  display: flex;
  align-items: center;
  height: 56px;
  font: $token-table-text-font;

  &__pager {
    display: flex;
  }

  &__list {
    text-align: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: inline-block;
  }

  &__link {
    display: block;
    width: $token-button-height;
    height: $token-button-height;
    line-height: $token-button-height;
    text-align: center;
    color: $token-theme-color-text-neutral-stronger;
    border-radius: $token-button-radius;

    &:focus,
    &:hover,
    &.current {
      text-decoration: none;
      font-weight: $token-font-weight-semibold;
      background-color: $token-theme-color-neutral-85;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.35;
    }
  }

  &__first,
  &__last,
  &__back,
  &__next {
    font-size: $token-icon-size-s;

    .absui-icon {
      line-height: 34px;
    }
  }

  &__settings {
    display: flex;
    margin-right: 24px;
  }

  &__settings-label {
    line-height: 32px;
    margin-right: 8px;
  }

  .absui-dropdown__menu {
    width: auto;
    min-width: 40px;

    .absui-dropdown__link {
      text-align: center;
      padding: 5px 8px;
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__summary-label {
    line-height: 32px;
  }
}

.absui-sortable-icon {
  font-size: $token-table-icon-size;
  color: $token-theme-color-text-positive;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 2px;
  margin-right: 6px;
}

.sortable {
  cursor: pointer;
  user-select: none;

  .absui-sortable-icon {
    display: none;
  }
}

.sortable--up,
.sortable--down {
  .absui-sortable-icon {
    display: inline-block;
  }
}

.sortable--up {
  .absui-sortable-icon {
    transform: rotate(180deg);
  }
}

.filtered {
  border-radius: $token-radius-small;
  background-color: rgba($token-theme-color-neutral-10, 0.07);
  font-size: 12px;
  padding: 4px 6px;
  margin-left: 5px;
  font-weight: normal;
}

/* Lumen data table - mobile styles
   ========================================================================= */

.absui-data-table {
  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
    display: block;

    .absui-data-table-header {
      display: none;
      background-color: $token-theme-color-neutral-100;
    }

    .absui-data-table-body {
      overflow-x: auto;
    }

    .absui-data-table-row {
      display: block;
      //border-top: 2px solid $token-table-border-color;
      border-bottom: 1px solid $token-table-border-color;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }

    &__cell {
      display: block;
      padding-top: 0;
      padding-bottom: 12px;
      border-bottom: none;
      vertical-align: baseline;

      &:first-child {
        display: flex;
        padding: 12px;
      }

      &:last-child {
        padding-bottom: 20px;
      }

      &--multi {
        padding: 0 12px 8px;
      }
    }

    &__cell-part {
      padding: 0;
      border: none!important;
    }

    &__amount {
      justify-content: flex-start;
      width: auto;
    }

    &__label {

      &:before {
        display: inline-flex;
        font-weight: bold;
        color: $token-theme-color-neutral-20;
        padding: 0 5px 0 0;
        font-size: 100%;
        content: attr(data-label) ": ";
      }

      &--hidden:before {
        //visibility: hidden;
        display: none;
      }
    }

    &__check-block {
      padding: 23px 10px 10px 20px;
      margin: 0!important;
    }

    &__data {
      display: block;
      flex-grow: 1;

      .absui-data-table-hint {
        display: none;
      }
    }

    &__hero {
      padding: 15px 0 10px 10px;
      margin-bottom: 0;
    }

    &__hero-panel {
      z-index: 998;
      display: flex;
      height: 65px;
      width: 100%;
      border-top: 1px solid $token-table-border-color;
      border-bottom: 1px solid $token-table-border-color;

      &--no-drop {
        width: 100%;
        position: static;
      }
    }

    &__hero-title {
      font-size: 16px;
      display: block;
      position: relative;

      &:after {
        font-family: 'Lumen-Linear-Icon-Font';
        content: '\e318';
        color: $token-theme-color-neutral-70;
        text-decoration: none;
        position: absolute;
        right: 12px;
        top: -8px;
        font-size: 24px;
      }
    }

    &__hero-info {
      display: none;
    }

    .absui-dropdown {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin: 0;

      .absui-dropdown__menu {
        top: 64px;
        margin: 2px 0;
      }

      .absui-dropdown__link {
        white-space: nowrap;

        &:focus {
          box-shadow: none;
        }
      }

      .absui-btn {
        background-color: transparent;
        box-shadow: none;
        color: $token-theme-color-brand-20;
        border-radius: 0;
        border: 1px solid $token-table-border-color;
        padding: 13px 15px;
        justify-content: center;
        font-size: 18px;
        height: 65px;
        width: 72px;

        &:hover {
          border-color: $token-table-border-color;
        }
      }
    }

    .absui-dropdown-submenu {
      position: relative;

      .absui-dropdown__menu {
        width: 100%;
        top: 0;
        left: auto;
        right: 100%;
        margin-right: 10px;
      }
    }
  }
}

.absui-data-table-list {

  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
    &--portrait {
      .absui-icon-text__desc,
      .absui-icon-text__ico {
        display: none;
      }
    }

    &__item {
      display: inline-block;

      &:not(:first-child):before {
        color: $token-theme-color-neutral-70;
        content: " • ";
        font-size: 1.846rem;
        font-weight: bold;
        vertical-align: sub;
        line-height: 1rem;
        padding-left: 5px;
      }
    }
  }
}

.absui-data-table-dlist {

  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
    &__item:not(:first-child) {
      padding-top: 10px;
    }

    &__term {
      display: none;
    }
  }
}

.absui-icon-text {
  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
    display: inline-flex;

    &__ico {
      font-size: 1.385rem;
      display: inline-block;
      padding-right: 3px;
    }

    &__desc {
      display: inline-block;
    }

    .absui-data-table__label::before {
      display: none !important;
    }
  }
}

.absui-table-scroll-wrap {

  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
    .absui-data-table {
      min-width: 100%;
    }
  }
}

.absui-table-pagination {
  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {
    flex-direction: column;
    align-items: center;
    height: auto;

    &__summary {
      margin-top: 12px;
      margin-left: 0;
    }
  }
}