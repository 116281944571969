.recharts-wrapper {
  display: flex;
  align-items: center;
  width: auto !important;

  @media (max-width: $grid-float-breakpoint-max) {
    flex-direction: column;
    padding-top: 20px;
  }

  .recharts-pie-sector {

    .recharts-sector.selected {
      opacity: 0.8;
    }
  }

  .recharts-legend-wrapper {
    position: relative !important;
    width: auto !important;

    ul {
      list-style: none;
      margin: 0px;
      padding: 20px 0px 20px 5px;
      display: table;

      li {
        display: table-row;
        height: 24px;

        &.selected {
          font-weight: bold;
        }

        .chart-legend-item-point {
          display: table-cell;
          padding-right: 10px;

          span {
            @include radius(50%);
            width: 8px;
            height: 8px;
            display: inline-block;
            top: 3px;
            position: static;
          }
        }

        .chart-legend-item-name {
          display: table-cell;
        }

        .chart-legend-item-value {
          display: table-cell;
          text-align: right;
          padding-left: 15px;
        }
      }
    }

  }

  .recharts-tooltip-wrapper {
    .chart-tooltip-content {
      background: #000;
      border: none;
      @include radius(2px);
      color: #fff;
      font-size: 13px;
      padding: 6px;
    }
  }
}

.recharts-wrapper .recharts-legend-wrapper ul li span.chart-legend-item .chart-legend-item-point {
  top: 0;
}

.text-chart-panel .text-chart-container ul li > span:nth-of-type(1) {
  display: inline-block;
  position: static;
  width: auto;
  height: auto;
}

.text-chart-panel .text-chart-container ul li > span:nth-of-type(3) {
  margin-left: 0;
  float: none;
  width: auto;
  height: auto;

  > span {
    position: static;
    width: auto;
    height: auto;
  }
}

.text-chart-panel .text-chart-container ul li > span:nth-of-type(2) {
  padding-left: 0;
}

.text-chart-tile-panel {
  border: none;
  margin: 0;
  @extend .text-chart-panel;
  @include box-shadow(none);
  .text-chart-container .text-chart-item-tile {
    @extend .text-chart-item;
    margin: auto;
    @media (max-width: $screen-sm-min - 1px) and (min-width: $screen-xs-min) {
      width: 100%;
    }
    &:nth-child(1) {
      padding: 0;
      border: none;
      margin: auto;
      &:before {
        border: none;
      }
      &:after {
        border: none;
      }
    }
    ul {
      padding-top: 10px;
      li {
        margin: 4px 0px;
      }
    }
    .recharts-wrapper {
      @media (max-width: $screen-sm-min - 1px) {
        flex-direction: row;
        width: 100% !important;
        justify-content: center;
      }
      .recharts-legend-wrapper {
        @media (max-width: $screen-sm-min - 1px) {
          width: 60% !important;
        }
        .chart-legend-item {
          .chart-legend-item-value {
            @media (min-width: $screen-sm-min) {
              flex-direction: column;
            }
            @media (max-width: $screen-xs-min - 1px) {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

.chart-tile-container {
  width: 100%;
  .chart-tile-title {
    text-align: center;
    display: block;
    font-weight: 600;
    padding-top: 25px;
  }
}
