/* Lumen modal window
   ========================================================================= */

.absui-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $token-z-index-modal;
  visibility: hidden;
  outline: 0;
  opacity: 0;
  transform: translateY(-20%);
  animation: window-show $token-time-transition-fast forwards;

  &.hide {
    animation: window-hide $token-time-transition-fast forwards;
  }

  &__dialog {
    position: relative;
    width: auto;
    margin: 8px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - (0.5rem * 2));

    @include absui-breakpoint-up(sm) {
      width: $token-window-width;
      height: 100%;
      margin: auto;
    }
  }

  &__dialog--big {

    @include absui-breakpoint-up(lg) {
      width: $token-window-width-double;
    }

    @include absui-breakpoint-down(sm) {
      .absui-modal__body {
        overflow-y: auto;
      }
    }
  }

  &__header {
    display: flex;
    padding: $token-window-header-padding;
    border-top-left-radius: $token-window-radius;
    border-top-right-radius: $token-window-radius;
    align-items: center;
    justify-content: flex-start;
    //height: $token-window-header-height;
  }

  &__title {
    font: $token-window-title-font;
    color: $token-window-title-color;
    margin-bottom: 0;
    flex-grow: 2;
  }

  &__body {
    position: relative;
    flex: 1 1 auto;
    color: $token-window-text-color;
    font-size: 1rem;
    text-align: left;
    max-height: 100%;
    padding: $token-window-body-padding;
    overflow-x: hidden;
    overflow-y: auto;

    &--border {
      border-top: 1px solid $token-window-border-color;
      border-bottom: 1px solid $token-window-border-color;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    pointer-events: auto;
    outline: 0;
    overflow: hidden;
    background-color: $token-window-background;
    background-clip: padding-box;
    box-shadow: $token-window-shadow;
    border-radius: $token-window-radius;
  }

  &__content--icon {
    padding: 16px 16px;

    .absui-modal__body {
      margin-bottom: 12px;
      padding: 0 0 0 32px;
      position: relative;
    }

    .absui-modal__title {
      margin-bottom: 8px;
    }

    .absui-modal__footer {
      padding: 0;
    }
  }

  &__icon {
    color: $token-window-icon-info-color;
    font-size: $token-window-icon-size;
    display: block;
    padding: 2px;
    position: absolute;
    top: 0;
    left: 0;

    &--positive {
      color: $token-window-icon-success-color;
    }

    &--negative {
      color: $token-window-icon-danger-color;
    }

    &--warning {
      color: $token-window-icon-warning-color;
    }
  }

  &__info-details {
    margin-top: 10px;
    border: 1px solid $token-window-border-color;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $token-window-footer-padding;

    > :not(:first-child) {
      margin-left: 12px
    }

    .absui-btn {
      @extend %btn-equal;
    }
  }

  .absui-close {
    margin: -4px 0 0 0;
  }
}

.absui-modal--overflow {

  .absui-modal__content {
    overflow: visible;
  }

  .absui-modal__body {
    overflow: visible;
  }
}

@keyframes window-show {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes window-hide {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20%);
  }
}