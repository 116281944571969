.icon-initials {
  color: $color-neutral-0;
  @include font-style($roboto-font-family, 400, normal);
  font-size: 30px;
  font-style: normal;
  text-rendering: optimizeLegibility;

  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
