.abs-rwd-list.collapse-container {
  border: none;
}

.assecobs .collapse-container .abs-rwd-list-row {
  background: transparent;
  padding: 0;

  .collapse-row {
    border-top: none;
    padding: 10px 0;
    height: auto;

    .abs-rwd-list-col {
      align-self: center;

      &.col-main {
        display: flex;
      }
    }

    .collapse-panel-section.collapse-state-indicator {
      padding: 0 20px 0 0;
    }
  }
}

.collapse-row {
  box-sizing: border-box;
  border-top: 1px solid $color-neutral-8;
  background: $color-neutral-0;
  cursor: pointer;
  padding: 25px 0;

  @media (min-width: 1024px) {
    min-height: 90px;
  }

  &[aria-expanded="true"] {
    background-color: $abs-scheduler-header-bg;
  }

  .collapse-panel-section {
    padding: 0 20px;
    align-items: center;

    @media (max-width: $grid-breakpoint-desktop-large) {
      padding: 0 15px;
    }

    &:not(:last-child) {
      border-right: 1px solid $color-neutral-8;
    }

    &.collapse-state-indicator {
      border: none;
    }

    &.abs-agreement-main-data-section {
      padding: 0 15px;
      justify-content: space-between;
      flex-basis: 33%;
      .abs-dropdown {
        /*margin-left: 20px;*/
      }
    }

    .fa-angle-down {
      color: $color-brand-primary-dark;
      font-size: 18px;
      font-weight: bold;

      &.anim-down {
        transform: rotate(0deg);
        transition: transform .2s linear;
      }
    }

    &.collapse-state-indicator {
      padding: 0 10px;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }

  &.collapse-row-open {
    background: $color-neutral-2;
    .fa-angle-down.anim-up {
      transform: rotate(180deg);
      transition: transform .2s linear;
    }
  }

  // .limit-text-values {
  //     @media (max-width: $screen-md-min) {
  //         display: none;
  //     }
  // }

  .abs-limit-progress-values {
    flex-grow: 1;
    padding-right: 20px;
  }

  &.abs-base-agreement-row {
    border-top: none;
  }
}

.collapse-container {
  border-top: 1px solid $color-neutral-8;

  &:first-child {
    border-top: none;
  }

  > div:first-child {
    .collapse-row {
      border-top: none;
    }
  }

  .collapse, .collapsing {
    margin: 0 25px 0 50px;
    border-top: 1px solid $color-neutral-8;
  }

  .collapse-in-content {
    padding: 30px 0;

    .collapse-in-title {
      color: $color-neutral-60;
      font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto';
      font-weight: 700;
      font-style: normal;
      text-rendering: optimizeLegibility;
      text-transform: uppercase;
    }

    .collapse-in-data {
      margin-top: 10px;
      border: 1px solid $color-neutral-8;

      .collapse-row {
        cursor: auto;
      }

      .collapse-row:not(:last-child) {
        border-bottom: 1px solid $color-neutral-8;
      }
    }
  }

  .dt-col-1 dt {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .dt-col-1 dd {
    padding-top: 1px;
    padding-bottom: 1px;

    &.any-value {
      padding-left: 10px;
    }
  }
}

.collapse-row {
  .limit-text-values .dt-col-1 dt {

    &.amount-label-small {
      text-align: right;

      @media (max-width: $screen-lg-min) {
        display: block;
        width: 100%;
      }
    }
  }

  .limit-text-values .dt-col-1 dd {
    &.amount-value-small {
      text-align: right;

      @media (max-width: $screen-lg-min) {
        display: block;
        width: 100%;
      }
    }

    &.any-value {
      padding-left: 10px;
    }
  }
}

.collapse-in-data.collapse-container {
  .collapse-row {
    border-bottom: 1px solid $color-neutral-8;

    &:last-child {
      border-bottom: none;
    }
  }

}

.abs-md-list.style-a, .abs-md-list.style-b {
  .abs-def {
    display: flex;
    align-self: center;

    p {
      margin: 0;
      padding: 0;
      line-height: 1.428571429;
    }

    .abs-def_item {
      text-align: right;
      display: flex;
      flex-direction: column;

      .formatted-money {
        line-height: 1.428571429;
        display: inline-flex;
        justify-content: flex-end;
      }

      .formatted-money span {
        display: inline;
      }

      .abs-def_title {
        display: none;
      }
    }

    .abs-def--bold {
      font-weight: bold;
      padding-right: 15px;
    }

    .abs-def--left {
      text-align: left;
      align-self: flex-start;
    }
  }

  .abs-agreement-main-data {
    padding: 5px 5px;

    .abs-agreement-number {
      word-break: break-all;
    }

    .abs-agreement-number, .abs-agreement-currency {
      font-size: 16px;
      font-weight: bold;
    }

    .hint {
      line-height: 17px;
      color: $color-neutral-60;
      font-size: 13px;
    }
  }

  .abs-agreement-limit-section {
    justify-content: space-between;
    display: flex;
  }

  .progress-bar-info {
    background-color: $abs-progress-bar-info;
  }

  .abs-md-title {
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .progress {
    margin-bottom: 0;
    overflow: hidden;
    height: 12px;
    background-color: $abs-login-message-border-top;
    border-radius: 2px;
    box-shadow: none;
  }

  .progress-bar {
    box-shadow: none;
  }

  .progress-with-percent {
    display: flex;

    .progress-container {
      width: 70%;
    }

    .progress-percent {
      margin-left: 10px;
    }

    & + div {
      margin-top: 5px;
    }
  }

  .lite-text {
    color: $abs-currency-data;
  }

  .flex-self-start {
    &.frame-agreement-details {
      align-self: flex-start;
    }
  }

  .agreement {
    display: flex;
    align-items: center;

    .abs-dropdown {
      margin-left: 2em;
    }

    .abs-agreement-main-data {
      margin-left: 16px;
    }
  }

  .abs-dropdown {
    .btn {
      display: flex;
      align-items: center;
      padding-left: 7px;
      padding-right: 2px;
    }
  }

  .middle-btn {
    padding: 3px 10px;
    display: flex;
    align-items: center;
  }

  .dropdown-submenu {
    position: relative;

    .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -1px;
    }

    .dropdown-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;

      i {
        color: $flat-gray;
        font-size: 20px;
      }
    }
  }

  .definition {
    display: flex;

    .definition_col {
      margin: 0;
      line-height: 1.428571429;

      span {
        display: block;

        span {
          display: inline;
        }
      }
    }

    .definition_col-right {
      text-align: right;
    }

    .definition_col-label {
      margin-right: 10px;
      font-weight: bold;
    }

    .definition_col-left {
      text-align: left;
    }
  }

  div[aria-expanded="true"] {
    .collapse-panel-section {
      .anim-down {
        transform: rotate(-180deg);
        transition: transform .2s linear;
      }
    }
  }

  @for $i from 1 through 12 {
    .grid-parent-#{$i} {
      > * {
        flex-basis: 100% / $i;
        // flex-grow: 1;
      }
    }
  }

  .grid-child-auto {
    flex-basis: auto;
  }

  .abs_menu {
    &:before {
      font-size: 22px;
    }
  }
}

.abs-md-list {
  &.style-b {
    .collapse-row--xs {
      height: 100%;

      .collapse-panel-section {
        &:not(:last-child) {
          border-right: none;
        }
      }
    }

    .abs-title {
      font-size: 13px;
      font-weight: bold;
      text-transform: none;
      color: #333;
      background: transparent;
    }

    .abs-flex-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .collapse-in-data .abs-agreement-main-data-section {
      width: 20%;
    }

    .abs-agreement-main-data {
      display: flex;
      flex-direction: column;
    }
  }

  .abs-tab-data {
    display: block;
    overflow: auto;

    &_row {

    }

    &_cell {
      white-space: nowrap;
      vertical-align: top;

      &--right {
        text-align: right;
        padding-right: 10px;
      }

      &:first-child {
        font-weight: bold;
      }

      &:last-child {
        padding-bottom: 3px;
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .abs-md-list.style-a {
    .collapse-row {
      .limit-text-values .dt-col-1 dd {
        &.label-left {
          text-align: left;
        }
      }
    }
  }
}

.abs-header-upp {
  text-transform: uppercase;
  font-weight: 600;
}

// **************************************************************************
// **************************************************************************
// **************************************************************************
// SOME MEDIUM STYLES
// **************************************************************************
// **************************************************************************
// **************************************************************************

@media screen and (max-width: 1024px) {
  .abs-md-list.style-a, .abs-md-list.style-b {
    // .abs-def {
    //     display: none;
    // }
    .collapse-row {
      .abs-def-a.limit-text-values {
        dl.dt-col-1 {
          display: flex;

          dd.amount-value-small {
            text-align: left;
          }
        }
      }
    }

    .agreement-additional-data-section {
      align-items: flex-start;
    }
  }

  .abs-md-list {
    &.style-b {
      .collapse-in-data {
        .abs-agreement-main-data-section {
          width: 35%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .abs-md-list.style-a, .abs-md-list.style-b {
    .abs-def--hide {
      display: none;
    }
  }
}

// @media screen and (max-width: 768px) {
//     .abs-md-list.style-a {
//         .abs-agreement-main-data {
//             .abs-agreement-number, .abs-agreement-currency {
//                 font-size: 13px;
//             }
//         }
//         .abs-agreement-limit-section {
//             .limit-text-values {
//                 display: none;
//             }
//             .abs-def {
//                 display: none;
//             }
//         }
//         .collapse-container {
//             .collapse, .collapsing {
//                 margin: 0 25px 0 50px;
//                 border-top: 1px solid #ebebeb;
//             }
//         }
//     }
// }

// **************************************************************************
// **************************************************************************
// **************************************************************************
// MOBILE GOES HERE, BRO!
// **************************************************************************
// **************************************************************************
// **************************************************************************

@media (max-width: 767px) {
  .abs-md-list.style-a, .abs-md-list.style-b {
    .dt-col-1 {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .abs-md-list.style-b {
    .abs-tab-data {
      margin-left: 20px;
    }
  }
  .abs-md-list.style-a, .abs-md-list.style-b {
    .abs-def--hide {
      display: block;
    }

    .collapse-row {
      flex-direction: column;
      padding: 0 15px;
      border-top: 3px solid $color-neutral-20;

      .collapse-panel-section {
        align-items: flex-start;
        padding: 0;

        &.abs-agreement-main-data-section {
          width: 100%;
          padding: 25px 0;
          border-bottom: 1px solid $color-neutral-20;
        }

        &.abs-agreement-limit-section {
          width: 100%;
          margin-top: 25px;
          flex-direction: column;

          .limit-text-values {
            display: block;
            margin-top: 15px;
          }
        }

        &.agreement-additional-data-section {
          width: 100%;

          &:last-child {
            padding-bottom: 25px;
          }
        }

        &.collapse-state-indicator {
          justify-content: center;
          padding: 15px 0;
          order: 2;
        }

        &:not(:last-child) {
          border-right: none;
        }
      }

      .abs-def-a {
        &.limit-text-values {
          dl.dt-col-1 {
            display: block;
            padding: 0;

            dt.amount-label-small {
              text-align: left;
            }
          }
        }
      }

      .limit-text-values {
        dl.dt-col-1 {
          padding: 0;

          dt.amount-label-small {
            text-align: left;
            margin-top: 15px;
          }

          dd.amount-value-small {
            text-align: left;
          }
        }
      }
    }

    .abs-def {
      align-self: flex-start;

      .abs-def--visibility {
        display: none;
      }

      .abs-def_item {
        text-align: left;
        width: 100%;

        .abs-def_title {
          display: block;
          font-weight: bold;
          padding-top: 15px;
        }

        .formatted-money {
          justify-content: flex-start;
        }
      }
    }

    .collapse-container {
      .collapse, .collapsing {
        margin: 0 25px;
      }

      .dt-col-1 {
        dt {
          padding-top: 15px;
          width: 100%;
          text-align: left;
        }
      }
    }

    .abs-agreement-main-data-section-b {
      flex-direction: row;
      align-items: center;
    }

    .collapse-row {
      .abs-limit-progress-values {
        width: 100%;
      }
    }
  }

  .abs-md-list.style-b {
    .abs-agreement-main-data {
      position: absolute;
      height: 65px;
      width: calc(100% - 75px);
      border-bottom: 1px solid $container-box-border-color;
      display: flex;
      justify-content: center;
      flex-direction: column;

      span {
        margin-left: 20px;
      }
    }

    .abs-agreement-main-data--in {
      position: absolute;
      height: 65px;
      // width: calc(100% - 129px);
      width: calc(100% - 75px);
      border-bottom: 1px solid $container-box-border-color;
      display: flex;
      justify-content: center;
      flex-direction: column;

      span {
        margin-left: 20px;
      }
    }

    .hamburger--none {
      position: relative;
      width: 100%;
    }

    .collapse-row {
      padding: 0;

      .abs-limit-progress-values {
        margin-left: 20px;
        margin-right: 0;
      }

      .collapse-panel-section {
        &.abs-agreement-main-data-section {
          padding-bottom: 0;
          padding-top: 0;
          border-bottom: none;

          .abs-dropdown {
            margin-left: 0;
          }
        }

        &.abs-agreement-limit-section {
          > span {
            margin-left: 20px;
          }
        }
      }
    }

    .collapse-row--xs {
      height: auto;
    }

    .abs-def {
      align-self: flex-start;
    }

    .section-agreement {
      .section-agreement_dropdown {
        display: flex;
        justify-content: flex-end;
      }
    }

    .abs-dropdown {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 100%;

      .dropdown-menu {
        position: relative;
        width: 100%;
      }

      .btn {
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-type-d {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .abs-def_item {
      margin-left: 20px;
    }

    .hamburger {
      .dropdown-menu {
        top: 0;
        width: 100%;
        margin: 0;
        border: none;
        background-color: $forms-elements-mouseover-bg;
        border-bottom: 1px solid $container-box-border-color;
        box-shadow: none;

        li {
          margin: 0px 0px 0px 30px;
          border-bottom: 1px solid $window-title-border-color;
          padding: 0;

          &:last-child {
            border: none;
          }

          a {
            font-size: 13px;
            padding: 10px;

            &:hover {
              background-color: $flat-light-blue;
            }
          }
        }

        .dropdown-submenu {
          a {
            background-color: transparent;

            &:active, &:focus {
              border: none;
              outline: none;
            }
          }

          .dropdown-menu {
            left: 0;
            font-size: 13px;
            border-bottom: none;

            li {
              margin-left: 30px;
              border-bottom: 1px solid $window-title-border-color;

              &:first-child {
                border-top: 1px solid $window-title-border-color;
              }
            }
          }
        }
      }

      button {
        background-color: transparent;
        box-shadow: none;
        color: $btn-blue;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid $container-box-border-color;

        &.btn {
          padding: 13px 20px;
          display: flex;
          align-items: center;
          height: 65px;
          border-bottom: 1px solid $container-box-border-color;
        }
      }

      button[aria-expanded="true"] {
        background-color: transparent;
        box-shadow: none;
        color: $btn-blue;
        border-radius: 0px;
        border: none;
        border-left: 1px solid $container-box-border-color;
        border-bottom: 1px solid transparent;
        background-color: $forms-elements-mouseover-bg;

        &.btn {
          padding: 6px 20px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .abs-md-list {
    .abs-tab-data {
      &_row {

      }

      &_cell {
        display: block;
        text-align: left;

        &--right {
          text-align: left;
        }

        &:first-child {
          padding-top: 15px;
        }
      }
    }
  }
}


.abs-md-list.style-b .hamburger .dropdown.open.btn-group {
  width: 100%;
}

.abs-md-list.style-b .hamburger .dropdown-submenu .dropdown.open.btn-group {
  width: auto;
}

.abs-md-list.style-a .dropdown-submenu .dropdown-menu,
.abs-md-list.style-b .dropdown-submenu .dropdown-menu {
  margin-top: -3px;
  margin-left: 4px;
}

.abs-md-list.style-a .dropdown-submenu .dropdown-menu {
  bottom: auto;
}

.abs-header-upp {
  color: $color-neutral-60;
  font-weight: 500;
}

.collapse-row {
  padding: 0;

  .collapse-panel-section {
    display: flex;

    &.collapse-state-indicator {
      margin-left: 0;
      padding: 0 0 0 15px
    }

    &.abs-agreement-main-data-section {
      flex-basis: 25%;

      .abs-agreement-main-data {
        > .abs-agreement-currency {
          color: #797979;
        }

        .abs-agreement-currency {
          font-weight: 500;
        }
      }
    }

    &.agreement-additional-data-section.col-md-4 {
      align-items: flex-start;

      .abs-tab-data {
        margin-top: 19px;
      }
    }
  }
}

.abs-md-list.style-a {
  .progress-with-percent {
    .progress-container {
      margin-right: 12px;
    }

    .progress-percent {
      margin-left: 0;
    }
  }

  .abs-agreement-main-data-section.grid-child-auto {
    flex-basis: 34%;
  }

  .abs-md-title {
    padding-bottom: 0;
  }

  .progress-with-percent + div {
    margin-top: 0;
  }

  .abs-agreement-main-data .abs-agreement-number {
    font-weight: 500;
  }

  @media (max-width: 1023px) {
    .collapse-row .collapse-panel-section > .col-xs-10 {
      padding-left: 0;
    }
  }
}

.collapse-container {
  .collapse {
    margin: 0 25px 28px 25px; //for collapsing list (contractors example)
  }

  .collapsing {
    margin-left: 25px; //for collapsing list (contractors example)
  }

  .collapse-pack-row {
    .collapse {
      margin: 0 45px 45px 45px;
    }
  }

  .collapse-in-content {
    padding: 0;

    .collapse-in-data {
      margin-top: 0;
    }
  }
}