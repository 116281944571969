


%vertical-table-cell{
  display:table-cell;
  vertical-align:middle;
}

%vertical-center{
  @include flexbox();
  @include align-items(center);
  @include flex-direction(row);
  @include justify-content(flex-end);
}

.abs-alert{
  @include flexbox();
  @include align-items(center);
  @include flex-direction(row);

  min-height:48px;
  margin-bottom:15px;
  position:relative;
  padding:10px 15px 10px 10px;

  @media (max-width: $grid-float-breakpoint-max) {
    flex-wrap:wrap;
    padding:10px;
  }

  @media (max-width:   $grid-breakpoint-desktop-large) {
    margin-left:15px;
    margin-right:15px;
    //width:calc(100% - 30px);
  }


  button.close{
    position:absolute;
    top:10px;
    right:10px;
    opacity:1;
    span{
      padding:0px;
      &:hover{
        color:$color-neutral-100;
      }
    }
  }

  &.alert-message{
    margin-top:1em;
    margin-bottom:1em;
    > span{
      padding-left:128px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-left:10px;
      }
    }
    .browser-link{
      display:inline-block;
      margin-right:5px;
    }
    h1:nth-of-type(1){
      font-size:15px;
      margin: .5em 0 .7em 0;
      display: block;
      text-transform:uppercase;
    }
    &:before{
      font-size:6.4em;
      padding-left:.2em;
      @media (max-width: $grid-float-breakpoint-max) {
        display:none;
      }
      .k-ie &{
        font-size:80px;

      }
    }

    a{
      // border-style:dotted;
      border-width:0px 0px 1px 0px;
    }

  }
  span{
    //@extend %vertical-table-cell;

    color:$alert-font-color;
    padding:0px 0px 0px 45px;

    @media (max-width: $grid-float-breakpoint-max) {
      padding:10px;
    }
    .k-ie & {
      line-height:1.9em;
      flex-grow:1;
    }
  }
  span > strong{
    color:$alert-strong-font-color;
  }

  > div{
    text-align:right;
    flex-grow:1;
    @extend %vertical-center;

    a, button{
      margin-left:8px;
      //float:left;
    }
    .k-ie & {
      flex:none;
    }
  }

  &:before{
    //@extend %vertical-table-cell;
    font-family: 'Lumen-Icon-Font';
    font-size: 2.4em;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto 0px;
    /*display: table-cell;
    vertical-align: middle;*/
    width: 1em;
    height: 1em;
    line-height: 1;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
    @media (max-width: $grid-float-breakpoint-max) {
      display:none;
    }
  }

  &.alert-warning{
    background-color:$warning-bg-color;
    border-color:$warning-border-color;
    // a:not(.btn), h1{
    // 	color:$warning-link-color;
    // }
    &:before{
      content: "\e002";
      color:$warning-icon-color;
    }
    &.alert-message:before{
      color:$warning-message-icon-color;
      opacity:.3;
    }
  }
  &.alert-danger{
    background:$danger-bg-color;
    border-color:$danger-border-color;
    // a:not(.btn), h1{
    // 	color:$danger-link-color;
    // }
    &:before{
      content:"\e000";
      color:$danger-icon-color;
    }
    &.alert-message:before{
      color:$danger-message-icon-color;
      opacity:.3;
    }
  }
  &.alert-info{
    background:$info-bg-color;
    border-color:$info-border-color;
    // a:not(.btn), h1{
    // 	color:$info-link-color;
    // }
    &:before{
      content:"\e88e";
      color:$info-icon-color;
    }
    &.alert-message:before{
      color:$info-message-icon-color;
      opacity:.3;
    }
  }
  &.alert-success{
    background:$success-bg-color;
    border-color:$success-border-color;
    // a:not(.btn), h1{
    // 	color:$success-link-color;
    // }
    &:before{
      content:"\e86c";
      color:$success-icon-color;
    }
    &.alert-message:before{
      color:$success-message-icon-color;
      opacity:.3;
    }
  }
  &.alert-default{
    background:$default-bg-color;
    border-color:$default-border-color;
    // a:not(.btn), h1{
    // 	color:$default-link-color;
    // }
    &:before{
      content:"\e86c";
      color:$default-icon-color;
    }
    &.alert-message:before{
      color:$default-message-icon-color;
      opacity:.3;
    }
  }

  &.alert-loader{
    @include justify-content(center);
    text-align:center;
    padding:10px;
    &:before{
      content:none;
    }
    span{
      color:$alert-loader-font-color;
      display: inline-block;
      padding: 0px;
      background: url('../../../css/loader.gif') no-repeat 0px 0px;
      //background-size: 28px 28px;
      line-height: 28px;
      height: 28px;
      position:relative;
      vertical-align: middle;
    }
  }
}

.alert .loader-spin
{
  position: absolute;
  top: 0; left: -10px; bottom: 0; right: 0;
  margin: auto;
  width: 28px;
  height: 28px;
  background: url('../../../img/loader.png') no-repeat center center;
  background-size: 28px 28px;
  -webkit-animation: spinner-spin infinite linear 1s;
  -moz-animation:    spinner-spin infinite linear 1s;
  -o-animation:      spinner-spin infinite linear 1s;
  animation:         spinner-spin infinite linear 1s;


}

@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}


@-moz-keyframes spinner-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes spinner-spin {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}


.fw-alerts-box {
  background: $color-neutral-0;
}

.fw-alerts {
  max-height: 530px;
  overflow-y: hidden;

  &__title {
    background: $abs-card-header-bg-color;
    @include box-shadow(0 1px 0 0 $abs-card-header-border-bottom-color);
    height: 42px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    margin-bottom: 1px;
    color: $abs-card-header-color;
  }

  &__title-label {
    @include font-style($roboto-font-family, 700, normal);
    font-size: 16px;
    margin-right: 5px;
  }

  &__unread-count {
    background: $color-negative-dark-3;
    border-radius: 25px;
    color: $color-neutral-0;
    font-size: 11px;
    font-weight: bold;
    height: 14px;
    min-width: 14px;
    text-align: center;
    padding: 0 4px;
    @include flexbox;
    @include align-items(center);
  }

  &__toolbar {
    background: $color-neutral-0;
    @include box-shadow(0 1px 0 0 $color-neutral-11);
    height: 42px;
    @include flexbox;
    @include align-items(center);
    padding: 0 8px;
    margin-bottom: 1px;
  }

  &__list {
    background: $color-neutral-0;
    height: 435px;
    overflow-y: auto;

    &--hidden {
      overflow-y: hidden;
    }
  }

  &--read-indicator {
    @include radius(25px);
    height: 10px;
    width: 10px;

    &--on {
      background: $color-positive-dark;
    }

    &--off {
      background: $color-neutral-16;
      border: 1px solid $color-neutral-33;
    }
  }
}

.fw-alerts__toggle-show-unread-only-btn {
  @include flexbox;
  @include align-items(center);
  border: none;
  background: none;
  position: relative;
  outline: none;
  margin-left: 8px;
  padding-left: 16px;

  &[disabled] {
    @include opacity(.35);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 26px;
    width: 1px;
    background: $color-neutral-16;
  }

  .fw-alerts__toggle-show-unread-only-btn-label {
    color: $color-neutral-60;
    font-size: $font-size-small;
    margin-left: 8px;

    &:hover {
      color: $color-neutral-100;
    }
  }
}

.abs-toggle {
  display: inline-block;
  width: 14px;
  height: 6px;
  border-radius: 4px;
  position: relative;

  &--off {
    background: $abs-toggle-off-line-color;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.20);

    &:before {
      background: $abs-toggle-off-point-color;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.20);
      height: 10px;
      width: 10px;
      content: '';
      border-radius: 25px;
      position: absolute;
      left: -4px;
      top: -2px;
    }

    &:active {
      background: $abs-toggle-off-line-color-active;
    }

    &:hover:before, &:focus:before {
      background: $abs-toggle-off-point-color-hover;
    }

    &:active:before {
      background: $abs-toggle-off-point-color-active;
    }
  }

  &--on {
    background: $abs-toggle-on-line-color;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.30);

    &:before {
      background: $abs-toggle-on-point-color;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.30);
      height: 10px;
      width: 10px;
      content: '';
      border-radius: 25px;
      position: absolute;
      left: 8px;
      top: -2px;
    }

    &:active {
      background: $abs-toggle-on-line-color-active;
    }

    &:hover:before, &:focus:before {
      background: $abs-toggle-on-point-color-hover;
    }

    &:active:before {
      background: $abs-toggle-on-point-color-active;
    }
  }
}

.fw-alerts__alert-line {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 3px 0 0 2px;

  &:hover {
    background: $color-brand-primary-light;
    @include radius(2px);
  }
}

.fw-alerts__alert {
  position: relative;
  padding-left: 34px;

  &:before {
    content: '';
    @include radius(25px);
    height: 10px;
    width: 10px;
    background: #5CB85C;
    position: absolute;
    top: 4px;
    left: 14px;
  }

  &--read {
    &:before {
      background: $color-positive-fw-alerts;
    }
  }

  &--unread {
    &:before {
      background: $color-neutral-16;
      border: 1px solid $color-neutral-33;
    }
  }
}

.fw-alerts__alert-name {
  color: $color-neutral-100;
  font-size: $font-size-small;
  line-height: 16px;
}

.fw-alerts__alert-date {
  color: $color-neutral-60;
  font-size: $font-size-small;
  line-height: 16px;
}

.fw-alerts__show-details-icon {
  color: $color-neutral-60;
}

.fw-alerts-menu--unread:after {
  height: 8px;
  width: 8px;
  background: $color-negative-dark-3;
  border-radius: 25px;
  position: absolute;
  content: '';
  top: 12px;
  left: 15px;
}

.fw-alert {
  max-height: 530px;
  overflow-y: hidden;

  &__back-to-list {
    align-self: flex-start;
    max-width: 34px;
    min-width: 34px;
  }

  &__header {
    background: $color-neutral-2;
    @include box-shadow(0 1px 0 0 $color-neutral-11);
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    margin-bottom: 1px;
    padding: 6px 5px;
  }

  &__title {
    margin: 3px 0 0 5px;
  }

  &__name {
    @include font-style($roboto-font-family, 500, normal);
    color: $color-neutral-100;
    font-size: 16px;
    line-height: 19px;
  }

  &__date {
    color: $color-neutral-60;
    font-size: $font-size-small;
    line-height: 16px;
  }

  &__text {
    color: $color-neutral-100;
    font-size: $font-size-small;
    margin-top: 10px;
  }

  &__body {
    margin: 0 5px 5px 45px;
  }

  &__attribute {
    margin-top: 13px;
  }

  &__attribute-name {
    font-size: $font-size-small;
    font-weight: 700;
    color: $color-neutral-100;
  }

  &__attribute-value {
    font-size: $font-size-small;
    color: $color-neutral-100;
  }

  &__link {
    font-size: $font-size-small;
    margin-top: 13px;
    display: block;
  }
}

.fw-alerts__empty {
  height: 435px;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);

  .abs-icon {
    font-size: 40px;
    color: $color-neutral-16;
  }
}

.fw-alerts__empty-label {
  font-size: $font-size-small;
  color: $color-neutral-27;
}
