
@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= 150) {
    @return $token-theme-color-neutral-10;
  } @else {
    @return $token-theme-color-neutral-100;
  }
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function breakpoint-min($name, $breakpoints: $absui-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $absui-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@function breakpoint-next($name, $breakpoints: $absui-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}


// Lumen mixins =========================================================================

@mixin absui-breakpoint-up($name, $breakpoints: $absui-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin absui-breakpoint-down($name, $breakpoints: $absui-breakpoints, $comfort-mode: false, $rwd-mode: true) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @if ($rwd-mode) {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @media (max-width: $max) {
        .absui-rwd & {
          @content;
        }
      }
    }
    @if ($comfort-mode and $name == "sm") {
      .absui-comfort-mode & {
        @content;
      }
    }
  } @else {
    @content;
  }
}

@mixin absui-breakpoint-between($lower, $upper, $breakpoints: $absui-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include absui-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include absui-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin absui-breakpoint-only($name, $breakpoints: $absui-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include absui-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include absui-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin absui-breakpoint-down-test($name, $breakpoints: $absui-breakpoints, $no-rwd: false) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @if $no-rwd {
      @media (max-width: $max) {
        .container:not(.no-rwd) & {
          @content;
        }
      }
      .container.rwd & {
        @content;
      }
    } @else {
      @media (max-width: $max) {
        @content;
      }
    }
  } @else {
    @content;
  }
  //@include absui-breakpoint-down(sm, $absui-breakpoints, true)
  //.absui-test:after { content: "DEFAULT STYLES"; }
  //@media (max-width: 767.98px) { .container:not(.no-rwd) .absui-test:after { content: "RWD STYLES"; } }
  //.container.rwd .absui-test:after { content: "RWD STYLES"; }
}

@mixin absui-container() {
  width: 100%;
  padding-right: $absui-grid-gutter;
  padding-left: $absui-grid-gutter;
  margin-right: auto;
  margin-left: auto;
}

@mixin absui-container-max($max-widths: $absui-max-containers, $breakpoints: $absui-breakpoints) {
  @each $breakpoint, $container-max-width in $absui-max-containers {
    @include absui-breakpoint-up($breakpoint, $absui-breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin absui-col-ready() {
  position: relative;
  width: 100%;
  min-height: 1px; // Prevent collapsing
  //padding-right: ($absui-grid-gutter / 2);
  //padding-left: ($absui-grid-gutter / 2);
}

@mixin absui-col($size, $columns: $absui-columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin absui-font-style( $font-name, $font-weight, $font-style) {
  font-family: $font-name;
  font-weight: $font-weight;
  font-style: $font-style;
}

@mixin absui-borders($b-color, $b-top, $b-right, $b-bottom, $b-left ) {
  border-left: $b-left solid $b-color;
  border-top: $b-top solid $b-color;
  border-right: $b-right solid $b-color;
  border-bottom: $b-bottom solid $b-color;
}

@mixin vertical-center {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin absui-clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin absui-button($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
  }

  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }
}

@mixin absui-button-variant(
  $color,
  $background,
  $background-active: darken($background, 10%),
  $background-focus: darken($background, 5%),
  $background-hover: darken($background, 10%),
  $background-open: darken($background, 20%),
  $border: $background,
  $border-active: darken($border, 10%),
  $border-focus: darken($border, 5%),
  $border-hover: darken($border, 10%),
  $border-open: darken($border, 20%)
  ) {
  color: $color;
  background-color: $background;
  border: 1px solid $border;

  &:focus,
  &.focus {
    color: $color;
    border-color: $border-focus;
    background-color: $background-focus;
    outline: 0;
  }

  &:hover {
    color: $color;
    background-color: $background-hover;
    border-color: $border-hover;
  }

  &:active,
  &.active {
    color: $color;
    background-color: $background-active;
    border-color: $border-active;
    outline: 0;
  }

  .absui-dropdown.show > & {
    background-color: $background-open;
    border-color: $border-open;
    box-shadow: none;
  }
}