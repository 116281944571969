
%gradient-mask-left{
content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 100%;  
  bottom:0px;
  left:-30px;
    background: -webkit-linear-gradient(right, transparent , $sub-nav-bg, $sub-nav-bg); 
  background: -o-linear-gradient(right, transparent, $sub-nav-bg , $sub-nav-bg); 
  background: -moz-linear-gradient(right, transparent, $sub-nav-bg, $sub-nav-bg); 
  background: linear-gradient(to right, transparent , $sub-nav-bg , $sub-nav-bg); 
}





.sub-nav, .sub-nav-type-a {
    @media (min-width: $grid-breakpoint-desktop-large) {
    max-width:$grid-breakpoint-desktop-large;
    margin:0px auto;  
    //box-shadow: -1px 0 $container-box-shadow-color, 1px 0 $container-box-shadow-color;    
  } 
  @media (min-width: $grid-float-breakpoint-max) and (max-width:   $grid-breakpoint-desktop-large){
    padding-left:20px;
    padding-right:20px;
  }
  z-index: 1029;
  width:100%;
  //background: $sub-nav-bg;  
  background:transparent;
  //height:auto;
  min-height:40px;
  //margin-top:$abs-menu-height;
  //border-bottom:4px solid $sub-nav-border-color;

    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
      

    .to-right{
      @media (max-width:   $grid-breakpoint-desktop-large) {
        margin-right:0px;
       } 
    }
  .col-md-8, .col-md-4{
    padding:0px;
    // padding-left:5px;
    // padding-right:5px;
  }
  h1{
    display:inline-block;
    line-height: $sub-nav-height;
    color:$sub-nav-title-color;
    font-size:$sub-nav-title-font-size;
    font-weight:bold;
    padding:0px 15px 0px 0px;
    margin:0px;
    // @media (max-width:   $grid-breakpoint-desktop-large) {
    //   margin-left:20px;
    //  } 
    @media (max-width: $grid-float-breakpoint-max){     
        position: absolute;
        top: 0px;
        left: 0px;
    }
    

  }
}
.sub-nav-type-a{
    @include flexbox;
    @include justify-content(flex-start);
    @include align-items(center);
    h1{    
      margin:0px; 
      @media (max-width:   $grid-breakpoint-desktop-large) {
        margin-left:0px;
      }   
      @media (max-width: $grid-float-breakpoint-max){     
        position: relative;
        top: 0px;
        left: 0px;
      } 
    }
}    

.container-nav-a  .sub-nav{
  margin-top:0px;
}


.buttons-group {  
  margin-top:1px;
  &.buttons-group-right{
    float:right;
    li:first-child{
      padding-right:0px;
      
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding-right:10px;
    } 
  }
    &.buttons-group-left{
    float:left;
    li:last-child{
      padding-left:0px;
    }
        @media (max-width: $grid-float-breakpoint-max) {
      padding-left:10px;
    } 
  }
  padding:0px;
  margin-bottom: 0px;
  padding-bottom: 8px;
  padding-left: 0px;  
  position:relative;
  &.with-gradient{
    &:before{
      @extend %gradient-mask-left;
    }
  }  
  >li{
    list-style:none;
    float:right;    
    padding:0px 3px;
    margin:1px 0px 0px 0px;    
    &.separator{
      padding-right:10px;
      margin-right:5px;
      
    }     
  }  
  .btn > i{
    font-size:18px;
  }   

}


.sub-nav{
  .toolbar{        
    box-shadow:none;
    border:0px;    
    padding: 0px 0px 8px 10px;
    margin-bottom: 0px;    
    
    .k-button{
      float:right;
      line-height:1.55em;
      margin-top:8px;      
      
    }
    .k-button , .k-separator{
      //float:right;
    }
    .k-button-group{
      float:right;
    }
    .k-separator{
      display:none;
      @media (max-width: $grid-float-breakpoint-max){
        display:none;     
      } 
    }
  }
}




.back-icon{
  font-size:22px;
  margin-top:1px;
  margin-right:5px;
  // @media (max-width:   $grid-breakpoint-desktop-large) {
  //   margin-left:10px;
  // } 

  &.in-simple-view{
  float:left;
  margin-left:-15px;
  margin-top:20px;
  margin-right:20px;
  position:absolute;
    @media (max-width:   $grid-breakpoint-desktop-large) {
      margin-right:10px;
      margin-left:-5px;
    }     
    & + .simple-view{
      margin-left:15px !important;
    }
  }

  .toolbar-with-linked-modules &{
    float: left;
    margin-right: 10px;
    margin-top:10px;
    @media (max-width: $grid-float-breakpoint-max){
      margin-top:0px;
    } 
  }
}