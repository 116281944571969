.dropzone {
  width: 382px;
  height: 47px;
  margin: auto;
  border: 1px dashed $color-neutral-11;
  display: flex;
  align-items: center;
  @media (max-width: $mobile-mode-max) {
    width: 100%;
  }

  &.rejected-dropzone {
    border: 1px dashed #FF4D4D;
  }

  .dropzone-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
    width: 100%;
    &.dropzone-content__no-margin {
      margin-left: 0;
    }
    @media (max-width: 400px) {
      display: flex;
    }
    .btn-type-b {
      width: 110px;
      height: 24px;
      padding: 2px 6px!important;
      line-height: 14px;
    }
    .dropzone-content-desc {
      margin-left: 10px;
      color: $color-neutral-60;
    }

  }
}

.uploaded-file-content {
  border-left: 1px solid $color-neutral-11;
  border-right: 1px solid $color-neutral-11;
  border-bottom: 1px solid $color-neutral-11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;

  @media (min-width: $desktop-mode-min) {
    width: 382px;
  }

  .file-name {
    display: inline-block;
    margin-left: 15px;
    text-align: left;
    width: 280px;
    word-wrap: break-word;
  }

  .remove {
    cursor: pointer;
    color: #FF3300;
    margin-right: 15px;
    display: flex;
    align-items: center;
    .remove-title {
      margin-left: 5px;
    }
  }
}

.uploaded-files-content {

  border-left: 1px solid $color-neutral-11;
  border-right: 1px solid $color-neutral-11;
  border-bottom: 1px solid $color-neutral-11;
  text-align: initial;

  @media (min-width: $desktop-mode-min) {
    width: 382px;
  }

  .uploaded-file-position {
    padding: 5px 0;
  }

  .file-name {
    display: inline-block;
    margin-left: 15px;
    text-align: left;
    width: 280px;
    word-wrap: break-word;

    &--error {
      color: $color-neutral-60 !important;
    }
  }

  .file-error-message {
    margin-left: 15px;
    margin-top: 3px;
    font-size: 12px;
    color: $color-negative-dark-2;
  }

  .remove {
    cursor: pointer;
    color: #FF3300;
    margin-right: 15px;
    display: flex;
    align-items: center;
    .remove-title {
      margin-left: 5px;
    }
  }

  .uploaded-files-content-right {
    float: right;
  }

  .uploaded-files-content-line {
    margin: 0;
  }
}

.fw-dropzone-centered {
  @media (min-width: $desktop-mode-min) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.fw-dropzone-toast {
  padding-left: 15px;
  margin-bottom: 5px;
}

.fw-dropzone-toast-ul {
  color : $color-neutral-33;
}

.fw-dropzone-toast-li {
  color : $color-neutral-100;
}

.dropzone-container {
  border: none;
  margin: 0;
  width: 100%;
}

.dropzone-container--lumen {
  display: flex;
  align-items: center;

  & .absui-icon-help {
    top: unset !important;
    right: -46px !important;
    position: absolute !important;
    color: #FFD014;
  }
}

.dropzone-button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 100%;
  text-align: left;
}

.dropzone-button--lumen {
  order: 1;
  width: 110px;
  height: 24px;
  padding: 2px 6px!important;
  line-height: 14px;

  & .absui-icon {
    display: none;
  }

  & .absui-btn__title {
    line-height: 14px;
  }
}

.dropzone-list {
  text-align: left;
  margin: 0 !important;
  box-shadow: none !important;
  border-radius: unset !important;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;

  & .absui-file-upload__item {
    margin: 0;

    & .absui-file-upload__item-icon {
      display: none;
    }

    & .absui-file-upload__item-info {

    }
  }
}

.dropzone-upload-area {
  width: 382px;
  height: 47px;
  & .absui-file-upload__area {
    border: 1px dashed #e3e3e3;
    border-radius: 0;
  }
}

.dropzone-loader--lumen {
  margin-bottom: 15px;
}

.dropzone-item-title {
  color: #333 !important;
}
.dropzone-item--lumen:not(:first-child) {
  border-top: 1px solid #e3e3e3;
}

.dropzone-item--lumen {
  padding: 5px 16px !important;
}

.dropzone-item-title--error {
  color: #666 !important;
}

.dropzone-error-label {
  color: #ff4d4d !important;;
}

.dropzone-delete-label {
  color: #FF3300 !important;
  cursor: pointer;
}