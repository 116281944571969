.abs-filter-panel {
  background: $abs-filter-background;
  border: 1px solid $abs-filter-border;
  padding: 0px 40px 6px 15px;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  @media (max-width: $grid-breakpoint-desktop-large) and (min-width: $grid-float-breakpoint-max) {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding:0px 5px 6px 5px;
  }

  .abs-filter-panel-clean {
    background: $grid-filterable-bg;
    border-left: 1px solid $abs-grid-border-color;
    width: 46px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0;
    cursor: pointer;
    > i {
      font-size:22px;
      &:before {
        margin: auto;
        height: 22px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        width: 20px;
        color: $abs-filter-close-icon;
      }
    }
  }
  .abs-dropdown-form {
    float: left;
    padding: 8px 6px 3px 6px;
    @media (max-width: $grid-float-breakpoint-max) {
      float:none;
      width:94%;
      padding: 8px 10px 3px 6px;
    }
    &.abs-dropdown-form-activated {
      > .btn {
        background: $abs-filter-activated-bg;
        padding-right: 40px;
        &:after {
          right: 30px;
        }
        span:nth-of-type(2) {
          padding-right: 10px;
          white-space: pre;
        }
        @media (max-width: $grid-float-breakpoint-max) {
          padding-right:25px;
          &:after {
            right: 8px;
          }
        }

      }

      .abs-reset-filter {
        position: absolute;
        color: $abs-filter-close-icon;
        right: 7px;
        border-left: 1px solid rgb(185, 185, 185);
        height: 2rem;
        line-height:2.3rem;
        margin-top: 0;
        padding: 0 5px 0 5px;
        top: 10px;
        z-index:100;
        font-size: 1.154rem;
        @media (max-width: $grid-float-breakpoint-max) {
          border-left:0px;
          padding:0 7px;
        }
        > i {
          // margin-top: 6px;
          // line-height:27px;
          line-height:2rem;
          height:2rem;
        }
      }

    }
    &.open {
      > .btn {
        background: $abs-filter-open-bg;
        border-color: $abs-filter-open-border;
        &:after {
          transform: rotate(180deg);
          transition: .2s all ease-in-out;
        }
      }
    }
    > .btn {
      @include border-radius(2px);
      padding: 1px 25px 1px 10px;
      font-size: 1rem;
      border: 1px solid $abs-filter-btn-border;
      line-height: 1.846rem;
      position: relative;
      @media (max-width: $grid-float-breakpoint-max) {
        width:calc(100% - 30px);
      }
      &:hover {
        box-shadow: none;
        border-color: $abs-filter-btn-hover;
        color: $abs-filter-btn-hover;
        background: $abs-filter-btn-bg;
      }
      > span:nth-of-type(1) {
        // padding-right:15px;
        font-weight: bold;
      }
      &:after {
        display: inline-block;
        font: normal normal normal 14px/1 Lumen-Icon-Font;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\e313";
        right: 5px;
        position: absolute;
        font-size: 18px;
        color: $abs-filter-ico-color;
        top: 0;
        transition: .2s all ease-in-out;
        transform: rotate(0);
        line-height:2rem;
        height:2rem;

      }
      .abs-reset-filter {
        color: $abs-filter-close-icon;
      }

    }
    > .btn:nth-of-type(2) {
      @include radius(0px, 2px, 2px, 0px);
      border: 1px solid $abs-filter-btn-border;
      line-height: 24px;
      padding: 1px 10px;
      margin-left: -5px;
      background: $abs-filter-btn-bg;
      &:after {
        content: none;
      }
      i {
        color: $abs-filter-close-icon;
      }
    }
  }
  .abs-filter-form-container {
    width: 420px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    background: $abs-k-filter-bg;
    border: 2px solid $abs-filter-popup-border;
    padding: 0px;
    font-size: 13px;
    @media (max-width: $grid-float-breakpoint-max) {
      width:100%;
    }
    .abs-filter-form {
      padding-left: 15px;
      > input:nth-of-type(1) {
        width: 265px;
        border-radius: 0px 2px 2px 0px;
        margin-left: -1px;
      }
      > input:nth-of-type(2) {
        width: 180px;
        margin-top: 10px;
        border-radius: 0px 2px 2px 0px;
        margin-left: -1px;
      }
      .k-dropdown {
        float: left;
        &:nth-of-type(1) {
          width: 120px;
          .k-dropdown-wrap {
            border-radius: 2px 0px 0px 2px;
          }
          &:hover {
            z-index: 1;
          }
        }
        &:nth-of-type(3) {
          width: 120px;
          margin: 10px 0px;
          .k-dropdown-wrap {
            border-radius: 2px 0px 0px 2px;
          }
          &:hover {
            z-index: 1;
          }
        }
        &:nth-of-type(2) {
          width: 73px;
          clear: both;
          margin: 10px 12px 10px 0px;
        }

      }

    }
    .abs-filter-form-label {
      margin-bottom: 10px;
      font-size:  13px;
      font-weight: 700;
      padding: 10px 0 10px 15px;
      border-bottom: 1px solid $window-title-border-color;
      background: $window-title-bg;
      display: none;
    }
    .abs-filter-actions {
      background-color: $abs-filter-popup-footer-bg;
      border-top: 1px solid $abs-filter-popup-footer-border;
      margin-top: 10px;
      clear: both;
      padding: 3px 0 10px 10px;
      text-align: right;
      > .btn {
        width: auto;
        margin: .5em .2em 0 0;
        &:nth-of-type(2) {
          margin-right: .8em;
        }
        &:nth-last-of-type(1) {
          position: relative;
          display: inline-block;
          font: normal normal normal 14px/1 Lumen-Icon-Font;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          padding: 1px 5px;
          float: left;
          background: 0 0;
          border: 0;
          color: $abs-link-color;
          span {
            font-size: 0px;
          }
          &:before {
            content: "\f0b0";
            font-size: 18px;
            color: $abs-link-color;
          }
          &:after {
            content: "x";
            font-family: roboto, arial;
            font-weight: 700;
            font-size: 13px;
            position: absolute;
            top: 11px;
            right: 0;
          }
        }
      }
    }
  }
}

//ihd
#filterPanel {
  margin-bottom: 0px;
  position: absolute;
  top: 56px;
  z-index: 1;
  @media (max-width: $grid-float-breakpoint-max) {
    top: 68px;
  }
}

#issueList > .k-header.k-grid-toolbar {
  margin-bottom: 46px;
}

//zakres dat
.abs-filter-panel .abs-filter-form-container.abs-filter-calendar-complex {
  width: 865px;
  @media (max-width: $grid-float-breakpoint-max) {
    width:100%;
  }
  .abs-filter-form {
    .abs-filter-calendar-complex-options {
      float: left;
      width: 215px;
      padding: 20px 20px 20px 5px;
      @media (max-width: $grid-float-breakpoint-max) {
        width:100%;
        padding: 15px 20px 0px 5px;
        >label{
          width:100%;
          margin:5px 0px 10px 0px;
        }
      }
      .k-widget {
        width: 190px;
        margin: 0px 0px 10px 0px;
        @media (max-width: $grid-float-breakpoint-max) {
          width:100%;
          margin:0px;
        }
      }
      > label > span {
        margin-bottom: 4px;
        display: block;
      }
    }

    .abs-start-calendar, .abs-end-calendar {
      float: left;
      padding: 5px 0px 10px 20px;
      margin: 0px;
      border-left: 1px solid $window-title-border-color;
      width: 285px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0px 20px 0px 0px;
        width: calc(100% - 20px);
        padding: 0px 0px 0px 5px;
        border-left: 0px;
      }
      > div:nth-of-type(1) {
        margin: 10px 0px;
        width: 285px;
        @media (max-width: $grid-float-breakpoint-max) {
          width:100%;
          margin:5px 0px;
        }
        > .k-textbox {
          height: 2.1em;
          width: 105px;
          @media (max-width: $grid-float-breakpoint-max) {
            width:100%;
          }
        }
        > span {
          font-weight: bold;
          padding-right: 6px;
          @media (max-width: $grid-float-breakpoint-max) {
            display:block;
            text-transform: capitalize;
            margin-bottom:4px;
          }
        }
      }

      .k-widget.k-calendar {
        .k-footer {
          display: none;
        }
        @media (max-width: $grid-float-breakpoint-max) {
          display:none;
        }
      }

      .k-calendar {
        // height: 315px;
        // overflow:hidden;
        width: 285px;
        border: 0px;
        // padding-top: 41px;
        // margin-top: -35px;
        background: transparent;
        //border:1px solid $window-title-border-color;
        td.k-state-focused.k-state-selected {
          background: $k-calendar-bg;
        }
        td.k-state-selected {
          background-color: $k-calendar-selected-date-bg;
          border-color: $k-calendar-selected-date-bg;
        }
        .k-content .k-state-focused.k-state-selected .k-link {
          font-size: 13px;
          text-align: center;
          padding: .5em 0;
          background: $abs-menu-bg;
        }
        .k-state-focused.k-state-hover > .k-link {
          color: $k-calendar-link-focused;
        }

        .k-icon.k-i-arrow-w:before, .k-icon.k-i-arrow-e:before {
          color: $k-calendar-icons-arrow;
        }
        .k-content {
          border: 1px solid $window-title-border-color;
          border-width: 0px 1px 1px 1px;

        }
        // .k-footer{
        //     position:absolute;
        //     top:0px;
        //     right:0px;
        //     >.k-nav-today{
        //         border:0px;
        //         color:$abs-link-color;
        //         margin:3px 10px 0px 0px;;
        //         background:none;
        //         &:before{
        //             content: "\e91e";
        //             display: block;

        //             position: absolute;
        //             font: normal normal normal 14px/1 FontAwesome;
        //             left: -19px;
        //         }
        //     }
        // }
      }
      .k-calendar > .k-header {
        background: $window-title-bg;
        border: 1px solid $window-title-border-color;

        width: 99.3%;
        > .k-link, .k-link.k-state-hover {
          background: $window-title-bg;
          color: $k-calendar-link-color;
          font-weight: bold;
        }
        > .k-link.k-nav-prev, .k-link.k-nav-next {
          margin-top: .5em;
          span:before {
            color: $k-calendar-icons-arrow;
          }
        }
      }
    }
    .abs-start-calendar {
      margin: 0px 20px 0px 0px;
    }
    .abs-end-calendar {
      border-left: 0px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom:20px;
      }
    }

  }
}

.abs-filter-calendar-date-complex {

  &__calendar {
    float: right;
    line-height: 26px;
    padding-right: 10px;
    @media (max-width: $grid-float-breakpoint-max) {
      display:none;
    }
    &:before {
      content: '\e91e';
      font-family: Lumen-Icon-Font;
      margin-right: 6px;
    }
  }

  &__clear {
    float: right;
    line-height: 26px;
    margin-right: 6px;
    @media (max-width: $grid-float-breakpoint-max) {
      display:none;
    }
  }
}

// filtry podstawowe

.abs-filter-panel .abs-filter-form-container.abs-filter-input-simple {
  .abs-filter-form {
    margin-top: 12px;
  }
}

.abs-filter-panel .abs-filter-form-container.abs-filter-input-primitive {
  .abs-filter-form {
    margin-top: 12px;
  }
}

.abs-filter-panel .abs-filter-form-container.abs-filter-input-complex {
  .abs-filter-form {
    margin-top: 12px;
  }
}

//wybor jednokrotny

.abs-filter-panel .abs-filter-form-container.abs-filter-select-one {
  .abs-filter-form {
    padding-left: 0px;
    > div:not(.k-loading-mask):nth-of-type(1) {
      border-bottom: 1px solid $window-title-border-color;
      height: 46px;
      margin-top: 9px;
      padding-left: 15px;
      .abs-filter-form-select-search {
        display: inline;
        float: left;
        width: 95%;
      }
    }
    > ul {
      clear: both;
      margin: 0 15px 10px 0px;
      padding-left: 15px;
      padding-top: 10px;
      max-height: 195px;
      list-style: none;
      overflow-y: auto;

      > li {
        @include border-radius(2px);
        padding: 5px;
        margin-left: -5px;
        cursor: pointer;
        &:hover {
          background: $abs-k-filter-multicheck-item-hover-bg;
        }
      }
      > li > span {
        margin-bottom: 0px;
        width: 100%;
        height: 1.1em;
      }
    }
  }
}

//wybor wielokrotny z filterm
.abs-filter-panel .abs-filter-form-container.abs-filter-select-multi {
  .abs-filter-form {
    padding-left: 0px;
    > div:not(.k-loading-mask):nth-of-type(1) {
      border-bottom: 1px solid $window-title-border-color;
      height: 46px;
      margin-top: 9px;
      .abs-filter-form-check-all {
        display: inline;
        float: left;
        margin: 11px 15px 0px 15px;
        height: 1em;
      }
      .abs-filter-form-select-search {
        display: inline;
        float: left;
        width: 85%;
      }
    }
    > ul {
      clear: both;
      margin: 0 15px 0 0px;
      padding-left: 15px;
      padding-top: 10px;
      max-height: 195px;
      list-style: none;
      overflow-y: auto;

      > li {
        @include border-radius(2px);
        padding: 5px;
        margin-left: -5px;
        &:hover {
          background: $abs-k-filter-multicheck-item-hover-bg;
          cursor: pointer;
        }
      }
      > li > label {
        margin-bottom: 0px;
        width: 100%;
        height: 1.1em;
        cursor: pointer;
      }
      > li > label > input {
        display: inline;
        float: left;
        height: auto;
        margin: 3px 9px 0px 0px;
      }
      > li > label > span {
        display: inline;
        float: left;
        font-weight: normal;
        height: 1.1em;
        margin: 2px 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
      }
    }
  }
}

//konfigurator filtrów

.abs-element-chosen {
  color: $abs-link-color;
  &:before {
    content: '\e5ca';
    font-family: Lumen-Icon-Font;
    color: $abs-link-color;
    position: absolute;
    left: 3px;
  }
}

.abs-configure-filters {
  &:before {
    content: '\e8b8';
    font-family: Lumen-Icon-Font;
    color: $abs-link-color;
    position: absolute;
    left: 3px;

  }
}


.abs-filter-panel {

  background: $abs-filter-background;
  border: 1px solid $abs-filter-border;
  padding: 8px 15px;
  width: 100%;
  margin: 0;

  &.collapse {
    display: none;
  }

  input[type="checkbox"], .btn-submit {
    height: 24px;
    line-height: 24px;
    float: left;
  }

  .btn-submit {
    float: right;
    background: #027ebb;
    @include border-radius(2px);
    border: none;
    -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto-Regular', 'Roboto';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-align: center;
    height: 28px;
    color: #fff;
    padding: 0 20px;
    outline: none;

    &:hover {
      background: #00437e;
    }

    &:active {
      background: #00325e;
    }
  }

  .abs-filter-panel-clean {
    background: $grid-filterable-bg;
    border-left: 1px solid $abs-grid-border-color;
    width: 46px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    > i {
      font-size: 21px;
      &:before {
        margin: auto;
        height: 22px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        position: absolute;
        width: 20px;
        color: $abs-filter-close-icon;
      }
    }
  }
  .abs-dropdown-form, .r-ss-wrap {
    float: left;

    > .btn {
      @include border-radius(2px);
      padding: 0 25px 0 10px;
      font-size: 13px;
      background: $input-background-color;
      border: 1px solid $input-border-color;
      color: $input-text-color;
      line-height: 24px;
      position: relative;

      @media (max-width: $grid-float-breakpoint-max) {
        line-height: 28px;
      }

      &:after {
        color: $color-neutral-60;
        line-height: 24px;
        height: 24px;
        right: 2px;
        font-size: 20px;

        @media (max-width: $grid-float-breakpoint-max) {
          line-height: 28px;
          height: 28px;
        }
      }

      &:hover {
        box-shadow: none;
        background: $input-background-color-hover;
        border-color: $input-border-color-hover;
        color: $input-text-color-hover;
      }

      &:focus {
        background: $input-background-color-focus;
        border-color: $input-border-color-focus;
        color: $input-text-color-hover;
        @include box-shadow(0 0 5px 0 $input-shadow-color-focus);
      }

      &:active {
        background: $input-background-color-active;
        border-color: $input-border-color-active;
        color: $input-text-color-active;
        @include box-shadow(none);
      }

      > span:nth-of-type(1) {
        // padding-right:15px;
        font-weight: 500;
      }
    }

    &.open {
      > .btn {
        background: $input-background-color-active;
        border-color: $input-border-color-active;
        color: $input-text-color-active;
        @include box-shadow(none);
        &:after {
          transform: rotate(180deg);
          transition: .2s all ease-in-out;
          color: $color-neutral-60;

          @media (max-width: $grid-float-breakpoint-max) {
            color: $color-neutral-60;
            line-height: 28px;
            height: 28px;
            right: 3px;
          }
        }
      }
    }

    &.abs-dropdown-form-activated {
      > .btn {
        background: $abs-filter-activated-bg;
        border-right: 1px solid rgba(186, 186, 186, 0.4);
        padding-right: 20px;
        margin-right: 25px;
        z-index: 2;

        &:hover {
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;
        }

        &:focus {
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;
          @include box-shadow(0 0 5px 0 $abs-filter-activated-shadow-color-focus);
        }

        &:active {
          background: $abs-filter-activated-background-color-active;
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;
          @include box-shadow(none);
        }

        @media (max-width: $grid-float-breakpoint-max) {
          margin-right: 30px;
          padding-right: 25px;
        }

        &:after {
          right: 1px;

          @media (max-width: $grid-float-breakpoint-max) {
            line-height: 28px;
            height: 28px;
            right: 5px;
          }
        }
        span:nth-of-type(2) {
          padding-right: 0px;
        }
        .abs-reset-filter {
          position: absolute;
          color: $abs-filter-close-icon;
          right: 3px;
          border-left: 1px solid rgb(185, 185, 185);
          height: 27px;
          margin-top: -1px;
          padding: 0px 3px 0px 7px;

          > i {
            margin-top: 0px;
          }
        }
      }

      &.open {
        > .btn {
          background: $abs-filter-activated-background-color-active;
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;
          @include box-shadow(none);
          &:after {
            transform: rotate(180deg);
            transition: .2s all ease-in-out;
            color: $color-neutral-60;
          }
        }
      }

      .abs-reset-filter {
        cursor: pointer;
        z-index: 1;
        top: auto;
        right: 7px;
        font-size: 18px;
        padding: 0 3px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        background: $abs-filter-activated-bg;
        color: $abs-filter-close-icon;
        border: 1px solid $color-neutral-27;
        border-left-color: transparent;

        &:hover {
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;

          @media (max-width: $grid-float-breakpoint-max) {
            width: 31px;
          }
          z-index: 2;
        }

        &:focus {
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;
          @include box-shadow(0 0 5px 0 $abs-filter-activated-shadow-color-focus);

          @media (max-width: $grid-float-breakpoint-max) {
            width: 31px;
          }
        }

        &:active {
          background: $abs-filter-activated-background-color-active;
          color: $abs-filter-close-icon;
          @include box-shadow(none);

          @media (max-width: $grid-float-breakpoint-max) {
            width: 31px;
          }
        }

        > i {
          margin-top: 0;
          line-height: 18px;
          height: 18px;
        }

        @media (max-width: $grid-float-breakpoint-max) {
          width: 31px;
          height: 30px;
          margin: 0;
          padding: 0;
          text-align: center;
          top: 5px;
          right: 0;
        }
      }

    }

    > .btn:nth-of-type(2) {
      @include radius(0px, 2px, 2px, 0px);
      border: 1px solid $abs-filter-btn-border;
      line-height: 24px;
      padding: 1px 10px;
      margin-left: -5px;
      background: $abs-filter-btn-bg;
      &:after {
        content: none;
      }
      i {
        color: $abs-filter-close-icon;
      }
    }

    .r-ss-dropdown {
      z-index: 1031;
    }

    &.r-ss-selected {
      .r-ss-trigger {
        background: $grid-filterable-bg;
      }
    }
  }
  .abs-filter-form-container {
    width: 420px;
    background: $abs-k-filter-bg;
    border: 1px solid $color-neutral-33;
    @include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.35));
    @include border-radius(2px);
    padding: 0px;
    margin-top: -4px;
    margin-left: 6px;

    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 4px;
      margin-left: 0;
    }

    .abs-filter-form {
      padding-left: 15px;
      > input:nth-of-type(1) {
        width: 265px;
      }
      > input:nth-of-type(2) {
        width: 180px;
        margin-top: 10px;
      }
      .k-dropdown {
        float: left;
        &:nth-of-type(1) {
          width: 120px;
        }
        &:nth-of-type(3) {
          width: 120px;
          margin: 10px 0px;
        }
        &:nth-of-type(2) {
          width: 73px;
          clear: both;
          margin: 10px 12px 10px 0px;
        }

      }

    }
    .abs-filter-form-label {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      padding: 10px 0 10px 15px;
      border-bottom: 1px solid $window-title-border-color;
      background: $window-title-bg;
      display: none;
    }
    .abs-filter-actions {
      background-color: $abs-filter-popup-footer-bg;
      border-top: 1px solid $abs-filter-popup-footer-border;
      margin-top: 10px;
      clear: both;
      padding: 3px 0 10px 10px;
      text-align: right;
      > .btn {
        width: auto;
        margin: .5em 13px 0 0;
        &:nth-last-of-type(1) {
          position: relative;
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          padding: 1px 5px;
          float: left;
          background: 0 0;
          border: 0;
          color: $abs-link-color;
          span {
            font-size: 0px;
          }
          &:before {
            content: "\f0b0";
            font-size: 20px;
            color: $abs-link-color;
          }
          &:after {
            content: "x";
            font-family: roboto, arial;
            font-weight: 700;
            font-size: 14px;
            position: absolute;
            top: 11px;
            right: 0;
          }
        }
      }
    }
  }
  .abs-filter-panel-block {
    display: inline-flex;
  }
}

//ihd
#filterPanel {
  margin-bottom: -2px;
  position: absolute;
  top: 58px;
  z-index: 1;
}

#issueList > .k-header.k-grid-toolbar {
  margin-bottom: 44px;
}

//zakres dat
.abs-filter-panel .abs-filter-form-container.abs-filter-calendar-complex {
  width: 635px;

  .rc-calendar-prev-year-btn {
    display: none;
  }
  .rc-calendar-next-year-btn {
    display: none;
  }

  &--range {
    @media (max-width: 1200px) {
      right: 50%;
      left: auto;
      transform: translateX(50%);
    }
  }

  .abs-filter-form {
    padding-left: 0;

    .abs-filter-calendar-complex-options {
      float: left;
      width: 215px;
      padding: 20px 20px 20px 5px;
      .k-widget {
        width: 190px;
        margin: 0px 0px 10px 0px;
      }
    }

    .abs-start-calendar, .abs-end-calendar {
      float: left;
      width: 315px;
      margin: 0;
      border-left: none;
      > div:nth-of-type(1) {
        > a {
          float: right;
          line-height: 34px;
          padding-right: 10px;
        }
      }
      .k-widget.k-calendar {
        .k-footer {
          display: none;
        }
      }

      @media (max-width: $mobile-mode-max) {
        > div:nth-of-type(1) > .k-textbox {
          width: 85px;
        }

        .abs-filter-calendar-date-complex__clear,
        .abs-filter-calendar-date-complex__calendar {
          display: inline-block;
        }
      }

      .k-calendar {
        // height: 315px;
        // overflow:hidden;
        // width:285px;
        border: 0px;
        // padding-top: 41px;
        // margin-top: -35px;
        background: transparent;
        //border:1px solid $window-title-border-color;
        td.k-state-focused.k-state-selected {
          background: $k-calendar-bg;
        }
        .k-content .k-state-focused.k-state-selected .k-link {
          font-size: 1em;
          text-align: center;
          padding: .5em 0;
          background: $abs-menu-bg;
        }
        .k-state-focused.k-state-hover > .k-link {
          color: $k-calendar-link-focused;
        }

        .k-icon.k-i-arrow-w:before, .k-icon.k-i-arrow-e:before {
          color: $k-calendar-icons-arrow;
        }
        .k-content {
          border: 1px solid $window-title-border-color;
          border-width: 0px 1px 1px 1px;
        }
      }
      .k-calendar > .k-header {
        background: $window-title-bg;
        border: 1px solid $window-title-border-color;

        width: 99.3%;
        > .k-link, .k-link.k-state-hover {
          background: $window-title-bg;
          color: $k-calendar-link-color;
          font-weight: bold;
        }
        > .k-link.k-nav-prev, .k-link.k-nav-next {
          margin-top: .5em;
          span:before {
            color: $k-calendar-icons-arrow;
          }
        }
      }
    }
    .abs-start-calendar {
      border-left: 0;
      padding: 5px 10px 10px 20px;
    }
    .abs-end-calendar {
      border-left: 0;
      padding: 5px 20px 10px 10px;
    }

  }

  &--single {
    width: auto;
    .abs-filter-form {
      .abs-start-calendar {
        padding: 0;
        > div:nth-of-type(1) {
          margin: 0;
          width: 100%;
          > a {
            float: right;
            line-height: 34px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

// filtry podstawowe

.abs-filter-panel .abs-filter-form-container.abs-filter-input-simple {
  .abs-filter-form {
    margin-top: 12px;
  }
}

.abs-filter-panel .abs-filter-form-container.abs-filter-input-primitive {
  .abs-filter-form {
    margin-top: 12px;
  }
}

.abs-filter-panel .abs-filter-form-container.abs-filter-input-complex {
  .abs-filter-form {
    margin-top: 12px;
  }
}

//wybor jednokrotny

.abs-filter-panel .abs-filter-form-container.abs-filter-select-one {
  .abs-filter-form {
    padding-left: 0px;
    > div:nth-of-type(1) {
      border-bottom: 1px solid $window-title-border-color;
      height: 46px;
      margin-top: 9px;
      padding-left: 15px;
      .abs-filter-form-select-search {
        display: inline;
        float: left;
        width: 95%;
      }
    }
    > ul {
      clear: both;
      margin: 0 15px 10px 0px;
      padding-left: 15px;
      padding-top: 10px;
      max-height: 184px;
      list-style: none;
      overflow-y: auto;

      > li {
        @include border-radius(2px);
        padding: 5px;
        margin-left: -5px;
        &:hover {
          background: $abs-k-filter-multicheck-item-hover-bg;
        }
      }
      > li > span {
        margin-bottom: 0px;
        width: 100%;
        height: 1.1em;
      }
    }
  }
}

//wybor wielokrotny z filterm
.abs-filter-panel .abs-filter-form-container.abs-filter-select-multi {
  .abs-filter-form {
    padding-left: 0px;
    > div:nth-of-type(1) {
      border-bottom: 1px solid $window-title-border-color;
      height: 46px;
      margin-top: 9px;
      .abs-filter-form-check-all {
        display: inline;
        float: left;
        margin: 11px 15px 0px 15px;
        height: 1em;
      }
      .abs-filter-form-select-search {
        display: inline;
        float: left;
        width: 85%;
      }
    }
    > ul {
      clear: both;
      margin: 0 15px 0 0px;
      padding-left: 15px;
      padding-top: 10px;
      max-height: 184px;
      list-style: none;
      overflow-y: auto;

      > li {
        @include border-radius(2px);
        padding: 5px;
        margin-left: -5px;
        &:hover {
          background: $abs-k-filter-multicheck-item-hover-bg;
        }
      }
      > li > label {
        margin-bottom: 0px;
        width: 100%;
        height: 1.1em;
      }
      > li > label > input {
        display: inline;
        float: left;
        height: auto;
        margin: 3px 9px 0px 0px;
      }
      > li > label > span {
        display: inline;
        float: left;
        margin: 2px 0px 0px 0px;
        font-weight: normal;
      }
    }
  }
}

/*custom-fw-scss*/
.abs-filter-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  @media (max-width: $grid-float-breakpoint-max) {
    display: flex;
    flex-direction: column;
  }

  .abs-filter-panel__buttons {
    @media (max-width: $grid-float-breakpoint-max) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 21px;
      &--pull-right {
        justify-content: flex-end;
      }
      .btn-type-b {
        width: auto;
        height: 30px;
        margin-top: 10px;
      }
    }
  }

  .abs-dropdown-form {
    padding: 8px 6px;

    @media (max-width: $grid-float-breakpoint-max) {
      display: flex;
      flex-direction: column;
      float: none;
      padding: 5px;
      margin: 0;
    }

    .abs-filter-panel-dropdown-btn {
      z-index: auto;
      text-align: left;
      font-weight: 400;
      width: auto;
    }

    .abs-filter-form-container {
      @media (max-width: $grid-float-breakpoint-max) {
        width: 100%;
        position: relative;
      }
    }

    &:not(.abs-dropdown-form-activated) {
      .abs-filter-form-container {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          margin-right: 29px;
        }
      }
    }

    .abs-filter-form-container.abs-filter-input-simple {
      padding: 12px 15px;

      .abs-filter-text {
        width: 100%;
        outline: none;
      }
    }
  }

  &.collapse.in {
    display: flex;
  }

  .abs-filter-panel__filters {
    display: flex;
    flex-direction: column;
    flex: 1;

    .abs-filter-panel__additional-filters {
      @include box-shadow(0 1px 0 0 #d5d5d5 inset);
    }
  }

  .abs-filter-panel__buttons {
    display: flex;

    .abs-filter-panel__toggle-additional-filters {
      border: none;
      background: none;
      outline: none;

      .abs_angle_down {
        display: inline-block;
        transform: rotate(0deg);
        transition: transform .2s linear;
      }

      &.abs-filter-panel__toggle-additional-filters-shown {
        .abs_angle_down {
          transform: rotate(-180deg);
        }
      }
    }

    .abs-filter-panel__submit {
      display: flex;
      align-items: center;
      border: 0 solid #cecece;
      border-left-width: 1px;
      padding: 0 10px;

      @media (max-width: $grid-float-breakpoint-max) {
        border-left-width: 0;
        border-top-width: 1px;
        padding: 0;
      }

      .btn {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          border-radius: 0;
        }
      }
    }

    .abs-filter-panel__clear {
      border: 0 solid #cecece;
      border-left-width: 1px;
      color: $color-negative-dark-4;
      background: $color-warning-light-2;
      outline: none;
      font-size: 18px;
    }
  }

  .abs-filter-fields-container {
    display: flex;
    float: left;

    @media (max-width: $grid-float-breakpoint-max) {
      display: flex;
      flex-direction: column;
      float: none;
    }

    .loader > div {
      opacity: 0.35;
    }
  }

  .abs-filter-icon-btn {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    height: 42px;

    &:disabled {
      opacity: 0.35;
    }

    .abs-icon {
      display: flex;
      align-items: center;

      &.abs_connect, &.abs_disconnect {
        color: $color-brand-primary-dark;

        @media (max-width: $mobile-mode-max) {
          margin: 0 10px;
        }

        &:before {
          font-size: 20px;
        }
      }
    }
  }

  .abs-filter-number {
    display: flex;
    align-items: center;
    position: relative;

    input {
      text-align: right;
      outline: none;
    }

    .abs-filter-number-placeholder {
      right: 5px;
      position: absolute;
    }
  }

  .abs-filter-number-behind {
    input {
      padding-right: 35px;
    }
  }
  // rc-calendar
  .rc-calendar {
    @include radius(0);

    .rc-calendar-year-select, .rc-calendar-month-select, .rc-calendar-day-select {
      padding: 0 2px;
    }
  }

  .r-ss-wrap {
    float: left;

    &:not(.abs-dropdown-form-activated) .r-ss-trigger {
      background: $input-background-color;
      border: 1px solid $input-border-color;
      color: $input-text-color;
      @include radius(2px);
      padding: 0 3px 0 10px;

      &:hover {
        background: $input-background-color-hover;
        border-color: $input-border-color-hover;
        color: $input-text-color-hover;
      }

      &:focus {
        background: $input-background-color-focus;
        border-color: $input-border-color-focus;
        color: $input-text-color-hover;
        @include box-shadow(0 0 5px 0 $input-shadow-color-focus);
      }

      &.r-ss-open, &:active {
        background: $input-background-color-active;
        border-color: $input-border-color-active;
        color: $input-text-color-active;
        @include box-shadow(none);
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      float: none;
      margin: 0;
    }

    .r-ss-trigger {
      line-height: 24px;
      height: 26px;

      @media (max-width: $grid-float-breakpoint-max) {
        line-height: 28px;
        height: 30px;
      }

      &:hover {
        box-shadow: none;
      }

      &.r-ss-placeholder {
        font-weight: 500;

        @media (max-width: $grid-float-breakpoint-max) {
          margin: 0;
          justify-content: center;
        }
      }

      .abs-multiselect-filter__placeholder {
        padding-left: 10px;

        .abs-multiselect-filter__placeholder-title {
          font-weight: 500;
        }
      }
    }

    &.abs-dropdown-form-activated {
      .r-ss-trigger {
        background: $abs-filter-activated-bg;
        @include radius(2px);
        border: none;
        padding: 0;

        &:after {
          right: 30px;
        }

        .abs-multiselect-filter__placeholder {
          margin-right: 25px;
          padding-right: 20px;
          border: 1px solid $input-border-color;
          border-right: 1px solid rgba(186, 186, 186, 0.4);
          z-index: 1;
          outline: none;
          position: relative;
          @include radius(2px, 0, 0, 2px);

          &:before {
            @extend .abs-icon;
            @extend .abs_expand_more;

            color: $color-neutral-60;

            margin-left: auto;

            display: block;
            height: 24px;
            line-height: 24px;

            transform: rotate(0deg);
            transition: transform .2s linear;

            position: absolute;
            right: 0;

            @media (max-width: $grid-float-breakpoint-max) {
              height: 28px;
              line-height: 28px;
              right: 5px;
            }
          }

          @media (max-width: $grid-float-breakpoint-max) {
            margin-right: 30px;
            flex-grow: 1;
            padding-right: 0;
          }

          &:hover {
            color: $color-neutral-100;
            border: 1px solid $abs-filter-activated-border-color-hover;
          }

          &:focus {
            color: $color-neutral-100;
            border: 1px solid $abs-filter-activated-border-color-hover;
            @include box-shadow(0 0 5px 0 $abs-filter-activated-shadow-color-focus);
          }

          &:active {
            background: $abs-filter-activated-background-color-active;
          }
        }

        &.r-ss-open {

          .abs-multiselect-filter__placeholder {
            background: $abs-filter-activated-background-color-active;
            border: 1px solid $abs-filter-activated-border-color-hover;

            &:before {
              color: $color-neutral-60;
              display: block;
              transform: rotate(180deg);
              transition: transform .2s linear;
            }
          }
        }
      }

      .abs-reset-filter {
        position: absolute;
        color: $abs-filter-close-icon;
        right: 3px;
        border-left: 1px solid $input-border-color;
        height: 27px;
        margin-top: -1px;
        padding: 0px 3px 0px 7px;
        > i {
          margin-top: 6px;
        }
      }

      .r-ss-selection-clear {
        background: $abs-filter-activated-bg;
        position: absolute;
        color: $abs-filter-close-icon;
        right: 0;
        border: 1px solid $color-neutral-27;
        border-left: none;
        height: 26px;
        padding: 0 3px;
        outline: none;
        line-height: 5px;
        z-index: 2;
        @include radius(0, 2px, 2px, 0);
        > span {
          @extend .abs-icon;
          @extend .abs_close;
          &:before {
            font-size: 18px;
            margin-top: 5px;
          }
        }

        @media (max-width: $grid-float-breakpoint-max) {
          height: 30px;
          width: 30px;
        }

        + .carat {
          display: none;
        }

        &:hover {
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;

          @media (max-width: $grid-float-breakpoint-max) {
            width: 31px;
          }
        }

        &:focus {
          color: $color-neutral-100;
          border: 1px solid $abs-filter-activated-border-color-hover;
          @include box-shadow(0 0 5px 0 $abs-filter-activated-shadow-color-focus);

          @media (max-width: $grid-float-breakpoint-max) {
            width: 31px;
          }
        }

        &:active {
          background: $abs-filter-activated-background-color-active;
          color: $abs-filter-close-icon;
          @include box-shadow(none);

          @media (max-width: $grid-float-breakpoint-max) {
            width: 31px;
          }
        }
      }
    }

    .abs-reset-filter {
      z-index: 2;
    }

  }

  &.open {
    .btn {
      background: $abs-filter-open-bg;
      border-color: $abs-filter-open-border;
      &:after {
        transform: rotate(180deg);
        transition: .2s all ease-in-out;
      }
    }
  }
  > .btn {
    @include border-radius(2px);
    padding: 1px 25px 1px 10px;
    font-size: 13px;
    border: 1px solid $abs-filter-btn-border;
    line-height: 24px;
    position: relative;

    > span:nth-of-type(1) {
      // padding-right:15px;
      font-weight: bold;
    }
    &:after {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f107";
      right: 8px;
      position: absolute;
      font-size: 17px;
      color: $abs-filter-ico-color;
      top: 4px;
      transition: .2s all ease-in-out;
      transform: rotate(0);

    }
    .abs-reset-filter {
      color: $abs-filter-ico-color;
    }

  }
  > .btn:nth-of-type(2) {
    @include radius(0px, 2px, 2px, 0px);
    border: 1px solid $abs-filter-btn-border;
    line-height: 24px;
    padding: 1px 10px;
    margin-left: -5px;
    background: $abs-filter-btn-bg;
    &:after {
      content: none;
    }
    i {
      color: $abs-filter-close-icon;
    }
  }

  .r-ss-dropdown {
    margin-left: 0;

    @media (max-width: $grid-float-breakpoint-max) {
      position: relative;
      width: 100%;
      margin: 0;
    }
  }

  &.r-ss-selected {
    .r-ss-trigger {
      background: $grid-filterable-bg;
    }
  }
}

.abs-filter-panel__toggle {
  padding: 1px;
  width: 40px;
  position: relative;
}

.abs-filter-panel__toggle--open {
  background: $color-neutral-100;
  border-color: $color-neutral-100;
}

.abs-filter-panel__toggle--submitted {

  &:after {
    border: 1px solid #FDEB76;
    content: '';
    display: block;
    position: absolute;
    width: 36px;
    bottom: 1px;
    left: 0px;
    right: 0px;
  }
}

.abs-filter-panel__main-filters, .abs-filter-panel__additional-filters {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $grid-float-breakpoint-max) {
    display: block;
  }
}

.abs-filter-range-from, .abs-filter-range-to {
  display: flex;
  align-items: center;
  padding: 15px 15px 10px 10px;

  .abs-filter-range-label {
    padding-right: 5px;
    font-weight: bold;
  }

  .abs-filter-number, .abs-filter-text {
    width: 100%;
  }
}

.abs-table-bar {
  width: 100%;
  min-height: 40px;
  height: auto;
  border: 1px solid $color-neutral-20;
  background-color: $color-neutral-4;
  border-radius: 2px;
  margin-bottom: 10px;

  &--collapsed {
    background-color: $color-neutral-8;
    padding-bottom: 11px;
    .abs-rotate {
      transform: rotate(180deg);
    }
    .abs-table-bar__main-panel {
      background: $color-neutral-8;
      @include box-shadow(0 1px 0 $color-neutral-16);
    }
  }
  &--show-active {
    background-color: $color-warning-light-2;
  }
  &__main-panel {
    @include flex-align-items-center();
    justify-content: space-between;
    height: 40px;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    &__info {
      @include flex-align-items-center();
      color: $color-neutral-60;
      > span {
        margin-right: 5px;
      }
    }
  }
  &__title {
    color: $color-neutral-100;
    font-size: 13px;
    font-weight: bold;
  }
  &__children-panel {
    overflow: hidden;

    .abs-filter-panel {
      background: transparent;
      border: none;
      height: auto;
      &__filters {
        padding-bottom: 7px;
        margin-right: 21px;
        margin-left: 21px;
        margin-top: 15px;
        border-bottom: 1px solid $color-neutral-20;
      }
      &--sortable {
        margin-top: 20px;
        .abs-filter-panel__filters {
          border-bottom: none;
        }
      }
      .abs-filter-panel__buttons .abs-filter-panel__clear {
        width: 34px;
        height: 30px;
        margin-left: 21px;
        border: 1px solid $color-neutral-27;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 18px;
        }
      }
      .abs-dropdown-form {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
      .r-ss-wrap {
        .r-ss-dropdown {
          margin-top: 5px;
        }
        .r-ss-trigger {

        }
      }
    }
  }
}

.abs-bar-item {
  height: 30px;
  width: 100%;
  background: $color-neutral-0;
  border: 1px solid $color-neutral-27;
  border-radius: 2px;
  @include flex-space-between();
  align-items: center;
  padding-left: 8px;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &__title {
    color: $color-neutral-80;
    font-weight: 500;
  }
  &__arrows {
    display: flex;
    height: 100%;
    &__arrow {
      @include flex-all-center();
      width: 30px;
      border-left: 1px solid $color-neutral-11;
      cursor: pointer;
      i {
        font-size: 18px;
        color: $color-neutral-46;
      }
    }
  }
  &--active {
    border-color: $color-positive-dark;
    .abs-bar-item__title {
      color: $color-neutral-100;
      font-weight: bold;
    }
    .abs-bar-item__arrows__arrow--active {
      background-color: $color-positive-light;
      i {
        color: $color-positive-dark;
      }
    }
  }
}

.abs-sorting-bar-info {
  margin-right: 5px;
  i {
    color: $color-positive-dark;
    font-size: 18px;
    margin-right: 7px;
  }
  span {
    color: $color-neutral-60;
    font-weight: 500;
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .contractor-filter-wrapper {
    display: flex;

    .abs-filter-icon-btn {
      background: #fff;
      height: 30px;
      margin: 5px 10px 5px 0;
      background: $input-background-color;
      border: 1px solid $input-border-color;
      color: $input-text-color;

      &:hover {
        background: $input-background-color-hover;
        border-color: $input-border-color-hover;
        color: $input-text-color-hover;
      }

      &:focus {
        background: $input-background-color-focus;
        border-color: $input-border-color-focus;
        color: $input-text-color-hover;
        @include box-shadow(0 0 5px 0 $input-shadow-color-focus);
      }

      &:active {
        background: $input-background-color-active;
        border-color: $input-border-color-active;
        color: $input-text-color-active;
        @include box-shadow(none);
      }

      + div {
        flex-grow: 1;
      }
    }
  }
}
