
.iphone input:not([type=checkbox]):not([type=radio]) {
	-webkit-appearance: none;
}


.form-horizontal{
	.control-label{
		color:$abs-form-label-color;
		font-size:$abs-form-label-size;
		font-weight:bold;
	}

	.control-checkboxes{
		margin-bottom: 0;
		padding-top: 7px;
		.k-checkbox-label,label{
			color:$forms-checkbox-color;
			font-weight:normal;
			padding-right:6px;
			padding-left:22px;

		}
	}



	.control-radio{
		margin-bottom: 0;
		padding-top: 7px;

	}

	.required:after{
		content:"*";
		color:$forms-required-color;
		position: absolute;
		right: 0px;
		font-size: 1.4em;
		top: 2px;
		right:4px;
		@media (max-width: $grid-float-breakpoint-max){
			top:-2px;
		}

	}
	.form-group{
		@media (max-width: $grid-float-breakpoint-max){

		}
	}
	.form-group input , .form-group .k-dropdown{
		@media (max-width: $grid-float-breakpoint-max){
			width:100%
		}
	}

}

.k-checkbox-radio,.k-radio-label{
	color:$abs-form-input-color;
	font-weight:normal;
	padding-right:6px;
	padding-left:22px;
	padding-top:1px;
	&:before{
		width:16px;
		height:16px;
	}
	&:after{
		background-color:$forms-checkbox-color;
	}
	&:hover{
		&:before{
			border-color: $forms-elements-hover-color;
		}
		&:after{
			//border-color: $forms-elements-hover-color;
			//color:$forms-elements-hover-color;
			background:$forms-elements-hover-color;
		}
	}
}


//ihd setting
.abs-col-1.settings{


	@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
		border-left:0px;
		border-right:0px;
	}


	&.container{
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
			width:100% ;
		}
	}


	.add-notification{
		padding:0px 0px 0px 0px;
		margin:10px 0px 20px 20px;
		cursor:pointer;
		.fa-plus{
			margin-right:6px;
			@include field-icon-style($edit-icon-color);
		}
	}
	.abs-grid{
		&.k-grid table{
			@media (max-width: $grid-float-breakpoint-max){
				background:$forms-element-bg;
			}
		}
		margin:15px 20px;
		.k-button.k-grid-delete , .k-button.k-grid-cancel{
			@include field-icon-style($delete-icon-color);
			//@extend %delete-field-icon-style;	
			@extend %lumen-icon-font-placeholder;
			font-size:0px;
			margin-left:2px;
			margin-right:2px;
			span{
				display:none;
			}
			&:before{
				font-size:13px;
				content: "\f00d";
			}
			&:focus{
				@include box-shadow(0px 0px 5px 0px $forms-validation-border-color);
			}
		}
		.k-button.k-grid-edit{
			@include field-icon-style($sub-nav-bg);
			@extend %lumen-icon-font-placeholder;
			margin-left:2px;
			margin-right:2px;
			font-size:0px;
			span{
				display:none;
			}
			&:before{
				font-size:13px;
				content: "\f040";
			}
		}
		.k-button.k-grid-update{
			@include field-icon-style($status-zaakceptowany);
			@extend %lumen-icon-font-placeholder;
			margin-left:2px;
			margin-right:2px;
			font-size:0px;
			span{
				display:none;
			}
			&:before{
				font-size:13px;
				content: "\e5ca";
			}
		}
		.k-grid-edit-row>td>.k-textbox{
			@media (max-width: $grid-float-breakpoint-max){
				width:95%;
				margin-bottom:4px;
			}
		}
	}
	h2{
		margin-bottom:20px;
	}
	.form-horizontal{
		margin-top:30px;
		margin-bottom:10px;
		@media (max-width: $grid-float-breakpoint-max){
			margin-top:20px;
			margin-bottom:0px;
		}
	}
	.form-horizontal .form-group{
		@media (max-width: $grid-float-breakpoint-max){
			border-top: 2px solid $abs-body-desktop-bg;
			padding-top: 20px;
			margin-bottom:0px;
		}
	}
	.form-horizontal:nth-of-type(1) .form-group{
		@media (max-width: $grid-float-breakpoint-max){
			border-top:0px;
		}
	}
	.control-label{
		padding-left:45px;
		padding-right:0px;
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
			padding-left:5px;
		}
		@media (max-width: $grid-float-breakpoint-max){
			width:100%;
			padding-left:0px;
		}
		> span{
			@extend %ellipsis;
			display:block;
			margin:15px auto 0px;
			font-size:14px;
			text-align:center;
			@media (max-width: $grid-float-breakpoint-max){
				display: block;
				margin: 8px 0px 0px 15px;
				font-size: 14px;
				text-align: left;
				float: left;
			}
		}
		.svg-icon{
			//	width:50%;
			//	height:50%;
			height:75px;
			margin:0px auto;
			display:block;
			@media (max-width: $grid-float-breakpoint-max){
				height: 35px;
				margin: 0px 0px 0px 15px;
				float: left;
			}


		}
		.svg-icon-email{
			width:82px;
			@media (max-width: $grid-float-breakpoint-max){
				width:29px;
			}
		}
	}
	.col-sm-8.col-md-6{
		margin-top:26px;
		@media (max-width: $grid-float-breakpoint-max){
			margin-top:16px;
		}
		@media (min-width: $grid-float-breakpoint-max + 1){
			padding-left:10px;
			padding-right:20px;
		}
	}
	.added-field{
		border-bottom:1px solid $hr-color;
		padding:15px 0px 30px;
		&:nth-of-type(1){
			margin-top:-26px;
		}
		p {
			@extend %ellipsis;
			color:$abs-form-input-color;
			padding:0px;
			float:left;
			width:75%;
		}
		.delete-field , .edit-field{
			float:right;
			display:block;
			margin-left:4px;

		}

		.help-info{
			margin:10px 0px 25px;
		}
		.help-info span{
			color:$info-text-color;
			font-size:.85em;
			margin-left:3px;
		}
		.help-info i{
			color:$info-yellow-color;
			font-size:1em;
			vertical-align:middle;

		}
	}

	.delete-field{
		@include field-icon-style($delete-icon-color);
	}
	.edit-field{
		@include field-icon-style($edit-icon-color);
	}
	.add-comment{
		@media (max-width: $grid-float-breakpoint-max){
			padding:20px 10px 0px 0px;
		}


		.fa-pencil , .fa-plus{
			@include field-icon-style($edit-icon-color);
		}
	}
}


//help info
.help-info{
	margin:10px 0px 10px;
	span{
		color:$info-text-color;
		font-size:.85em;
		margin-left:3px;
	}
	i{
		color:$info-yellow-color;
		font-size:1em;
		vertical-align:middle;
	}
}

.panel-add-field{
	@include border-radius(2px);
	@include borders($hr-color, 1px, 1px, 1px ,1px);
	padding:0px 0px 0px 0px;
	position:relative;
	margin-top:20px;
	clear:both;

	#passwordChangeMessagePanel , #addEmailMessagePanel , #addPhoneMessagePanel, #observationMessagePanel{
		margin:0px 0px 20px 0px;
	}

	.validation-header{
		width:auto;
		margin:-1px -1px 0px -1px;
		@media (max-width: $grid-float-breakpoint-max){
			margin:-1px 0px 0px 0px;
		}
		i{
			width:50px;
			float:left;
		}
		h1{
			float:left;
			width:calc(100% - 50px);
		}
	}
	input{
		margin-bottom:10px;
		/*&:last-of-type{
			margin-bottom:45px;
		}*/
	}
	.k-dropdown{
		width:100%;
		margin-top:10px;
		margin-top:20px;
		/*&:last-of-type{
			margin-bottom:45px;
		}*/

	}

	.abs-title{
		margin:20px 20px 20px 20px !important;
	}
	div:not(:last-of-type){
		margin:0px 20px 10px 20px;
	}
	.panel-actions{
		//position:absolute;
		//bottom:0px;
		//left:0px;
		height:44px;
		background:$abs-container-box-bg;
		padding:10px 20px;
		width:100%;
		button{
			float:right;
			&:nth-of-type(2){
				margin-right:10px;
			}
		}
	}

}

.col-sm-12 .panel-add-field-12 {
	.col-sm-6{
		@media (max-width: $screen-md-min) {
			width:100%;
		}
	}
	.col-sm-10{
		@media (max-width: $screen-lg-min){
			width:78%;
		}
	}
}

.panel-add-field-12{
	@include border-radius(2px);
	@include borders($hr-color, 1px, 1px, 1px ,1px);
	position:relative;
	margin:0px 20px;
	padding:0px 0px 60px 0px;
	.validation-header{
		width:auto;
		margin:-1px -1px 0px -1px;
		@media (max-width: $grid-float-breakpoint-max){
			margin:-1px 0px 0px 0px;
		}
		i{
			width:50px;
			float:left;
		}
		h1{
			float:left;
			width:calc(100% - 50px);
		}
	}
	.abs-title{
		margin:20px 0px 20px 0px !important;
		padding-left:20px;
		padding-right:20px;
	}
	.control-label{
		padding-left:15px !important;
		padding-right:15px !important;
	}
	.form-horizontal{
		margin:10px 0px 0px !important;
		@media (max-width: $screen-md-min) {
			padding:0px;
		}
		@media (max-width: $grid-float-breakpoint-max){
			padding:0px 20px;
		}
	}
	.form-horizontal .form-group{
		@media (max-width: $grid-float-breakpoint-max){
			border-top: 0px !important;
			padding-top: 0px !important;
		}
	}

	.form-group .col-sm-8.col-md-6{
		margin-top:0px;
	}
	.panel-actions{
		position:absolute;
		bottom:0px;
		left:0px;
		background:$abs-container-box-bg;
		padding:10px 20px;
		width:100%;
		button{
			float:right;
			&:nth-of-type(2){
				margin-right:10px;
			}
		}
	}
}
.settings .panel-add-field-12{
	.form-horizontal .control-radio{
		float:left;
		margin-right:20px;
	}
	.form-horizontal  .choose-form{
		float:left;
		@media (max-width: $grid-float-breakpoint-max){
			margin-top:15px;
		}
	}
}


.form-window{
	margin-top:25px;
	.col-sm-10{
		width: 74.333333%;
	}
	.form-label{
		color: $abs-form-label-color;
		font-size: 1em;
		font-weight: bold;
		width:100px;
		padding-right:15px;
		display:block;
		float:left;
		label{
			text-align: right;
			margin-bottom: 0;
			padding-top: 7px;
			width:100%;
			position:relative;
			@media (max-width: $grid-float-breakpoint-max){
				text-align:left;
				margin-bottom:10px;
			}
		}
	}
	.required:after{
		content:"*";
		color:$color-negative-dark-4;
		position: absolute;
		right: 0px;
		font-size: 1.3em;
		top: 2px;
		right:4px;
		@media (max-width: $grid-float-breakpoint-max){
			top:-2px;
		}
		.k-ie &{
			font-size:.7em;
		}
	}

	.form-content{
		width:78%;
		float:left;
		@media (max-width: $grid-float-breakpoint-max){
			width:100%;
		}
		textarea{width:100%;box-sizing: border-box; }
	}
	.k-widget *{
		box-sizing:border-box;

	}
	.k-widget.k-upload{
		width:auto;
	}

}
.k-ie .form-window .required::after{
	font-size:.7em;
}

.panel-action{
	width: 100%;
	background:$forms-panel-action-bg;
	border-top:1px solid $forms-panel-action-border;
	height:40px;
	.panel-action-bottom{
		float:right;
		padding:7px;
	}
}


.iphone textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	@include box-shadow(none);
}

textarea , textarea.form-control{
	@include box-shadow(none);
	@include borders($forms-element-border-color, 1px,1px,1px,1px);
	@include border-radius(2px);
	//-webkit-appearance: none;
	font-size:13px;
	padding:5px 8px;
	resize: vertical;
	&:hover{
		border-color: $forms-elements-hover-color;
	}
	&:focus{
		@extend %input-focus-style;
		background:$forms-element-bg;
	}
	&:active {
		@include box-shadow(none);
		background:$forms-elements-pressed-bg;
		outline: 0;
	}
}




// k-multiselect

.k-multiselect-wrap{
	@include box-shadow(none);
	min-height:2em;
	li {
		margin: 3px 0px 0px 3px;
		padding: 0 1.6em 0 .4em;
		//margin: 2px 0 1px 2px;
		line-height: 1.4em;
	}

	.k-input{
		// height: 1.9em;
		// line-height: 1.7em;
		// margin:1px 0px 0px 0px;
		height: 1.95em;
		line-height: 1.9em;
		margin: 0px;
		padding: 0px;
	}

	.k-select{
		padding:0 .2em;
	}

	.k-icon{
		background-image:none;
		&.k-i-close{
			@extend %lumen-icon-font-placeholder;
			font-size: 1.077rem;
			color:$forms-multiselect-elements-bg;
			@media (max-width: $grid-float-breakpoint-max){
				font-size:1.3em;

			}
			&:before {
				content: "\e5cd";
				color:$abs-form-icon-color;
			}

		}
	}
}

.k-multiselect{
	&.k-header{
		&.k-state-hover , &.k-state-focused{
			@extend %input-hover-style;
		}
		&.k-state-focused.k-state-border-up ,
		&.k-state-focused.k-state-border-down {
			@include box-shadow(none);
			border-color: $forms-element-border-color;
		}
		&.k-state-focused {
			@extend %input-focus-style;
		}
	}
	&.k-state-focused.k-state-border-down{
		@include radius(2px,2px,0px,0px);
	}
	&.k-state-focused.k-state-border-up{
		@include radius(0px,0px,2px,2px);
	}
	.k-button {
		background:$forms-multiselect-elements-bg;
		color:$abs-form-input-color;
		border-color:$forms-multiselect-elements-bg;
	}
	.k-button.k-state-hover, .k-button:active{
		@include box-shadow(none);
		background:$forms-multiselect-elements-bg;
		color:$forms-elements-hover-color;
		border-color:$forms-multiselect-elements-bg;
		.k-select .k-icon:before{
			color:$red-icon-color;
		}
	}
	.k-input{
		@include box-shadow(none);
	}
}


.k-list-container.k-popup > .k-list > .k-item.k-state-selected{
	border-color: $forms-elements-hover-color;
	color:$forms-elements-hover-color;
	background:$forms-element-bg;
}


//placeholders for inputs
%input-default-style{
	//-webkit-appearance: none;
	@include box-shadow(none);
	@include border-radius(2px);
	border-color: $forms-element-border-color;
	color:$abs-form-input-color;
}
%input-hover-style{
	border-color: $forms-elements-hover-color;
	color:$forms-elements-hover-color;
	background:$forms-element-bg;
}
%border-down-style{
	@include box-shadow(none);
	border-color: $forms-elements-hover-color;
	background:$forms-elements-pressed-bg;
	color:$forms-elements-hover-color;
}
%input-focus-style{
	@include box-shadow(0px 0px 5px 0px $abs-form-input-focus-shadow-color);
	border-color: $forms-elements-hover-color;
	color:$forms-elements-hover-color;
}
%input-active-style{
	@include box-shadow(none);
	background:$forms-elements-pressed-bg;
	outline: 0;
	color:$forms-elements-hover-color;
}


input::-ms-clear {
	display: none;
}


input, input.k-textbox{
	@extend %input-default-style;
	font-size:1rem;
	// height: 2.43em;
	height:2rem;
	// text-indent: .8em;
	text-indent:0em;
	padding:0px 8px;
	// line-height: 1.6em;
	line-height:2rem;
	@include borders($forms-element-border-color, 1px, 1px, 1px, 1px );
	border-width:1px;


	&:hover {
		@extend %input-hover-style;
	}
	&:focus {
		@extend %input-focus-style;
	}
	&:active {
		@extend %input-active-style;
	}
}
*[disabled], .k-state-disabled{
	// opacity: .7;
	pointer-events: none;
}
.k-textbox[disabled], .k-textbox[disabled]:hover {
	background-color: #fdfdfd;
	opacity: .3;
}

// numeric wrap strzalki po obu stronach dla mobilu

.k-numeric-wrap{
	@media (max-width: $grid-float-breakpoint-max){

		.k-link{
			height:auto;
		}
		position:relative;
		padding:0px;
		width:100%;
		.k-icon{
			height:15px;
		}
		> .k-input{
			@include border-radius(0px);
			width:calc(100% - 57px) !important;
			margin: 0px auto;
			left: 0px;
			right: 0px;
			position: absolute;
			top: 0px;
			z-index: 2;
			border-radius: 0px;
			border-left: 1px solid $forms-element-border-color;
			border-right: 1px solid $forms-element-border-color;
			&:hover{
				border-color:$forms-elements-hover-color;
			}
		}
		>.k-select{
			width: 100% !important;
			z-index: 1;
			position: absolute;
			> .k-link:nth-of-type(1){
				float: right;
				padding: 4px 6px 7px 9px;
				span.k-i-arrow-n{
					margin-top:1px;
				}
				span.k-i-arrow-n:before {
					content: "\f067";
					height:15px;
					font-size:10px;
					color:$forms-element-icons-color;


				}
			}
			> .k-link:nth-of-type(2){
				float: left;
				padding: 5px 6px 5px 7px;
				span.k-i-arrow-s:before {
					content: "\f068";
					height:15px;
					font-size:10px;
					color:$forms-element-icons-color;
				}
			}
		}
	}
}


.k-dropdown-wrap , .k-numeric-wrap{

	&.k-state-default, &.k-state-default > .k-select{
		@extend %input-default-style;
	}
	&.k-state-default > .k-select , &.k-state-hover > .k-select{
		@include radius(0px, 2px, 2px, 0px);
	}

	&:hover,&.k-state-hover /*, &.k-state-hover > .k-select*/{
		@extend %input-hover-style;
	}
	/*&.k-state-hover > .k-select{
	 	background:$forms-elements-pressed-bg;
	 	> .k-icon{
	 		color:$forms-elements-pressed-bg;
	 	}
	}*/

	&.k-state-focused{
		@extend %input-focus-style;

		>.k-input{
			@extend %input-focus-style;
			@include box-shadow(none);
		}
		>.k-select{
			@extend %input-focus-style;
			@include box-shadow(none);
		}
	}
	&:active, &.k-state-active  {
		@extend %input-active-style;
		>.k-input{
			@include box-shadow(none);
		}
		>.k-select{
			@include box-shadow(none);
		}
	}
	//  	&.k-state-active.k-state-border-up{
	// 	@extend %input-active-style;
	// 	border-color: $forms-element-border-color;
	//     	&:hover, .k-input , .k-select{
	//     		background:$forms-element-bg;
	//     		color:$abs-form-input-color;
	//     	}
	// }
	&.k-state-border-down{
		@extend %border-down-style;
		.k-select > .k-icon:before{
			color:$forms-elements-hover-color;
		}
		&:hover, .k-input ,.k-select{
			@extend %border-down-style;

		}
	}
	&.k-state-active.k-state-border-down{
		@extend %border-down-style;
		&:hover, .k-input ,.k-select{
			@extend %border-down-style;
		}
	}

}

.k-ff input.k-textbox{height:26px;}

.k-invalid.k-dropdown[aria-expanded="false"]{
	.k-dropdown-wrap.k-state-active{
		border-width:1px;
		padding-bottom:0px;

	}
}
.k-invalid.k-dropdown[aria-expanded="true"]{
	.k-dropdown-wrap.k-state-active{
		border-width:1px;
		padding-bottom:0px;

	}
}


.k-dropdown{
	font-size:1rem;
	width:100%;
	.k-state-hover > .k-input{
		color:$forms-elements-hover-color;
	}
	.k-state-focused > .k-input{
		color:$forms-elements-hover-color;
	}
}
.k-combobox , .k-numerictextbox, .k-datepicker ,.k-datetimepicker{
	font-size:1rem;
	width:100%;
}

.k-dropdown-wrap .k-icon{
	background-image:none;
	&.k-i-arrow-s, &.k-i-arrow-60-down{
		@extend %lumen-icon-font-placeholder;
		font-size: 1.3em;
		// color:#fff;
		// margin-right:-5px;
		@include non-select-font();
		&:before {
			content: "\e313";
			color:$abs-form-icon-color;
			transform: rotate(0deg);
			transition: transform .2s linear;
			display: inline-block;
		}
	}
}
.k-state-border-down .k-dropdown-wrap .k-icon{
	&.k-i-arrow-s , &.k-i-arrow-60-down{
		&:before {
			transform: rotate(180deg);
			transition: transform .2s linear;
			display: inline-block;
		}
	}
}

//combobox
.k-widget.k-combobox {
	width:100%;

	.k-dropdown-wrap{
		padding-right:0px;
		>.k-i-close{
			color:$color-negative-dark-4;
		}
	}

	.k-input{
		width:98% !important;
		width:calc(100% - 27px) !important;
	}
	.k-state-default .k-input{
		box-shadow:none;
	}

	.k-select{
		//padding-left:4px;
		width:2.2em;
		// width:28px;
		//border-width:0px;
		&:active{
			background:$forms-element-bg;
		}
		.k-icon.k-i-arrow-s{
			@include non-select-font();
			// margin-right:-3px;

		}
	}

	.k-dropdown-wrap{
		//padding:0 2.2em 0 0;
		&.k-state-hover > .k-select{
			margin-right:0px;

			background:$forms-elements-pressed-bg;
			border-color: $forms-elements-hover-color;
			> .k-icon{
				color:$forms-elements-pressed-bg;
			}
		}
		&.k-state-border-down{
			@extend %border-down-style;
			.k-select > .k-icon:before{
				color:$forms-elements-hover-color;
			}
			&:hover, .k-input ,.k-select{
				@extend %border-down-style;

			}
		}
		&.k-state-active.k-state-border-down{
			@extend %border-down-style;
			&:hover, .k-input ,.k-select{
				@extend %border-down-style;
			}
		}
		// &.k-state-active.k-state-border-down{
		// 	@extend %input-active-style;
		// 	border-color: $forms-element-border-color;
		//    		&:hover, .k-input ,.k-select{
		//    			background:$forms-element-bg;
		//    			color:$abs-form-input-color;
		//    			border-color: $forms-element-border-color;
		//    		}
		// }
	}
}
/*
.k-ie .k-combobox.k-widget .k-select .k-i-arrow-s.k-icon,
.k-ff .k-combobox.k-widget .k-select .k-i-arrow-s.k-icon{
	margin-right:-6px;
}*/
.k-combobox.k-invalid{

	.k-dropdown-wrap{
		&.k-state-hover{

			>.k-select{
				background:$forms-validation-bg;
				border-color:$forms-validation-border-color;
			}

		}

	}

	/*
        .k-dropdown-wrap{
        border-color: $forms-validation-border-color !important;
        > .k-select{
            > .k-icon{
                color:$forms-validation-bg;
            }
        }

                &.k-state-hover > .k-select,
                &.k-state-hover.k-state-focused > .k-select{
                 background:darken($forms-validation-bg,5);
                 border-color: $forms-validation-border-color;
                 > .k-icon{
                     color:darken($forms-validation-bg,5) ;
                 }
            }

            &.k-state-hover.k-state-active > .k-select,
            &.k-state-hover.k-state-focused > .k-select{
                > .k-icon{
                     color:$forms-validation-bg ;
                 }
            }
    }
    .k-dropdown-wrap.k-state-border-up , .k-dropdown-wrap.k-state-border-down{
        > .k-input, > .k-select{
            background:$forms-validation-bg !important;
            border-color: $forms-validation-border-color !important;
        }

    }

        .k-dropdown-wrap  .k-select{
                &:active{
                background:$forms-validation-bg;
            }
        }
    */
}

//numeric box
// .k-numerictextbox .k-link{
//     height: .97rem;
// }
.k-numeric-wrap{
	height:2rem;

	.k-input{
		height:1.8rem;
		line-height:1.8rem;
		padding:0px;
	}
	.k-select{
		min-height:0em;
		line-height:1.846rem;
	}
	.k-link.k-state-selected{
		box-shadow:none;
		background-color:darken($forms-elements-pressed-bg,3);
	}
	&.k-state-hover > .k-select{
		background:$forms-elements-pressed-bg;
		border-color: $forms-elements-hover-color;
		> .k-icon{
			color:$forms-elements-pressed-bg;
		}
	}

	&.k-state-hover > .k-select{
		background:$forms-elements-pressed-bg;
		> .k-icon{
			color:$forms-elements-pressed-bg;
		}
	}
	&.k-state-active.k-state-border-down{
		@extend %input-active-style;
		border-color: $forms-element-border-color;
		&:hover, .k-input ,.k-select{
			background:$forms-element-bg;
			color:$abs-form-input-color;
			border-color: $forms-element-border-color;
		}
	}
	.k-select{
		//padding-left:4px;
		width:2.2em;
		// width:28px;
	}
	&.k-state-default  .k-input{
		box-shadow:none;
	}

	.k-select .k-link span.k-i-arrow-n, .k-select .k-link span.k-i-arrow-60-up {
		@include  non-select-font();
		@extend %lumen-icon-font-placeholder;
		background-image:none;
		color:$color-neutral-60;
		font-size:1.154rem;
		// margin-top:-1px;
		height:.92rem;
		line-height:.92rem;
		// margin-right:-4px;

		&:before{
			content: "\e316";
			// color:rgba(102,102,102,1);
			color:$forms-element-icons-color;


		}
	}
	.k-select .k-link span.k-i-arrow-s , .k-select .k-link span.k-i-arrow-60-down{
		@include  non-select-font();
		@extend %lumen-icon-font-placeholder;
		background-image:none;
		color:$color-neutral-60;
		font-size:1.154rem;
		// margin-top:-5px;
		height:.92rem;
		line-height:.92rem;
		// margin-right:-4px;
		&:before{
			content: "\e313";
			color:$forms-element-icons-color;
			// color:rgba(102,102,102,1);

		}
	}
	.k-select .k-link span.k-i-arrow-60-up{
		display:block;
		margin:0px auto 0px auto;
	}
	.k-select .k-link span.k-i-arrow-60-down{
		display:block;
		margin:0px auto 0px auto;

	}
}

.k-ff .k-numeric-wrap .k-select .k-link span.k-i-arrow-s{
	margin-top:-7px;
	// margin-right:-6px;
}
.k-ff .k-numeric-wrap .k-select .k-link span.k-i-arrow-n{
	margin-top:-2px;
	// margin-right:-6px;
}
.k-ie .k-numeric-wrap .k-select .k-link span.k-i-arrow-s,
.k-ie .k-numeric-wrap .k-select .k-link span.k-i-arrow-n{
	// margin-right:-6px;
}


//maskedtextbox
.k-masked-textbox, .k-numerictextbox{
	width:100%;
}

//datepicker, date time picker - przeniesc do kendo.scss
.abs-col-1 .k-datepicker , .abs-col-1 .k-datetimepicker {
	width:100%;
}


.k-calendar-container {

	@include border-radius(2px);
	&.k-state-border-down{
		@include border-radius(0px);
	}
	&.k-group{
		//@include box-shadow(none);
		@include box-shadow(0px 2px 3px 0px rgba(0,0,0, 0.35));
		//border-color:#afafaf;
		background:$forms-element-bg;
		padding:0px;
		border-color: $color-neutral-33;
		border-width:1px;
		@include border-radius(2px);
	}
}

.k-datepicker{
	.k-picker-wrap {
		height: 2rem;
	}
	.k-picker-wrap .k-input{
		padding:0;
		height:1.8rem;
		line-height:1.8rem;
	}
	.k-select{
		padding-right:1px;
		min-height:0em;
		line-height:2rem;
	}
	.k-state-default .k-input{
		@include box-shadow(none);
	}
	.k-state-default>.k-select{
		//@include radius(0px,2px,2px,0px);
		border-color:$k-calendar-bg;
	}
	.k-state-default.k-state-focused>.k-select{
		@include box-shadow(none);

	}
}

.k-datetimepicker{
	.k-picker-wrap {
		height: 2rem;
	}
	.k-picker-wrap .k-input{
		padding:0;
		// height:1.85em;
		// line-height:1.85em;
		height:1.769rem;
		line-height:1.769rem;
	}
	.k-select{

		min-height:0em;
		line-height:2rem;
	}
	.k-select .k-icon.k-i-calendar , .k-icon.k-i-clock{
		.k-ie & {
			margin-top:-3px;
		}
		.k-ff &{
			margin-top:-4px;
		}
	}
}

.k-picker-wrap{
	color:$abs-form-input-color;
	.material-i-calendar{
		margin-top:5px;
		font-size:20px;

	}
	&.k-state-default{
		> .k-input{
			@include box-shadow(none);
		}
		border-color:$forms-element-border-color;
		@media only screen and (device-width: 768px) {
			padding-bottom:1px;
		}

	}
	&.k-state-hover{
		border-color: $forms-elements-hover-color;
		color:$forms-elements-hover-color;
		background:$forms-element-bg;
	}
	&.k-state-default.k-state-focused{
		@extend %input-focus-style;
		background:$forms-element-bg;
		.k-input{
			border-color: $forms-elements-hover-color;
		}
		.k-select{
			background:$forms-element-bg
		}
		&:active{
			>.k-select , >.k-input{
				background:$forms-elements-pressed-bg;
				border-color:$forms-elements-pressed-bg;
			}
		}
	}
	&.k-state-default.k-state-active.k-state-focused.k-state-border-down,
	&.k-state-default.k-state-active.k-state-focused.k-state-border-up,
	&.k-state-default.k-state-active.k-state-border-down,
	&.k-state-default.k-state-active.k-state-border-up{
		@include box-shadow(none);
		border-color:$forms-element-border-color;
		background:$forms-element-bg;
		>.k-input , >.k-select{
			background:$forms-element-bg;
			border-color:$forms-element-bg;
			color:$abs-form-input-color;
		}
	}

	&.k-state-border-down{
		@include radius(2px,2px,0px,0px);
		background:$forms-element-bg;
	}
	&.k-state-border-up{
		@include radius(0px,0px,2px,2px);
		background:$forms-element-bg;
	}
	.k-select{
		border-width:0px;
		.k-icon.k-i-calendar , .k-icon.k-i-clock{
			@extend %lumen-icon-font-placeholder;
			background-image:none;
			margin-top:-3px;
			// font-size:0px;
			color:$forms-element-icons-color;
			.k-ie & {
				margin-top:-3px;
			}
			.k-ff &{
				margin-top:-3px;
			}
		}

		.k-icon.k-i-calendar:before {
			font-family: Lumen-Icon-Font;
			content: "\e91e";
		}
		.k-icon-clock{
			-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		.k-icon.k-i-clock:before{
			content: "\f017";

		}

	}
}



.k-calendar{
	@include box-shadow(none);
	@include borders($k-calendar-border, 0px, 1px,1px,1px);
	width:200px;
	// .k-today{
	// 	background:transparent;
	// 	border:2px solid $color-neutral-8;
	// }
	>.k-header{
		border-color: $color-neutral-2;
		background:$color-neutral-2;
		width:100%;
		padding:.3em 0 .2em 0;
		border-bottom:1px solid $color-neutral-11;
		> .k-link.k-state-hover:not(.k-nav-fast){
			@include box-shadow(none);
			background:$color-brand-primary-light;
			// opacity:.7;
		}
		.k-link.k-nav-prev{
			margin-top:7px;
			border-radius:2px;
			left:2%;
			span{
				margin-top:-2px;
			}
		}
		.k-link.k-nav-next{
			margin-top:7px;
			border-radius:2px;
			right:2%;
			span{
				margin-top:-2px;
			}

		}
		.k-nav-fast.k-state-hover{
			background:$color-neutral-2;
		}

	}

	.k-other-month .k-link{
		color:$k-calendar-other-month-link-color;
	}
	.k-content{
		margin:0px auto;
		//width:95%;
		width:100%;
		//table-layout:auto;
		text-align:center;
		//float:none;
		color:$k-calendar-content-color;
		.k-link{
			font-size:1em;
			text-align:center;
			padding:.5em 0 .5em 0;

		}
	}
	.k-content th{
		background:$forms-element-bg;
		border-color:$k-calendar-cell-border;
		font-size:1em;
		padding:.9em 0;
		text-align:center;
		vertical-align: middle;
		color:$color-neutral-60;
	}
	td{
		@include border-radius(2px);
		@include box-shadow(none);
		&.k-state-hover{
			background:$color-brand-primary-light;
			@include box-shadow(none);
		}
		&.k-state-focused{
			@include box-shadow(none);
			vertical-align: middle;
			text-align:center;
		}
		&.k-state-focused.k-state-selected{
			@include box-shadow(none);
			background:$forms-elements-hover-color;
		}
	}
	.k-footer>.k-nav-today{
		background:$forms-element-bg;
		border-top:1px solid $k-calendar-cell-border;
		color:$color-brand-primary-dark;
		font-size:1em;
		padding:.9em 0 .8em 0;
		font-weight:bold;
	}

	.k-link.k-nav-fast
	{
		color:$color-brand-primary-dark;
		margin-top: 5px;
		font-weight: bold;
	}

}

%k-calendar-header-icon{
	font-size: 18px;
	color:$forms-elements-hover-color;
}

.k-calendar .k-icon{
	background-image:none;
	&.k-i-arrow-w ,  &.k-i-arrow-60-left{
		@extend %lumen-icon-font-placeholder;
		@extend %k-calendar-header-icon;
		&:before {
			content: "\e317";
			color:$color-brand-primary-dark;
		}
	}
	&.k-i-arrow-e , &.k-i-arrow-60-right{
		@extend %lumen-icon-font-placeholder;
		@extend %k-calendar-header-icon;
		&:before {
			content: "\e318";
			color:$color-brand-primary-dark;
		}
	}
}




// walidacja

%validation-style{
	@include box-shadow(none);
	border-color: $forms-validation-border-color;
	background:$forms-validation-bg;
	color:$forms-validation-border-color;
}

.k-invalid.k-dropdown[aria-expanded="true"]{
	.k-dropdown-wrap.k-state-focused.k-state-border-down,
	.k-dropdown-wrap.k-state-focused.k-state-border-up{
		@include box-shadow(none);
	}

}
.k-invalid > .k-dropdown-wrap ,.k-invalid > .k-numeric-wrap {
	margin-bottom:-2px;
	&.k-state-default,
	&.k-state-active, &.k-state-focused,
	&.k-state-active.k-state-border-down,
	&.k-state-active.k-state-border-up{
		@extend %validation-style;
	}
	&.k-state-default > .k-select{
		@include radius(0px, 2px, 2px, 0px);
		@extend %validation-style;
	}

	&.k-state-default.k-state-focused{
		@extend %validation-style;
		@include box-shadow(0px 0px 5px 0px $forms-validation-border-color);
	}


	&.k-state-active{
		>.k-input , >.k-select{
			background:$forms-validation-bg;
		}
	}
	.k-icon {
		color:$forms-validation-bg;
	}
	&.k-state-hover > .k-input , &.k-state-focused > .k-input , &.k-state-hover > .k-select {
		color:$forms-validation-border-color;
		border-color:$forms-validation-border-color;
	}
}

.k-multiselect.k-header.k-invalid{
	@extend %validation-style;
	&.k-state-focused{
		@extend %validation-style;
		@include box-shadow(0px 0px 5px 0px $forms-validation-border-color);
	}
	&.k-state-focused.k-state-border-down,
	&.k-state-focused.k-state-border-up
	{
		@include box-shadow(none);
	}
	> .k-multiselect-wrap{
		padding-right:30px;
		background:$forms-validation-bg;
		&:hover, &.k-state-hover{
			> .k-input{
				color:$forms-validation-border-color;
			}
		}
	}
}
.k-invalid.k-datepicker > .k-picker-wrap , .k-invalid.k-datetimepicker > .k-picker-wrap {
	@include border-radius(2px);
	background:$forms-validation-bg;
	border-color: $forms-validation-border-color;
	&.k-state-default{
		.k-input , .k-select{
			background:$forms-validation-bg;
			border-color: $forms-validation-border-color;
		}
		.k-select{
			//margin-right:28px;
			//padding-right:0px;
			border-color:$forms-validation-bg;
		}
	}
	&.k-state-focused , &.k-state-active.k-state-focused{
		@extend %validation-style;
		@include box-shadow(0px 0px 5px 0px $forms-validation-border-color);
		.k-input , .k-select{
			@extend %validation-style;
		}
		.k-select{
			border-color:$forms-validation-bg;
		}
	}
	&.k-state-active.k-state-focused{
		@extend %validation-style;
		@include box-shadow(none);
	}
	&.k-state-hover{
		@extend %validation-style;
		> .k-input{
			color:$forms-validation-border-color;
		}
	}
}

.k-textbox.k-invalid, input.k-invalid, textarea.k-invalid {
	padding-right:30px;
	border-color: $forms-validation-border-color;
	background:$forms-validation-bg;
	&:hover{
		@extend %validation-style;
	}
	&:focus {
		@extend %validation-style;
		@include box-shadow(0px 0px 5px 0px $forms-validation-border-color);
	}
	&:active{
		@extend %validation-style;
	}
}


%validation-icon-style{
	color:$forms-validation-border-color;
	font-size:16px;
	padding:2px 0px;
	line-height: 1.6em;
}
%validation-sm-position{
	position:absolute;
	right:22px;
	top:2px;
}

.k-invalid-input-icon{
	@extend %validation-icon-style;
	position:relative;
	z-index:2;
	margin-left:-24px;
	margin-top:2px;
	@media (max-width: $grid-float-breakpoint-max){
		position:absolute;
		right:22px;
		top:0px;
	}
}
.k-invalid-textarea-icon ,  .k-invalid-dropdown-icon{
	@extend %validation-icon-style;
	@extend %validation-sm-position;
}

.k-invalid > .k-dropdown-wrap.k-state-default > .k-select {
	//margin-right: 28px;
}
.k-combobox.k-invalid > .k-dropdown-wrap.k-state-default > .k-select {
	margin-right: 0px;
}
.validation-tooltip-icon-hide{
	.k-tooltip-button{
		display:none;
	}
	.k-tooltip-content{
		padding-right:10px;
		padding-left:10px;
	}
}


.k-popup{
	@include box-shadow(0px 0px 5px 0px rgba(0,0,0, 0.3));
}
.k-popup.k-tooltip{
	@include box-shadow(none);
	@include border-radius(3px);
	padding:6px 7px;
}

.k-popup.k-list-container{
	@include border-radius(2px);
	@include box-shadow(0px 2px 3px 0px rgba(0,0,0, 0.35));
	border-color: $color-neutral-33;
	border-width:1px;
	padding:0px;

	> li > .k-button{
		@include border-radius(2px);
		text-decoration:none;
		margin: 2px;
	}

	.k-list-optionlabel{
		font-style:italic;
	}
	.k-list > .k-item , .k-list-optionlabel{
		@include border-radius(0px);
		// @include borders(#fff, 1px, 0px, 1px, 0px);
		padding:2px 10px 1px 10px;
		// border-width:0px 0px 1px 0px;
		border-width:1px;
		// border-color:$forms-combo-hover-border-color;
		@include borders(#fff, 0px,1px,0px,1px);
	}
	.k-list > .k-item:hover , .k-list-optionlabel:hover , .k-list > .k-item.k-state-focused{
		@include box-shadow(none);
		background:$forms-elements-mouseover-bg;
		// border-color:$forms-combo-hover-border-color;
		// border-width:0px 0px 1px 0px;
		border-width:1px;
		@include borders(#fff, 0px,1px,0px,1px);
	}

	.k-list > .k-item.k-state-selected:hover , .k-item.k-state-selected.k-state-hover
	{
		background:$forms-elements-selected-mouseover-bg;
	}
	.k-list > .k-item.k-state-selected , .k-list-optionlabel.k-state-focused{
		@include box-shadow(none);
		background:$forms-elements-pressed-bg;
		//outline: 0;
		color:$forms-elements-hover-color;
		// border-color:$forms-combo-hover-border-color;
		//background:#fff;
		//color:#000;
		//border-color:#fff;
		// border-width:0px 0px 1px 0px;
		border-width:1px;
		@include borders(#fff, 0px,1px,0px,1px);
	}
}



.input-group-type-c{
	@include box-shadow(0px 1px 1px #15558a);
	float:left;
	margin: 8px 0px 0px 13px;

	@media (max-width: $grid-float-breakpoint-max){
		float: none;
		margin: 8px auto 0px;
		width: 96%;
		margin-bottom:10px;

	}

	input{
		@include radius(2px);
		@include box-shadow(none);
		float:left;
		padding: 1px 40px 1px 10px;
		font-size: 13px;
		line-height: 1.65em;
		width:180px;
		text-align:left;
		border-width:1px;
		height:24px;
		color:#8d8d8d;
		@media (max-width: $grid-float-breakpoint-max){
			float:none;
			width:100%;

		}
		&:hover{
			@extend %input-hover-style;
			border-color:#fff;
		}
		&:focus {
			@extend %input-focus-style;
			border-color:$btn-type-c-hover-bg;
			background:#fff;

		}
		&:active{
			@extend %input-active-style;
			background:#fff;
		}
	}


	.input-button > button.input-inner-button{
		@include radius(0px,2px,2px,0px);
		@include box-shadow(none);
		float:left;
		line-height: 1.65em;
		border-width:0px;
		padding:1px 10px 1px 10px;
		border-width:0px;
		background:transparent;
		margin-left:-32px;
		height:auto;
		z-index:10;
		@media (max-width: $grid-float-breakpoint-max){
			float: none;
			margin-left:0px;
			display:inline;
			position: absolute;
			top: 7px;
			right: 10px;
		}

		i{
			color:$btn-type-c-color;
		}
		&:hover, &:focus, &:active  {
			@include box-shadow(none);
			border-color:#fff;
			outline:0;

		}
		&:hover{
			i{
				color:lighten($btn-type-c-color ,10);
			}
		}
		&:active{
			i{
				color:lighten($btn-type-c-color ,30);
			}
		}

	}




	.input-button > .input-inner-button.clear-button{
		margin-left:-45px;
		padding:2px 5px 0px 5px;
		> i{
			color:$red-icon-color;
		}
		@media (max-width: $grid-float-breakpoint-max){
			right: 40px;
		}

		&:hover{
			i{
				color:darken($red-icon-color ,10);
			}
		}
		&:active{
			i{
				color:lighten($red-icon-color ,20);
			}
		}
	}
}


.input-white{
	@include borders(#fff, 1px,1px,1px,1px);
	@include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

.input-addon{
	float:left;
	width:auto;
	height:40px;
	position:relative;
	.input-white{
		width:150px;
		margin-top:8px;
		margin-left:13px;
	}
	.fa-search{
		color: #1a69aa;
		margin-left: -25px;
		z-index: 22;
		position: absolute;
		right: 6px;
		top: 13px;
		/*
		color:$sub-nav-bg;
		margin-left:-5 -$padding-xs-horizontal;
		*/
	}
}


// dropdowns - do zrobienia w kedno, nie tu

.dropdown-type-c{
	.btn.dropdown-toggle{
		padding:1px 10px 1px 10px;
		.fa-angle-down{
			display: inline-block;
			vertical-align: middle;
			color:$btn-type-c-color;
			margin-left:5px;
			margin-top:-1px;
			&:before{
				font-size: 1.2em;
			}
		}
		&[aria-expanded="true"]	{
			.fa-angle-down:before{
				content: "\e316";

			}
		}
	}
	.dropdown-menu{
		@include box-shadow(0px 0px 5px 0px rgba(0,0,0, 0.3));
		@include border-radius(0px);

		float:left;
		padding:0px;
		border-color:$sub-nav-bg;
		top:23px;

	}
	.dropdown-menu li{
		width:100%;
		display:block;
		padding:0px;
		margin:0px;
		border-bottom:1px solid $forms-combo-hover-border-color;

	}
	.dropdown-menu li a{
		padding:8px 10px 6px;
		margin:0px;
		color:#000;
		font-size:13px;
		&:hover{
			background:$forms-elements-mouseover-bg;
		}
		&:focus{
			outline:0;
		}
	}

	&.open > .btn-type-c.dropdown-toggle,
	&.open > .btn-type-c.dropdown-toggle:active,
	&.open > .btn-type-c.dropdown-toggle:focus,
	&.open > .btn-type-c.dropdown-toggle:active:focus{
		background:$forms-elements-pressed-bg;
		border-color:$forms-elements-pressed-bg;
	}
}


// dla sm-menu
.dropdown-type-d{
	.btn.dropdown-toggle{
		@include box-shadow(none);
		@include border-radius(0px);
		padding:10px 25px 8px 15px	;
		background: $color-brand-primary;
		color: $tabstrip-button-active-link;
		font-weight:bold;
		width:100%;
		text-align:left;
		font-size:13px;
		span{
			float:left;
		}
		> i{
			font-size:22px;
			margin-top:-2px;
			float:right;
			margin-right:-20px;
			transition:all .2s linear;
		}
		.abs_expand_more{
			float:right;
		}
	}
	&.open .btn.dropdown-toggle{
		.abs_expand_more{
			transform:rotate(180deg)
		}
	}
	.dropdown-menu{
		@include box-shadow(none);
		@include border-radius(0px);
		float:left;
		padding:0px;
		border-color:$color-brand-primary;
		border-width:2px;
		border-top:0px;
		width:100%;
		margin:0px;
		padding:0px;
	}
	.dropdown-menu li{
		width:100%;
		display:block;
		padding:0px;
		margin:0px;
		border-bottom:1px solid $forms-combo-hover-border-color;
	}
	.dropdown-menu li a{
		padding:8px 10px 6px;
		margin:0px;
		color:#333;
		font-size:13px;
		&:hover{
			background:$forms-elements-mouseover-bg;
		}
		&:focus{
			outline:0;
		}
	}
	.dropdown-toggle:hover,
	.dropdown-toggle:active,
	.dropdown-toggle:focus,
	.dropdown-toggle:active:focus,
	&.open > .dropdown-toggle,
	&.open > .dropdown-toggle:active,
	&.open > .dropdown-toggle:focus,
	&.open > .dropdown-toggle:active:focus{
		@include box-shadow(none);
		background:$color-brand-primary !important;
		border-color:$color-brand-primary !important;
		color: $tabstrip-button-active-link !important;
		outline:none;
	}
	.dropdown-toggle:hover{
		background:$color-brand-primary-dark !important;
		border-color:$color-brand-primary-dark !important;
	}
}


.dropdown-type-e{
	.btn.dropdown-toggle{
		@include box-shadow(none);
		@include border-radius(0px);
		padding:12px 25px 12px 15px	;
		background: transparent;
		color: $color-brand-primary;
		font-weight:bold;
		width:100%;
		text-align:left;
		&:after{
			content:'';
			position:absolute;
			bottom:0px;
			left:0px;
			width:100%;
			height:4px;
			background:$color-brand-primary
		}
		&:hover{
			color:$color-brand-primary-dark;
			border-color:transparent;
			&:after{
				background:$color-brand-primary-dark;
			}
			> i {
				color:$color-brand-primary-dark;
			}
		}
		span{
			float:left;
		}
		> i{
			font-size:22px;
			margin-top:-2px;
			float:right;
			margin-right:-20px;
			transition:all .2s linear;
			color: $color-brand-primary;
		}
		.abs_expand_more{
			float:right;
		}
	}
	&.open .btn.dropdown-toggle{
		.abs_expand_more{
			transform:rotate(180deg)
		}
	}
	.dropdown-menu{
		@include box-shadow(none);
		@include border-radius(0px);
		float:left;
		padding:0px;
		border-color:$color-brand-primary;
		border-width:2px;
		border-top:3px solid $color-brand-primary;
		width:100%;
		margin:-5px 0 0 0;
		padding:0;
	}
	.dropdown-menu li{
		width:100%;
		display:block;
		padding:0px;
		margin:0px;
		border-bottom:1px solid $forms-combo-hover-border-color;
	}
	.dropdown-menu li a{
		padding:8px 10px 6px;
		margin:0px;
		color:#333;
		font-size:13px;
		&:hover{
			background:$forms-elements-mouseover-bg;
		}
		&:focus{
			outline:0;
		}
	}
	.dropdown-toggle:active,
	.dropdown-toggle:focus,
	.dropdown-toggle:active:focus,
	&.open > .dropdown-toggle:active,
	&.open > .dropdown-toggle:focus,
	&.open > .dropdown-toggle:active:focus{
		@include box-shadow(none);
		background:transparent !important;
		border-color:transparent !important;
		color: $color-brand-primary !important;
		outline:none;
	}
}

.select-to-dropdown-sm{
	position:relative;
	.sm-menu{
		//bottom:0px;
		//position:absolute;
	}
}



//sm menu for kendo tabs

.sm-menu{
	@extend %input-default-style;
	@include borders($forms-element-border-color, 1px, 1px, 1px, 1px );
	height: 2.43em;
	padding:0px 0px 0px 10px;
	//	text-indent: .8em;
	line-height: 1.6em;
	border-width:1px;
	@media (max-width: $grid-float-breakpoint-max){
		width:100%;
	}

	&:hover {
		@extend %input-hover-style;
	}
	&:focus {
		@extend %input-focus-style;
	}
	&:active {
		@extend %input-active-style;
	}
}









/*
div.loading {
    position: absolute;
    top:40%;
    left:48%;
    background: url('../img/spinner.gif') no-repeat center center;
    width:100px;
    height:100px;

}*/


.k-upload{
	margin:0px auto;
	max-width:800px;
	background:$k-upload-bg;
	border-color:transparent;
	&.k-upload-empty{
		background:$k-upload-bg;
	}
	> .k-upload-files{
		margin:5px 0px;
		border-top-width:0px;
		.k-file{
			@include border-radius(2px);
			border:1px solid $k-upload-file-border;
			margin:10px 0px 0px 0px;

		}
		.k-file.k-file-error{
			border-color:$forms-validation-border-color;
			.k-progress{
				background:$forms-validation-bg;
			}
		}
	}
	> .k-dropzone{
		@include border-radius(4px);
		border-width:1px;
		border-style:dashed;
		border-color:$k-upload-dropzone-border;
		text-align:center;
		> em{
			font-weight:bold;
		}
		.k-upload-status {
			line-height: 2;
		}
	}
}

.loading {
	width:100px;
	height:100px;
	position: absolute;
	top:40%;
	left:48%;
	opacity:.4;
	-webkit-animation:spin 2s linear infinite;
	-moz-animation:spin 2s linear infinite;
	animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% {
	-moz-transform:rotate(360deg);
}
}
@-webkit-keyframes spin { 100% {
	-webkit-transform:rotate(360deg);
}
}
@keyframes spin { 100% {
	-webkit-transform:rotate(360deg);
	transform:rotate(360deg);
}
}

// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                            rwd list checkbox
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************



.preview-block{
	position: relative;
	background: #f8f8f8;
	padding: 16px;
	border: 1px solid #cccccc;
	text-align: center;
}
.block-switch{
	position: relative;
	text-align: center;
	display: inline-block;
	padding: 10px 40px 10px 20px;
	width: 16px;
}
input.toggle {
	position: absolute;
	visibility: hidden;
	height: 16px;
	width: 16px;
	margin: 0;
}
input.toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	user-select: none;
}
input.toggle-flat + label {
	width: 100px;
	height: 16px;
	background-color: #BF2A23;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
input.toggle-flat + label:before,
input.toggle-flat + label:after{
	display: block;
	position: absolute;
	content: "";
}
input.toggle-flat + label:before{
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
input.toggle-flat+label:after{
	top: 4px;
	left: 4px;
	bottom: 4px;
	width: 45px;
	background-color: #fff;
	-webkit-transition: margin 0.5s, background 0.5s;
	-moz-transition: margin 0.5s, background 0.5s;
	-o-transition: margin 0.5s, background 0.5s;
	transition: margin 0.5s, background 0.5s;
}
input.toggle-flat:checked+label {
	background-color: #FFF;
}
input.toggle-flat:checked+label:after {
	margin-left: 47px;
	background-color: #fff;
}


input.toggle-flip + label {
	height: 16px;
}
input.toggle-flip + label:before, input.toggle-flip + label:after {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	width: 16px;
	height: 16px;
	color: #fff;
	text-align: center;
	line-height: 16px;
}
input.toggle-flip + label:before {
	background-color: #FFF;
	border: 1px solid #ccc;
	border-radius: 2px;
	content: " ";
	-webkit-transition: -webkit-transform 0.5s;
	-moz-transition: -moz-transform 0.5s;
	-o-transition: -o-transform 0.5s;
	transition: transform 0.5s;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
}
input.toggle-flip + label:hover:before {
	border: 1px solid #aeaeae;
}
input.toggle-flip + label:after {
	font-family: Lumen-Icon-Font;
	background-color: #FFF;
	border-radius: 2px;
	border: 1px solid #aeaeae;
	color: #858585;
	content: "\e5ca";
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	-o-transition: -o-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	-ms-transform: rotateX(180deg);
	-o-transform: rotateX(180deg);
	transform: rotateX(180deg);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
}
input.toggle-flip + label:hover:after {
	font-family: Lumen-Icon-Font;
	background-color: #FFF;
	border-radius: 2px;
	border: 1px solid $abs-filter-btn-hover;
	color: $abs-filter-btn-hover;
	content: "\e5ca";
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	-o-transition: -o-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	-ms-transform: rotateX(180deg);
	-o-transform: rotateX(180deg);
	transform: rotateX(180deg);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
}
input.toggle-flip:checked + label:before {
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	-ms-transform: rotateX(180deg);
	-o-transform: rotateX(180deg);
	transform: rotateX(180deg);
}
input.toggle-flip:checked + label:after {
	-webkit-transform: rotateX(0);
	-moz-transform: rotateX(0);
	-ms-transform: rotateX(0);
	-o-transform: rotateX(0);
	transform: rotateX(0);
}

// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
//                            END rwd list checkbox
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************




.abs-searchbox{
	position:relative;
	width:100%;
	input{
		padding-right:30px;
		width:100%;
	}
	span{
		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
		position:absolute;
		right:0px;
		top:0px;
		line-height:2rem;
		height:2rem;
		font-size:1.385rem;
		padding:0px 5px;
		cursor:pointer;
		.abs_close{
			color:$color-negative-dark-4;
			&:hover{
				color:$color-neutral-100;
			}

		}
		.abs_material_search{
			color:$color-neutral-60;
			&:hover{
				color:$color-neutral-100;
			}
		}
	}
	&.abs-searchbox-b{
		span{
			padding:0px 2px;
		}
		span i{
			height:1.7rem;
			line-height:1.7rem;
			border-radius:2px;
			padding:0px 3px;
		}
		span  .abs_close{
			color:$text-on-color-brand-secondary;
			background:$color-brand-secondary;
			&:hover{
				background:darken($color-brand-secondary,100)
			}
		}
		span  .abs_material_search{
			color:$text-on-color-brand-primary;
			background:$color-brand-primary-dark;
			&:hover{
				background:darken($color-brand-primary-dark,100);
			}
		}


	}
}



.abs-grid-filter{
	width:100%;
	button{
		width:100%;
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-start);
		@extend %input-default-style;
		background:$color-neutral-0;
		height:2rem;
		line-height:2rem;
		border:1px solid  $color-neutral-27;

		padding-top:0px;
		padding-bottom: 0px;
		font-size:1rem;
		&:hover{
			@extend %input-hover-style;
		}
		&:focus{
			@extend %input-focus-style;
		}
		&:active{
			@extend %input-active-style;
		}
		span{
			height: 1.846rem;
			line-height: 1.846rem;
			&.label-title{
				font-weight:bold;
				padding-right:3px;
			}
			&.label-quantity{
				padding-right:3px;
			}
			&.label-value{
				text-overflow:ellipsis;
				overflow: hidden;
				white-space: nowrap;

			}
		}
		.clear-filter{
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
			line-height:1.846rem;
			height:1.846rem;
			font-size: 1.385rem;
			padding:0px 5px;
			margin-right:-6px;
			margin-left:3px;
			cursor:pointer;
			color:$color-negative-dark-4;
			border-left:1px solid $color-neutral-11;
			.k-ie &{
				margin-right:9px;
			}
			&:before{
				margin-top:-3px;
			}
			&:hover{
				color:$color-neutral-100;
			}
		}
		.abs_expand_more{
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			font-size:1.308rem;
			margin-right:0 !important;
			margin-left:auto !important;
			text-align:right;
			transform: rotate(0deg);
			transition: transform .2s ease-in-out;

		}
	}
	&.open{
		button .abs_expand_more{
			transform: rotate(180deg);
			transition: transform .2s ease-in-out;
			outline:0;
			box-shadow:none;
		}
	}
}

.validation-info{
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-start);
	color:$color-negative-dark-4;
	clear:both;
	padding:3px 0px 0px 0px;

	&:before{
		font-family:Lumen-Icon-Font;
		content:'\e000';
		margin-right:3px;
	}
}

.input-select.invalid .absui-form-control {
	background:$forms-validation-bg !important;
	border-top-color:$forms-validation-border-color !important;
	border-bottom-color:$forms-validation-border-color !important;
	border-left-color:$forms-validation-border-color !important;
}

.input-select.absui-dropdown--combo.invalid .absui-input-group .absui-input-group-append .absui-input-group-text,
.input-select.absui-dropdown--combo.show.invalid .absui-input-group .absui-input-group-append .absui-input-group-text {
	background:$forms-validation-bg !important;
	border-top-color:$forms-validation-border-color !important;
	border-bottom-color:$forms-validation-border-color !important;
	border-right-color:$forms-validation-border-color !important;
}

.input-select.absui-dropdown--combo.show .absui-input-group {
	box-shadow: none !important;
}

.input-select.absui-dropdown--combo.show .absui-input-group .absui-input-group-append .absui-input-group-text {
	box-shadow: none !important;
	background-color: transparent !important;

	i {
		color: #4c4c4c !important;
	}
}

.input-select.absui-dropdown--combo .absui-input-group input {
	width: 100% !important;
}

.input-select.absui-dropdown--combo.is-focused {
	box-shadow: 0 0 5px 0 #24708f;
	border-color: #24708f;
}

.input-select.absui-dropdown--combo .absui-dropdown__item {
	margin: 0;
}


.abs-form-panel.fw-form {

	.fw-form-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		margin-top: 30px;

		&--disabled:before {
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: 100;
			left: 0;
		}

		&--enabled {
			opacity: 1;
			transition: opacity 0.5s linear;
		}

		&--disabled {
			opacity: 0.2;
		}

		&__header {
			display: flex;
			flex-direction: column;
			margin: 0 0 15px 0;
			align-items: center;

			&__icon {
				background: $icon-placeholder-background;
				border-radius: 50%;
				width: 26px;
				height: 26px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 8px;

				&--checked {
					background: $color-positive-dark;
				}

				.abs-icon {
					color: $color-neutral-0;
					font-size: 16px;
				}
			}

			&__title {
				color: $color-neutral-60;
				font-size: 13px;
				font-weight: 500;
				margin: 0;
				text-transform: uppercase;
			}

			&__subtitle {
				color: $color-neutral-60;
				font-size: 13px;
				font-weight: 400;
				margin: 0;
			}
		}

		&__body {

			position: relative;

			.fw-form-field {
				margin-bottom: 10px;

				&__label {
					padding: 0 10px 0 0;
					position: relative;

					&--required:after {
						content: "*";
						color: $color-negative-dark-3;
						position: absolute;
						top: -2px;
						right: 0;
						font-size: 1em;
					}

					&.double-sized {
						line-height: 40px;
					}
				}

				&__control {

					input {
						color: $color-neutral-80;
						outline: none;
					}

					textarea {
						color: $color-neutral-80;
						outline: none;
						width: 405px;
						height: 215px;
					}

					.remaining-signs {
						font-size: 11px;
						text-align: right;
					}

					.remaining-signs-creatable-select-input {
						font-size: 11px;
						text-align: right;
						margin-top: 3px;
					}

					.fw-suffix-input-field {
						position: relative;
						display: inline-block;

						&.fw-currency-suffix-input-field input {
							padding-right: 40px;
						}

						&__suffix {
							position: absolute;
							right: 8px;
							color: $color-neutral-60;
							line-height: 26px;
						}
					}

					&--size-S {
						input {
							width: 117px;
						}

						.r-ss-wrap {
							width: 96px;
						}
					}

					&--size-M {
						input {
							width: 209px;
						}

						.r-ss-search-inner input {
							width: 189px;
						}

						.abs-money-input-field {
							width: 147px;
						}

						.calendar-for.calendar-form .abs-dropdown-for m, .abs-custom-calendar.abs-custom-calendar--single, .abs-custom-calendar-input-field.abs-custom-calendar-input-field--single {
							width: 105px;
						}

						.r-ss-wrap {
							width: 209px;
						}
					}

					&--size-L {
						input {
							width: 266px;
						}

						.r-ss-wrap {
							width: 266px;
						}
					}
				}

				&--invalid {
					.r-ss-wrap {
						.r-ss-trigger {
							border-color: $color-negative-dark-4;
							background: $color-negative-light;

							&:hover {
								border-color: $color-negative-dark-4;
								background: $color-negative-light;
								color: $color-negative-dark-4;
							}

							&:focus {
								box-shadow: 0 0 5px 0 $color-negative-dark-4;
							}
						}
					}

					.Select-input {
						input {
							border-color: $color-negative-dark-4;
							background: $color-negative-light;

							&:hover {
								border-color: $color-negative-dark-4;
								background: $color-negative-light;
								color: $color-negative-dark-4;
							}

							&:focus {
								box-shadow: 0 0 5px 0 $color-negative-dark-4;
							}
						}
					}
				}

				.fw-dropzone {
					@media (max-width: $mobile-mode-max) {
						width: 80%;
						display: inline-block;
						padding-left: 30px;
					}
				}
			}
		}

		@media (max-width: $mobile-mode-max) {

			&__body {
				padding: 0 15px;
				width: 100%;
				position: relative;

				.fw-form-field {
					text-align: left;
					margin-bottom: 10px;

					&__control {
						input, textarea, .r-ss-wrap {
							width: 100%;
						}
					}

					.fw-suffix-input-field {
						width: 100%;

						input {
							width: 100%;
						}
					}

					.calendar-form {
						.abs-custom-calendar, .abs-dropdown-form, .abs-custom-calendar-input-field {
							width: 100%;
						}

						.abs-filter-calendar-complex--single .abs-start-calendar {
							margin: 0 auto;
							float: none;
						}
					}

					.fw-dropzone {
						@media (max-width: $mobile-mode-max) {
							width: 80%;
							display: inline-block;
							padding-left: 30px;
						}
					}
				}
			}
		}

		@media (min-width: $desktop-mode-min) {

			&--divide-half {
				.fw-form-section__body {
					width: 100%;
					position: relative;

					.fw-form-field {
						display: flex;
						flex-direction: row;
						justify-content: center;

						&__label {
							flex-basis: 50%;
							text-align: right;
							margin: 0 10px 0 0;
							line-height: 26px;

							&.double-sized {
								line-height: 40px;
							}
						}

						&.double-sized {
							line-height: 40px;
						}

						&__control {
							flex-basis: 50%;
							text-align: left;
						}

						.fw-dropzone {
							@media (max-width: $mobile-mode-max) {
								width: 80%;
								display: inline-block;
								padding-left: 30px;
							}
						}

					}
				}
			}

			&--table-centered {
				.fw-form-section__body {
					display: table;
					position: relative;

					&--fixed {
						table-layout: fixed;
						width: 100%;

						.fw-form-field__label {
							width: 45%;
						}
					}

					.fw-form-field {
						display: table-row;

						&__label {
							display: table-cell;
							text-align: right;
							line-height: 26px;
							vertical-align: top;

							&.double-sized {
								line-height: 40px;
							}
						}

						&__control {
							text-align: left;
							display: table-cell;
							padding-left: 10px;
							padding-bottom: 10px;
						}

						&--no-label {
							.fw-form-field__control {
								padding-left: 0;
							}
						}

						.fw-dropzone {
							@media (max-width: $mobile-mode-max) {
								width: 80%;
								display: inline-block;
								padding-left: 30px;
							}
						}
					}
				}
			}
		}
	}

	.r-ss-wrap {
		float: none;
		position: relative;
		word-break: break-all;
		word-wrap: break-word;

		&.fw-select--double-sized {
			.r-ss-trigger {
				min-height: 40px;
				height: auto;
				line-height: 18px;
			}
		}

		&.double-sized {
			.r-ss-trigger {
				height: 40px;
				line-height: 18px;
			}
		}

		.r-ss-trigger {
			padding: 1px 1px 1px 8px;
			width: inherit;
		}

		.r-ss-dropdown {
			width: inherit;
			margin-left: 0;

			background: $color-neutral-0;
			border: 1px solid $color-neutral-33;
			@include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.35));
			@include border-radius(2px);

			@media (max-width: $mobile-mode-max) {
				position: static;
				width: 100%;
				margin-top: 5px;
			}
		}
	}

	.form-select-additional-info {
		margin: 5px 0 0 5px;
		height: 15px;
	}

	.calendar-form {

		.abs-dropdown-form {
			padding: 0;
			margin-right: 0;

			.abs-custom-calendar-input-field {
				cursor: auto;
				position: relative;
				z-index: 1;
				background: transparent;
			}
		}
	}

	.abs-money-input-field {
		text-align: right;
	}

	&.abs-col-1 hr {
		margin-bottom: 0;
		border-color: $color-neutral-8;
	}
}

@media (min-width: $desktop-mode-min) {
	.fw-form-container {

		.fw-form.container,
		.validation-header.container {
			max-width: $desktop-mode-min;
			width: auto;
		}

		.container.button-wrap.abs-fixed-bottom {
			width: auto;
		}
	}
}

@media (min-width: 992px) {
	.fw-form-container {

		.fw-form.container,
		.validation-header.container {
			max-width: 992px;
			width: auto;
		}
	}
}

@media (max-width: $mobile-mode-max) {
	.fw-form-container .container.button-wrap.abs-fixed-bottom:not(.on-scroll),
	.abs-form-panel + .container.button-wrap.abs-fixed-bottom:not(.on-scroll) {
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (min-width: $mobile-mode-max + 1) and (max-width: 807px) {
	.fw-form-container .container.button-wrap.abs-fixed-bottom:not(.on-scroll),
	.abs-form-panel + .container.button-wrap.abs-fixed-bottom:not(.on-scroll) {
		margin-left: 20px;
		margin-right: 20px;
	}
}

@media (min-width: 808px) {
	.fw-form-container .container.button-wrap.abs-fixed-bottom,
	.abs-form-panel + .container.button-wrap.abs-fixed-bottom {
		width: auto;
		margin-left: calc(50% - 384px);
		margin-right: calc(50% - 384px);
	}
}

@media (min-width: 992px) {
	.fw-form-container .container.button-wrap.abs-fixed-bottom,
	.abs-form-panel + .container.button-wrap.abs-fixed-bottom {
		width: auto;
		margin-left: calc(50% - 496px);
		margin-right: calc(50% - 496px);
	}
}

@media (min-width: 992px) and (max-width: 1032px) {
	.fw-form-container .container.button-wrap.abs-fixed-bottom,
	.abs-form-panel + .container.button-wrap.abs-fixed-bottom {
		width: auto;
		margin-left: 20px;
		margin-right: 20px;
	}
}

@media (min-width: 992px) and (max-width: 1032px) {
	.fw-form-container .container.button-wrap.abs-fixed-bottom.on-scroll,
	.abs-form-panel + .container.button-wrap.abs-fixed-bottom.on-scroll {
		width: auto;
		margin-left: 0;
		margin-right: 0;
	}
}

.abs-container .fw-form-container .fw-settings-user-profile-form {
	padding: 0;
	margin-bottom: 0;

	.fw-settings-user-profile-section {
		display: flex;
		flex-direction: column;

		&.fw-settings-user-profile-section-details {
			margin: 29px 35px 40px;
			@media (max-width: $mobile-mode-max) {
				margin: 29px 10px 40px;
			}

			.header-2b {
				margin: 10px 0 5px;
			}

			.fw-settings-user-profile-section-details__content {
				margin-top: 15px;
				padding-right: 120px;
				@media (max-width: $mobile-mode-max) {
					padding-right: 0;
				}
			}
		}

		&.fw-settings-user-profile-section-authorities {
			margin: 54px 35px 20px;
			@media (max-width: $mobile-mode-max) {
				margin: 54px 10px 20px;
			}

			.fw-settings-user-profile-section-authorities__content {
				display: flex;
				justify-content: space-between;
				word-break: break-word;
				margin: 20px 0 5px;

				.column {
					@media (min-width: $desktop-mode-min) {
						column-count: 2;
						column-gap: 30px;
					}

					> span {
						display: block;
						margin-bottom: 10px;
						width: 100%;
						word-break: normal;
					}
				}
			}
		}

		.header-2b {
			margin: 10px 0 0;
		}
	}

}

input[type=checkbox][disabled] + label {
	opacity: 0.4;
	cursor: not-allowed;
}

.abs-form-field-error-message {
	.abs-icon.abs_error {
		margin-right: 2px;

		&:before {
			color: $color-negative-dark-3;
			font-size: 14px;
		}
	}

	.icon-text {
		color: $color-negative-dark-4;
		font-size: 13px;
	}
}

input.k-invalid {
	border-color: $color-negative-dark-1;
}

.fw-custom-form {
	.abs-form-panel {
		.custom-select-height {
			@media (max-width: $mobile-mode-max) {
				height: auto;
			}
		}

		.r-ss-wrap {
			@media (min-width: $desktop-mode-min) {
				width: 246px;
			}
		}

		.form-horizontal .form-group.form-radio-group {
			@media (max-width: $mobile-mode-max) {
				display: flex;
				align-items: center;
				justify-content: center;

				.fw-radio-group-element-input {
					width: 0;
				}
			}
		}
	}
}

input.toggle-flip + label:before {
	border-color: $input-border-color;
}

input.toggle-flip + label:hover:before {
	border-color: $input-border-color-hover;
}

input.toggle-flip + label:focus:before {
	border-color: $input-border-color-focus;
	@include box-shadow($input-box-shadow-focus)
}

input.toggle-flip + label:active:before {
	border-color: $input-border-color-focus;
	@include box-shadow($input-box-shadow-focus);
	background: $input-bg-color-active;
}

input.toggle-flip + label:after {
	color: $input-checkbox-color;
}

input.toggle-flip + label:hover:after {
	border-color: $input-checkbox-border-color-active;
	color: $input-checkbox-color-active;
}

input.toggle-flip:checked + label:before {
	background: $input-bg-color-active;
	border-color: $input-border-color-active;
}

input.toggle-flip:checked + label:after {
	background: $input-bg-color-active;
	border-color: $input-checkbox-border-color-active;
	color: $input-checkbox-color-active;
}

input, input.k-textbox, textarea, textarea.form-control {
	background: $input-bg-color;
	border-color: $input-border-color;
	color: $input-color;

	&:hover {
		border-color: $input-border-color-hover;
		color: $input-color;
	}

	&:focus {
		@include box-shadow($input-box-shadow-focus);
		border-color: $input-border-color-focus;
		color: $input-color;
	}

	&:active {
		background: $input-bg-color-active;
		border-color: $input-border-color-active;
		color: $input-color-active;
	}
}

*[disabled], .k-state-disabled {
	opacity: .35;
}

.k-textbox[disabled], .k-textbox[disabled]:hover, textarea[disabled], textarea[disabled]:hover {
	opacity: .35;
}

%validation-style-positive-number {
	@include box-shadow(none);
	border-color: $forms-validation-border-color;
	background: $forms-validation-bg;
	color: $forms-validation-border-color;
}

.invalid-positive-number {
	border-color: $forms-validation-border-color;
	background: $forms-validation-bg;

	&:hover {
		@extend %validation-style-positive-number;
	}

	&:focus {
		@extend %validation-style-positive-number;
		@include box-shadow(0px 0px 5px 0px $forms-validation-border-color);
	}

	&:active {
		@extend %validation-style-positive-number;
	}
}

.fw-form-section-additional-information {
	border: 1px solid $color-neutral-27;
	display: block;
	padding: 10px;
	position: relative;

	&__info {
		color: $color-neutral-46;
	}

	&__link {
		color: $color-brand-primary-utility-1-darken-10 !important;
		font-weight: bold;
		padding: 0 10px;
	}

	&__icon {
		color: $color-warning-dark-1;
		font-size: 16px;
		margin: 0 5px;
		vertical-align: text-bottom;
	}

	&:before,
	&:after {
		position: absolute;
		display: block;
		content: "";
		border-color: transparent;
		border-style: solid;

		border-width: 0 8px 10px;
	}
}

.abs-modal-title {
	text-align: center !important;
}

.abs-form-import {
	max-width: 970px;

	@media (max-width: 991px) {
		max-width: 680px;
	}
}

.abs-form-parent-invoice-number {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
