/* Lumen footer
   ========================================================================= */

.absui-footer {
  align-self: flex-end;
  background: $token-footer-background;
  width: 100%;
  min-height: $token-footer-min-height;
  border-top: 1px solid $token-footer-border-color;
  bottom: 0;
  margin: auto 0 0;
  padding: 0;
  color: $token-footer-text-color;
  font-size: 1rem;

  &__logo {
    float: left;
    margin: 10px 30px 10px 10px;
    height: 20px;

    @include absui-breakpoint-down(sm) {
      min-width: 100px;
      display: block;
      margin: 20px auto;
      float: none;
      text-align: center;
    }
  }

  &__logo-abs {
    float: right;
    margin-top: 16px;
    margin-right: 0;
    fill: currentColor;
    color: $token-theme-color-neutral-100;

    &:hover {
      color: $token-theme-color-neutral-70;
    }

    @include absui-breakpoint-down(sm) {
      display: none;
    }
  }

  &__item {
    float: left;
    color: $token-footer-text-color;
    display: inline-block;
    line-height: 50px;
    margin-right: 10px;
    margin-bottom: 0;
    padding: 0;
    position: relative;
    font-size: 1rem;

    @include absui-breakpoint-down(sm) {
      clear: left;
      line-height: 1.6;
      margin: 0;
      width: 300px;
      float: none;
      display: block;
      padding-left: 25px;
    }

    &:last-of-type {
      &:before {
        content: " • ";
        display: inline;
        width: 14px;
        color: $token-theme-color-neutral-60;
        font-size: 26px;
        vertical-align: bottom;
        //line-height: 1rem;
      }

      @include absui-breakpoint-down(sm) {
        padding-bottom: 16px;

        &:before {
          display: none;
        }
      }
    }
  }

  &__item--icon {
    padding-left: 25px;
    margin-right: 10px;
    font-weight: 500;

    @include absui-breakpoint-down(sm) {
      padding-top: 15px;
    }

    .absui-icon {
      position: absolute;
      left: 0;
      top: 15px;
      font-size: 18px;
    }
  }

  &__item--contact {
    font-weight: bold;
    padding-top: 0;
    padding-left: 0;
    margin-left: 0;

    @include absui-breakpoint-down(sm) {
      padding: 0;
      margin-top: 15px;
      margin-left: 0;
      margin-right: 0;
      display: block;
      float: left;
      width: auto;
    }
  }

  &__item--version {
    margin-left: 5px;

    @include absui-breakpoint-down(sm) {
      padding: 0;
      margin-top: 15px;
      display: block;
      clear: none;
      float: right;
      text-align: right;
      width: auto;
    }
  }

  &__version {
    border-radius: 3px;
    background: $token-theme-color-neutral-30;
    color: $token-theme-color-neutral-70;
    padding: 3px 8px;
  }

  &__link {
    //text-decoration: none;
    color: $token-footer-text-color;

    &:hover,
    &:focus {
      color: $token-footer-text-color;
    }
  }

  &__copyrights {
    background: $token-theme-color-neutral-20;
    color: $token-theme-color-neutral-60;
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__copyrights-text {
    padding: 5px 10px 5px 20px;
    color: $token-theme-color-neutral-60;
    text-align: center;
    margin: 0;
    font-size: 0.8462rem;
  }

  &__cookies {
    padding: 15px 0;
  }

  &__cookies-text {
    max-width: 900px;
    display: block;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
  }

  &__cookies-link {
    color: $token-theme-color-neutral-70;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $token-theme-color-neutral-80;
    }
  }
}