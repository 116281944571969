@font-face {
  font-family: 'Inter';
  src: url('#{$lumen-font-path}/Inter-Regular.woff') format('woff');
  font-weight: $token-font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$lumen-font-path}/Inter-Medium.woff') format('woff');
  font-weight: $token-font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$lumen-font-path}/Inter-SemiBold.woff') format('woff');
  font-weight: $token-font-weight-semibold;
  font-style: normal;
}

$absui-columns: 12 !default;
$absui-grid-gutter: 16px !default;

$absui-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1280px,
        xxl: 1920px
) !default;

$absui-max-containers: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: $token-container-size-desktop
) !default;

html,
body {
  display: flex;
  flex-direction: column;
  background-color: $token-body-background;
  -ms-overflow-style: scrollbar;

  @include absui-breakpoint-up(md) {
    &.vertical-menu {
      margin-left: 48px;
    }
  }
}

html {
  @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
    font-size: 14px;
  }
}

body > * {
  flex-shrink: 0;
}

%header-placeholder {
  line-height: 1;
  white-space: nowrap;
  text-align: left;
}

.absui-header {
  font: $token-typeset-header-1;
  @extend %header-placeholder;
}

.absui-header-2 {
  @extend %header-placeholder;
  font: $token-typeset-header-2;
}

.absui-header-2b {
  @extend %header-placeholder;
  font: $token-typeset-header-2;
  margin: 12px;
}

.absui-header-3 {
  @extend %header-placeholder;
  font: $token-typeset-header-3;
}

.absui-header-4 {
  @extend %header-placeholder;
  font: $token-typeset-header-4;
}

.absui-header-4b {
  @extend %header-placeholder;
  font: $token-typeset-header-4;
  color: $token-theme-color-text-brand;
}

.absui-subtitle {
  font: $token-typeset-subheader-regular;
}

.absui-subtitle-b {
  font: $token-typeset-subheader-medium;
  color: $token-theme-color-text-neutral-strong;
}

.absui-section-header {
  display: flex;
  align-items: center;
  color: $token-section-header-text-color;
  height: $token-section-header-height;
  background-color: $token-section-header-background;
  font-size: $token-section-header-text-font-size;
  font-weight: $token-section-header-text-font-weight;
  border-radius: $token-section-header-radius;
  padding: 0 12px;
  margin: $token-section-header-margin;

  &__icon {
    font-size: $token-section-header-icon-size;
    margin-right: 6px;
  }

  &__text {
    margin-right: 10px;
  }

  &__label {
    color: $token-theme-color-text-neutral-middle;
    font-weight: $token-font-weight-regular;
    font-size: 1rem;
    margin-right: 10px;
  }

  &__divider {
    &:before {
      content: " • ";
      color: $token-theme-color-neutral-50;
      font-size: $token-section-header-icon-size;
      margin-right: 10px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    font-size: $token-section-header-icon-size;
    margin-left: auto;
  }

  &__toggle {
    display: none;
    cursor: pointer;
    padding: 4px;
  }

  &.open {
    .absui-section-header__toggle {
      transform: rotate(180deg);
    }
  }
}

.absui-header-expand {
  position: relative;
  padding: 12px 40px 12px 0;
  margin: 0;
  line-height: 1;
  cursor: pointer;

  &--border {
    border-bottom: 1px solid $token-separator-color;
  }

  &:after {
    font-family: Lumen-Linear-Icon-Font;
    content: "\e313";
    font-size: 18px;
    position: absolute;
    right: 12px;
    top: calc(50% - 9px);
  }

  &.open:after {
    transform: rotate(180deg);
  }

  @include absui-breakpoint-down(sm) {
    &:before {
      display: block;
      content: " ";
      height: 1px;
      width: 100%;
      border-bottom: 1px solid $token-separator-color;
      position: absolute;
      bottom: 0;
    }
  }
}

/* Lumen containers
   ========================================================================= */

.absui-container {
  @include absui-container();
  @include absui-container-max();
}

.absui-container-fluid {
  @include absui-container();
}

.absui-container-box {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  position: relative;
  flex-grow: 1;

  @media (min-width: $token-container-size-desktop) {
    max-width: $token-container-size-desktop;
    margin: 0 auto;
  }
}

.absui-container-flex {
  display: flex;
  justify-content: space-between;

  @include absui-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.absui-row {
  display: flex;
  flex-wrap: wrap;
}

.absui-box {
  flex: 1;
  min-height: 1rem;
  border-radius: $token-radius-small;
  box-shadow: $token-shadow-down-s;
  background: $token-theme-color-neutral-100;
  height: auto;
  padding: $token-box-padding;
  margin: $token-box-margin;

  @include absui-breakpoint-down(sm) {
    flex: 100%;
  }

  &--full {
    border-radius: 0;
    //box-shadow: $token-shadow-down-s;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.14);
  }

  &__header {
    color: $token-theme-color-neutral-100;
    background: linear-gradient(20deg, $token-theme-color-brand-10, $token-theme-color-brand-30);
    border-radius: $token-radius-small $token-radius-small 0 0;
    padding: 16px;
    margin: -12px -16px 12px;
  }

  &__title {
    font-size: $token-font-size-subheader;
    font-weight: $token-box-title-font-weight;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-size: 14px;
  }

  &__separator {
    height: 0;
    margin: $token-box-padding -16px;
    border-bottom: 1px solid $token-separator-color;
  }
}

.absui-actions-container {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  &__group {
    &:last-of-type {
      text-align: right;
    }
  }
}

.absui-icon-help {
  color: $token-theme-color-neutral-50;
  font-size: 20px;
  line-height: 32px;
  margin: 0 10px;
  cursor: help;

  @include absui-breakpoint-down(sm) {
    margin: 4px 10px;
  }
}

.absui-expand {
  display: flex;
  font-size: 18px;
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;

  &:focus,
  &.active {
    outline: none;
  }

  &.open {
    .absui-icon--expand-more {
      transform: rotate(0.5turn);
    }
  }
}

.absui-simple-list {
  margin-bottom: 16px;
}

.absui-simple-list__item {
  display: flex;
  padding: 2px 0;

  &:hover {
    background-color: $token-theme-color-neutral-95;
  }
}

.absui-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $token-z-index-overlay;
  background: $token-overlay-background;
  opacity: $token-overlay-opacity;
  animation: backdrop-show $token-time-transition-fast forwards;

  &.hide {
    animation: backdrop-hide $token-time-transition-fast forwards;
  }
}

.absui-cover {
  position: relative;

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(to bottom, transparent, $token-theme-color-neutral-100);
  }
}

.absui-simple-truncate {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.absui-truncate__line {
  margin-bottom: 0;
  display: block;
  width: 100%;
}

@keyframes backdrop-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes backdrop-hide {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}

.absui-scroll-up {
  display: none;
  border-radius: 4px;
  opacity: 0.9;
  background: #6a7682;
  padding: 4px 5px;
  position: fixed;
  bottom: 20px;
  z-index: 1048;
  right: 10px;

  @include absui-breakpoint-down(sm) {
    border-radius: 3px;
    bottom: 60px;
    right: 0;
  }

  &:focus,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  .absui-icon {
    color: $token-theme-color-neutral-100;
    font-size: 22px;
  }
}

%cookies-policy-style {
  line-height: 15px;
  color: $token-theme-color-neutral-60;
  font-size: 0.85rem;
  text-align: center;
}
