/* Lumen loader
   ========================================================================= */

.absui-loader {
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: $token-loader-overlay-opacity;
    background: $token-loader-overlay-color;
    z-index: $token-z-index-loader;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    width: $token-loader-size;
    height: $token-loader-size;
    border-radius: 50%;
    background: transparent;
    z-index: $token-z-index-loader;
    border-top: 3px solid $token-loader-oval-color;
    border-right: 3px solid $token-loader-oval-color;
    border-bottom: 3px solid $token-loader-oval-color;
    border-left: 3px solid $token-loader-spinner-color;
    animation: loading $token-time-transition-slow infinite cubic-bezier(0.30, 0.50, 0.75, 0.50);

    @keyframes loading {
      to {
        transform: rotate(360deg);
      }
    }
  }

  &--big::after {
    width: $token-loader-size-big;
    height: $token-loader-size-big;
    margin: -18px 0 0 -18px;
    border-width: 4px;
  }

  &--small::after {
    width: $token-loader-size-small;
    height: $token-loader-size-small;
    margin: -8px 0 0 -8px;
    border-width: 2px;
  }

  &--light {

    &::before {
      background: transparent;
    }

    &::after {
      border-color: transparent;
      border-bottom-color: $token-theme-color-neutral-100;
      border-left-color: $token-theme-color-neutral-100;
    }
  }

  &--transparent {
    &::before {
      display: none;
    }
  }

  &--fullscreen {

    &::before {
      position: fixed;
      height: 100vh;
      z-index: $token-z-index-loader;
    }

      &::after {
      position: fixed;
    }
  }
}