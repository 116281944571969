
/* Lumen data table flex - general styles
   ========================================================================= */

.absui-data-table {

  &__item {
    border-bottom: none;
    word-break: break-all;

    &--bold,
    &--name {
      font-weight: bold;
    }

    &--clean {
      border-right: none !important;
    }

    &--right {
      text-align: right !important;
    }
  }

  &__expand {
    font-size: $token-table-icon-size;
    height: 18px;
    padding-right: 10px;
    color: $token-link-text-color;
  }

  .absui-collapse-toggle {
    border-top: none;
    height: 100%;
    cursor: pointer;

    &.open {
      .absui-data-table__expand {
        transform: rotate(-180deg);
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }

  .absui-collapse-content-row {
    display: none;
    padding: 11px 37px;
    border-bottom: 1px solid $token-table-border-color;
  }
}

.absui-data-table--flex {
  display: block;

  .absui-data-table__head {
    width: 20%;
  }

  .absui-data-table-header__cell {
    display: block;
    border-bottom: 1px solid $token-table-border-color;
    width: auto;
    flex-grow: 1;
  }

  .absui-data-table-header__content {
    height: 16px;
    display: flex;
    align-items: center;
  }

  .absui-data-table-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $token-table-border-color;

    &:last-child {
      border-bottom: none;
    }

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .absui-data-table__cell {
    display: block;
    height: auto;
    width: auto;
    min-height: auto;
    flex-grow: 1;
    padding: 8px 12px;

    &:first-child {
      display: flex;
      //align-items: center;
      justify-content: space-between;
      word-break: break-all;
    }
  }

  .absui-data-table__label--mobile {
    display: none;
  }

  .absui-data-table__check-block {
    height: 16px;
    padding: 0 15px 0 0;
    margin: 0;
  }

  .absui-load-more__btn {
    border-bottom: none;
    border-right: none;
    border-left: none;
  }
}

.absui-data-table-header--flex {
  display: flex;

  &.fixed {
    position:fixed;
    top:0;
    z-index: 1039;
    background-color: $token-theme-color-neutral-100;
  }

  .absui-icon--clear {
    margin-right: 0;
  }
}

%absui-col {
  flex-shrink: 0;
  flex-grow: 1;
}

.absui-col-60 {
  flex-basis: 60px;
  @extend %absui-col;
}
.absui-col-120 {
  flex-basis: 120px;
  @extend %absui-col;
}
.absui-col-180 {
  flex-basis: 180px;
  @extend %absui-col;
}
.absui-col-240 {
  flex-basis: 240px;
  @extend %absui-col;
}
.absui-col-300 {
  flex-basis: 300px;
  @extend %absui-col;
}
.absui-col-360 {
  flex-basis: 360px;
  @extend %absui-col;
}
.absui-col-420 {
  flex-basis: 420px;
  @extend %absui-col;
}
.absui-col-480 {
  flex-basis: 480px;
  @extend %absui-col;
}
.absui-col-540 {
  flex-basis: 540px;
  @extend %absui-col;
}
.absui-col-600 {
  flex-basis: 600px;
  @extend %absui-col;
}
.absui-col-660 {
  flex-basis: 660px;
  @extend %absui-col;
}
.absui-col-720 {
  flex-basis: 720px;
  @extend %absui-col;
}

/* Lumen data table flex - mobile styles
   ========================================================================= */

.absui-data-table {

  @include absui-breakpoint-down(md, $absui-breakpoints, false, $absui-rwd-mode) {

    &--flex {

      .absui-data-table-row {
        display: block;
      }

      .absui-data-table__cell {
        display: block;
        max-width: none;
        padding: 0 12px 12px;
      }

      .absui-data-table__check-block {
        padding: 23px 10px 10px 20px;
      }

      .absui-data-table__hero-panel {
        margin-left: -12px;
      }

      .absui-dropdown {
        margin-right: -12px;
      }
    }

    &__head {
      border-bottom: 1px solid $token-table-border-color;
      padding: 25px 0;
      margin-bottom: 20px;
    }

    &__item {

      &:empty {
        display: none;
      }

      &--name {
        font-size: 1.231rem;
        color: darken($token-theme-color-brand-20, 100);
        text-transform: uppercase;
        font-weight: bold;
      }

      &--under {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }

    &__expand {
      display: none;
    }

    .absui-data-table-header--flex {
      display: none;
    }

    .absui-collapse-toggle {
      height: 100%;

      &:after {
        display: block;
        text-align: center;
        width: 100%;
        content: "\e313";
        font-family: 'Lumen-Linear-Icon-Font';
        font-size: 1.846rem;
        line-height: 24px;
        color: $token-link-text-color;
        border-top: 1px solid $token-table-border-color;
        cursor: pointer;
      }

      &.open:after {
        content: "\e316";
      }
    }

    .absui-collapse-content-row {
      display: none;
      padding: 11px;
      margin-top: -10px;
    }
  }
}