@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin radius($topleft, $topright: $topleft, $bottomright: $topleft, $bottomleft: $topright){
  -moz-border-radius-topleft:     $topleft;
  -moz-border-radius-topright:    $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft:  $bottomleft;
  -ms-border-radius:          $topleft $topright $bottomright $bottomleft;
  -webkit-border-radius:          $topleft $topright $bottomright $bottomleft;
  border-radius:                  $topleft $topright $bottomright $bottomleft;
}

@mixin borders($b-color, $b-top, $b-right, $b-bottom, $b-left ){
  border-left: $b-left solid $b-color;
  border-top: $b-top solid $b-color;
  border-right: $b-right solid $b-color;
  border-bottom: $b-bottom solid $b-color;
}

@mixin box-shadow($top, $left, $blur, $color, $spread:false,  $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread  $color;
    box-shadow:inset $top $left $blur $spread  $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread  $color;
    box-shadow: $top $left $blur $spread  $color;
  }
}

@mixin background-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}


@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


// gradient na komóce grida
@mixin element-gradient($color,$width, $height){
  content: "";
  display: block;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(to right, transparent, $color, $color);
  width: $width;
  height: $height;
  position: absolute;
}


@mixin font-style($size, $weight, $line-height: false){}

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-wrap($value: wrap) {
  -ms-flex-wrap:$value;
  -webkit-flex-wrap:$value;
  flex-wrap:$value;
}

@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  -ms-align-content: $value;
  align-content: $value;
}


@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%clearfix{
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin font-style( $font-name , $font-weight, $font-style){
  font-family: $font-name;
  font-weight:$font-weight;
  font-style: $font-style;
}


@mixin fonts-size($normal, $mobile){
  font-size:$normal;
  @media (max-width: $grid-float-breakpoint-max) {
    font-size:$mobile;
  }
}

@mixin non-select-font(){
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
@mixin field-icon-style($color) {
  @include radius(2px);
  background:$color;
  color:#fff;
  padding:2px 3px 2px 3px;
  padding:4px 5px;
  @media (max-width: $grid-float-breakpoint-max){
    padding:5px 6px;
  }
  cursor:pointer;
  border-width:0px;
  min-width:auto;
  &:focus{
    text-decoration:none;
  }
  &:hover{
    background:darken($color , 10);
  }
  &.disabled{
    background:$disabled-action-icon-color;
  }
}


%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin gradient-primary ($first-color, $second-color) {
  background: $first-color;
  background: -moz-linear-gradient(-45deg, $first-color 0%, $second-color 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, $first-color), color-stop(100%, $second-color));
  background: -webkit-linear-gradient(-45deg, $first-color 0%, $second-color 100%);
  background: -o-linear-gradient(-45deg, $first-color 0%, $second-color 100%);
  background: -ms-linear-gradient(-45deg, $first-color 0%, $second-color 100%);
  background: linear-gradient(135deg, $first-color 0%, $second-color 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$first-color', endColorstr='$second-color', GradientType=1 );
}

@mixin box-shadow ($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin flex-space-between() {
  display: flex;
  justify-content: space-between;
}

@mixin flex-align-items-center() {
  display: flex;
  align-items: center;
}

@mixin flex-all-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}