/* Lumen login page
   ========================================================================= */

.absui-login-page {
  height: 100vh;
  background-color: $token-body-background;
  //background: url("../img/login-panel-bg.jpg") top center no-repeat $token-body-background;
  background-size: cover;

  @include absui-breakpoint-down(sm) {
    height: auto;
  }

  @include absui-breakpoint-down(xs) {
    justify-content: flex-start;
    align-items: stretch;
    border-top: 0;
  }

  &__container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    position: relative;
    flex-grow: 2;
    padding: 0;
    width: 100%;

    @include absui-breakpoint-down(xs) {
      justify-content: flex-start;
    }
  }
}

/* Lumen login panel
   ========================================================================= */

.absui-login-panel {
  width: $token-login-panel-width;
  position: relative;
  margin-right: auto;
  margin-left: 32px;
  overflow: hidden;
  background-color: $token-login-panel-background;
  border-radius: $token-login-panel-radius;
  box-shadow: $token-login-panel-shadow;

  @include absui-breakpoint-down(sm) {
    margin: 40px 16px;
    width: auto;
  }

  &__lang {
    position: absolute;
    top: 28px;
    right: -8px;

    .absui-dropdown__link {
      white-space: nowrap;
    }
  }

  &__form {
    position: relative;
    margin: 0 40px 40px;

    @include absui-breakpoint-down(xs) {
      margin: 0 32px 32px;
      align-self: center;
    }
  }

  &__brand {
    padding: 40px 0;
    text-align: center;
    fill: $token-login-panel-background;
  }

  &__title {
    font: $token-typeset-header-4;
    margin: 32px 0 20px;
  }

  &__message {
    margin: 16px 0 12px;
  }

  &__message-text {
    opacity: .8;
    margin: 0;
  }

  &__btn {
    width: 100%;
    margin: 15px 0!important;
  }

  &__label {
    color: $token-login-panel-label-color;
    padding: $token-login-panel-label-padding;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  &__remember {
    display: flex;
    justify-content: space-between;
    margin: 12px 16px 16px;
  }

  &__or {
    text-align: center;
  }

  .absui-form-group {
    position: relative;
    margin-bottom: 24px;
  }
}

/* Lumen password panel
   ========================================================================= */

.absui-password-panel {
  background: $token-login-panel-background;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    line-height: 1.2;
    font-weight: 500;
  }

  &__form {
    width: 260px;
    margin: 20px auto 78px;

    .absui-btn {
      float: right;
    }
  }

  &__footer {
    clear: both;
    height: 154px;
    border-top: 1px solid $token-separator-color;
    padding: $token-login-panel-footer-padding;
    margin: 0 48px;
    background-image: radial-gradient($token-theme-color-neutral-95, transparent 70%, transparent 100%);
    background-position: 50% -60px;
    background-repeat: no-repeat;

    img {
      width: 120px;
    }
  }
}