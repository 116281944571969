.limits {
  @include flexbox;
  justify-content: space-between;
  .limits-labels, .limits-values {
    text-align: right;
  }
  .limits-labels {
    .limit-label {
      display: block;
      font-weight: 700;
    }
  }
  .limits-values {
    margin-left: 12px;
    .formatted-money {
      display: block;
    }
  }
}