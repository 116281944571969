.abs-documents-for-acceptance-button{
  display: inline;
  width: 100%;
}

.abs-documents-for-acceptance-button-left{
  float: left;
}

.abs-documents-for-acceptance-button-right{
  float: right;
}

.abs-documents-for-acceptance-button-content{
  font-size: 12px !important;
}

.abs-documents-for-acceptance-title-left{
  float: left;
  padding-left: 10px;
  @media (max-width: $mobile-mode-max) {
    font-size: 12px;
  }
}

.abs-documents-for-acceptance-title-right{
  float: right;
  padding-right: 10px;
  @media (max-width: $mobile-mode-max) {
    float: left;
    padding-left: 10px;
    font-size: 12px;
  }
}

.abs-documents-for-acceptance_content{
  max-width: 100%;
  max-height: 200px !important;
  .content-icon {
    @extend .abs-filter-form-icon;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    float: none;
    text-align: center;

    .abs-icon {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 50px !important;
      color:$color-brand-primary !important;
      margin-right: 0px !important;
    }
  }
  @media (max-width: $mobile-mode-max) {
    overflow: inherit !important;
  }
}

.abs-documents-for-acceptance_title{
  text-align:center;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 17px;
  @media (max-width: $mobile-mode-max) {
    font-size: 14px;
  }
}

.modal-open .file-documents-for-acceptance-modal .modal-dialog .modal-content {
  width: 440px;
  height: 366px;
  max-width: none;
  .modal-body {
    justify-content: space-between;
    width: 100%;
    display: inline;
    padding-top: 40px;
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      word-wrap: break-word;
    }
    @media (max-width: $mobile-mode-max) {
      display: block;
      padding: 22px 10px;
      hr {
        margin: 22px 10px 22px 10px
      }
    }
  }
}

.file-documents-for-acceptance-modal .modal-dialog .modal-content .modal-body .modal-body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0 42px;
  height: 100%;
  max-height: none;
  &:nth-child(1) {
    @media (min-width: $desktop-mode-min) {
      border-right: 1px solid #e3e3e3;
    }
  }
  > div {
    width: 100%;
  }
  input {
    width: 100%;
  }
  p, h4 {
    margin-bottom: 7px;
    line-height: 18px;
  }

  .abs-form-field-error-message {
    .abs-icon.abs_error {
      margin-right: 2px;
      font-size: 14px;
      &:before {
        color: $color-negative-dark-3;
        font-size: 14px;
      }
    }

    .icon-text {
      color: $color-negative-dark-4;
      font-size: 13px;
    }
  }

  .hint {
    color: $color-neutral-60;
    font-size: 13px;
    text-align: center;
  }
  .btn-send {
    margin-top: 7px;
    width: 80px;
  }

  @media (max-width: $mobile-mode-max) {
    height: auto;
    padding: 0 31px;
    width: 100%;
  }
}

.modal-open .file-documents-for-acceptance-modal {
  @media (max-width: $mobile-mode-max) {
    &.modal {
      .modal-dialog {
        min-width: 304px;
      }
      .modal-content {
        width: 304px;
        height: 552px;
      }
    }
  }
}