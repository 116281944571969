.fw-error-form {
  max-width: 612px;
  min-height: 201px;
  width: auto;
  border: 1px solid $color-negative-dark-1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  background-color: $color-negative-light;
  margin: auto;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .fw-error-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 27px 10px 12px;
    .fw-error-form-header-label {
      align-self: center;
      i {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: $color-negative-dark-2;
        font-size: 36px;
      }
    }
    .fw-error-form-header-title {
      color: $color-neutral-80;
      font-size: 13px;
      text-align: center;
      margin-top: 5px;
      &.fw-error-form-header-title__no-margin {
        margin-bottom: 0px;
      }
    }
  }
  .btn-type-b {
    height: 26px;
    margin-bottom: 31px;
  }
}

.fw-error-form-content-list {
  padding-left: 31px;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 13px;
  list-style-type: none;
  color: $color-neutral-60;
  li {
    margin-top: 3px;
    &:before {
      content: "-";
      margin-right: 2px;
    }
  }
}
