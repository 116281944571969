.values-sequence {
  color: $abs-font-color;

  > div, > span {
    &:not(:first-of-type):before {
      color: $color-neutral-27;
      content: " • ";
      font-size: 24px;
      display: inline-block;
      vertical-align: middle;
      line-height: 13px;
      width: 20px;
      text-align: center;
    }
  }

  .values-sequence-value {
    > div {
      display: inline-block;
    }
  }

  .light-txt, .hint {
    color: $color-neutral-60;

    &.danger {
      color: $danger-icon-color;
    }
  }

  &--separate-lines {
    > div, > span {
      display: block;
      white-space: normal;

      &:not(:first-of-type):before {
        display: none;
      }
    }
  }
}

.vl-separated-values {
  @include flexbox();

  > :not(:first-child):before {
    content: "";
    margin: 0 5px;
    border-left: 1px solid $color-neutral-27;
  }
}

.mobile-column-label + .values-sequence {
  display: inline-block;
}

.values-sequence--separate-lines > div, .values-sequence--separate-lines > span {
  display: block;
  white-space: normal;
}
