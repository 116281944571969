$progress-background: $color-neutral-4 !default;
$progress-foreground: $color-brand-primary !default;

.progress-with-percent {
    font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
}

.progress-with-percent {
  display: inline-flex;
}

.progress-container {
    width: 180px;
    display: block;
    margin-right: 5%;

    @media (max-width: $screen-lg-min) {
        width: 150px;
    }

    @media (max-width: $screen-sm-min) {
        width: 100px;
    }

    .progress {
        height: 12px;
        background-color: $progress-background;
        border-radius: 2px;

        margin: 5px 0;

        @include box-shadow(none);

        .progress-bar {
            @include box-shadow(none);

            &.progress-bar-info {
                background-color: $progress-foreground;
            }
        }
    }
}

.progress-percent {
    line-height: 22px;
}



