.container-up-menu{
	background:$abs-up-menu-bg;
	height:25px;
	@media (max-width: $grid-float-breakpoint-max){
		min-height:37px;
		height:auto;
	}	
	
	.container-nav-a {
		@include flexbox;
		@include align-items(center);
		@include justify-content(flex-end);
		height:100%;
		z-index:1090;
		@media (max-width: $grid-float-breakpoint-max){
			@include flex-wrap(wrap);
				> a, > div ,  > .k-widget.k-combobox{
				height:37px !important;
			}
		}	

		//keno menu
		.k-menu.k-header{
			@include border-radius(0px);
			border-width:0px 1px 0px 1px;
			background-color:$abs-up-menu-bg;
			border-color:$abs-up-menu-bg;			
		}
		.k-widget.k-menu-horizontal>.k-item.k-first{
			@include border-radius(0px);
			@include box-shadow(none);
			// background-color:$abs-up-menu-bg;
			border-color:$abs-up-menu-bg;
			height:25px;
			padding:0px;
			@media (max-width: $grid-float-breakpoint-max){
				padding:0px 4px;
			}	
			@media (max-width: $grid-float-breakpoint-max){
				height:37px;
			}
			&.k-state-focused , &.k-state-hover{
				@include box-shadow(none);
			}
			&:hover{
				background-color:$abs-up-menu-link-hover-bg;
				>.k-link{
					background-color:$abs-up-menu-link-hover-bg;
				}
			}

			>.k-link{
				@include flexbox;
				@include align-items(center);
				@include justify-content(center);
				@include border-radius(0px);
				height:100%;
				background-color:$abs-up-menu-bg;
				border-color:$abs-up-menu-bg;
				color:$abs-up-menu-icon-color;
				padding:0px 5px;
				&.k-state-border-down{
					background-color:$abs-up-menu-link-hover-bg;	
				}
				> i{
					font-size:18px;
					@media (max-width: $grid-float-breakpoint-max){
						font-size:20px;
					}	
				}
				.k-icon.k-i-arrow-60-down{
					margin-right:0px;
					margin-left:2px;
				}
				.k-icon.k-i-arrow-60-down:before{
					content:'\f107';
					font-family:Lumen-Icon-Font;
					color:$abs-up-menu-icon-color;
				}
			}

		}





		//dropdown-menu-up
		.dropdown-menu-up{
			// @include flexbox;
			// @include align-items(center);
			// @include justify-content(flex-end);			
			height:100%;
			>.dropdown-toggle{
				&:hover{
					background-color:$abs-up-menu-link-hover-bg;
				}
				@media (max-width: $grid-float-breakpoint-max){				
					@include flexbox;
					@include align-items(center);
					@include justify-content(center);
					height:100%;
    				width: 40px;
    				cursor:pointer;
				}
				@media (min-width: $grid-float-breakpoint-max){				
					display:none;
				}
				>i{
					color:$abs-up-menu-icon-color;
					font-size:22px;
				}
			}
			&.open{
				>.dropdown-menu{
					z-index:1040;
				}	
			}
			>.dropdown-menu{
				@media (min-width: $grid-float-breakpoint-max){	
					@include border-radius(0px);
					@include box-shadow(none);
					border-color:$abs-up-menu-bg;
					background-color:$abs-up-menu-bg;
					 z-index:1070;
					width:100%;
					margin-top:0px;
					color:$abs-up-menu-font-color;
					padding:0px;			
					float:right;
					top:0px;
					width:auto;
					position:relative;
					// display:block;
					height:25px;
					border-width:0px;
					 @include flexbox;
					 @include align-items(center);
					 @include justify-content(flex-end);
					 @include flex-wrap(wrap);
					height:100%;
				}

				> li{
					@media (min-width: $grid-float-breakpoint-max){				
						float:left;
						height:25px;
					}
					a{
						color:$abs-up-menu-font-color;	
						padding: 0px 5px;
						height:100%;
						@include flexbox;
						@include align-items(center);
						@include justify-content(center);	
						&:hover, &:focus{
							background-color:$abs-up-menu-link-hover-bg;
						}
						&.truncated{
							overflow:hidden;						
							>span{
								text-overflow:ellipsis;
								overflow:hidden;
								white-space:nowrap;
							}
						}
						> i{
							font-size: 18px;
						}
						>span{
							font-size:11px;
							display: inline-block;
    						padding: 0px 3px 0px 6px;
    						@media (max-width: $grid-float-breakpoint-max){		
    							font-size:13px;
    						}	
						}
					}
				}

			}

			&.open > .dropdown-menu {
				@include border-radius(0px);
				@include box-shadow(none);
				border-color:$abs-up-menu-bg;
				background-color:$abs-up-menu-bg;
				
				width:100%;
				margin-top:0px;
				color:$abs-up-menu-font-color;
				padding:0px;
				@media (min-width: $grid-float-breakpoint-max){				
					float:right;
					top:0px;
					width:auto;
					position:relative;
					display:block;
				}

				> li{
					border-top:1px solid #4c4c4c;
					height:40px;
					@media (min-width: $grid-float-breakpoint-max){				
						float:left;
						height:25px;
						border-top:0px;						
					}
					>a {
						color:$abs-up-menu-font-color;
						height:40px;
						line-height:40px;
						padding-left:5px;
						@media (min-width: $grid-float-breakpoint-max){				
							line-height:25px;
							height:100%;
						}
						@media (max-width: $grid-float-breakpoint-max){				
							@include justify-content(flex-start);							
						}
						&:hover, &:focus{
							background-color:$abs-up-menu-link-hover-bg;
						}
					}

				}
				.k-widget.k-combobox{
					// width:100%;
					width: 250px;
    				height: 100%;    		
					@media (max-width: $grid-float-breakpoint-max){									
						width:100%;
					}	
		
					.k-dropdown-wrap , .k-dropdown-wrap.k-state-focused ,.k-dropdown-wrap.k-state-hover{
						padding-bottom:0px;
					}
				}
			}
		}



		.k-widget.k-combobox{
			width:250px;
			height:100%;
			background:$abs-up-menu-bg;


			.k-dropdown-wrap , .k-dropdown-wrap.k-state-focused ,.k-dropdown-wrap.k-state-hover {
				@include box-shadow(none);
				@include border-radius(0px);
				border-color:$abs-up-menu-bg;
				background-color:$abs-up-menu-bg;
				@media (max-width: $grid-float-breakpoint-max){				
					// padding-bottom:5px;
				}
				@media (min-width: $grid-float-breakpoint-max){				
					height:100%;
					border-width:0px 1px 0px 1px;
				}	

			}
			.k-dropdown-wrap > .k-select {
				height:25px;
				line-height:25px;
				.k-icon{
					margin-top:-4px;
					@media (max-width: $grid-float-breakpoint-max){				
						margin-top:7px;
					}
				}	
			}

			.k-dropdown-wrap > .k-input , .k-dropdown-wrap > .k-select{
				@include border-radius(0px);
				border-color:$abs-up-menu-bg;
				background-color:$abs-up-menu-bg;
				color:$abs-up-menu-font-color;				
				font-size:11px;
			}
			.k-dropdown-wrap > .k-input{
				padding-top:0px;
				@media (max-width: $grid-float-breakpoint-max){
					padding-top: 5px;
    				font-size: 13px;
    				padding-bottom: 7px;
    				height: 37px;
					.k-ie &{
						padding-top:5px;
					}
				}
				@media (min-width: $grid-float-breakpoint-max){				
					padding-top: 5px;
    				font-size: 11px;
    				padding-bottom: 5px;
    				height: 25px;

				}
			}

			.k-icon{
				color:$abs-up-menu-font-color;
			}
			.k-icon.k-clear-value{
				top: 12px;
    			margin-top: 0px;
    			@media (max-width: $grid-float-breakpoint-max){				
					top:17px;
				}
			}
			.k-dropdown-wrap > .k-select > .k-icon:before{
				color:$abs-up-menu-font-color;
				font-size:15px;	
				@media (max-width: $grid-float-breakpoint-max){				
					font-size:18px;
				}
			}
		}


  		.abs-icon{
  			margin-top:-1px;
  		}
		> a  , .text-element{	
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);		
			padding:0px 5px;
			height:100%;

			@media (max-width: $grid-float-breakpoint-max){
				padding:0px 9px;
				min-width:38px;
			}
			

			&:hover{
				background:$abs-up-menu-link-hover-bg;
				text-decoration:none;
			}
			&.active{
				background:$abs-up-menu-link-active-bg;
				color:$abs-up-menu-link-active-color;
				span,i{
					color:$abs-up-menu-link-active-color;
				}
			}
			&.truncated{
				overflow:hidden;
				max-width:200px;
				>span{
					text-overflow:ellipsis;
					overflow:hidden;
					white-space:nowrap;
				}
			}

			 .abs-icon{
				color:$abs-up-menu-icon-color;
				font-size:18px;				
				@media (max-width: $grid-float-breakpoint-max){
					font-size:20px;
				}
			}
			span{
				color:$abs-up-menu-icon-color;
				font-size:11px;
				display:inline-block;
				padding:0px 3px 0px 6px;	
				@media (max-width: $grid-float-breakpoint-max){
						font-size:13px;
				}				
				}
			}
		//dropdown w menu
		.dropdown{
			height:100%;
			> div{
				cursor:pointer;
				background:transparent;
				border-color:transparent;
				font-size:11px;
				padding: 0px 5px;
				@media (max-width: $grid-float-breakpoint-max){
					padding:0px 9px;
				}	
				height:100%;
				color:$abs-up-menu-icon-color;
				@include flexbox;
				@include align-items(center);
				@include justify-content(center);
				@include flex-direction(row);
  					@media (max-width: $grid-float-breakpoint-max){
						font-size:13px;
					}
				
				&:hover{
					background:$abs-up-menu-link-hover-bg;
				}
				>.abs-icon{
					position:relative;
				}
				>.abs-icon:nth-of-type(1){
					color:$abs-up-menu-icon-color;
					font-size: 18px;
					@media (max-width: $grid-float-breakpoint-max){
						font-size:20px;
					}
    				vertical-align: middle;
    				display: inline-block;
    				// top: -1px;
    				margin-right: 5px;    	
				}
				> i.abs-icon:nth-of-type(1){

				}
				> span{
					display:inline-block;
					@media (max-width: $grid-float-breakpoint-max){
						font-size:13px;
					}
				}
				>span.abs-icon{
					margin-left:7px;
				}
				> span.abs-icon{					
    					font-size: 14px;    
    					// position: absolute;
    					// top: -11px;  
    					// right:-18px;  				
    					transform: rotate(0deg);
    					transition: transform .2s ease-in-out;
    					transform-origin: 50% 50%;
    					@media (max-width: $grid-float-breakpoint-max){
							font-size:18px;
						}
    				
				}
			}
			&.open > div {
				background:$abs-up-menu-link-hover-bg;	
				> span.abs-icon{
						transform: rotate(180deg);
    					transition: transform .2s ease-in-out;
    					transform-origin: 50% 50%;
    				
    			}
			}
			ul{
				 // z-index:99999;
			}


			.dropdown-menu{
				@include border-radius(0px);
				@include box-shadow(none);
				border-color:$lang-box-border-color;
				padding:0px;
				margin:0px 0px 0px 0px;
				min-width:60px;
			 	li a{
			 		padding:.28em 1.8em .38em .9em;
			 		font-size:1em;
			 		@include border-radius(2px);
			 	}
			 	li a:hover{
				 	background:$context-menu-hover-bg;
			 	}
			}

		}
	}	
}