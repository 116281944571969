/* Lumen buttons
   ========================================================================= */

.absui-btn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: none;
  padding: 0 12px;
  border: 1px solid $token-button-outline-border-color;
  border-radius: $token-button-radius;
  background-color: $token-button-outline-background;
  background-image: none;
  height: $token-button-height;
  min-width: $token-button-min-width;
  margin-bottom: 0;
  color: $token-button-outline-text-color;
  //font: $token-button-text-font;
  font-size: 1rem;
  line-height: $token-button-text-line-height;
  font-weight: $token-button-text-font-weight;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  z-index: 99;
  transition: all $token-button-transition-time ease-in-out 0s;

  @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
    height: $token-button-height-mobile;
  }

  &:focus,
  &.focus {
    background-color: $token-button-outline-background-focus;
    border-color: $token-button-outline-border-color-focus;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    background-color: $token-button-outline-background-hover;
    border-color: $token-button-outline-border-color-hover;
    text-decoration: none;
  }

  &:active,
  &.active {
    background-color: $token-button-outline-background-active;
    border-color: $token-button-outline-border-color-active;
    outline: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: $token-button-opacity;
    pointer-events: none;
  }

  &__icon {
    vertical-align: middle;
    font-size: $token-button-icon-size;
  }

  &__title {
    display: inline-block;
    vertical-align: middle;
    line-height: $token-button-text-line-height;
    width: 100%;
    padding: 0 6px;
  }

  &--big {
    min-height: 38px;
    height: auto;
  }

  &--full {
    width: 100%;
    justify-content: center;
  }

  &--round {
    border-radius: 28px;
  }

  &--quad {
    width: $token-button-quad-width;
    min-width: $token-button-quad-width;
    padding: 0;
    justify-content: center;

    @include absui-breakpoint-down(sm, $absui-breakpoints, $absui-comfort-mode, $absui-rwd-mode) {
      width: $token-button-quad-width-mobile;
    }
  }

  &--anim {
    &:after {
      z-index: 9999;
      background: $token-theme-color-neutral-10;
      content: "";
      height: 150px;
      width: 25px;
      left: -45px;
      opacity: .2;
      position: absolute;
      top: -60px;
      transform: rotate(35deg);
      transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
      &:after {
        left: 120%;
        transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  .absui-dropdown & {
    .absui-icon--expand-more {
      display: inline-flex;
      font-size: $token-font-size-s;
      margin-left: auto;
      transition: transform $token-dropdown-transition-time ease-in-out;
    }
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-icon {
  font-size: 1.692rem;
  margin-top: 1px;
  margin-right: 5px;
  border-radius: 2px;
  height: 26px;
  line-height: 26px;

  &:hover {
    background: $token-theme-color-brand-70;
    text-decoration: none;
  }

  .absui-icon {
    height: 26px;
    line-height: 26px;
  }
}

%btn-equal {
  min-width: 64px;
  justify-content: center;
}

/* Button type A
   ========================================================================= */

.absui-btn--a {
  @include absui-button-variant(
    $token-button-outline-text-color,
    $token-button-outline-background,
    $token-button-outline-background-active,
    $token-button-outline-background-focus,
    $token-button-outline-background-hover,
    $token-button-outline-background-open,
    $token-button-outline-border-color,
    $token-button-outline-border-color-active,
    $token-button-outline-border-color-focus,
    $token-button-outline-border-color-hover,
    $token-button-outline-border-color-open
  );
}

/* Button type B
   ========================================================================= */

.absui-btn--b {
  @include absui-button-variant(
    $token-button-brand-text-color,
    $token-button-brand-background,
    $token-button-brand-background-active,
    $token-button-brand-background-focus,
    $token-button-brand-background-hover,
    $token-button-brand-background-open,
    $token-button-brand-border-color,
    $token-button-brand-border-color-active,
    $token-button-brand-border-color-focus,
    $token-button-brand-border-color-hover,
    $token-button-brand-border-color-open
  );
}

/* Button type C
   ========================================================================= */

.absui-btn--c {
  @include absui-button-variant(
    $token-button-light-text-color,
    $token-button-light-background,
    $token-button-light-background-active,
    $token-button-light-background-focus,
    $token-button-light-background-hover,
    $token-button-light-background-open,
    $token-button-light-border-color,
    $token-button-light-border-color-active,
    $token-button-light-border-color-focus,
    $token-button-light-border-color-hover,
    $token-button-light-border-color-open
  );
}

/* Button type D
   ========================================================================= */

.absui-btn--d {
  @include absui-button-variant(
    $token-button-transparent-text-color,
    $token-button-transparent-background,
    $token-button-transparent-background-active,
    $token-button-transparent-background-focus,
    $token-button-transparent-background-hover,
    $token-button-transparent-background-open,
    $token-button-transparent-border-color,
    $token-button-transparent-border-color-active,
    $token-button-transparent-border-color-focus,
    $token-button-transparent-border-color-hover,
    $token-button-transparent-border-color-open
  );
}

/* Button type E
   ========================================================================= */

.absui-btn--e {
  @include absui-button($token-theme-color-brand-10, $token-body-background, $token-theme-color-brand-20);
  font-size: 14px;
  font-weight: 500;
  border-radius: unset;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-width: 2px;
  height: 44px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  &:hover,
  &:active,
  &:focus {
    color: $token-theme-color-brand-20;
    background-color: $token-body-background;
    border-color: $token-theme-color-brand-10;
  }

  .absui-icon {
    margin-left: auto;
  }
}

/* Button negative/positive
   ========================================================================= */

.absui-btn--negative {
  @include absui-button-variant(
    $token-button-negative-text-color,
    $token-button-negative-background,
    $token-button-negative-background-active,
    $token-button-negative-background-focus,
    $token-button-negative-background-hover,
    $token-button-negative-background-open,
    $token-button-negative-border-color,
    $token-button-negative-border-color-active,
    $token-button-negative-border-color-focus,
    $token-button-negative-border-color-hover,
    $token-button-negative-border-color-open
  );
}

.absui-btn--positive {
  @include absui-button-variant(
    $token-button-positive-text-color,
    $token-button-positive-background,
    $token-button-positive-background-active,
    $token-button-positive-background-focus,
    $token-button-positive-background-hover,
    $token-button-positive-background-open,
    $token-button-positive-border-color,
    $token-button-positive-border-color-active,
    $token-button-positive-border-color-focus,
    $token-button-positive-border-color-hover,
    $token-button-positive-border-color-open
  );
}

/* Button expand
   ========================================================================= */

.absui-btn--expand {
  @include absui-button-variant(
    $token-theme-color-brand-10,
    transparent,
    transparent,
    $token-button-outline-background-hover
  );

  &:active {
    background-color: $token-button-outline-background-hover;
    color: $token-theme-color-brand-10;
  }

  &:focus {
    border-color: transparent!important;
  }

  &.show {
    .absui-btn__icon {
      transform: rotate(180deg);
    }
  }
}

%btn-type-mini-actions {
  border-radius: 2px;
  padding: 4px 5px;
  margin: 0 2px;
  cursor: pointer;
  color: $token-theme-color-neutral-10;
}

.absui-btn--danger {
  @extend %btn-type-mini-actions;
  background: $token-theme-color-negative-20;
}

.absui-btn--primary {
  @extend %btn-type-mini-actions;
  background: $token-theme-color-brand-20;
}

.absui-btn--success {
  @extend %btn-type-mini-actions;
  background: $token-theme-color-positive-20;
}

/* Button group
   ========================================================================= */

.button-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  margin-bottom: 20px;
  margin-top: 15px;

  .absui-btn {
    min-width: 80px;
    white-space: pre-line;

    &:first-of-type {
      border-radius: 2px 0 0 2px;
      margin-right: -1px;

      &:focus,
      &:hover {
        position: relative;
        z-index: 10;
      }
    }

    &:last-of-type {
      border-radius: 0 2px 2px 0;
    }
  }
}

/* Lumen button close
   ========================================================================= */

.absui-close {
  float: right;
  height: $token-button-height;
  width: $token-button-quad-width;
  min-width: unset;
  padding: 0;
  justify-content: center;
  font-size: $token-button-icon-size;
  line-height: $token-button-text-line-height;
  font-weight: $token-button-text-font-weight;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $token-button-radius;
  -webkit-appearance: none;

  @include absui-button-variant(
                  $token-button-transparent-text-color,
                  $token-button-transparent-background,
                  $token-button-transparent-background-active,
                  $token-button-transparent-background-focus,
                  $token-button-transparent-background-hover,
                  $token-button-transparent-background-open,
                  $token-button-transparent-border-color,
                  $token-button-transparent-border-color-active,
                  $token-button-transparent-border-color-focus,
                  $token-button-transparent-border-color-hover,
                  $token-button-transparent-border-color-open
  );

  &:not(:disabled) {
    cursor: pointer;
  }
}

/* Lumen icon link
   ========================================================================= */

.absui-icon-link {
  display: inline-flex;
  align-items: center;
  height: $token-link-icon-size;
  color: $token-link-text-color;
  cursor: pointer;
  transition: all $token-link-transition-time ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: $token-link-text-color-hover;
    outline: none;
    text-decoration: none;

    .absui-icon-link__text {
      text-decoration: underline;
    }
  }

  &--negative {
    color: $token-theme-color-negative-20;
  }

  &__icon,
  .absui-icon {
    font-size: $token-link-icon-size;
    margin-right: 6px;
  }
}
