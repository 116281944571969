.summary-display-position {
  @media (min-width: $desktop-mode-min) {
    display: flex;
    margin-left: 10px;
  }
  display: block;
  p {
    margin-bottom: 0px;
  }
}

.summary-block {
  text-align: center;
  margin: 0 0 20px 0;
  @media (max-width: $mobile-mode-max) {
    &:last-child {
      margin: 0;
    }
  }
  @media (min-width: $desktop-mode-min) {
    margin: 0 0 0 30px;
  }
  .summary-title {
    color: rgb(134, 134, 134);
    @media (min-width: $desktop-mode-min) {
      text-align: left
    }
    line-height: 16px;
    font-size: 13px;
    font-weight: 400;
  }
  .summary-value {
    font-size: 24px;
    line-height: normal;
    @media (min-width: $desktop-mode-min) {
      text-align: left
    }
    .formatted-money-integer {
      font-weight: 500;
    }
    .formatted-money-fraction, .formatted-money-currency {
      font-weight: 400;
      color: #797979;
    }
  }
}
