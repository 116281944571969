.text-chart-panel{
  @include border-radius(2px);
  background:$info-chart-panel-bg;
  border:1px solid $info-chart-panel-border;
  margin:20px 0px;
  transition:all .2s ease-in-out;
  @media (max-width: $grid-breakpoint-desktop-large) {
    margin:10px 20px;
  }
  .text-chart-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;

    @media (max-width: $grid-float-breakpoint-max){
      flex-direction:column;
    }
    .text-chart-item p{

    }
    .text-chart-item{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      @media (max-width: $grid-float-breakpoint-max){
        align-items:center;
      }
    }

    ul{
      list-style:none;
      margin:0px;
      padding:20px 0px 20px 5px;
      @media (max-width: $grid-float-breakpoint-max){
        padding-top:0px;
        margin-top:-10px;
      }
      li{
        margin:4px 0px 4px 0px;
        position:relative;
        padding:4px 5px;
        &.selected{
          background:$info-chart-li-selected-bg;
        }
      }

      li span:nth-of-type(1){
        @include border-radius(50%);
        width:8px;
        height:8px;
        display:inline-block;
        position:absolute;
        top:7px;
      }
      li span:nth-of-type(2){
        padding-left:15px;
      }
      li span:nth-of-type(3){
        float:right;
        margin-left:20px;
      }
    }

    .text-chart-item:nth-child(1) {
      border-right:1px solid $grid-border-color;
      padding:30px 40px;
      position:relative;
      margin-right:20px;
      @media (max-width: $grid-float-breakpoint-max){
        margin-right:0px;
        padding:20px 0px;
        border-right:0px;
        border-bottom:1px solid $grid-border-color;
      }
      p{
        line-height:1.2em;
        margin:0px;
        padding:0px 20px;
      }
      .item-info{
        font-size:1.2em;
      }
      .item-info-main{
        font-size:2.2em;
        font-weight:bold;
        >span{
          font-weight:normal;
        }
      }
      .item-info-sub{
        color:$info-chart-item-info-sub;

      }
    }

    .text-chart-item:nth-child(1):after{
      /*
        content:"";
        position:absolute;
        right:-8px;
        border-top:1px solid $grid-border-color;
        width:14px;
        height:14px;
        transform:rotate(45deg);
        background:$info-chart-panel-bg;
        display:block;
        vertical-align:middle;
        */
      content:'';
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 11px solid $info-chart-panel-bg;
      position:absolute;
      top:calc(50% - 11px);
      right:-10px;
      @media (max-width: $grid-float-breakpoint-max){
        border-top:11px solid $info-chart-panel-bg;
        border-left:11px solid transparent;
        border-right:11px solid transparent;
        border-bottom:none;
        top:calc(100% - 1px);
        left:calc(50% - 11px);
      }

    }
    .text-chart-item:nth-child(1):before{
      /*content:"";
      position:absolute;
      right:-8px;
      border-bottom:1px solid $grid-border-color;
      width:14px;
      height:14px;
      transform:rotate(-45deg);
      background:$info-chart-panel-bg;
      top:calc(50% - 6px);
      display:block;
      vertical-align:middle;*/
      content:'';
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 11px solid $info-chart-panel-border;
      position:absolute;
      top:calc(50% - 11px);
      right:-11px;

      @media (max-width: $grid-float-breakpoint-max){
        border-top:11px solid $info-chart-panel-border;
        border-left:11px solid transparent;
        border-right:11px solid transparent;
        border-bottom:0px;

        left:calc(50% - 11px);
        top:calc(100%);
      }

    }


    .text-chart-item .item-chart{


    }
  }

}




//rechart faktor charts


.recharts-wrapper{
  width:auto !important;
  height:auto !important;
  @include flexbox();
  @include align-items(center);
  > .recharts-surface{
    float:left !important ;
  }

  .recharts-tooltip-wrapper .chart-tooltip-content {
    @include border-radius(2px);
    background: $info-chart-tooltip-bg;
    border: none;
    color: $info-chart-tooltip-color;
    font-size: 13px;
    padding: 6px;
  }
  .recharts-pie-sector .recharts-sector.selected {
    opacity: 0.8;
  }
  .recharts-legend-wrapper{
    position:relative  !important;
    width:auto !important;
    height:auto !important;
    float:left;
    ul{
      list-style:none;
      margin:0px;
      padding: 20px 0px 20px 5px;
      li{
        margin:4px 0px 4px 0px;
        position:relative;
        padding:4px 5px;

      }
      li .chart-legend-item{
        @include flexbox();
        @include align-items(center);
        @include flex-direction(row);
      }
      li.selected span:not(.formatted-money-integer){
        font-weight:normal;
      }
      li .chart-legend-item .chart-legend-item-point{
        @include border-radius(50%);
        width: 8px;
        height: 8px;
      }
      li .chart-legend-item .chart-legend-item-value{
        padding: 0px 0px 0px 5px;
        display: flex;
        flex-grow: 2;
        justify-content: space-between;
      }
      li .chart-legend-item .chart-legend-item-value >span{
        padding-left: 2px;
        display: block;
      }
      li .chart-legend-item .chart-legend-item-value .formatted-money{
        @include flexbox();
        @include justify-content(flex-end);
        flex-grow: 1;
        text-align: right;
      }


      .formatted-money > .formatted-money-integer{
        font-weight:bold;
      }
      .formatted-money > span{
        padding:0px 1px 0px 2px;
      }

    }
  }
}


.text-chart-panel {

  min-height: 130px;
  @include box-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));

  margin: 0 0 20px;

  .text-chart-container {

    .text-chart-item {
      &.additional-item {
        border-right: none !important;
        border-left: 1px solid $grid-border-color;
        margin-left: 20px;
        padding-left: 15px !important;
        @media (max-width: $grid-float-breakpoint-max) {
          margin-right: 0px;
          margin-left: 0px;
          padding: 20px 0px;
          border-right: none;
          border-left: none;
          border-top: 1px solid $grid-border-color;
        }

        p {
          line-height: 24px;
          margin: 0px;
          padding: 0px 20px;
        }
      }

      p, &:nth-child(1) p {
        line-height: 24px;
      }

      .item-info, .item-info-sub {
        font-size: 1em;
        color: $color-neutral-60;
      }
      .item-info-main {
        font-size: 24px;
        font-weight: 400;
        > span {
          font-weight: normal;
        }

        .formatted-money-integer {
          color: $formatted-money-integer-color;
          font-weight: 500;
        }

        .formatted-money-fraction, .formatted-money-currency {
          color: $formatted-money-fraction-color;
          font-weight: 400;
        }

        .formatted-money-currency {
          color: $formatted-money-currency-color;
        }
      }

      &:nth-child(2n + 1) {
        .item-info-sub {
          color: $color-neutral-60;
        }
      }

      &:nth-child(1) {
        .item-info {
          font-size: 1em;
        }

        .item-info-main {
          font-size: 24px;
        }
      }
    }
  }
}


