.details-panel, .details-panel [role="listbox"] {
  min-height: 98px;

  .details-panel-sections {
    min-height: 98px;
    border-top: 1px solid $color-neutral-8;
    @media (min-width: $desktop-mode-min) {
      display: flex;
      .detail-section-container {
        width: 100%;
      }
    }
    @media (max-width: $mobile-mode-max) {
      .detail-sections-block {
        display: flex;
        margin: auto;
        flex-wrap: nowrap;

        &.scrollable {
          margin: 0;
        }

        .detail-section {
          margin: 0;
          padding-right: 0;
          min-height: 57px;

          &:not(:last-child) {
            padding-right: 16px;
            margin-right: 16px;
            border-right: 1px solid $color-neutral-8;
          }
        }
      }
    }

    .detail-section:nth-of-type(1) {
      .detail-title {
        color: #868686;

        &.title-primary {
          @include font-style($roboto-font-family, 500, normal);
          font-size: 16px;
          color: #333;
        }

        .detail-title-truncate-number {
          @media (min-width: $desktop-mode-min) {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

      }
    }

    .detail-section {
      display: inline;
      @media (min-width: $desktop-mode-min) {
        margin-left: 3%;
        margin-bottom: 0;
      }
      @media (max-width: $mobile-mode-max) {
        .limits {
          justify-content: normal;
        }
        &__icon {
          margin-bottom: 15px;
        }
      }

      .detail-title {
        color: #1E1E1E;
        font-weight: 500;
      }

      .detail-value {
        @include font-style($roboto-font-family, 400, normal);
        font-size: 13px;
        color: #000;

        &.additional-info {
          font-size: 13px;
          color: #868686;
        }
      }

      .r-ss-wrap {
        margin-top: -5px;
        min-width: 200px;
        max-width: 300px;

        .r-ss-trigger {
          border: 1px solid transparent;
          @include border-radius(2px);
          padding: 4px 5px;
          margin: 0 0 0 -6px;
          line-height: normal;
          height: auto;

          &:hover, &:active {
            background: $color-brand-primary-utility-1;
            border-color: $color-brand-primary-utility-2;
          }

          &.r-ss-disabled {
            background: transparent;
            border-color: transparent;
            cursor: default;
          }

          .carat.down, .carat.up {
            color: $color-neutral-60;
          }
        }
      }

      .dt-col-1 {
        dt, dd {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .detail-section-container .detail-section-main {
      margin: 30px 0 0 70px;
      @media (max-width: $grid-breakpoint-desktop-large) {
        margin: 30px 0 0 80px;
      }
      @media (max-width: $mobile-mode-max) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
        margin: 5px auto 32px;
        justify-content: center;
      }
    }
  }
}

.container-nav .details-panel {
  overflow: visible;
}

.details-invoice-panel {
  @media (max-width: $mobile-mode-max) {
    overflow: auto;
  }
}

.details-panel [role="listbox"] .icon-placeholder {
  height: 64px;
  width: 64px;
  @media (max-width: $mobile-mode-max) {
    position: static;
  }
  @media (min-width: $desktop-mode-min) {
    top: 50%;
  }

  > .abs-icon {
    font-size: 2.3em;
  }
}

.details-panel .icon-placeholder {
  background: $icon-placeholder-background;
}