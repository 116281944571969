.abs-menu .navbar-non-collapse > .nav > li.dropdown.open .dropdown-menu {
  &.abs-quick-search-panel {

    width: auto;
    right: 0;
    left: inherit;
    padding: 0;
  }
}

.load-more a {

  &.abs-quick-search-load-more {
    border: none;
    border-top: 1px solid $abs-quick-search-load-more-background;
  }

}

.load-more {
  &.quick-search-button-hidden {
    display: none;
  }
}

.dropdown.open .dropdown-menu.sub-parent div {
  &.quick-search-more-button-padding-none {
    padding: 0;
  }
}

.quick-search-close {

  display: none;
}

.dropdown.open .dropdown-menu.sub-parent div:nth-child(1).abs-quick-search-open {
  width: 354px;
  padding: 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $abs-quick-search-result-list-scroll-bar-color;
    outline: 1px solid slategrey;

  }

  .abs-icon {
    line-height: 1;
    height: auto;
  }
  .quick-search-main-search-block {

    height: auto;
    width: auto;
    text-align: center;
    line-height: 28px;
    padding: 10px 0 0 0;
    background-color: $abs-quick-search-background-color;

    @include align-items(center);
    @include box-shadow(0 1px 0 0 $abs-quick-search-box-shadow);

    .quick-search-search-block {

      height: 69px;
      width: auto;
      padding: 0;

    }

    .quick-search-help-block-close {
      display: none;
    }
    .quick-search-help-block-open {
      position: relative;
      display: block;
      height: 29px;
      text-align: left;
      margin-left: 5%;
      padding: 0;
      border: none;

      .help-icon {
        color: $abs-quick-search-help-icon;
        font-size: 1.538rem;
      }
      .help-hint {
        position: absolute;
        color: $abs-quick-search-hint-color;
        font-size: 1rem;
        left: 8%;
        bottom: 14%;
      }

    }

    .quick-search-title-text {
      font-size: 16px;
      box-shadow: none;
      margin-bottom: 5px;

      @include font-style("Roboto", 700, "");
    }
    .quick-search-input-block {
      position: relative;
      padding: 0;

      .abs-icon {
        @media (min-width: 768px) {
          margin-right: 0;
        }
      }

      .input-field {
        width: 90%;
        height: 26px;
        text-align: left;
        font-size: 13px;
        padding-right: calc(7% + 40px);
        color: $color-neutral-46;
      }
      .abs_material_search {
        position: absolute;
        color: $color-brand-primary-dark;
        font-size: 18px;
        right: 7%;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        margin-right: 0;
        &:hover {
          cursor: pointer;
        }
        @media (max-width: 767px) {
          right: 6.5%;
        }
      }
      .clear-icon {
        position: absolute;
        color: $abs-quick-search-clear-input-icon;
        font-size: 18px;
        top: 48%;
        transform: translateY(-50%);
        right: calc(7% + 25px);
        @media (min-width: 768px) {
          right: calc(7% + 27px);
        }
        &:hover {
          cursor: pointer;
        }
      }
      .clear-icon-hidden {
        display: none;
      }
      .quick-search-icons-separator {
        position: absolute;
        top: 2.2px;
        border-left: 1px solid $color-neutral-11;
        height: calc(100% - 4px);
        right: calc(7% + 20px);
        @media (min-width: 768px) {
          right: calc(7% + 22px);
        }
      }
    }

  }

  .quick-search-searching-block {
    height: 114px;
  }

  .quick-search-advanced-block {
    height: 36px;
    @include box-shadow(inset 0 1px 0 0 $abs-quick-search-box-shadow);
    text-align: right;
    padding: 0;
    background-color: $abs-quick-search-background-color;

    .advanced-search {
      @include flex-align-items-center();
      margin-right: 10px;
      padding-top: 10px;
      float: right;
      &:hover, &:focus {
        color: $color-neutral-100;
        text-decoration: none;
        .advanced-search-text {
          text-decoration: underline;
        }
      }
      .abs-icon {
        @media (min-width: 768px) {
          margin-right: 0;
        }
      }
      .advanced-search-icon {
        font-size: 16px;
        float: none;
      }
      .advanced-search-text {
        font-size: 13px;
      }
    }
  }

  .quick-search-loading-block {

    text-align: center;
    background: $color-neutral-0 url('../../../css/quickSearchLoader.gif') 50% 40% no-repeat;
    background-size: 30px 30px;
    padding: 0;

    .is-searching-title {

      display: inline-block;
      padding: 77px 0 33px 0;
      color: $abs-quick-search-hint-color;
      font-size: 13px;
    }
  }

  .quick-search-fetch-more-loading {
    height: 36px;
    text-align: center;
    background: $color-neutral-0 url('../../../css/quickSearchLoader.gif') 50% 40% no-repeat;
    background-size: 20px 20px;
    padding: 0;
  }

  .quick-search-results-list-block {
    max-height: 432px;
    overflow: hidden;
    overflow-y: scroll;
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 0 0 0 0;
    font-size: 1rem;
    background: $color-neutral-0;

    .result {
      height: auto;
      padding: 10px 0 10px 8%;

      .number {
        width: auto;
        padding: 0;
        word-wrap: break-word;
      }
      .status {
        font-family: Roboto-Regular, Roboto;
        color: $abs-quick-search-hint-color;
      }
      .package-number {
        font-family: Roboto-Regular, Roboto;
      }
      .currency {
        font-family: Roboto-Regular, Roboto;
        padding: 0;
        color: $abs-quick-search-hint-color;
      }
      .separator {
        color: $abs-quick-search-result-separator-color;
      }
      .secondary-value-color {
        font-family: Roboto-Regular, Roboto;
        color: $abs-quick-search-secondary-value-color;
      }
      .hint {
        font-family: Roboto-Regular, Roboto;
        padding: 0;
        border: none;
      }

      .values-sequence-separator {
        margin: 0 4px;

        &:after {
          content: "●";
        }
      }

    }

  }

  .quick-search-results-list-block-hidden {
    display: none;
  }

  .quick-search-no-results-block {
    background: $color-neutral-0;
    text-align: center;
    padding: 0;
    border: none;
    .no-results-icon {
      padding-top: 18px;
      float: none;
      display: block;
      font-size: 40px;
      color: $color-neutral-16;
      @media (min-width: 768px) {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        padding-top: 4%;
      }
    }
    .no-results-title {
      padding: 10px 0 33px 0;
      display: block;
      font-size: 13px;
      color: $color-neutral-27;
      word-wrap: break-word;
    }
  }
}
