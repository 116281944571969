.Select.Select--single {
  position: relative;

  .Select-control {
    .Select-multi-value-wrapper {
      display: block;

      .Select-value {
        position: absolute;
        line-height: 26px;
        padding-left: 7px;
        z-index: 2;
        padding-right: 30px;
        height: 26px;
        overflow: hidden;
        word-break: break-word;
        width: 373px;
      }

      .Select-input {
        position: relative;

        input {
          width: 373px !important;
          padding: 0 25px 0 5px;

          + div {
            display: none;
          }
        }
      }
    }

    .Select-clear-zone {
      display: none;
    }

    .Select-arrow-zone {
      align-items: center;
      justify-content: center;
      display: flex;
      border-left: 1px solid $color-neutral-8;
      position: absolute;
      height: 26px;
      width: 26px;
      right: 1px;
      top: 1px;

      .Select-arrow {
        @extend .abs-icon;
        @extend .abs_expand_more;

        transform: rotate(0deg);
        transition: transform .2s linear;
      }
    }
  }

  &.is-open {
    .Select-arrow-zone {
      .Select-arrow {
        transform: rotate(-180deg);
        transition: transform .2s linear;
      }
    }
  }

  .Select-menu-outer {
    position: absolute;
    width: 100%;
    margin-top: 1px;

    background: $color-neutral-0;
    border: 1px solid $color-neutral-33;
    @include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.35));
    @include border-radius(2px);

    .Select-menu {
      max-height: 350px;
      overflow-y: auto;

      .Select-option {
        align-items: center;
        background: $color-neutral-0;
        display: flex;
        min-height: 26px;
        cursor: pointer;
        outline: none;
        padding-left: 9px;
        padding-right: 9px;
        margin: 1px;
        text-align: left;
        font-size: 13px;
        line-height: 16px;
        color: $color-neutral-100;

        &.is-focused {
          background: #DAF2FB;
          @include radius(2px);
          outline: none;
        }

        &.is-selected {
          background: #F0FAFD;
          @include radius(2px);
          outline: none;
          color: #017ebb;
        }
      }
    }
  }
}

.Select-creatable-label{
  padding-right: 25px;
  overflow: hidden;
  display: inline-block;
  height: 30px;
}
