.detail-section-container.summary-panel {

  .detail-section {

    @media (max-width: $screen-lg-min) {
      padding-right: 25px;
    }

    @media (max-width: $screen-md-min) {
      padding-right: 18px;
    }

    .formatted-money {
      .formatted-money-integer {
        color: $formatted-money-integer-color;
        text-align: left;
        font-size: 36px;
        font-weight: 700;

        @media (max-width: $screen-lg-min) {
          font-size: 30px;
        }

        @media (max-width: $screen-md-min) {
          font-size: 23px;
        }
      }

      .formatted-money-fraction, .formatted-money-currency {
        color: $formatted-money-fraction-color;
        text-align: left;
        font-size: 30px;
        font-weight: 400;

        @media (max-width: $screen-lg-min) {
          font-size: 24px;
        }

        @media (max-width: $screen-md-min) {
          font-size: 17px;
        }
      }

      .formatted-money-currency {
        color: $formatted-money-currency-color;
        margin-left: 7px;
      }
    }
  }
}

.page-summary-panel {
  .limit-values {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 30px;

    .limit-progress-values {
      display: inline-block;

      .formatted-money {
        .formatted-money-integer, .formatted-money-fraction {
          color: $color-brand-secondary-dark;
          font-weight: normal;
          font-size: 13px;
        }

        .formatted-money-currency {
          font-weight: normal;
          font-size: 13px;
        }
      }
    }

    .limit-text-values {
      display: inline-block;
      margin-left: 30px;

      @media (max-width: $screen-sm-min) {
        display: none;
      }

      .amount-label-small {
        padding-right: 5px;
      }

      .amount-value-small {
        padding-left: 5px;

        .formatted-money {
          .formatted-money-integer, .formatted-money-fraction, .formatted-money-currency {
            color: #333;
            font-weight: normal;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.frame-agreement-details {
  width: 100%;

  .dt-col-1 dt {
    white-space: normal;
  }
}

.agreement-additional-info-select-item, .option-additional-info {
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  color: $color-neutral-60;
  display: flex;
  align-items: center;

  .fa {
    font-size: 6px;
    margin: 0 4px;
  }
}

.base-agreement-contractors-list {

  @media (min-width: $screen-sm-min + 1) {
    .abs-rwd-list-col-header.col:nth-child(2), .abs-rwd-list-col.col:nth-child(2) {
      width: calc(40% - 240px);
    }
    .abs-rwd-list-col-header.col:nth-child(3), .abs-rwd-list-col-header.col:nth-child(4),
    .abs-rwd-list-col.col:nth-child(3), .abs-rwd-list-col.col:nth-child(4) {
      width: 120px;
    }
  }
}

.agreement-details-header {

  .details-panel [role="listbox"] .detail-section .r-ss-wrap .r-ss-trigger {
    min-height: 45px;

    .agreement-number-container {

      .agreement-number-select-item, .agreement-currency {
        font-weight: 500;
        font-size: 16px;
      }

      .agreement-currency {
        color: $color-neutral-60;
      }
    }
  }
}

.agreements-header {

  .agreement-number-select-item {
    font-weight: 700;
    font-size: 13px;
    text-decoration: none;
    color: $color-neutral-100;
  }

  .currency-select-item {
    font-weight: 700;
    font-size: 13px;
    text-decoration: none;
    color: $color-neutral-60;
  }

  .details-panel [role="listbox"] .detail-section {
    margin-right: 30px;

    &:nth-of-type(1) {
      width: auto;
    }

    @media (max-width: $screen-lg-min) {
      padding-right: 25px;
    }

    @media (max-width: $screen-md-min) {
      padding-right: 18px;
    }

    .formatted-money {
      display: block;
      margin-top: 12px;

      .formatted-money-integer {
        color: $color-neutral-100;
        text-align: left;
        font-size: 36px;
        font-weight: 700;

        @media (max-width: $screen-lg-min) {
          font-size: 30px;
        }

        @media (max-width: $screen-md-min) {
          font-size: 23px;
        }
      }

      .formatted-money-fraction, .formatted-money-currency {
        color: $color-neutral-60;
        text-align: left;
        font-size: 30px;
        font-weight: 400;

        @media (max-width: $screen-lg-min) {
          font-size: 24px;
        }

        @media (max-width: $screen-md-min) {
          font-size: 17px;
        }
      }

      .formatted-money-currency {
        margin-left: 7px;
      }
    }
  }

}

.agreement-number-with-currency {
  word-break: break-word;
}
