// paleta kolorow
$color-positive-light: #f1faea !default;
$color-positive-dark: #5CB85C !default;
$color-negative-light: #fef5f5 !default;
$color-negative-dark-1: #dd9985 !default;
$color-negative-dark-2: #ff4d4d !default;
$color-negative-dark-3: #ee4747 !default;
$color-negative-dark-4: #D05B61 !default;
$color-warning-light-1: #fffceb !default;
$color-warning-light-2: #fff9d5 !default;
$color-warning-light-3: #fef5bc !default;
$color-warning-dark-1: #FFD014 !default;
$color-warning-dark-2: #f7af00 !default;
$color-warning-dark-3: #ff930c !default;
$color-neutral-0: #ffffff !default;
$color-neutral-2: #fafafa !default;
$color-neutral-4: #f5f5f5 !default;
$color-neutral-8: #ebebeb !default;
$color-neutral-11: #e3e3e3 !default;
$color-neutral-16: #d6d6d6 !default;
$color-neutral-20: #cccccc !default;
$color-neutral-27: #bababa !default;
$color-neutral-33: #ababab !default;
$color-neutral-46: #8a8a8a !default;
$color-neutral-60: #666666 !default;
$color-neutral-70: #4c4c4c !default;
$color-neutral-80: #333333 !default;
$color-neutral-100: #000000 !default;
// Font Variables
$roboto-font-family: 'Roboto', sans-serif;
$abs-font-color: $color-neutral-100 !default;
//variables
$abs-link-color: $color-brand-primary-dark !default;
$abs-link-hover-color: $color-neutral-100 !default;
$abs-body-desktop-bg: $color-neutral-2 !default;
$container-box-border-color: $color-neutral-20 !default;
$container-box-shadow-color: $color-neutral-11 !default;
$container-box-bg-color-a: $color-neutral-11 !default;

//alerts
$color-positive-fw-alerts: $color-positive-dark !default;

// buttons
$font-size-small: 13px !default;
$btn-border-radius-small: 2px !default;
$line-height-small: 1.72 !default;
$btn-white-color: $color-brand-primary !default;
$btn-white-bg: $color-neutral-0 !default;
$btn-white-border: $color-neutral-0 !default;
$btn-white-hover-bg: $color-brand-primary-light !default;
$btn-white-pressed-bg: $color-brand-primary-light !default;


$btn-type-a-bg: $color-brand-secondary !default;
$btn-type-a-color: $text-on-color-brand-secondary !default;
$btn-type-a-border-color: $color-brand-secondary !default;
$btn-type-a-focus-bg: $color-brand-secondary !default;
$btn-type-a-focus-border-color: darken($color-brand-secondary, 10) !default;
$btn-type-a-active-bg: darken($color-brand-secondary, 10) !default;
$btn-type-a-active-border-color: darken($color-brand-secondary, 10) !default;
$btn-type-a-hover-bg: darken($color-brand-secondary, 100) !default;
$btn-type-a-hover-border-color: darken($color-brand-secondary, 100) !default;

$btn-type-a-states-color: $color-neutral-0 !default;
$btn-type-a-states-border-color: $color-brand-secondary !default;
$btn-type-a-states-bg: $color-brand-secondary !default;


$btn-type-b-bg: $color-brand-primary-dark !default;
$btn-type-b-hover-bg: darken($color-brand-primary-dark, 100) !default;
$btn-type-b-hover-border-color: darken($color-brand-primary-dark, 100) !default;
$btn-type-b-pressed-bg: $color-brand-primary-dark !default;
$btn-type-b-color: $text-on-color-brand-primary !default;
$btn-type-b-color-hover: $btn-type-b-color !default;
$btn-type-b-border-color: $color-brand-primary-dark !default;
$btn-type-b-focus-bg: $color-brand-primary-dark !default;
$btn-type-b-focus-border-color: darken($color-brand-primary-dark, 10) !default;
$btn-type-b-active-bg: darken($color-brand-primary-dark, 10) !default;
$btn-type-b-active-border-color: darken($color-brand-primary-dark, 10) !default;


$btn-type-c-bg: $color-neutral-0 !default;
$btn-type-c-color: $color-neutral-60 !default;
$btn-type-c-border-color: $color-neutral-27 !default;
$btn-type-c-hover-bg: $color-neutral-0 !default;
$btn-type-c-hover-border-color: $color-brand-primary !default;
$btn-type-c-hover-color: $color-brand-primary-dark !default;
$btn-type-c-focus-bg: $color-neutral-0 !default;
$btn-type-c-focus-border-color: $color-brand-primary-utility-2 !default;
$btn-type-c-active-bg: $color-brand-primary-utility-2 !default;
$btn-type-c-active-border-color: $color-brand-primary !default;


$btn-type-c-pressed-bg: $color-brand-primary-light !default;
$btn-type-c-pressed-border-color: $color-brand-primary-light !default;


$btn-type-d-bg: $color-neutral-0 !default;
$btn-type-d-color: $color-brand-primary-dark !default;
$btn-type-d-border-color: $color-neutral-0 !default;
$btn-type-d-hover-bg: $color-brand-primary-light !default;
$btn-type-d-hover-border-color: $color-brand-primary-light !default;
$btn-type-d-focus-bg: $color-brand-primary-light !default;
$btn-type-d-focus-border-color: $color-brand-primary-light !default;
$btn-type-d-active-bg: $color-brand-primary-utility-2 !default;
$btn-type-d-active-border-color: $color-brand-primary-utility-2 !default;

$border-color: $color-neutral-11 !default;
$fade-color: $color-neutral-2 !default;

$btn-gray: #717c89 !default;
$btn-dark-gray: #555e69 !default;
$btn-light-blue: $color-brand-primary-utility-2 !default;
$btn-blue: $color-brand-primary-dark !default;
$btn-dark-blue: #005985 !default;

$font-gray: $color-neutral-60 !default;


// menu
$abs-menu-border-radius: 0px !default;
$abs-menu-bg: $color-brand-primary-menu !default;
$abs-menu-border-color: $color-brand-primary-menu !default;
$abs-menu-height: 40px !default;
$abs-menu-margin-bottom: 0px !default;
$abs-menu-sm-border-color: lighten($color-brand-primary-menu-active, 4) !default;

$abs-menu-element-border-color: $abs-menu-bg !default;

$abs-menu-default-color: $color-neutral-0 !default;
$abs-menu-default-link-color: $color-neutral-0 !default;
$abs-menu-default-link-hover-color: $color-neutral-0 !default;
$abs-menu-default-link-hover-bg: $color-brand-primary-menu-active !default;
$abs-menu-default-link-active-color: $color-neutral-0 !default;
$abs-menu-default-link-active-bg: $color-brand-primary-menu-active !default;
$abs-menu-mobile-a-bg: darken($color-brand-primary-dark, 10) !default;

$abs-menu-mobile-2nd-level-border: darken($color-brand-primary-menu-active, 10) !default;
$abs-menu-3rd-level-bg: $color-neutral-2 !default;
$abs-menu-3rd-level-border: $color-neutral-11 !default;
$abs-menu-icon-size: 22px !default;

$abs-menu-mobile-collapse-ico: $color-neutral-60 !default;


$abs-menu-header-color: $color-neutral-100 !default;
$abs-menu-2nd-level-bg: $color-neutral-0 !default;
$abs-menu-2nd-level-mobile-bg: $color-neutral-4 !default;
$abs-menu-2nd-level-mobile-border: $color-neutral-2 !default;

$abs-menu-default-toggle-hover-bg: transparent !default;
$abs-menu-default-toggle-icon-bar-bg: $color-neutral-0 !default;
$abs-menu-default-toggle-border-color: #274d78 !default;
$abs-menu-hamburger-right-margin: 53px !default;

$abs-menu-dropdown-left-icon-margin: 28px !default;
$abs-menu-dropdown-right-icon-margin: 12px !default;


$abs-menu-dropdown-border-color: $color-brand-primary-menu-active !default;
$abs-menu-dropdown-caret-up: $color-neutral-0 !default;

$abs-menu-padding-vertical: 8px !default;
$abs-menu-padding-horizontal: 15px !default;

$abs-menu-4th-level-focus: $color-brand-primary-menu-light !default;
$abs-menu-4th-level-hover-focus: $color-brand-primary-menu-light !default;


$abs-menu-star-bg: $color-neutral-0 !default;
$abs-menu-star-border: darken($color-brand-primary-light, 20) !default;
$abs-menu-star-clicked-bg: $color-warning-dark-1 !default;
$abs-menu-star-clicked-border: darken($color-warning-dark-1, 10) !default;

$abs-menu-search-ico: $color-neutral-60 !default;
$abs-menu-close-ico: $color-neutral-60 !default;


$abs-menu-action-button-hover: $color-brand-primary-utility-1 !default;

$abs-vertical-menu-height: 400px !default;
$abs-vertical-menu-level1-width: 200px !default;
$abs-vertical-menu-level2-font-color: $color-neutral-100 !default;
$abs-vertical-menu-level2-bg: $color-neutral-0 !default;


$abs-menu-type-a-inside-border-color: $color-neutral-11 !default;
$abs-menu-type-a-inside-bg: $color-neutral-2 !default;
$abs-menu-type-a-carousel-icon-color: $color-neutral-60 !default;

// sub-nav
$sub-nav-bg: $color-brand-primary !default;
$sub-nav-height: 40px !default;
$sub-nav-title-color: $color-neutral-100 !default;
$sub-nav-title-font-size: 15px !default;
$sub-nav-border-color: $color-neutral-16 !default;
$sub-nav-dropdown-border: $color-neutral-11 !default;


$abs-container-box-bg: $color-neutral-2 !default;
$abs-record-card-margin-top: 40px !default;
$abs-record-card-bg: $color-neutral-0 !default;

$abs-record-card-title-color: $color-neutral-60 !default;
$abs-record-card-title-size: 14px !default;

$abs-buttons-wrap-height: 50px !default;

// kendo buttongroup

$km-buttongroup-active-bg: darken($color-brand-primary-dark, 10) !default;

//items-button
$items-button-border: $color-neutral-16 !default;
$items-button-i-color: $color-neutral-60 !default;
$items-button-span-color: $color-neutral-60 !default;
$items-button-selected-bg: $color-warning-light-2 !default;
$items-button-selected-border: $color-neutral-16 !default;


// buttons
$padding-xs-horizontal: 15px !default;


//button wrap
$button-wrap-bg: $color-neutral-11 !default;

//containers
$container-details-panel-bg: $color-neutral-0 !default;
$details-panel-color: $color-neutral-100 !default;
$details-panel-linked-button-hover-color: $color-brand-primary-dark !default;

//tabstripe
$abs-tab-item-color: $color-neutral-100 !default;
$abs-tab-border-color-type-a: $color-neutral-16 !default;

// forms
$abs-form-label-color: $color-neutral-100 !default;
$abs-form-label-size: 1em !default;
$abs-form-input-color: $color-neutral-60 !default;
$abs-form-icon-color: $color-neutral-60 !default;
$abs-form-input-focus-shadow-color: darken($color-brand-primary-utility-2, 20) !default;


// $forms-element-border-color:$color-neutral-27  !default;
$forms-element-border-color: $color-neutral-27 !default;
$forms-element-bg: $color-neutral-0 !default;
$forms-element-icons-color: $color-neutral-60 !default;
$forms-elements-hover-color: $color-brand-primary !default;
$forms-elements-pressed-bg: $color-brand-primary-utility-1 !default;
$forms-elements-active-border-color: darken($color-brand-primary, 10) !default;

$forms-elements-mouseover-bg: $color-neutral-2 !default;
$forms-multiselect-elements-bg: $color-neutral-4 !default;
$forms-elements-selected-mouseover-bg: $color-brand-primary-light !default;

$forms-combo-border-color: $color-neutral-46 !default;
$forms-combo-hover-border-color: $color-neutral-11 !default;


$validation-header-border-color: $color-negative-dark-1 !default;
$forms-validation-border-color: $color-negative-dark-4 !default;
$forms-validation-bg: $color-negative-light !default;

// $validation-solid-border: #ffc6c6;
$validation-solid-fill: $color-negative-light;

$forms-checkbox-color: $color-neutral-46 !default;
$forms-required-color: $color-negative-dark-3 !default;


$forms-panel-action-bg: $color-neutral-2 !default;
$forms-panel-action-border: $color-neutral-11 !default;


//k-calendar
$k-calendar-border: $color-neutral-33 !default;
$k-calendar-other-month-link-color: $color-neutral-16 !default;
$k-calendar-content-color: $color-neutral-100 !default;
$k-calendar-cell-border: $color-neutral-11 !default;
$k-calendar-bg: $color-neutral-0 !default;
$k-calendar-link-focused: $color-neutral-0 !default;
$k-calendar-icons-arrow: $color-neutral-60 !default;
$k-calendar-link-color: $color-neutral-100 !default;
$k-calendar-advanced-btn-border: $color-neutral-16 !default;
$k-calendar-selected-date-bg: $color-brand-primary !default;

//k-upload
$k-upload-bg: $color-neutral-0 !default;
$k-upload-file-border: $color-neutral-4 !default;
$k-upload-dropzone-border: $color-neutral-20 !default;
$k-upload-empty-bg: $color-neutral-4 !default;
$k-upload-dropzone-color: $color-neutral-100 !default;

// grid
$grid-breakpoint-desktop: 1200px !default;
$grid-breakpoint-desktop-large: 1600px !default;


$grid-filterable-icon-color: $color-neutral-60 !default;
$grid-filterable-icon-bg-hover: $color-neutral-11 !default;
$grid-filterable-bg: $color-warning-light-2 !default;
$grid-filterable-border-color: $color-warning-dark-2 !default;
$grid-border-color: $color-neutral-20 !default;
$grid-border-color-elements: $color-neutral-11 !default;
$grid-filterable-bg-on-hover: $color-neutral-4 !default;

$grid-footer-bg: $color-neutral-2 !default;
//$grid-icon-type-bg:$color-neutral-33  !default;

$grid-selectable-row-bg: $color-warning-light-1 !default;
$grid-tr-on-hover: $color-neutral-2 !default;

//tooltip
$tooltip-bg: $color-neutral-100 !default;
$tooltip-border-color: $color-neutral-100 !default;
$tooltip-color: $color-neutral-0 !default;
//panel
$panel-default-border-color: $color-neutral-11 !default;

//login page
$abs-login-page-color-top: $color-brand-primary !default;
$abs-login-panel-width: 870px !default;
$abs-login-page-border-top-color: $color-brand-primary !default;
// $abs-login-page-title-color:#2c619f !default;
$abs-login-page-background: $color-neutral-0 !default;
$abs-login-page-title-size: 19px !default;

$abs-login-message-border-top: $color-neutral-11 !default;
$abs-login-message-icon-color: $color-negative-dark-3 !default;
$abs-login-message-type-a-border: $color-brand-primary-utility-2 !default;
$abs-login-message-type-a-bg: rgba(255, 255, 255, .1) !default;
// $abs-login-remember-me-color:#b5e1f1 !default;
$abs-login-font-color: $color-neutral-0 !default;
$abs-login-button-bg: $color-neutral-0 !default;
$abs-login-button-bg-hover: $color-brand-primary-light !default;
$abs-login-button-color: $color-neutral-100 !default;
$abs-login-button-color-hover: $abs-login-button-color !default;
$abs-login-check-border: $color-neutral-11 !default;
$lang-box-border-color: $color-neutral-46 !default;


//error page
$error-circle-bg: $color-negative-dark-2 !default;

//panels
$panel-a-bg: $color-neutral-0 !default;
$panel-a-border-color: $color-neutral-16 !default;
$abs-col-border-color: $color-neutral-16 !default;

// title

$abs-title-color: $color-neutral-60 !default;
$abs-title-line-bg: $color-neutral-11 !default;
$abs-title-font-size: 13px !default;
$abs-title-bg-in-container: $color-neutral-0 !default;

$context-menu-bg: $color-neutral-0 !default;
$context-menu-hover-bg: $color-brand-primary-light !default;
$context-menu-border: $color-neutral-46 !default;
$context-menu-ico: $color-neutral-60 !default;
$context-menu-link-color: $color-neutral-80 !default;


//status colors
$status-do-wyceny: #a186be !default;
$status-zaakceptowany: $color-positive-dark !default;
$status-odrzucony: $color-negative-dark-2 !default;
$status-client: $color-neutral-33 !default;
$boolean-color: $color-negative-dark-2 !default;
$boolean-ico-color: $color-neutral-0 !default;

$mini-icon-font-color: $color-neutral-0 !default;
$success-icon-color: $color-positive-dark !default;
$delete-icon-color: $color-negative-dark-3 !default;
$edit-icon-color: $color-brand-primary-dark !default;
$info-yellow-color: $color-warning-dark-2 !default;
$info-text-color: $color-neutral-27 !default;
$disabled-action-icon-color: $color-neutral-46 !default;

// hr
$hr-color: $color-neutral-16 !default;


// info section
$info-section-bg: $color-neutral-0 !default;

// mini cards

$card-inside-border-color: $color-neutral-11 !default;
$card-box-border-color: $color-neutral-16 !default;
$card-box-background-color: $color-neutral-0 !default;
$card-box-square-alert-color: $color-negative-dark-4 !default;
$card-box-square-default-color: $color-neutral-33 !default;
$card-box-header-color: $color-neutral-0 !default;
$card-nav-font-color: $color-neutral-60 !default;
$card-nav-link-bg: $color-neutral-11 !default;
$card-nav-icon-color: $color-neutral-0 !default;
$card-carousel-control-icon-color: $color-neutral-0 !default;
$card-carousel-indicator: $color-neutral-20 !default;
$card-carousel-indicator-active: $color-neutral-46 !default;
$card-nav-expand-ico: $color-neutral-27 !default;
$card-group-title-color: $color-neutral-60 !default;
$card-group-title-badge-color: $color-neutral-0 !default;
//ihd issue
$action-right-bg: $color-neutral-0 !default;

// typogrpahy

$font-family-sans-serif: Arial, Helvetica, "Helvetica Neue", sans-serif;


// kendo filters
$abs-k-filter-bg: $color-neutral-0 !default;
$abs-k-filter-search-icon: $color-neutral-0 !default;
$abs-k-filter-close-icon: $color-neutral-0 !default;
$abs-k-filter-search-icon-without-bg: $color-neutral-60 !default;
$abs-k-filter-text-color: $color-neutral-100 !default;
$abs-k-filter-text-secondary-color: $color-neutral-46 !default;
$abs-k-filter-collapse-ico: $color-brand-primary !default;
$abs-k-filter-picker-wrap-border: $color-neutral-20 !default;
$abs-k-filter-field-hover-bg: $color-brand-primary-light !default;
$abs-k-filter-field-hover-text: $color-brand-primary-dark !default;

$abs-k-filter-tree-icon-hover: $color-neutral-100 !default;
$abs-k-filter-highlight-bg: $color-warning-light-3 !default;
$abs-k-filter-icon-hover: $color-neutral-100 !default;


$abs-k-filter-multicheck-item-hover-bg: $color-brand-primary-light !default;
$abs-k-filter-multicheck-item-active-check: $color-brand-primary !default;

$abs-k-filter-qs-li-hover: $color-brand-primary-light !default;

$abs-k-filter-pin-icon: $color-neutral-100 !default;

//kendo.scss
$k-column-border-color: $color-neutral-16 !default;


//panel-3-col :: karta rekordu :: zalaczniki
$panel-3-col-font-color: $color-neutral-100 !default;


//details-list
$details-list-span-color: $color-neutral-46 !default;
$details-list-summary: $color-neutral-60 !default;

//details panel
$details-panel-h1-color: $color-neutral-100 !default;
$details-panel-h2-color: $color-neutral-60 !default;

$icon-placeholder-color: $color-neutral-0 !default;
$details-panel-inner-border: $color-neutral-11 !default;

//colors
$red-icon-color: $color-negative-dark-4 !default;
$cancel-color: $color-negative-dark-3 !default;
$accept-color: $color-positive-dark !default;

// footer
$abs-footer-bg: $color-brand-secondary-dark !default;
$abs-footer-copyrights-bg: lighten($color-brand-secondary-dark, 5) !default;
$abs-footer-copyrights-color: $color-neutral-46 !default;
$abs-footer-margin-top: 20px !default;
$abs-footer-font-color: $text-on-color-brand-secondary-dark !default;
$abs-footer-link-color: $text-on-color-brand-secondary-dark !default;
$abs-footer-version-bg: lighten($color-brand-secondary-dark, 10) !default;
$abs-footer-version-color: $color-neutral-33 !default;
$abs-footer-type-a-color: $color-neutral-27 !default;
$abs-footer-type-a-bg: $color-brand-secondary-dark !default;


//kendo window
$window-title-color: $color-neutral-100 !default;
$window-title-bg: $color-neutral-2 !default;
$window-title-border-color: $color-neutral-11 !default;
$window-inside-container-bg: $color-neutral-0 !default;
$window-button-wrap-bg: $color-neutral-2 !default;
$window-button-wrap-border: $color-neutral-11 !default;
$window-close-icon: $color-neutral-60 !default;
$window-k-action-icon: $color-neutral-0 !default;

//scroll-up
$scroll-up-bg: $color-brand-secondary !default;
$scroll-up-icon-color: $color-neutral-0 !default;


//kendo-grid

$abs-grid-bg: $color-neutral-0 !default;
$abs-grid-header-font-color: $color-neutral-100 !default;
$abs-grid-header-bg-color: $color-neutral-2 !default;
$abs-grid-header-hover-bg-color: $color-neutral-4 !default;
$abs-grid-header-active-bg-color: $color-warning-light-2 !default;
$abs-grid-bg-color: $color-neutral-0 !default;
$abs-grid-bg-border-color: $color-neutral-20 !default;
$abs-grid-border-color: $color-neutral-20 !default;
$abs-grid-border-color-mobile: $color-neutral-11 !default;
$abs-grid-font-size: 13px !default;
$abs-grid-link-color: $abs-link-color !default;
$abs-grid-icon-green: $color-positive-dark !default;
$abs-grid-icon-blue: $color-brand-primary-dark !default;
$abs-grid-icon-red: $color-negative-dark-2 !default;
$abs-grid-icon-yellow: $color-warning-dark-1 !default;
$abs-grid-icon-orange: $color-warning-dark-3 !default;
$abs-grid-border-top-mobile: $color-neutral-16 !default;
$abs-grid-filter-counter-bg: $color-neutral-33 !default;
$abs-grid-filter-counter-color: $color-neutral-0 !default;

$abs-grid-tr-selected-bg: $color-warning-light-1 !default;

$abs-grid-buttons-group-separator-color: $color-neutral-20 !default;
$abs-grid-range-button-separator-color: $color-neutral-16 !default;

$abs-grid-context-menu-bg: $color-neutral-0 !default;
$abs-grid-context-menu-border: $color-neutral-46 !default;

$abs-grid-empty-img-border: $color-neutral-2 !default;
$abs-grid-empty-img-icon: $color-neutral-16 !default;

$abs-grid-grouping-row-title: $color-neutral-60 !default;

$abs-grid-norecords-color: $color-neutral-60 !default;
$abs-grid-mobile-title: $color-neutral-46 !default;
$abs-grid-alt-tr-bg: $color-neutral-0 !default;
$abs-grid-tr-bg: $color-neutral-0 !default;

$abs-grid-header-icon-color: $color-positive-dark !default;
$abs-grid-header-sort-index-color: $color-neutral-46 !default;
$abs-grid-header-sort-ico-color: $color-positive-dark !default;

$abs-grid-footer-border-color: $color-neutral-16 !default;

$abs-grid-dropdown-menu-hover-bg: $color-brand-primary-utility-2 !default;
$abs-grid-dropdown-menu-open-bg: $color-neutral-100 !default;
$abs-grid-dropdown-menu-open-icon-color: $color-neutral-0 !default;

$abs-grid-toolbar-bg-in-tab: $color-neutral-0 !default;

$main-before-column-width: 50px !default;
$main-column-width: 350px !default;

$abs-grid-toolbar-background: $color-neutral-11 !default;
$abs-grid-toolbar-border-bottom-color: $color-neutral-16 !default;

$abs-grid-pager-numbers-link-color: $color-neutral-60 !default;
$abs-grid-pager-numbers-link-hover: $color-neutral-100 !default;
$abs-grid-pager-numbers-link-selected: $color-brand-primary !default;
$abs-grid-pager-nav-icon: $color-brand-secondary !default;
$abs-grid-pager-nav-button-text-color: $color-neutral-0 !default;


//k-hint dla drag & dropa
$abs-k-hint-border-color: $color-neutral-27 !default;
$abs-k-hing-bg: $color-neutral-0 !default;
$abs-k-hint-text-color: $color-neutral-100 !default;
$abs-k-hint-actions-bg: $color-brand-primary-utility-2 !default;
$abs-k-hint-actions-hover: $color-neutral-100 !default;
$abs-k-hint-dropdown-menu-border: $color-neutral-46 !default;
$abs-k-hint-dropdown-menu-btn-hover: $color-brand-primary-light !default;

$abs-grid-drag-icon: $color-neutral-27 !default;
$abs-grid-drag-placeholder-border: $color-neutral-20 !default;

// spliterek
$k-spliter-line-color: $color-neutral-16 !default;
$k-splitter-arrow-hover-color: $color-neutral-100 !default;
$k-splitter-hover-bg: $color-neutral-4 !default;
$k-spliter-bar-icon-color: $color-neutral-27 !default;
$k-spliter-bar-icon-hover-color: $color-brand-primary !default;

// cookies policy
$cookies-policy-color: $color-neutral-46 !default;


//thumbnail section iHD::karta rekordu
$thumbnail-panel-border-color: $color-neutral-16 !default;
$thumbnail-panel-border-hover-color: $color-neutral-46 !default;
$thumbnail-panel-desc-bg: $color-neutral-0 !default;
$thumbnail-panel-color: $color-neutral-100 !default;


// kendo tabstrip
$tabstrip-button-bg: $color-neutral-0 !default;
$tabstrip-button-border: $color-neutral-20 !default;
$tabstrip-button-link: $color-neutral-100 !default;
$tabstrip-button-bg-hover: $color-neutral-2 !default;
$tabstrip-bg: $color-neutral-0 !default;
$tabstrip-border-bottom-hover: $color-brand-primary-dark !default;

$tabstrip-button-active-link: $color-neutral-0 !default;
$tabstrip-link-hover: $color-brand-primary-dark !default;
$tabstrip-link-color: $color-neutral-46 !default;
$tabstrip-icon-color: $color-brand-primary-dark !default;
$tabstrip-type-a-prev-next-hover-bg: $color-brand-primary-light !default;


//panel-group ihd
$panel-group-border-color: $color-neutral-16 !default;
$panel-group-bg: $color-neutral-0 !default;
$panel-group-a-hover-bg: $color-neutral-2 !default;
$panel-group-ico: $color-neutral-60 !default;


// alerts

$alert-strong-font-color: $color-neutral-100 !default;
$alert-font-color: $color-neutral-60 !default;
$alert-loader-font-color: $color-neutral-46 !default;

$warning-bg-color: $color-warning-light-1 !default;
$warning-icon-color: $color-warning-dark-1 !default;
$warning-message-icon-color: $color-warning-dark-1 !default;
$warning-border-color: $color-warning-dark-1 !default;
// $warning-link-color:#c09854 !default;

$danger-bg-color: $color-negative-light !default;
$danger-icon-color: $color-negative-dark-3 !default;
$danger-message-icon-color: $color-negative-dark-1 !default;
$danger-border-color: $color-negative-dark-1 !default;
// $danger-link-color:#a94442 !default;

$info-bg-color: $color-brand-primary-utility-1 !default;
$info-icon-color: $color-brand-primary !default;
$info-message-icon-color: $color-brand-primary !default;
$info-border-color: $color-brand-primary !default;
// $info-link-color:#0088bb !default;

$success-bg-color: $color-positive-light !default;
$success-icon-color: $color-positive-dark !default;
$success-message-icon-color: $color-positive-dark !default;
$success-border-color: $color-positive-dark !default;
// $success-link-color:#3c763d !default;

$default-bg-color: $color-neutral-4 !default;
$default-icon-color: $color-neutral-20 !default;
$default-message-icon-color: $color-neutral-20 !default;
$default-border-color: $color-neutral-20 !default;
$default-link-color: $color-neutral-46 !default;


//timeline

$timeline-date-color: $color-neutral-60 !default;
$timeline-second-desc-color: $color-neutral-46 !default;
$timeline-arrow-right-color: $color-positive-dark !default;
$timeline-arrow-left-color: $color-negative-dark-2 !default;
$timeline-circle-bg-color: $color-brand-primary-utility-1 !default;
$timeline-line-width: 2px !default;
$timeline-nonactive-color: $color-neutral-16 !default;
$timeline-bg: $color-neutral-0 !default;


// konfigurator
$abs-filter-preferences-border: $color-neutral-11 !default;
$abs-filter-preferences-hover-bg: $color-brand-primary-light !default;
$abs-filter-preferences-font-color: $color-neutral-100 !default;

// filter panel nad gridem

$abs-filter-border: $color-neutral-20 !default;
$abs-filter-background: $color-neutral-11 !default;
$abs-filter-btn-border: $color-neutral-27 !default;
$abs-filter-popup-border: $color-brand-primary !default;
$abs-filter-popup-footer-bg: $color-neutral-2 !default;
$abs-filter-popup-footer-border: $color-neutral-11 !default;
$abs-filter-activated-bg: $color-warning-light-2 !default;
$abs-filter-open-bg: $color-brand-primary-utility-1 !default;
$abs-filter-open-border: $color-brand-primary !default;
$abs-filter-btn-hover: $color-brand-primary !default;
$abs-filter-btn-bg: $color-neutral-0 !default;
$abs-filter-text-color: $color-neutral-100 !default;

$abs-filter-close-icon: $color-negative-dark-4 !default;

$abs-filter-additional-filters-bg: $color-neutral-11 !default;


$abs-filter-active-hover-border: $color-neutral-46 !default;
$abs-filter-ico-color: $color-neutral-60 !default;
$abs-filter-k-grid-filter-border: $color-neutral-33 !default;

$abs-filter-focus-shadow-color: $color-neutral-100 !default;


//breadcrumb
$breadcrumb-color: $color-neutral-60 !default;
$breadcrumb-border-color: $color-neutral-11 !default;
$breadcrumb-line-color: $color-neutral-27 !default;


// comments
$comment-date-color: $color-neutral-60 !default;
$comment-icon-color: $color-neutral-60 !default;
$comment-client-icon-color: $color-positive-dark !default;
$comment-author-color: $color-neutral-80 !default;


// accordion

$abs-accordion-border-color: $color-neutral-16 !default;
$abs-accordion-bg: $color-neutral-0 !default;
$abs-accordion-icons: $color-neutral-60 !default;
$abs-accordion-heading-bg: $color-neutral-0 !default;

// navbar

$navbar-height: $abs-menu-height;
$navbar-margin-bottom: $abs-menu-margin-bottom;
$navbar-border-radius: $abs-menu-border-radius;
$navbar-default-bg: $abs-menu-bg;
$navbar-default-border: $abs-menu-border-color;
$navbar-default-color: $abs-menu-default-color;
$navbar-padding-vertical: $abs-menu-padding-vertical;
$navbar-default-link-color: $abs-menu-default-link-color;
$navbar-default-link-hover-color: $abs-menu-default-link-hover-color;
$navbar-default-link-hover-bg: $abs-menu-default-link-hover-bg;
$navbar-default-link-active-color: $abs-menu-default-link-active-color;
$navbar-default-link-active-bg: $abs-menu-default-link-active-bg;

// Navbar toggle
$navbar-default-toggle-hover-bg: $abs-menu-default-toggle-hover-bg;
$navbar-default-toggle-icon-bar-bg: $abs-menu-default-toggle-icon-bar-bg;
$navbar-default-toggle-border-color: $abs-menu-default-toggle-border-color;


// up menu

$abs-up-menu-bg: $color-brand-secondary-dark !default;
$abs-up-menu-level2-bg: $color-neutral-0 !default;
$abs-up-menu-link-hover-bg: darken($color-brand-secondary-dark, 5) !default;
$abs-up-menu-link-active-bg: darken($color-brand-secondary-dark, 10) !default;
$abs-up-menu-link-active-color: $text-on-color-brand-secondary-dark !default;
$abs-up-menu-icon-color: $text-on-color-brand-secondary-dark !default;
$abs-up-menu-font-color: $text-on-color-brand-secondary-dark !default;


// mini card
$card-type-b-bg: $color-brand-primary !default;

//bootstrap-tabs
$tab-default-bg: $color-neutral-0 !default;
$tab-default-mouseover-bg: $forms-elements-mouseover-bg !default;
$tab-active-bg: $color-brand-primary !default;
$tab-active-mouseover-bg: $tab-active-bg !default;


//info-chart-panel
$info-chart-panel-border: $color-neutral-20 !default;
$info-chart-panel-bg: $color-neutral-0 !default;
$info-chart-li-selected-bg: $color-neutral-2 !default;
$info-chart-item-info-sub: $color-neutral-0 !default;
$info-chart-tooltip-bg: $color-neutral-100 !default;
$info-chart-tooltip-color: $color-neutral-0 !default;


// k-toolbar
$k-toolbar-anchor-icon-color: $color-neutral-0 !default;


//ihd icons
$icon-filter-clear: $color-neutral-0 !default;
$icon-data-eye: $color-neutral-0 !default;
$icon-pin: $color-neutral-60 !default;
$grid-odd-tr-bg: $color-neutral-2 !default;
//statusy ihd
$status-default-color: $color-neutral-0 !default;
$status-type-1-border: #f69697 !default;
$status-type-1-color: #fd5d5d !default;
$status-type-2-bg: #fd5d5d !default;
$status-type-2-border: #fd5d5d !default;
$status-type-3-bg: #fa9e31 !default;
$status-type-4-bg: #29b6f6 !default;
$status-type-5-bg: #3cb878 !default;
$status-type-6-bg: #a186be !default;
$status-type-7-bg: $color-neutral-27 !default;
$status-type-8-border: $color-neutral-27 !default;
$status-type-8-color: $color-neutral-60 !default;

//scheduler
$abs-scheduler-event-color: $color-neutral-0 !default;
$abs-scheduler-event-bg: $color-brand-primary !default;
$abs-scheduler-event-selected-bg: $color-brand-primary-dark !default;
$abs-scheduler-event-disabled-bg: $color-neutral-11 !default;
$abs-scheduler-selected-bg: $color-brand-primary-light !default;
$abs-scheduler-cell-bg: $color-neutral-2 !default;
$abs-scheduler-hover-bg: $color-brand-primary-light !default;
$abs-scheduler-today-bg: rgba(232, 232, 232, 0.15) !default;
$abs-scheduler-today-selected-bg: $color-warning-light-1 !default;
$abs-scheduler-today-selected-border-color: $color-warning-light-3 !default;
$abs-scheduler-today-selected-border-left-color: $color-neutral-11 !default;
$abs-scheduler-header-bg: $color-neutral-2 !default;
$abs-scheduler-toolbar-border-color: $color-neutral-16 !default;
$abs-scheduler-toolbar-button-color: $color-neutral-46 !default;
$abs-scheduler-day-selected-color: $color-neutral-100 !default;
$abs-scheduler-viewtype-hover-color: $color-neutral-100 !default;
$abs-scheduler-viewtype-hover-border: darken($color-brand-primary, 10) !default;
$abs-scheduler-layout-color: $color-neutral-60 !default;


//prompt
$abs-backdrop-bg: $color-neutral-0 !default;
$abs-prompt-box-shadow: rgba(0, 0, 0, 0.3) !default;
$abs-prompt-border-color: $color-brand-primary !default;
$abs-prompt-icon-color: $abs-prompt-border-color !default;
$abs-prompt-body-bg: $color-neutral-0 !default;
$abs-prompt-body-color: $color-neutral-100 !default;
$abs-prompt-footer-bg: $color-neutral-2 !default;
$abs-prompt-footer-box-shadow: rgba(233, 233, 233, 1) !default;


//quick-search
$abs-search-button-clicked-color: $abs-link-color;
$abs-search-icon-color: $color-neutral-0 !default;
$abs-quick-search-border-color: $abs-search-button-clicked-color;
$abs-quick-search-box-shadow: $window-title-border-color;
$abs-quick-search-background-color: $window-title-bg;
$abs-quick-search-start-search-icon: $color-neutral-60;
$abs-quick-search-advanced-search-link: $btn-blue;
$abs-quick-search-advanced-search-icon: $abs-link-color;
$abs-quick-search-clear-input-icon: $color-negative-dark-4;
$abs-quick-search-help-icon: $info-yellow-color;
$abs-quick-search-hint-color: $abs-form-input-color;
$abs-quick-search-no-results-icon: $window-button-wrap-border;
$abs-quick-search-no-results-title: $color-neutral-27;
$abs-quick-search-secondary-value-color: $color-neutral-80;
$abs-currency-data: $color-neutral-60;
$abs-progress-bar-info: $color-brand-primary;
$abs-quick-search-load-more-background: $border-color;
$abs-quick-search-result-separator-color: $color-neutral-33;
$abs-quick-search-result-list-scroll-bar-color: $color-neutral-60;


//multi-combo-box
$abs-multi-combo-box-border-color: $abs-filter-k-grid-filter-border;
$abs-multi-combo-box-bg-color: $color-neutral-0 !default;
$abs-multi-combo-box-dropdown-border-color: $color-neutral-33;
$abs-multi-combo-box-dropdown-search-input-border: $color-neutral-20;
$abs-multi-combo-box-dropdown-option-list-border: $color-neutral-8;
$abs-multi-combo-box-dropdown-option-additional-info: $color-neutral-60;
$abs-multi-combo-box-dropdown-option-selected-bg: $color-brand-primary-light;
$abs-multi-combo-box-dropdown-option-hover: $color-neutral-2;

// bootstrap-tabs
$bootstrap-tabs-context-border: $color-neutral-16 !default;
$bootstrap-tabs-context-color: $color-neutral-46 !default;
$bootstrap-tabs-context-border-hover: darken($color-brand-primary, 10) !default;
$bootstrap-tabs-context-color-hover: $color-brand-primary-dark !default;

$bootstrap-tabs-context-color-active: $color-brand-primary !default;


//toast
$abs-toast-info-background-color: $color-brand-primary-utility-1 !default;
$abs-toast-info-border-color: $color-brand-primary !default;
$abs-toast-danger-background-color: $color-negative-light !default;
$abs-toast-danger-border-color: $color-negative-dark-1 !default;
$abs-toast-success-background-color: $color-positive-light !default;
$abs-toast-success-border-color: $color-positive-dark !default;
$abs-toast-warning-background-color: $color-warning-light-1 !default;
$abs-toast-warning-border-color: $color-warning-dark-1 !default;
$abs-toast-margin-top-all-elements: 10px !default;
$abs-toast-margin-top-line: 0px !default;
$abs-toast-margin-right-all-elements: 30px !default;
$abs-toast-margin-bottom-all-elements: 10px !default;
$abs-toast-margin-left-all-elements: 15px !default;

$abs-toast-width-all-elements: 300px !default;

$btn-font-weight: 500 !default;
$light-gray-drop: $color-neutral-27 !default;
$flat-light-gray: $color-neutral-11 !default;
$text-info: $color-neutral-100 !default;
$flat-gray: $color-neutral-60 !default;
$flat-light-blue: $color-brand-primary-light !default;


$grid-breakpoint-desktop-large: 1350px;
$grid-breakpoint-desktop-large-value: $grid-breakpoint-desktop-large;
$mobile-mode-max: 767px;
$desktop-mode-min: 768px;
$tooltip-bg: #474747;
$tooltip-opacity: 1;
$select-width: 246px;
$label-icon-size: 26px;
$danger-icon-color: $color-negative-dark-3;
$color-brand-primary-utility-1-darken-10: #69b9d8 !default;

$color-brand-primary-dark-darken-10: #015c88;
$icon-placeholder-background: $color-brand-primary !default;
$scroll-up-button-background: #697480;
$dropdown-item-selected-bg-color: $color-brand-primary-utility-1 !default;
$dropdown-item-selected-color: $color-brand-primary-dark !default;
$dropdown-item-hover-bg-color: $color-brand-primary-light !default;
$sub-nav-mobile-color: $color-brand-primary !default;

$input-background-color: $color-neutral-0 !default;
$input-border-color: $color-neutral-27 !default;
$input-text-color: $color-neutral-80 !default;

$input-background-color-hover: $input-background-color !default;
$input-border-color-hover: $color-brand-primary !default;
$input-text-color-hover: $input-text-color !default;

$input-background-color-focus: $input-background-color !default;
$input-border-color-focus: $color-brand-primary !default;
$input-text-color-focus: $input-text-color !default;
$input-shadow-color-focus: $abs-form-input-focus-shadow-color !default;

$input-background-color-active: $color-brand-primary-utility-1 !default;
$input-border-color-active: $color-brand-primary !default;
$input-text-color-active: $color-brand-primary-dark !default;

$abs-filter-activated-border-color-hover: $color-neutral-46 !default;
$abs-filter-activated-shadow-color-focus: #bbbbbb !default;
$abs-filter-activated-background-color-active: $color-warning-light-3 !default;

$formatted-money-integer-color: $color-neutral-100 !default;
$formatted-money-fraction-color: $color-neutral-46 !default;
$formatted-money-currency-color: $color-neutral-46 !default;

$text-on-menu-hover: $text-on-color-brand-primary-menu-active !default;
$text-on-menu-focus: $text-on-color-brand-primary-menu-active !default;

$abs-dropdown-open-color: $color-neutral-60 !default;
$abs-dropdown-open-bg-color: $color-brand-primary-utility-1 !default;
$abs-dropdown-open-border-color: $color-brand-primary-utility-1 !default;

$abs-dropdown-open-color-hover: $color-neutral-60 !default;
$abs-dropdown-open-bg-color-hover: $color-brand-primary-utility-1 !default;
$abs-dropdown-open-border-color-hover: $color-brand-primary-utility-1 !default;

$quick-settings-item-color: $color-neutral-60 !default;
$quick-settings-item-color-focus: $color-neutral-60 !default;
$quick-settings-item-color-hover: $color-neutral-60 !default;
$quick-settings-item-bg-color-hover: $color-brand-primary-light !default;
$quick-settings-item-border-color-hover: $color-brand-primary-light !default;
$quick-settings-icon-color: $color-brand-primary-dark !default;
$quick-settings-placeholder-text-color: $color-brand-primary-dark !default;
$quick-settings-placeholder-icon-color: $color-brand-primary-dark !default;
$quick-settings-placeholder-text-color-active: $quick-settings-placeholder-text-color !default;
$quick-settings-placeholder-icon-color-active: $quick-settings-placeholder-icon-color !default;

$fw-menu-configuration-icon-color: $color-brand-primary-dark !default;

$abs-card-header-border-bottom-color: $color-neutral-16 !default;
$abs-card-header-bg-color: $color-neutral-2 !default;
$abs-card-header-bg-color-hover: $color-brand-primary-utility-1 !default;
$abs-card-arrow-bg-color-hover: $color-brand-primary-utility-1 !default;

$abs-menu-dropdown-open-bg-color: $color-neutral-2 !default;
$abs-menu-dropdown-border-width: 3px !default;
$abs-menu-dropdown-open-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !default;

$tab-context-active-border-color: $tab-active-bg !default;
$tab-nested-active-bg-color: $tab-active-bg !default;

$input-bg-color: #FFFFFF !default;
$input-color-active: $forms-elements-hover-color !default;
$input-border-color: #CCCCCC !default;
$input-border-color-hover: #aeaeae !default;
$input-border-color-focus: $input-border-color-hover !default;
$input-border-color-active: $input-border-color-hover !default;
$input-box-shadow-focus: none !default;
$input-bg-color-active: $color-neutral-0 !default;
$input-checkbox-color: #858585 !default;
$input-checkbox-color-active: $abs-filter-btn-hover !default;
$input-checkbox-border-color-active: $abs-filter-btn-hover !default;

$abs-list-dropdown-color: $btn-type-d-color !default;

$dropdown-item-selected-color: $color-brand-primary-dark !default;

$abs-toggle-off-line-color: $color-neutral-16 !default;
$abs-toggle-off-line-color-active: $color-neutral-16 !default;
$abs-toggle-off-point-color: $color-neutral-46 !default;
$abs-toggle-off-point-color-hover: $color-neutral-46 !default;
$abs-toggle-off-point-color-active: $color-neutral-46 !default;

$abs-toggle-on-line-color: $color-neutral-16 !default;
$abs-toggle-on-line-color-active: $color-neutral-16 !default;
$abs-toggle-on-point-color: $color-brand-primary !default;
$abs-toggle-on-point-color-hover: $color-brand-primary !default;
$abs-toggle-on-point-color-active: $color-brand-primary !default;

$btn-primary-mobile-bg-color: $color-brand-primary-utility-2 !default;
$btn-primary-mobile-color: $color-brand-primary-dark !default;
$btn-primary-mobile-bg-active: $color-brand-primary-utility-2 !default;
$btn-primary-mobile-color-active: $color-brand-primary-dark !default;

$timeline-main-color: $sub-nav-bg !default;
$abs-card-header-color: #000000 !default;
$abs-page-header-color: $color-neutral-100 !default;
