.calendar-form {
  @extend .abs-filter-panel;
  width: auto;
  height: auto;
  background: none;
  border: none;

  .button-panel {
    background-color: #fafafa;
    border-top: 1px solid #e3e3e3;
    margin-top: 10px;
    clear: both;
    padding: 3px 0 10px 10px;
    text-align: right;
    .submit-button {
      width: auto;
      margin: .5em .2em 0 0;
    }
    .cancel-button {
      margin-right: .8em;
      width: auto;
      margin: .5em .2em 0 0;
    }
  }
}

.calendar-dropdown.dropdown-menu {
  background: $color-neutral-0;
  border: 1px solid $color-neutral-33;
  @include box-shadow(0 2px 3px 0 rgba(0, 0, 0, 0.35));
  @include radius(2px);

  .rc-calendar {
    @include box-shadow(none);
    border-color: $color-neutral-11;

    .rc-calendar-header, .rc-calendar-month-panel-header, .rc-calendar-year-panel-header, .rc-calendar-decade-panel {
      background: $color-neutral-2;
      border-color: $color-neutral-11;
      height: 42px;
    }

    .rc-calendar-header {

      a, .rc-calendar-prev-month-btn, .rc-calendar-next-month-btn, .rc-calendar-prev-year-btn, .rc-calendar-next-year-btn {
        color: $color-neutral-100;
        text-decoration: none;
        line-height: 42px;
        font-size: 13px;
        font-weight: 400;
        @include radius(2px);

        &:hover {
          background: $color-brand-primary-light
        }
      }

      .rc-calendar-decade-panel-selected-cell, .rc-calendar-year-panel-selected-cell, .rc-calendar-month-panel-selected-cell {
        a {
          color: $color-neutral-0;
          border-color: $color-brand-primary-dark;
          background: $color-brand-primary-dark;
        }
      }

      > div:first-child, .rc-calendar-month-panel-header, .rc-calendar-year-panel-header, .rc-calendar-decade-panel-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .rc-calendar-month-panel, .rc-calendar-year-panel, .rc-calendar-decade-panel {
        top: 1px;
      }

      .rc-calendar-month-select,
      .rc-calendar-year-select,
      .rc-calendar-prev-month-btn,
      .rc-calendar-prev-year-btn,
      .rc-calendar-next-month-btn,
      .rc-calendar-next-year-btn,
      .rc-calendar-month-panel-prev-year-btn,
      .rc-calendar-month-panel-year-select,
      .rc-calendar-month-panel-next-year-btn,
      .rc-calendar-year-panel-prev-decade-btn,
      .rc-calendar-year-panel-decade-select,
      .rc-calendar-year-panel-next-decade-btn,
      .rc-calendar-decade-panel-prev-century-btn,
      .rc-calendar-decade-panel-century,
      .rc-calendar-decade-panel-next-century-btn {
        color: $color-brand-primary-dark;
        line-height: 26px;
        position: static;
      }

      .rc-calendar-prev-month-btn, .rc-calendar-prev-year-btn,
      .rc-calendar-next-month-btn, .rc-calendar-next-year-btn,
      .rc-calendar-month-panel-prev-year-btn,
      .rc-calendar-month-panel-next-year-btn,
      .rc-calendar-year-panel-prev-decade-btn,
      .rc-calendar-year-panel-next-decade-btn,
      .rc-calendar-decade-panel-prev-century-btn,
      .rc-calendar-decade-panel-next-century-btn {
        font-size: 18px;
        font-weight: 400;
      }

      .rc-calendar-my-select,
      .rc-calendar-month-panel-year-select,
      .rc-calendar-year-panel-decade-select,
      .rc-calendar-decade-panel-century {
        margin: 0 auto;
        font-size: 13px;
        font-weight: 500;
      }

      .rc-calendar-month-panel-body,
      .rc-calendar-year-panel-body,
      .rc-calendar-decade-panel-body {
        padding: 0;
        background: $color-neutral-0;
      }

      > div:first-child {
        height: 100%;

        .rc-calendar-prev-month-btn, .rc-calendar-prev-year-btn,
        .rc-calendar-next-month-btn, .rc-calendar-next-year-btn {
          width: 31px;
          height: 31px;
          font-size: 25px;
        }

        .rc-calendar-my-select {
          margin: 0 auto;
        }

        .rc-calendar-prev-month-btn {
          margin-right: auto;
        }

        .rc-calendar-next-month-btn {
          margin-left: auto;
        }
      }

      .rc-calendar-month-panel-year-select {
        width: auto;
      }

      .rc-calendar-year-panel-decade-select {
        width: auto;
      }

      .rc-calendar-decade-panel-header {
        height: 42px;
      }
    }

    .rc-calendar-body {
      padding: 0;

      .rc-calendar-table {
        thead tr {
          height: 35px;
          border-bottom: 1px solid $color-neutral-11;
          color: $color-neutral-60;
        }
      }
    }

    .rc-calendar-date, .rc-calendar-year-panel-year, .rc-calendar-month-panel-month, .rc-calendar-decade-panel-decade {
      color: $color-neutral-100;
    }

    .rc-calendar-today .rc-calendar-date {
      border-color: $color-neutral-27;
      @include radius(2px);
    }

    .rc-calendar-selected-date .rc-calendar-date {
      font-size: 13px;
      color: $color-neutral-0;
      border-color: $color-brand-primary-dark-darken-10;
      background: $color-brand-primary-dark-darken-10;
      @include radius(2px);
    }

    .rc-calendar-last-month-cell, .rc-calendar-next-month-btn-day {
      .rc-calendar-date {
        color: $color-neutral-16;
      }
    }

    .abs-filter-calendar-complex-date-start-text,
    .abs-filter-calendar-complex-date-end-text {
      width: 85px;
    }

    .rc-calendar-year-panel-table, .rc-calendar-decade-panel-table, .rc-calendar-month-panel-table {
      height: 239px;
    }
  }
}

.abs-custom-calendar {
  position: relative;
  cursor: pointer;

  &.invalid {
    background: $color-negative-light;
  }

  .abs-custom-calendar-input-field {
    width: 180px;
    padding-right: 22px;
  }

  .abs-icon {
    &.abs-calendar-clear-icon {
      position: absolute;
      color: $abs-quick-search-clear-input-icon;
      font-size: 14px;
      top: 20%;
      right: 12%;
      z-index: 10;
      &:hover {
        cursor: pointer;
      }
      &.open {
        right: 32%
      }
    }

    &.abs-calendar-trigger-icon {
      position: absolute;
      font-size: 18px;
      color: $color-neutral-60;
      top: 4px;
      right: 4px;
      z-index: 1;
    }
  }
}

.abs-custom-calendar-filter {
  display: inline-block;
  width: auto;
}

.calendar-dropdown.dropdown-menu .rc-calendar .rc-calendar-today .rc-calendar-date {
  background-color: $color-neutral-8;
  border-color: transparent;
  &:hover {
    background-color: #E7E7E7;
  }
}

.calendar-dropdown.dropdown-menu .rc-calendar .rc-calendar-selected-day .rc-calendar-date {
  color: #fff;
}

.calendar-dropdown.dropdown-menu .rc-calendar .rc-calendar-selected-day .rc-calendar-date {
  background: $color-brand-primary-dark;
  color: #fff;
}

.data-input-border{
  border: solid 1px $color-neutral-20;
  padding: 8px;
  text-align: initial;
  margin-left: 40px;
  width: 267px;
  .data-input-element{
    width: 105px;
  }
}

.data-input-field-section{
  margin-top: 0px !important;
}

.data-input-field{
  display: block !important;
  width: 105px !important;
  margin-top: 5px
}

.data-input-field-calendar{
  width: 105px !important;
}

.abs-filter-calendar-complex a.rc-calendar-month-panel-month {
  width: auto;
}