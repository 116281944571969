.abs-page-header {
  min-height: 40px;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  &-title {
    font-size: 16px;
    color: $abs-page-header-color;

    @media (max-width: 480px) {
      margin: 10px 0;
    }

    &__main {
      @include font-style($roboto-font-family, 500, normal);
    }

    &__subtitle {
      @include font-style($roboto-font-family, 400, normal);
    }

    &__slash {
      margin-left: 7px;
      margin-right: 1px;
    }
  }

  &__toolbar {

    display: flex;

    > .dropdown:not(:first-child), > .btn:not(:first-child) {
      margin-left: 5px;
    }
    .dropdown.abs-page-header__toolbar__dropdown .dropdown-menu {
      right: 0;
      left: unset;
      li a, li span.menuitem {
        padding: 0;
        .btn-type-a {
          font-size: 13px;
          font-weight: 400;
          padding: 6px 2px 6px 7px;
          color: $color-neutral-100;
          background-color: transparent;
          border-radius: 2px;
          border: none;
          width: 100%;
          height: 100%;
          &:hover {
            background-color: $color-brand-primary-light;
          }
        }
      }
    }

    .dropdown.abs-page-header__toolbar__dropdown .dropdown-menu {
      li span.menuitem li a {
        padding: 6px 7px 6px 7px;
      }
    }

    .dropdown-menu {
      min-width: 250px;
    }
  }

  .abs-dropdown .dropdown-menu li {
    padding: 0;
    .dropdown-link {
      padding: 6px 2px 6px 7px;
      display: flex;
      text-decoration: none;
      width: auto;
      &.active {
        color: $color-brand-primary-dark;
        background-color: $color-brand-primary-utility-1;

      }
    }
  }

}
