.abs-settings{
  width: 300px;
  .abs-card-container {
    background: $color-neutral-0;
    @include radius(0 0 2px 2px);
    height: 315px;
    box-sizing: border-box;
    .slick-list {
      height: 100%;
      box-sizing: border-box;
      .slick-track {
        height: 100%;
        .slick-slide {
          display: flex;
          padding: 15px;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
    .abs-card-content {
      padding-top: 5px;
      box-sizing: border-box;
      height: 275px;
      &:active {
        cursor: move;
      }

    }

    .abs-card-dots-position {
      bottom: -40px;
      margin: auto;
      right: 0;
      left: 0;
    }
  }
}

.abs-settings-templates-body{
  justify-content: flex-start !important;
  padding: 10px !important;
}

.abs-settings-templates-order-column{
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
}

.abs-settings-templates-order-title{
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
}

.abs-settings-header{
  text-align: center !important;
  vertical-align: middle !important;
  font-weight: 500;
  height: 42px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  padding: 3px;

  .abs-settings-header-title{
    font-size: 16px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .abs-settings-header-subtitle{
    font-size: 13px;
    color: #666666;
  }
}

.abs-settings-footer{
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(232, 232, 232, 1);
  justify-content: space-between;
  padding: 10px;
}

.abs-settings-table{
  line-height: 25px;
}

.abs-settings-button-left{
  display: flex;
  justify-content: flex-start;
}

.abs-settings-button-right{
  display: flex;
  justify-content: flex-end;
}

.abs-settings-column-right{
  text-align: left;
  padding-left: 7px;
  font-size: 13px;
  font-family: 'Roboto-Regular', 'Roboto';
}

.abs-settings-column-right-body{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 110px;
}

.abs-settings-column-left{
  text-align: right;
  padding-right: 7px;
  font-size: 13px;
  font-weight: 700;
  font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto';
}

.abs-settings-opacity{
  opacity: 0.5;
}

.abs-settings-format-fields{
  display: flex;
  flex-wrap: wrap;
}



  .abs-settings-format-fields-label {
    position: relative;
    &:before {
      border-color: #ccc;
      background: #fff;
      border-radius: 3px;
    }

  }

  input.abs-settings-format-fields-checkbox-content {
    display:none;
  }
  input.abs-settings-format-fields-checkbox-content + label {
    display:inline-block;
    width:16px;
    height:16px;
    margin:5px 0px 0px 5px;
    vertical-align:middle;
    cursor:pointer;
    border: 1px solid $input-border-color;
    border-radius: 3px;
  }
  input.abs-settings-format-fields-checkbox-content:checked + label {
    background-size: -16px top;
    background-repeat: no-repeat;
  }

  input.abs-settings-format-fields-checkbox-content + label:before {
    border-color: $input-border-color;
    border-radius: 3px;
  }

  input.abs-settings-format-fields-checkbox-content + label:after {
    border-color: $input-border-color;
    border-radius: 3px;
  }

  input.abs-settings-format-fields-checkbox-content + label:hover:before {
    border-color: $input-border-color-hover;
  }

  input.abs-settings-format-fields-checkbox-content + label:focus:before {
    border-color: $input-border-color-focus;
    @include box-shadow($input-box-shadow-focus)
  }

  input.abs-settings-format-fields-checkbox-content + label:active:before {
    border-color: $input-border-color-focus;
    @include box-shadow($input-box-shadow-focus);
    background: $input-bg-color-active;
  }

  input.abs-settings-format-fields-checkbox-content + label:after {
    color: $input-checkbox-color;
  }

  input.abs-settings-format-fields-checkbox-content + label:hover:after {
    border-color: $input-checkbox-border-color-active;
    color: $input-checkbox-color-active;
  }

  input.abs-settings-format-fields-checkbox-content:checked + label:before {
    background: $input-bg-color-active;
    border-color: $input-border-color-active;
  }

  input.abs-settings-format-fields-checkbox-content:checked + label:after {
    background: $input-bg-color-active;
    border-color: $input-checkbox-border-color-active;
    color: $input-checkbox-color-active;
    position: absolute;
    font-family: Lumen-Icon-Font;
    left: 0.5px;
    top: 0.5px;
    content: "\e5ca";
  }




