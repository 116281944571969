.abs-tab-listview
{
    width:400px;
    min-height: 100px;
    position: relative;

    &.frozen-only
        {
            .row
            {
                .frozen-columns
                {
                    cursor:auto;
                    width: 100%!important;

                    .column-style:first-child
                    {
                        width: 50%;
                        text-align: right;
                        margin-right: 5px;
                    }

                    .column-style:last-child
                    {
                         margin-left: 5px;
                    }
                }
            }   
        }

    .row
     {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;

            .frozen-columns
            {
                display: inline-block;
                float: left;
                padding-left: 8px;
                padding-right:10px;
                height: 22px;
                line-height: 22px;
                vertical-align: middle;
                cursor: pointer;
            }

             &.k-state-selected
            {
                    background:rgba(250, 250, 250, 1)!important;
                    color:black!important;

                    .frozen-columns
                    {        
                        border-left: 2px solid #EDEDED;
                        position: relative;
                        right: 2px;
                        z-index: 2;
                        background: rgba(250, 250, 250, 1) !important;
                        border-radius: 5px;
                        line-height: 20px;
                        height: 20px;
                        padding-left: 7px;
                    }

                    .full-height-columns
                    {
                        border-width: 1px;
                        border-color: #EDEDED;
                        border-style: solid;
                        border-radius: 5px;

                    }
                        
            }
 


             &:first-child 
             {
                .frozen-columns
                {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                .full-height-columns
                {
                    border-top-left-radius: 0px;
                }
             }

             &:nth-child(5)
             {
                 .frozen-columns
                {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                .full-height-columns
                {
                    border-bottom-left-radius: 0px;
                }
             }

    }

   

    .k-state-selected
    {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-width: 1px;
        border-color: #EDEDED;
        border-style: solid;
        border-right: 0px;
        border-radius: 5px;

         .full-height-columns 
         {
            display: flex;
            background:rgba(250, 250, 250, 1);
            color:black!important;
            position: absolute;
            top:0px;
            right: 0px;
            height: 100%;
            min-height: 100px;
            justify-content: center;
            align-content:center;
            flex-direction: column;
            align-items: center;

            > .horizontal-container
            {
                width: 100%;
            }

            .column-header 
            {
                font-weight: bold;
                width: 50%;
                text-align: right;
            }

             .column-value
            {
                width: auto;
            }
        }
    }

    .full-height-columns {
        display:none;
        box-sizing: border-box;
    }

    .frozen-columns 
    {
        div {
         display: inline-block;
         margin: 0px;
        }
    }
}

.popover
{
   .k-listview 
   {
       border: none;  
        box-shadow: none;
        -webkit-box-shadow: none;

        .row
        {
            margin-left:0px;
            margin-right: 0px;
        }

        .k-grid-norecords
        {
            background: #ffffff;
            height:100px;
            line-height: 100px;
            vertical-align: middle;
            color: #6f6f6f;
            border: none;

            &.k-state-selected
            {
                box-shadow: none;
                -webkit-box-shadow: none;
            }
        }
   }
}
